import axios from 'axios';
import SnackBarUtils from '@utils/SnackBarUtils';
import { errorLogger } from '@utils/ErrorLogging';

export let podName: string = '';
export const setPodName = (podname: string) => {
  podName = podname;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (history = null) => {
  const baseURL = '/rfpserver/';

  const guestAxiosInstance = axios.create({
    baseURL: baseURL
  });

  const checkVersions = function (response: any) {
    const responseHeaders = response.headers;
    if (responseHeaders['rfpio-version']) {
      const version = responseHeaders['rfpio-version'] ? JSON.stringify(responseHeaders['rfpio-version']) : '';
      const existingVersion = localStorage.getItem('version');
      if (existingVersion !== version) {
        localStorage.setItem('version', version);
        if (existingVersion !== '') {
          window.location.reload();
        }
      }
    }
  };

  const showErrorToast = (obj: { [k: string]: string | undefined }) => {
    const msg = `${obj.message} ${obj.additional_message || ''}`;
    SnackBarUtils.error(msg);
  };

  guestAxiosInstance.interceptors.request.use(function (config) {
    const user = localStorage.getItem('guestUserData');
    if (user) {
      const currentUser = JSON.parse(user);
      config.headers.authorization = 'Bearer ' + currentUser.accessKey;
      config.headers['guest-key'] = currentUser.guestKey;
      if (currentUser.issuerPodName) {
        config.headers['issuer-pod-name'] = currentUser.issuerPodName;
      }
    }
    if (podName) {
      config.headers['pod-name'] = podName;
    }
    return config;
  });

  guestAxiosInstance.interceptors.response.use(
    response =>
      new Promise((resolve, reject) => {
        if (
          response.config.url &&
          response.config.url.indexOf('guest/viewers') !== 0 &&
          response.config.url.indexOf('guest/update-time-tracker-for-guest') !== 0 &&
          response.config.url.indexOf('proactive-proposal/update-time-tracker') < 0
        ) {
          localStorage.setItem('LastResponseTime', new Date().getTime() + '');
        }
        // checkSessionTimeout(response);
        checkVersions(response);
        resolve(response);
      }),
    error => {
      if (!error.response) {
        return new Promise((resolve, reject) => {
          if (
            error.response &&
            error.response.config.url.indexOf('guest/viewers') !== 0 &&
            error.response.config.url.indexOf('guest/update-time-tracker-for-guest') !== 0 &&
            error.response.config.url.indexOf('proactive-proposal/update-time-tracker') < 0
          ) {
            localStorage.setItem('LastResponseTime', new Date().getTime() + '');
          }
          reject(error);
        });
      }
      checkVersions(error.response);
      if (error.response.status === 401) {
        // const baseUrl = window.location.hostname === 'localhost' ? '/app/' : '/';
        // const redirectTo = window.location.pathname + window.location.search;
        // window.location.href = window.location.origin + baseUrl + '?return_to_v2=' + redirectTo + '#/page/login';
        //console.log('401 Unauthorised');
        if (!error.response.config?.url?.includes('/validate-otp')) {
          localStorage.removeItem('guestUserData');
        }
        window?.location?.reload();
        localStorage.removeItem('guestUserData');
      } else if (error.response.status === 403) {
        if (error?.response?.config?.url?.indexOf('proactive-proposal') > -1) {
          window?.location?.reload();
          localStorage.removeItem('guestUserData');
        }
        console.log('403 Forbidden');
      } else if (error.response.status === 400) {
        console.log('400 Bad Request');
        if (error.response.data) {
          if (error.response.data.error_description && !window?.location?.href?.includes('proactive-proposal')) {
            showErrorToast({ message: error.response.data.error_description, additional_message: error.response.data.additional_message });
          }
          return new Promise((resolve, reject) => {
            resolve(error.response);
          });
        }
      } else if (error.response.status === 413) {
        console.log('413 (Request Entity Too Large)');
        if (error.response.statusText === 'Request Entity Too Large') {
          SnackBarUtils.error('File size exceeds.');
          return new Promise((resolve, reject) => {
            resolve(error.response);
          });
        }
      } else if (error.response.status === 440) {
        console.log('440 Handled Exception');
      } else if (error.response.status === 500) {
        console.log('500 Internal Server Error');
      } else if (error.response.status === 502 || error.response.status === 503 || error.response.status === 504) {
        SnackBarUtils.toast('An error occurred. Please try again later.');
      } else if (error.response.status === 404) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      } else {
        errorLogger(error.response.data, true);
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    }
  );

  return guestAxiosInstance;
};
