export const handleReplaceHTMLEntity = (str: string) => {
  const mapHTMLEntities: { [key: string]: string } = {
    '&lt;': '<',
    '&gt;': '>',
    'href=""': '',
    '&quot;': '"',
    '&amp;': '&'
  };
  str = str.replace(/(?:&lt;|&gt;|&quot;|&amp;|href="")/g, matched => mapHTMLEntities[matched]);
  return str;
};

export const PROJECT_ENTITY = 'projectEntity';
export const SECTION_ID = 'sectionId';
export const DESCRIPTION = 'description';
export const PERFORMED_BY = 'performedBy';
let isQuestionLinkClicked = false;

export function goToQuestion(data: any, questionMap: any) {
  isQuestionLinkClicked = true;
  const url = window.location.hostname === 'localhost' ? '/app/#' : '/#';
  if (!questionMap[data.questionId] || data.type.includes('QUESTION_DELETED') || !data.questionId) {
    return '';
  }
  if (data.type.includes('CLARIFICATION')) {
    return `${url}/respond/view/${questionMap[data.questionId].sectionId}?tab=clarifications&q=${data.questionId}&companyId=${questionMap[data.questionId].companyId}`;
  } else {
    return `${url}/respond/section/${questionMap[data.questionId].sectionId}?companyId=${questionMap[data.questionId].companyId}&q=${data.questionId}`;
  }
}
