import MergeTagVO from '@interfaces/Models/MergeTagVO';
import ProjectVO from '@interfaces/Models/ProjectVO';
import AnswerVO from '@interfaces/Models/AnswerVO';
import store from '../store';
import { getShortDate } from './DayjsUtils';
import { isFeatureEnabled } from '@utils/FeaturePreference';
import { getMentionName, getUser } from './UserLoadDetailUtil';
import { getTinymce } from '../vendors/tinymce/TinyMCE';
import $ from 'jquery';
import { getSystemDefinedCustomFieldMap, getSystemDefinedCustomFieldMapByEntityType } from './CustomFieldUtils';
import { addMergeTag } from '@containers/project/MergeTagService';
import cloneDeep from 'clone-deep';
import UserVO from '@interfaces/Models/UserVO';
import axiosInstance from '@helpers/Axios';
import { trackCustomEvent } from '@utils/analyticsUtil';

export interface MergeTagObjectProps {
  projectMergeTags: {
    [key: string]: any;
  };
  customFields: any;
  mergeTags: any[];
  systemDefinedCustomFieldsMap: any;
  usersMap: {
    [key: string]: UserVO;
  };
  advSearchMergeTagsList: string[];
  usedmergeTagsMap: any;
}

export const systemDefinedMergeTags = (isRfpioLite: boolean) => {
  const mergeTagList: MergeTagVO[] = [
    {
      name: 'projectname',
      displayName: 'ProjectName',
      defaultDisplayName: 'Project Name',
      groupName: 'Basic Details',
      type: 'TEXT_BOX',
      mergeTagName: '[projectName]',
      crmAssociate: true,
      modelName: 'name',
      companyId: '',
      createdBy: '',
      createTs: '',
      id: '',
      lastUpdateTs: '',
      projectId: '',
      tag: '',
      translatedMergetagText: {},
      updatedBy: '',
      value: '',
      values: [],
      internal: false
    },
    {
      name: 'projecttype',
      displayName: 'ProjectType',
      defaultDisplayName: 'Project Type',
      groupName: 'Basic Details',
      mergeTagName: '[ProjectType]',
      type: 'TEXT_BOX',
      crmAssociate: true,
      modelName: 'rfpType',
      companyId: '',
      createdBy: '',
      createTs: '',
      id: '',
      lastUpdateTs: '',
      projectId: '',
      tag: '',
      translatedMergetagText: {},
      updatedBy: '',
      value: '',
      internal: false,
      values: []
    },
    {
      name: 'projectstage',
      displayName: 'Project Stage',
      defaultDisplayName: 'Project Stage',
      groupName: 'Basic Details',
      type: 'TEXT_BOX',
      crmAssociate: true,
      modelName: 'projectStage',
      companyId: '',
      createdBy: '',
      createTs: '',
      id: '',
      lastUpdateTs: '',
      projectId: '',
      tag: '',
      translatedMergetagText: {},
      updatedBy: '',
      value: '',
      mergeTagName: '',
      internal: false,
      values: []
    },
    {
      name: 'projectdescription',
      displayName: 'ProjectDescription',
      defaultDisplayName: 'Description',
      groupName: 'Basic Details',
      type: 'TEXT_AREA',
      mergeTagName: '[ProjectDescription]',
      crmAssociate: true,
      modelName: 'description',
      companyId: '',
      createdBy: '',
      createTs: '',
      id: '',
      lastUpdateTs: '',
      projectId: '',
      tag: '',
      translatedMergetagText: {},
      updatedBy: '',
      value: '',
      internal: false,
      values: []
    },
    {
      name: 'projectduedate',
      displayName: 'ProjectDueDate',
      defaultDisplayName: 'Due date',
      groupName: 'Basic Details',
      mergeTagName: '[ProjectDueDate]',
      type: 'DATE',
      crmAssociate: true,
      modelName: 'dueDate',
      companyId: '',
      createdBy: '',
      createTs: '',
      id: '',
      lastUpdateTs: '',
      projectId: '',
      tag: '',
      translatedMergetagText: {},
      updatedBy: '',
      value: '',
      internal: false,
      values: []
    },
    {
      name: 'projectvalue',
      displayName: 'ProjectValue',
      defaultDisplayName: 'Project Value',
      groupName: 'Basic Details',
      mergeTagName: '[ProjectValue]',
      type: 'TEXT_BOX',
      crmAssociate: true,
      modelName: 'dealSize',
      companyId: '',
      createdBy: '',
      createTs: '',
      id: '',
      lastUpdateTs: '',
      projectId: '',
      tag: '',
      translatedMergetagText: {},
      updatedBy: '',
      value: '',
      internal: false,
      values: []
    },
    {
      name: 'projectcategory',
      displayName: 'ProjectCategory',
      defaultDisplayName: 'Category',
      groupName: 'Basic Details',
      mergeTagName: '[ProjectCategory]',
      type: 'CHECKBOX',
      crmAssociate: true,
      values: [],
      modelName: 'category',
      companyId: '',
      createdBy: '',
      createTs: '',
      id: '',
      lastUpdateTs: '',
      projectId: '',
      tag: '',
      translatedMergetagText: {},
      updatedBy: '',
      value: '',
      internal: false
    },
    {
      name: 'clientname',
      displayName: 'ClientName',
      defaultDisplayName: 'Client Name',
      groupName: 'Client Details',
      mergeTagName: '[ClientName]',
      type: 'TEXT_BOX',
      crmAssociate: true,
      modelName: 'issuerName',
      companyId: '',
      createdBy: '',
      createTs: '',
      id: '',
      lastUpdateTs: '',
      projectId: '',
      tag: '',
      translatedMergetagText: {},
      updatedBy: '',
      value: '',
      internal: false,
      values: []
    },
    {
      name: 'clientaddress1',
      displayName: 'ClientAddress1',
      defaultDisplayName: 'Client Address 1',
      groupName: 'Client Details',
      mergeTagName: '[ClientAddress1]',
      type: 'TEXT_BOX',
      crmAssociate: true,
      modelName: 'issuerAddress1',
      companyId: '',
      createdBy: '',
      createTs: '',
      id: '',
      lastUpdateTs: '',
      projectId: '',
      tag: '',
      translatedMergetagText: {},
      updatedBy: '',
      value: '',
      internal: false,
      values: []
    },
    {
      name: 'clientaddress2',
      displayName: 'ClientAddress2',
      defaultDisplayName: 'Client Address 2',
      groupName: 'Client Details',
      mergeTagName: '[ClientAddress2]',
      type: 'TEXT_BOX',
      crmAssociate: true,
      modelName: 'issuerAddress2',
      companyId: '',
      createdBy: '',
      createTs: '',
      id: '',
      lastUpdateTs: '',
      projectId: '',
      tag: '',
      translatedMergetagText: {},
      updatedBy: '',
      value: '',
      internal: false,
      values: []
    },
    {
      name: 'clientemail',
      displayName: 'ClientEmail',
      defaultDisplayName: 'Client Email',
      groupName: 'Client Details',
      mergeTagName: '[ClientEmail]',
      type: 'TEXT_BOX',
      crmAssociate: true,
      modelName: 'issuerEmail',
      companyId: '',
      createdBy: '',
      createTs: '',
      id: '',
      lastUpdateTs: '',
      projectId: '',
      tag: '',
      translatedMergetagText: {},
      updatedBy: '',
      value: '',
      internal: false,
      values: []
    },
    {
      name: 'clientphone',
      displayName: 'ClientPhone',
      defaultDisplayName: 'Client Phone No.',
      groupName: 'Client Details',
      mergeTagName: '[ClientPhone]',
      type: 'TEXT_BOX',
      crmAssociate: true,
      modelName: 'issuerPhone',
      companyId: '',
      createdBy: '',
      createTs: '',
      id: '',
      lastUpdateTs: '',
      projectId: '',
      tag: '',
      translatedMergetagText: {},
      updatedBy: '',
      value: '',
      internal: false,
      values: []
    },
    {
      name: 'contactname',
      displayName: 'ContactName',
      defaultDisplayName: 'Contact Name',
      groupName: 'Basic Details',
      mergeTagName: '[ContactName]',
      type: 'TEXT_BOX',
      companyId: '',
      createdBy: '',
      createTs: '',
      id: '',
      lastUpdateTs: '',
      projectId: '',
      tag: '',
      translatedMergetagText: {},
      updatedBy: '',
      value: '',
      internal: false,
      modelName: '',
      crmAssociate: false,
      values: []
    },
    {
      name: 'contactemail',
      displayName: 'ContactEmail',
      defaultDisplayName: 'Project Primary Contact',
      groupName: 'Basic Details',
      mergeTagName: '[ContactEmail]',
      type: 'TEXT_BOX',
      modelName: 'ownedBy',
      companyId: '',
      createdBy: '',
      createTs: '',
      id: '',
      lastUpdateTs: '',
      projectId: '',
      tag: '',
      translatedMergetagText: {},
      updatedBy: '',
      value: '',
      internal: false,
      crmAssociate: false,
      values: []
    },
    {
      name: 'contactemail#firstname',
      displayName: 'ContactEmail#firstName',
      defaultDisplayName: '',
      groupName: '',
      type: 'TEXT_BOX',
      modelName: '',
      mergeTagName: '[ContactEmail#firstName]',
      internal: true,
      companyId: '',
      createdBy: '',
      createTs: '',
      id: '',
      lastUpdateTs: '',
      projectId: '',
      tag: '',
      translatedMergetagText: {},
      updatedBy: '',
      value: '',
      crmAssociate: false,
      values: []
    },
    {
      name: 'contactemail#lastname',
      displayName: 'ContactEmail#lastName',
      mergeTagName: '[ContactEmail#lastName]',
      internal: true,
      companyId: '',
      createdBy: '',
      createTs: '',
      id: '',
      lastUpdateTs: '',
      projectId: '',
      tag: '',
      translatedMergetagText: {},
      updatedBy: '',
      value: '',
      groupName: '',
      type: '',
      modelName: '',
      crmAssociate: false,
      values: [],
      defaultDisplayName: ''
    },
    {
      name: 'contactemail#language',
      displayName: 'ContactEmail#language',
      mergeTagName: '[ContactEmail#language]',
      internal: true,
      companyId: '',
      createdBy: '',
      createTs: '',
      id: '',
      lastUpdateTs: '',
      projectId: '',
      tag: '',
      translatedMergetagText: {},
      updatedBy: '',
      value: '',
      groupName: '',
      type: '',
      modelName: '',
      crmAssociate: false,
      values: [],
      defaultDisplayName: ''
    },
    {
      name: 'contactemail#jobtitle',
      displayName: 'ContactEmail#jobTitle',
      mergeTagName: '[ContactEmail#jobTitle]',
      internal: true,
      companyId: '',
      createdBy: '',
      createTs: '',
      id: '',
      lastUpdateTs: '',
      projectId: '',
      tag: '',
      translatedMergetagText: {},
      updatedBy: '',
      value: '',
      groupName: '',
      type: '',
      modelName: '',
      crmAssociate: false,
      values: [],
      defaultDisplayName: ''
    },
    {
      name: 'contactemail#phonenumber',
      displayName: 'ContactEmail#phoneNumber',
      mergeTagName: '[ContactEmail#phoneNumber]',
      internal: true,
      companyId: '',
      createdBy: '',
      createTs: '',
      id: '',
      lastUpdateTs: '',
      projectId: '',
      tag: '',
      translatedMergetagText: {},
      updatedBy: '',
      value: '',
      groupName: '',
      type: '',
      modelName: '',
      crmAssociate: false,
      values: [],
      defaultDisplayName: ''
    },
    {
      name: 'contactemail#location',
      displayName: 'ContactEmail#location',
      mergeTagName: '[ContactEmail#location]',
      internal: true,
      companyId: '',
      createdBy: '',
      createTs: '',
      id: '',
      lastUpdateTs: '',
      projectId: '',
      tag: '',
      translatedMergetagText: {},
      updatedBy: '',
      value: '',
      groupName: '',
      type: '',
      modelName: '',
      crmAssociate: false,
      values: [],
      defaultDisplayName: ''
    },
    {
      name: 'additionalprimarycontacts',
      displayName: 'AdditionalPrimaryContacts',
      defaultDisplayName: 'Additional Primary Contact',
      groupName: 'Basic Details',
      mergeTagName: '[AdditionalPrimaryContacts]',
      type: 'TEXT_BOX',
      values: [],
      modelName: 'additionalContacts',
      companyId: '',
      createdBy: '',
      createTs: '',
      id: '',
      lastUpdateTs: '',
      projectId: '',
      tag: '',
      translatedMergetagText: {},
      updatedBy: '',
      value: '',
      crmAssociate: false,
      internal: false
    },
    {
      name: 'projectcreatedbyname',
      displayName: 'ProjectCreatedbyName',
      groupName: 'Project Created by Name',
      mergeTagName: '[ProjectCreatedbyName]',
      type: 'TEXT_BOX',
      companyId: '',
      createdBy: '',
      createTs: '',
      id: '',
      lastUpdateTs: '',
      projectId: '',
      tag: '',
      translatedMergetagText: {},
      updatedBy: '',
      value: '',
      modelName: '',
      crmAssociate: false,
      values: [],
      defaultDisplayName: '',
      internal: false
    },
    {
      name: 'businessunits',
      displayName: 'Business Units',
      defaultDisplayName: 'Business Units',
      groupName: 'Basic Details',
      mergeTagName: '',
      type: 'DROPDOWN',
      companyId: '',
      createdBy: '',
      createTs: '',
      id: '',
      lastUpdateTs: '',
      projectId: '',
      tag: '',
      translatedMergetagText: {},
      updatedBy: '',
      value: '',
      modelName: '',
      crmAssociate: false,
      values: [],
      internal: false
    },
    {
      name: 'projectcreatedbyemail',
      displayName: 'ProjectCreatedbyEmail',
      groupName: 'Project Created by Email',
      mergeTagName: '[ProjectCreatedbyEmail]',
      type: 'TEXT_BOX',
      companyId: '',
      createdBy: '',
      createTs: '',
      id: '',
      lastUpdateTs: '',
      projectId: '',
      tag: '',
      translatedMergetagText: {},
      updatedBy: '',
      value: '',
      modelName: '',
      crmAssociate: false,
      values: [],
      defaultDisplayName: '',
      internal: false
    },
    {
      name: 'projectcreatedbyemail#firstname',
      displayName: 'ProjectCreatedbyEmail#firstName',
      mergeTagName: '[ProjectCreatedbyEmail#firstName]',
      internal: true,
      companyId: '',
      createdBy: '',
      createTs: '',
      id: '',
      lastUpdateTs: '',
      projectId: '',
      tag: '',
      translatedMergetagText: {},
      updatedBy: '',
      value: '',
      groupName: '',
      type: '',
      modelName: '',
      crmAssociate: false,
      values: [],
      defaultDisplayName: ''
    },
    {
      name: 'projectcreatedbyemail#lastname',
      displayName: 'ProjectCreatedbyEmail#lastName',
      mergeTagName: '[ProjectCreatedbyEmail#lastName]',
      internal: true,
      companyId: '',
      createdBy: '',
      createTs: '',
      id: '',
      lastUpdateTs: '',
      projectId: '',
      tag: '',
      translatedMergetagText: {},
      updatedBy: '',
      value: '',
      groupName: '',
      type: '',
      modelName: '',
      crmAssociate: false,
      values: [],
      defaultDisplayName: ''
    },
    {
      name: 'projectcreatedbyemail#language',
      displayName: 'ProjectCreatedbyEmail#language',
      mergeTagName: '[ProjectCreatedbyEmail#language]',
      internal: true,
      companyId: '',
      createdBy: '',
      createTs: '',
      id: '',
      lastUpdateTs: '',
      projectId: '',
      tag: '',
      translatedMergetagText: {},
      updatedBy: '',
      value: '',
      groupName: '',
      type: '',
      modelName: '',
      crmAssociate: false,
      values: [],
      defaultDisplayName: ''
    },
    {
      name: 'projectcreatedbyemail#jobtitle',
      displayName: 'ProjectCreatedbyEmail#jobTitle',
      mergeTagName: '[ProjectCreatedbyEmail#jobTitle]',
      internal: true,
      companyId: '',
      createdBy: '',
      createTs: '',
      id: '',
      lastUpdateTs: '',
      projectId: '',
      tag: '',
      translatedMergetagText: {},
      updatedBy: '',
      value: '',
      groupName: '',
      type: '',
      modelName: '',
      crmAssociate: false,
      values: [],
      defaultDisplayName: ''
    },
    {
      name: 'projectcreatedbyemail#phonenumber',
      displayName: 'ProjectCreatedbyEmail#phoneNumber',
      mergeTagName: '[ProjectCreatedbyEmail#phoneNumber]',
      internal: true,
      companyId: '',
      createdBy: '',
      createTs: '',
      id: '',
      lastUpdateTs: '',
      projectId: '',
      tag: '',
      translatedMergetagText: {},
      updatedBy: '',
      value: '',
      groupName: '',
      type: '',
      modelName: '',
      crmAssociate: false,
      values: [],
      defaultDisplayName: ''
    },
    {
      name: 'projectcreatedbyemail#location',
      displayName: 'ProjectCreatedbyEmail#location',
      mergeTagName: '[projectcreatedbyemail#location]',
      internal: true,
      companyId: '',
      createdBy: '',
      createTs: '',
      id: '',
      lastUpdateTs: '',
      projectId: '',
      tag: '',
      translatedMergetagText: {},
      updatedBy: '',
      value: '',
      groupName: '',
      type: '',
      modelName: '',
      crmAssociate: false,
      values: [],
      defaultDisplayName: ''
    }
  ];
  if (isRfpioLite) {
    const liteCustomFieldsRemove = ['clientaddress1', 'clientaddress2', 'clientphone', 'additionalprimarycontacts', 'projectcategory', 'projectdescription'];
    for (let i = 0; i < mergeTagList.length; i++) {
      if (liteCustomFieldsRemove.indexOf(mergeTagList[i].name) > -1) {
        mergeTagList.splice(i, 1);
        i--;
      }
    }
  }
  return mergeTagList;
};

/*RES-95823
Below custom Fields has disable options in org level, this list is used to skip those disabled fields from showing in UI.
*/
export const mergeTagsWithDisable: string[] = ['clientemail', 'clientphone', 'clientaddress2', 'clientaddress1'];

const defaultProjectMergeTagListMaker = () => {
  const systemDefinedCustomFieldsMap: { [key: string]: any } = store.getState().customFieldDetails.systemDefinedCustomFieldsMap;
  const originalList: string[] = [];
  const systemDefinedMergeTagsList = systemDefinedMergeTags(isFeatureEnabled('rfpio_lite'));
  systemDefinedMergeTagsList.forEach(eachTag => {
    if (
      !['projectstage', 'businessunits'].includes(eachTag.name) &&
      (!mergeTagsWithDisable.includes(eachTag.name) || (systemDefinedCustomFieldsMap && !systemDefinedCustomFieldsMap[eachTag.name]?.enabled))
    ) {
      originalList.push(eachTag.displayName);
    }
  });
  return [...originalList];
};

export const listProjectMergeTagsForAutocomplete = (
  mergeTags: MergeTagVO[],
  catalogMergeTags: string[],
  viewType?: any,
  advSearchMergeTagsList?: any,
  proposalCustomFields?: string[],
  nameListNeeded?: boolean
) => {
  var data: string[] = [];
  if (viewType !== 'REQUEST') {
    const mergeTagList = defaultProjectMergeTagListMaker();
    mergeTagList.forEach(tag => {
      var newTag = '[' + tag + ']';
      var filterTemp = data.filter(checkFilterFor(newTag));
      if (!filterTemp || filterTemp.length === 0) {
        data.push(newTag);
      }
    });

    if (Array.isArray(mergeTags)) {
      mergeTags.forEach(tag => {
        var temp = data.filter(checkFilterFor(tag.tag));
        if (!temp || temp.length === 0) {
          data.push(tag.tag);
        }
      });
    }
  }
  if (proposalCustomFields && proposalCustomFields.length >= 1) {
    for (const key of proposalCustomFields) {
      const bracketedKey = `[${key}]`;
      const index = data.findIndex(tag => bracketedKey.toLowerCase() === tag.toLowerCase());
      if (index <= -1) {
        data.push(bracketedKey);
      }
    }
  }

  if (Array.isArray(advSearchMergeTagsList) && advSearchMergeTagsList.length > 0) {
    advSearchMergeTagsList.forEach(tag => {
      const temp = data.filter(checkFilterFor(tag));
      if (!temp || temp.length === 0) {
        data.push(tag);
      }
    });
  }

  let customFields;
  if (viewType) {
    customFields = getSystemDefinedCustomFieldMapByEntityType(viewType);
  } else {
    customFields = getSystemDefinedCustomFieldMap();
  }
  const userSelectOptions = ['firstName', 'lastName', 'language', 'jobTitle', 'phoneNumber', 'location'];
  if (customFields && Array.isArray(Object.values(customFields))) {
    Object.values(customFields).forEach((tag: any) => {
      if (!['projectstage', 'businessunits'].includes(tag.name) && (!mergeTagsWithDisable.includes(tag.name) || !tag?.enabled)) {
        var newTag = '[' + tag.name + ']';
        var temp = data.filter(checkFilterFor(newTag));
        if (!temp || temp.length === 0) {
          data.push(newTag);
        }
        if (tag.type === 'SELECT_USER' && tag.name !== 'additionalprimarycontacts' && viewType !== 'REQUEST') {
          userSelectOptions.forEach(val => {
            var temp = '[' + tag.name + '#' + val + ']';
            var tempTag = data.filter(checkFilterFor(temp));
            if (!tempTag || tempTag.length === 0) {
              data.push(temp);
            }
          });
        }
      }
    });
  }
  catalogMergeTags?.forEach((tag: any) => {
    data.push(tag);
  });
  var newData = data.sort();

  var returnData: { name: string }[] = [];
  newData.forEach(tag => {
    returnData.push({
      name: tag
    });
  });

  if (nameListNeeded) {
    return [returnData, newData];
  }

  return [returnData];
};

const checkFilterFor = (query: string) => {
  var lowercaseQuery = query.toLowerCase();
  return function filterFn(tag: string) {
    return tag.toLowerCase().indexOf(lowercaseQuery) >= 0;
  };
};

const setMergetagValue = (project: ProjectVO, viewType?: any) => {
  var data: { [key: string]: any | number } = {};
  if (viewType === 'REQUEST') {
    data.requestname = project.name;
    data.requeststage = project.projectStage?.stage;
    data.requestowners = project?.projectOwners;
    data.requesttype = project.rfpType;
    data.requestprojectname = project.customFields && Object.keys(project.customFields).length && project.customFields['requestprojectname'];
    data.responseduedate = project.customFields && Object.keys(project.customFields).length && project.customFields['responseduedate'];
    data.requestduedate = project.dueDate;
  } else {
    data.projectname = project.name;
    data.projecttype = project.rfpType;
    data.projectdescription = project.description;
    if (project.dueDate) {
      data.projectduedate = getShortDate(project.dueDate);
    }
    if (project.dealSize && project.dealSize > 0) {
      data.projectvalue = project.dealSize;
    }
    if (Array.isArray(project.category)) {
      data.projectcategory = project.category.toString();
    }
    var contact = getUser(project.ownedBy);
    data.contactname = getMentionName(project.ownedBy, project.ownedBy) + '';
    data.contactemail = project.ownedBy;
    if (contact) {
      data['contactemail#firstname'] = contact.firstName;
      data['contactemail#lastname'] = contact.lastName;
      data['contactemail#language'] = contact.language;
      data['contactemail#jobtitle'] = contact.jobTitle;
      data['contactemail#phonenumber'] = contact.phoneNumber;
      data['contactemail#location'] = contact.location;
    }
    data.projectcreatedbyemail = project.createdBy;
    data.projectcreatedbyname = getMentionName(project.createdBy, project.createdBy) + '';
    var created = getUser(project.createdBy);
    if (created) {
      data['projectcreatedbyemail#firstname'] = created.firstName;
      data['projectcreatedbyemail#lastname'] = created.lastName;
      data['projectcreatedbyemail#language'] = created.language;
      data['projectcreatedbyemail#jobtitle'] = created.jobTitle;
      data['projectcreatedbyemail#phonenumber'] = created.phoneNumber;
      data['projectcreatedbyemail#location'] = created.location;
    }
    if (project.additionalContacts) {
      data['additionalprimarycontacts'] = project.additionalContacts.join();
    }
  }
  data.clientemail = project.issuerEmail;
  data.clientname = project.issuerName;
  data.clientaddress1 = project.issuerAddress1;
  data.clientaddress2 = project.issuerAddress2;
  data.clientphone = project.issuerPhone;

  return data;
};
export { setMergetagValue };

export const updateMergeTagObjectForPopover = (
  project: ProjectVO,
  userDefinedMergeTagList: any[],
  systemDefinedMergeTagList: any[],
  viewType?: any,
  process?: boolean,
  emptyCustomFieldsKeysArray?: any[]
) => {
  const projectMergeTags = setMergetagValue(project, viewType);
  const activeMergeTagMap = store.getState().contentMasterData.activeMergeTagMap;
  const usedMergeTagMap = store.getState().contentMasterData.usedMergeTagMap;
  const mergeTagArray: string[] = [];
  Object.values(activeMergeTagMap).forEach(entry => {
    mergeTagArray.push(entry.key);
  });
  const customFields = store.getState().customFieldDetails.customFields;
  const companyUserMap = store.getState().userData.companyUserMap;
  const systemDefinedCustomFieldsMap: any = {};
  const usedmergeTagsMap: any = {};
  const advSearchMergeTagsList: string[] = [];
  systemDefinedMergeTagList.forEach((eachField: any, index: number) => {
    systemDefinedCustomFieldsMap[eachField.name] = { ...eachField };
  });
  mergeTagArray?.length > 0 &&
    mergeTagArray.forEach((tag: string) => {
      if (tag) {
        advSearchMergeTagsList.push('[c-' + tag + ']');
      }
    });

  const lableTypeFields: { [key: string]: string } = {};
  customFields.companyCustomFieldList.forEach((c: any) => {
    if (c.type === 'LABEL') {
      lableTypeFields[c.name] = c.values.toString();
    }
  });

  if (usedMergeTagMap && Object.keys(usedMergeTagMap)?.length > 0) {
    Object.keys(usedMergeTagMap).forEach((mergeTag: string) => {
      usedmergeTagsMap[mergeTag] = usedMergeTagMap[mergeTag];
    });
  }
  const mergeTagObject = {
    projectMergeTags: projectMergeTags,
    customFields: { ...project.customFields, ...lableTypeFields },
    mergeTags: userDefinedMergeTagList,
    systemDefinedCustomFieldsMap: { ...systemDefinedCustomFieldsMap },
    usersMap: companyUserMap,
    advSearchMergeTagsList: advSearchMergeTagsList,
    usedmergeTagsMap: { ...usedmergeTagsMap },
    proposalCustomFields: emptyCustomFieldsKeysArray
  };
  return mergeTagObject;
};

export const handleRemoveMergeTags = () => {
  const currentModule = store.getState().additionalInfoStore.additionalInfo?.currentModule?.menuModule;
  let tinymceActiveEditor = getTinymce().activeEditor;
  const tagid = $('#merge_tag_tooltip_text>span, #merge_tag_tooltip_text>div').attr('tagid');
  let mergeTag = $('span[tag-id="' + tagid + '"]');
  if (currentModule === 'PROACTIVE_PROPOSAL_BUILDER') {
    if (mergeTag?.length === 0) {
      mergeTag = $('[tag-id="' + tagid + '"]');
    }
    const tinyId = mergeTag.closest('.mce-content-body').attr('id');
    tinymceActiveEditor = getTinymce().get(tinyId);
  }
  if (mergeTag[0]?.tagName !== 'SPAN' || !mergeTag[0]) {
    mergeTag = $('div[tag-id="' + tagid + '"]');
  }

  if (mergeTag.attr('merge-tag')?.startsWith('c-')) {
    /* removing all the attributes in the parent for CONTENT REFERENCE TAG ,
    since the binding events for the child merge tags should be retained */
    while (mergeTag[0].attributes.length > 0) {
      mergeTag[0].removeAttribute(mergeTag[0].attributes[0].name);
    }

    // removing .child-merge-tag class from all the child merge tags to show the Merge Actions
    mergeTag[0].querySelectorAll('.child-merge-tag').forEach((child: Element) => {
      child.classList.remove('child-merge-tag');
    });

    if (tinymceActiveEditor && tinymceActiveEditor.selection?.getContent() && currentModule !== 'PROACTIVE_PROPOSAL_BUILDER') {
      tinymceActiveEditor.selection.collapse();
    }
  } else {
    if (mergeTag.parent().hasClass('rfpio-autocomplete')) {
      mergeTag.parent().replaceWith(mergeTag.text() + `<span id="cursor" unlinked-merge-tag = '${tagid}'></span>`);
    } else {
      mergeTag.replaceWith(mergeTag.text() + `<span id="cursor" unlinked-merge-tag = '${tagid}'></span>`);
    }
  }
  if (tinymceActiveEditor) {
    tinymceActiveEditor.insertContent('');
    tinymceActiveEditor.selection.select($('#cursor').get(0));
  }
  $('#cursor')?.remove();
  const projectProperty = localStorage.getItem('lastAccessedData');
  const parsedData = projectProperty ? JSON.parse(projectProperty) : {};
  const isProjectModule = currentModule === 'PROJECT';

  trackCustomEvent({
    eventName: 'Merge Tag - Unlink',
    eventProperties: {
      module: currentModule,
      projectId: isProjectModule ? parsedData?.projectId : '',
      sectionId: isProjectModule ? parsedData?.sectionId : '',
      questionId: isProjectModule ? parsedData?.questionId : '',
      source: 'Unlink Merge Tags'
    }
  });
};

const getLowerCaseProjectMergeTags = (mergeTagList: { [key: string]: any }) => {
  const lowerCase: string[] = [];
  for (const key in mergeTagList) {
    lowerCase.push(key);
  }
  return lowerCase;
};

const isExistingMergeTag = (projectMergeTags: { [key: string]: any }, systemDefinedMergeTagList: string[], userDefinedMergeTags: MergeTagVO[], tempTag: string) => {
  const projectTags = getLowerCaseProjectMergeTags(projectMergeTags);
  const processedMergeTagName = tempTag?.replace(/[[\]]/g, '');
  if (projectTags.indexOf(processedMergeTagName.toLowerCase()) >= 0) {
    return true;
  }
  for (let i = 0, len = userDefinedMergeTags.length; i < len; i++) {
    if (tempTag.toLowerCase() === userDefinedMergeTags[i].tag.toLowerCase()) {
      return true;
    }
  }
  if (Array.isArray(systemDefinedMergeTagList)) {
    for (let i = 0, len = systemDefinedMergeTagList.length; i < len; i++) {
      if (processedMergeTagName.toLowerCase() === systemDefinedMergeTagList[i].toLowerCase()) {
        return true;
      }
    }
  }
  return false;
};

export const saveMergeTagForTextFields = (
  answerList: string | AnswerVO[],
  contentType: string,
  mergeTagObject: MergeTagObjectProps,
  systemDefinedMergeTagList: string[],
  setUserDefinedMergeTags: any,
  currentProjectId: string
) => {
  const contentToBeChecked: any[] = [];
  const tempUserDefinedMergeTags = cloneDeep(mergeTagObject.mergeTags);
  if (contentType === 'QUESTION' && typeof answerList !== 'string') {
    for (let itr = 0, len = answerList.length; itr < len; itr++) {
      const ans = answerList[itr];
      if (['RICH_TEXT', 'BASIC_RICH_TEXT'].includes(ans.answerType) && ans !== null) {
        contentToBeChecked.push(ans?.answer);
      }
    }
  } else {
    contentToBeChecked.push(answerList);
  }

  contentToBeChecked?.forEach(content => {
    saveMergeTag(mergeTagObject, systemDefinedMergeTagList, tempUserDefinedMergeTags, setUserDefinedMergeTags, currentProjectId, content);
  });
};

export const saveMergeTag = (
  mergeTagObject: MergeTagObjectProps,
  systemDefinedMergeTagList: string[],
  tempUserDefinedMergeTags: MergeTagVO[],
  setUserDefinedMergeTags: any,
  currentProjectId: string,
  savedTag: string
) => {
  const regexp = /\[[a-zA-Z0-9_]{1,30}\]/gi;
  const matchesArray: string[] | null = savedTag?.match(regexp);
  let tagInMemory: { value: string; tag: string } | null = null;
  if (matchesArray && matchesArray?.length > 0) {
    matchesArray.forEach(machedTag => {
      const isSame = isExistingMergeTag(mergeTagObject.projectMergeTags, systemDefinedMergeTagList, tempUserDefinedMergeTags, machedTag);
      if (!isSame) {
        tagInMemory = { value: machedTag, tag: machedTag };
        tempUserDefinedMergeTags.push(tagInMemory as any);
        const url = '/projects/respond/add-merge-tag';
        const obj = {
          projectId: currentProjectId,
          tag: tagInMemory.tag,
          value: tagInMemory.value
        };
        addMergeTag(url, obj, mergeTagObject.mergeTags, setUserDefinedMergeTags);
      }
    });
  }
};

export const processFeeTable = (id: string, unlinkCallBack?: () => void, availableMergeTags?: string[], proposalMergeTagMap?: Record<string, string>, element?: Element | Document) => {
  const content = element ? element : document.querySelector(`[id="${id}"]`);
  if (!content) {
    return;
  }
  const tables = content.getElementsByClassName('fee-table');
  if (!tables) {
    return;
  }
  for (let i = 0; i < tables.length; i++) {
    const table = tables[i];
    let symbol = '';
    let decimalPlaces = 2;
    let subtotal = 0;
    const gtotalElement = table.getElementsByClassName('fee-gtotal');
    if (gtotalElement?.length > 0 && gtotalElement[0].textContent != null) {
      const gtotal: string[] = gtotalElement[0].textContent?.trim().split(' ');
      if (gtotal && gtotal.length > 1) {
        symbol = gtotal[0];
        const feeValue: string = gtotal[1];
        const integerPlaces = feeValue.indexOf('.');
        if (integerPlaces != -1) {
          decimalPlaces = feeValue.length - integerPlaces - 1;
        }
      }
    }
    const tableRows = table.getElementsByTagName('tr');
    for (let idx = 0; idx < tableRows.length; idx++) {
      const tr = tableRows[idx];
      const feeAmountElement = tr.getElementsByClassName('fee-amount');
      if (feeAmountElement != null && feeAmountElement.length > 0) {
        if (feeAmountElement[0].textContent != null && feeAmountElement[0].textContent.trim() != null) {
          const feeAmount: string[] = feeAmountElement[0].textContent?.trim().split(' ');
          let amount = 0;
          let needUpdate = true;
          if (feeAmount.length > 1) {
            amount = parseFloat(feeAmount[1].replace(',', ''));
          } else {
            amount = parseFloat(feeAmount[0].replace(',', ''));
          }
          if (isNaN(amount)) {
            amount = 0;
            needUpdate = false;
          }
          if (needUpdate) {
            const feeElement = feeAmountElement[0].querySelector(`[tag-id^="${id}"]`);
            if (feeElement) {
              feeElement.textContent = symbol + ' ' + amount.toFixed(decimalPlaces);
            }
          }
          subtotal = subtotal + amount;
        }
      } else {
        let priceTag: string = '';
        const feePriceElement = tr.getElementsByClassName('fee-price');
        if (feePriceElement?.length > 0 && feePriceElement[0].textContent && feePriceElement[0].textContent.trim() != null) {
          const feePrice: string[] = feePriceElement[0].textContent.trim().split(' ');
          if (availableMergeTags?.length || (proposalMergeTagMap && Object.keys(proposalMergeTagMap)?.length)) {
            if (feePrice.length > 1) {
              priceTag = isNaN(feePrice[1]) ? '0.0' : feePrice[1];
            }
          } else {
            if (feePrice.length > 1) {
              priceTag = feePrice[1];
            } else {
              priceTag = feePrice[0];
            }
          }
        }
        let unit: string[] = [];
        const feeUnitElement = tr.getElementsByClassName('fee-unit');
        if (feeUnitElement?.length > 0 && feeUnitElement[0].textContent != null && feeUnitElement[0].textContent.trim() != null) {
          unit = feeUnitElement[0].textContent.trim().split(' ');
        }
        let feeUnit: string = '0';
        if (unit.length == 1) {
          feeUnit = unit[0];
        } else {
          feeUnit = unit[unit.length - 1];
        }
        let feeQty: string = '0';
        const feeQtyElement = tr.getElementsByClassName('fee-qty');
        if (feeQtyElement?.length > 0 && feeQtyElement[0].textContent != null && feeQtyElement[0].textContent.trim() != null) {
          if (availableMergeTags?.length || (proposalMergeTagMap && Object.keys(proposalMergeTagMap)?.length)) {
            feeQty = feeQtyElement[0].textContent.trim();
            availableMergeTags?.forEach((mergeTag: string) => {
              feeQty = proposalMergeTagMap && proposalMergeTagMap[`[${mergeTag}]`];
            });
          } else {
            feeQty = feeQtyElement[0].textContent.trim();
          }
        }
        let price: number = 0;
        let quantity: number = 0;
        if (feeQty) {
          quantity = parseFloat(feeQty.replace(',', ''));
        }
        let rate: number = 0;
        let needUpdate = true;
        if (feeUnit) {
          rate = parseFloat(feeUnit.replace(',', ''));
          if (isNaN(rate)) {
            rate = 0;
            needUpdate = false;
          }
        }
        const value: number = 0;
        if (priceTag == value.toFixed(decimalPlaces)) {
          price = rate * quantity;
        } else if (priceTag) {
          price = parseFloat(priceTag.replace(',', ''));
        }
        if (!isNaN(priceTag)) {
          price = rate * quantity;
        }
        subtotal = subtotal + price;
        if (feeUnitElement?.length > 0 && feeUnitElement[0] != null && needUpdate) {
          const feeElement = feeUnitElement[0].querySelector(`[tag-id^="${id}"]`);
          if (feeElement) {
            feeElement.textContent = symbol + ' ' + rate.toFixed(decimalPlaces);
          }
        }
        if (feePriceElement?.length > 0 && feePriceElement[0] != null && needUpdate) {
          feePriceElement[0].textContent = symbol + ' ' + price.toFixed(decimalPlaces);
        }
      }
      if (tr.previousElementSibling && tr.previousElementSibling.getElementsByClassName('fee-sn')?.length > 0 && tr.previousElementSibling.getElementsByClassName('fee-sn')[0].textContent) {
        if (tr.getElementsByClassName('fee-sn')?.length > 0) {
          const sno = tr.previousElementSibling.getElementsByClassName('fee-sn')[0].textContent;
          if (sno) {
            let serialNo = parseInt(sno);
            if (!isNaN(serialNo)) {
              serialNo += 1;
              tr.getElementsByClassName('fee-sn')[0].textContent = '' + serialNo;
            }
          }
        }
      }
    }
    const feeStotalElement = table.getElementsByClassName('fee-stotal');
    if (feeStotalElement?.length > 0 && feeStotalElement[0] != null) {
      feeStotalElement[0].textContent = symbol + ' ' + subtotal.toFixed(decimalPlaces);
    }
    const taxLabelElement = table.getElementsByClassName('fee-taxlabel-wrap');
    if (taxLabelElement?.length > 0) {
      const styleAttr = taxLabelElement[0].getAttribute('style');
      if (!styleAttr?.includes('display: none') && !styleAttr?.includes('visibility: hidden') && taxLabelElement?.length > 0) {
        const tax: string[] = taxLabelElement[0].textContent?.trim().split(' ');
        if (tax.length >= 3) {
          const feeTax = (parseFloat(tax[2]) / 100) * subtotal;
          table.getElementsByClassName('fee-taxttotal')[0].textContent = symbol + ' ' + feeTax.toFixed(decimalPlaces);
          subtotal = subtotal + feeTax;
        }
      }
    }
    const discountElement = table.getElementsByClassName('fee-discountlabel-wrap');
    if (discountElement?.length > 0) {
      const styleAttr = discountElement[0].getAttribute('style');
      const discountValue = table.getElementsByClassName('fee-discount-label');
      if (!styleAttr?.includes('display: none') && !styleAttr?.includes('visibility: hidden') && discountValue?.length > 0) {
        const discount: string[] = discountValue[0].textContent?.trim().split(' ');
        if (discount.length > 1) {
          if (!isNaN(parseFloat(discount[2]))) {
            const feeDiscount = (parseFloat(discount[2]) / 100) * subtotal;
            subtotal = subtotal - feeDiscount;
            if (table.getElementsByClassName('fee-discountotal')?.length > 0) {
              table.getElementsByClassName('fee-discountotal')[0].textContent = symbol + ' ' + feeDiscount.toFixed(decimalPlaces);
            }
          }
        } else {
          if (table.getElementsByClassName('fee-discountotal')?.length > 0) {
            const discountval: string[] = table.getElementsByClassName('fee-discountotal')[0].textContent?.trim().split(' ');
            if (discountval.length > 1) {
              subtotal = subtotal - parseFloat(discountval[1].replace(',', ''));
            }
          }
        }
      }
    }
    if (gtotalElement?.length > 0 && gtotalElement[0] != null) {
      gtotalElement[0].textContent = symbol + ' ' + subtotal.toFixed(decimalPlaces);
    }
  }
  unlinkCallBack && unlinkCallBack();
};
