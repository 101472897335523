import axios from 'axios';
import SnackBarUtils from '@utils/SnackBarUtils';
import { errorLogger } from '@utils/ErrorLogging';
import { getCurrentUser, cleanupUser } from '@utils/UserLoadDetailUtil';
import i18next from 'i18next';
import ProjectLockedStoreTrigger from '@components/ProjectLockedStoreTrigger';
import { getUrlParam } from '@utils/URLParamUtil';
import { getJson } from '@utils/JsonUtils';
import store from '@store/index';
import { trackCustomEvent } from '@utils/analyticsUtil';
import { sessionTimeoutCheck } from '@utils/SessionTimeoutCheckUtil';

export let podName: string = '';
export const setPodName = (podname: string) => {
  podName = podname;
};

var getCompany = (userData: { [k: string]: any }, companyId: string) => {
  let companyDetail = null;
  if (Object.keys(userData).length > 0) {
    var loop = true,
      i = 0;
    if (!companyId) {
      companyId = userData['id_0'];
    }
    while (loop == true) {
      if (!userData['id_' + i]) {
        loop = false;
      }
      if (userData['id_' + i] == companyId) {
        companyDetail = {
          company_name: userData['company_name_' + i],
          role: userData['role_' + i],
          status: userData['status_' + i],
          id: userData['id_' + i],
          addl_role: userData['addl_role_' + i],
          pod_name: userData['pod_name_' + i]
        };

        loop = false;
      }
      i++;
    }
  }
  return companyDetail;
};
var getUserPod = function () {
  const companyStore: any = localStorage.getItem('company');
  if (companyStore) {
    const tempdata = JSON.parse(companyStore || '{}');
    return tempdata.pod_name || null;
  } else {
    let userDataStore;
    if (
      (window.location.search?.includes('return_to_ask=') || window.location.pathname?.startsWith('/ask') || localStorage.getItem('v2_redirect_url_ask')?.includes('return_to_ask')) &&
      window?.matchMedia('(display-mode: standalone)')?.matches
    ) {
      userDataStore = localStorage.getItem('userDataAsk');
    } else {
      userDataStore = localStorage.getItem('userData');
    }
    const urlCompanyId = location.href?.replace(/.*companyId\=([a-zA-Z0-9]+).*/gm, '$1');
    const tempdata = JSON.parse(userDataStore as any);
    const tempSelecteddata = getCompany(tempdata || {}, urlCompanyId);
    return tempSelecteddata ? tempSelecteddata.pod_name : null;
  }
};
var userPodName = getUserPod();

const getCompanyId = () => {
  let finalCmpId = null;
  const selectedCompanyId = localStorage.getItem('selectedCompanyId');
  if (selectedCompanyId) {
    finalCmpId = getJson(selectedCompanyId, selectedCompanyId)[1];
  } else {
    const urlId = getUrlParam('companyId');
    if (urlId && urlId !== 'null' && urlId !== 'undefined') {
      finalCmpId = urlId;
    }
  }
  return finalCmpId ? `companyId=${finalCmpId}&` : '';
};

const setRedirectUrl = (pageName: string = 'login', errorMsg?: string) => {
  const params = window.location.search.split('?');
  const paramValue = params.length > 1 ? '&' + params[1] : '';
  const redirectTo = window.location.origin + window.location.pathname + paramValue;
  if (redirectTo?.includes('return_to_v2') && pageName === 'login') {
    return; // skipping this method because redirect url already contains 'return_to_v2' param (which is from Login.tsx )
  }
  // window.location.href = window.location.origin + baseUrl + '?return_to_v2=' + redirectTo + '#/page/login';
  const validCompanyId = getCompanyId();
  const validErrorMessage = errorMsg ? `errorMsg=${errorMsg}&` : '';
  let href = `${window.location.origin}/v2/login/?${validErrorMessage}return_to_v2=${redirectTo}`;
  cleanupUser();
  if (pageName === 'logout') {
    href = `${window.location.origin}/v2/logout?${validCompanyId}${errorMsg ? 'errorMsg=' + errorMsg : ''}`;
  } else {
    if (redirectTo?.startsWith(window.location.origin + '/ask') && window?.matchMedia('(display-mode: standalone)')?.matches) {
      localStorage.setItem('v2_redirect_url_ask', `?return_to_ask=${redirectTo}`);
    } else {
      localStorage.setItem('v2_redirect_url', `?return_to_v2=${redirectTo}`);
    }
  }
  window.location.href = href;
};

export default (history = null) => {
  const baseURL = '/rfpserver/';
  const axiosInstance = axios.create({
    baseURL: baseURL
  });
  // let channel;
  // try {
  //   channel = new BroadcastChannel('sessionTimeout');
  // } catch (error) {
  //   // handled for unsupported version of browsers(< Safari 15.4)
  // }

  // channel?.addEventListener('message', event => {
  //   if (event.data.type === 'sessionTimeout') {
  //     SessionTimeoutCheck({ timeLimitUpdated: event.data.value });
  //   }
  // });

  const checkSessionTimeout = (response: any) => {
    const responseHeaders = response.headers;
    if (responseHeaders['session_timeout_limit']) {
      const sessionLimit = responseHeaders['session_timeout_limit'];
      if (sessionLimit > 0) {
        sessionTimeoutCheck(sessionLimit);
        // channel?.postMessage({ type: 'sessionTimeout', value: sessionLimit });
      }
    }
  };

  const logApiTime = (response: any) => {
    const userDataFromLocal = localStorage.getItem('userData');
    if (!userDataFromLocal) {
      return;
    }
    const endDate = new Date();
    response.duration = endDate - response.config.metadata.startTime;
    const totalTimeTaken = response.duration / 1000;
    if (totalTimeTaken > 2) {
      const responseHeaders = response.headers;
      let userRequestTime = 0;
      if (responseHeaders['user_request_time']) {
        userRequestTime = responseHeaders['user_request_time'];
      }
      trackCustomEvent({
        eventName: 'API Performance Monitoring',
        eventProperties: { apiUrl: response.config.url, totalDuration: response.duration / 1000, userRequestTime: userRequestTime / 1000, userDataFromLocal }
      });
    }
  };

  const checkVersions = function (response: any) {
    const responseHeaders = response.headers;
    if (responseHeaders['rfpio-version']) {
      const version = responseHeaders['rfpio-version'] ? JSON.stringify(responseHeaders['rfpio-version']) : '';
      const existingVersion = localStorage.getItem('version');
      if (existingVersion !== version) {
        localStorage.setItem('version', version);
        if (existingVersion != '') {
          window.location.reload(true);
        }
      }
    }
  };

  axiosInstance.interceptors.request.use(
    function (config) {
      let user;
      if (
        (window.location.search?.includes('return_to_ask=') || window.location.pathname?.startsWith('/ask') || localStorage.getItem('v2_redirect_url_ask')?.includes('return_to_ask')) &&
        window?.matchMedia('(display-mode: standalone)')?.matches &&
        self === top
      ) {
        user = localStorage.getItem('userDataAsk');
      } else {
        user = localStorage.getItem('userData');
      }
      let currentUser = null;
      if (user && !podName) {
        currentUser = JSON.parse(user);
        config.headers.authorization = 'Bearer ' + (config.customBearerToken ? config.customBearerToken : currentUser.access_token);
        if (currentUser.support) {
          config.headers.support = currentUser.support;
          const supportAccess = localStorage.getItem('supportAccess');
          if (supportAccess) {
            config.headers['rfpio-support-token'] = supportAccess;
          }
        }
      }
      let company = localStorage.getItem('company');
      if (company) {
        config.headers.company = JSON.parse(company).id;
        const companyPodName = JSON.parse(company).pod_name;
        if (!podName && companyPodName) {
          userPodName = companyPodName;
        }
      }
      const storagePodName = config.headers.customPodName || podName || userPodName || null;
      if (storagePodName && !currentUser?.support) {
        config.headers['pod-name'] = storagePodName;
      }
      if (config.headers.customPodName) {
        delete config.headers.customPodName;
      }
      return config;
    },
    error => {
      errorLogger(JSON.stringify(error), true, 'Caught from request in the method axios.ts');
      return Promise.reject(error);
    }
  );

  const showErrorToast = (obj: { [k: string]: string | undefined }) => {
    const msg = `${obj.message} ${obj.additional_message || ''}`;
    SnackBarUtils.error(msg);
  };

  axiosInstance.interceptors.response.use(
    response =>
      new Promise((resolve, reject) => {
        if (
          response.config.url !== 'viewers/get' &&
          response.config.url !== 'user-nps/get' &&
          response.config.url !== 'users/update-time-tracker' &&
          response.config.url &&
          response.config.url.indexOf('guest/viewers') != 0 &&
          response.config.url.indexOf('guest/update-time-tracker-for-guest') != 0
        ) {
          localStorage.setItem('LastResponseTime', new Date().getTime() + '');
          checkSessionTimeout(response);
        }
        if (response.config?.metadata?.startTime) {
          logApiTime(response);
        }
        checkVersions(response);
        resolve(response);
      }),
    error => {
      if (!error.response) {
        return new Promise((resolve, reject) => {
          if (
            error.response &&
            error.response.config.url != 'viewers/get' &&
            error.response.config.url != 'user-nps/get' &&
            error.response.config.url != 'users/update-time-tracker' &&
            error.response.config.url.indexOf('guest/viewers') != 0 &&
            error.response.config.url.indexOf('guest/update-time-tracker-for-guest') != 0
          ) {
            localStorage.setItem('LastResponseTime', new Date().getTime() + '');
          }
          errorLogger(JSON.stringify(error), true, 'Caught from response in the method axios.ts');
          reject(error);
        });
      }
      checkVersions(error.response);
      if (error.response.status === 401) {
        const userDataFromLocal = localStorage.getItem('userData');
        if (error.response.config.url.indexOf('/rfpserver/vendor/details/init') < 0) {
          if (userDataFromLocal && getCurrentUser()?.access_token == JSON.parse(userDataFromLocal).access_token) {
            setRedirectUrl('logout', 'Session Expired');
          } else {
            window.location.reload();
          }
        }
      } else if (error.response.status === 403) {
        console.log('403 Unauthorised');
        if (error.response.data != null && error.response.data.additional_message === 'U111') {
          setRedirectUrl('login', 'Your IP Address is restricted. Please contact your Administrator.');
        } else if (getCurrentUser()) {
          if (
            !error.response.config.url.indexOf('/rfpserver/content-library/bulk-update') &&
            !error.response.config.url.indexOf('/rfpserver/answer-lib/search-replace-by-search') &&
            !error.response.config.url.indexOf('/rfpserver/answer-lib/update-content-format')
          ) {
            SnackBarUtils.error(i18next.t('GT105'));
          }
        } else if (error.response.config.url.indexOf('/rfpserver/batch-process/get-batch-details')) {
          SnackBarUtils.error(i18next.t('GT105'));
        } else {
          setRedirectUrl('login');
        }
      } else if (error.response.status === 400) {
        console.log('400 Bad Request');
        if (error.response.data) {
          // P112 is the error code used for project lock
          if (error.response.data.error === 'P112' && error.response.data.additional_message) {
            // This component will trigger the project lock store
            ProjectLockedStoreTrigger({ projectId: error.response.data.additional_message });
          }
          if (error.response.data.error !== 'P112' && error.response.data.error_description) {
            let isTranslateError = false;
            if (error.response.data.additional_message && error.response.data.additional_message.includes('TRANSLATED_BY')) {
              const obj: any = { translationInProgress: true };
              obj['translatedBy'] = error.response.data.additional_message.split('TRANSLATED_BY_')[1];
              store.getActions().projectMasterData.setProjectTranslationData(obj);
              isTranslateError = true;
            } else {
              if (error.response.data.error === 'U115') {
                SnackBarUtils.error(i18next.t('U115'));
              } else {
                showErrorToast({
                  message: error.response.data.error_description,
                  additional_message: isTranslateError || error.response.config.url === 'login/password-validation' ? '' : error.response.data.additional_message
                });
              }
            }
          }
          return new Promise((resolve, reject) => {
            if (error.response.data.error !== 'P112') {
              resolve(error.response);
            }
          });
        }
      } else if (error.response.status === 413) {
        console.log('413 (Request Entity Too Large)');
        // if (error.response.statusText === 'Request Entity Too Large') {
        SnackBarUtils.error('File size exceeds.');
        return new Promise((resolve, reject) => {
          resolve(error.response);
        });
        // }
      } else if (error.response.status === 440) {
        console.log('440 Handled Exception');
        setRedirectUrl('logout', 'Session Expired');
      } else if (error.response.status === 500) {
        console.log('500 Internal Server Error');
      } else if (error.response.status === 502 || error.response.status === 503 || error.response.status === 504) {
        if (error.response.status === 504 && error.response.config.url === 'bot-configuration/get-channel-specific-details') {
          return new Promise((resolve, reject) => {
            resolve(error.response);
          });
        } else {
          SnackBarUtils.toast('An error occurred. Please try again later.');
        }
      } else if (error.response.status === 404) {
        SnackBarUtils.toast('An error occurred. Please try again later.');
        return new Promise((resolve, reject) => {
          reject(error);
        });
      } else {
        errorLogger(error.response.data, true);
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    }
  );

  return axiosInstance;
};
