export enum TaskTypeEnum {
  ANSWER_LIBRARY_REVIEW = 'ANSWER_LIBRARY_REVIEW',
  SECTION = 'SECTION',
  PROJECT_TASK = 'PROJECT_TASK',
  REQUEST_TASK = 'REQUEST_TASK',
  REQUEST_SECTION = 'REQUEST_SECTION',
  SCORECARD = 'SCORECARD',
  SECTION_TEMPLATE_REVIEW = 'SECTION_TEMPLATE_REVIEW',
  ANSWER_LIBRARY_MODERATION = 'ANSWER_LIBRARY_MODERATION',
  DOCUMENT_LIBRARY_REVIEW = 'DOCUMENT_LIBRARY_REVIEW',
  SECTION_AUTHOR_TASK = 'SECTION_AUTHOR_TASK',
  SECTION_REVIEW_TASK = 'SECTION_REVIEW_TASK',
  LANGUAGE_REVIEW = 'LANGUAGE_REVIEW',
  DOCUMENT_LIBRARY_MODERATION = 'DOCUMENT_LIBRARY_MODERATION',
  SCORECARD_EVALUATOR_TASK = 'SCORECARD_EVALUATOR_TASK'
}

export enum Modules {
  MY_WORK = 'MY_WORK',
  CONTENT_LIBRARY = 'CONTENT_LIBRARY',
  PROJECT = 'PROJECT',
  CONTENT_ANALYSIS = 'CONTENT_ANALYSIS',
  INTAKE = 'INTAKE',
  PROFILE = 'PROFILE'
}

export enum DaysEnum {
  TODAY = 'TODAY',
  TOMORROW = 'TOMORROW'
}
export enum TypeDiscussion {
  SECTION = 'SECTION',
  QUESTION = 'QUESTION',
  E_SIGN = 'E_SIGN',
  TOPIC = 'TOPIC',
  ANSWER = 'ANSWER',
  DOCUMENT = 'DOCUMENT',
  PROJECT = 'PROJECT',
  CONTENT_LIBRARY = 'CONTENT_LIBRARY',
  CATALOG = 'CATALOG',
  SECTION_TEMPLATE = 'SECTION_TEMPLATE'
}

export enum ProjectFormat {
  DESCRIPTIVE_PROPOSAL = 'DESCRIPTIVE_PROPOSAL'
}

export enum ProjectType {
  RESPOND = 'RESPOND',
  REQUEST = 'REQUEST'
}

export enum StatusEnum {
  ASSIGNED = 'ASSIGNED',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING_REVIEW = 'PENDING_REVIEW'
}

export enum PriorityEnum {
  NONE = 'NONE',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW'
}

export enum PasswordPolicyEnum {
  atleast_one_lowercase = 'atleast_one_lowercase',
  atleast_one_number = 'atleast_one_number',
  atleast_one_special_character = 'atleast_one_special_character',
  atleast_one_uppercase = 'atleast_one_uppercase',
  begin_with_alphabet = 'begin_with_alphabet',
  minimum_password_length = 'minimum_password_length',
  password_expiration_days = 'password_expiration_days',
  password_history_count = 'password_history_count',
  notify_before_days = 'notify_before_days',
  enable_password_expiration = 'enable_password_expiration',
  enforce_password_history = 'enforce_password_history'
}

export enum MyTaskDatesEnum {
  OVERDUE = 'MW_OVERDUE',
  REMINDERS = 'MW_REMINDERS',
  TODAY = 'MW_TODAY',
  TOMORROW = 'MW_TOMORROW',
  NEXT_SEVEN_DAYS = 'MW_NEXT_SEVEN_DAYS',
  NEXT_THIRTY_DAYS = 'MW_NEXT_THIRTY_DAYS',
  NEXT_NINETY_DAYS = 'MW_NEXT_NINETY_DAYS',
  FUTURE_TASKS = 'MW_FUTURE_TASKS'
}

export enum PasswordEnum {
  CURRENT_PASSWORD = 'CURRENT_PASSWORD',
  NEW_PASSWORD = 'NEW_PASSWORD',
  CONFIRM_NEW_PASSWORD = 'CONFIRM_NEW_PASSWORD'
}

export enum OsType {
  MacOS = 'MacOS',
  iOS = 'iOS',
  Windows = 'Windows',
  Android = 'Android',
  Linux = 'Linux'
}

export enum LoadingStatusEnum {
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  EMPTY = 'EMPTY'
}

export enum BatchProcessStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR'
}

export type BatchProcessStatusType = 'PENDING' | 'IN_PROGRESS' | 'COMPLETED' | 'ERROR';
