import axiosInstance from '@helpers/Axios';
import { UserPreferenceDataVO } from '@interfaces/Models/UserPreferenceDataVO';
import UserVO from '@interfaces/Models/UserVO';
import { errorLogger } from '@utils/ErrorLogging';

export const getUserLoadDetails = async () => {
  try {
    const response = await axiosInstance().get('/load/user-load-details');
    if (response && response.status === 200 && response.data && typeof response.data === 'object') {
      return response.data;
    }
  } catch (e) {
    errorLogger(e, false, 'Caught in getUserLoadDetails in GeneralService.ts');
  }
};

export const addNewUsersAPI = async (obj: any) => {
  try {
    const response = await axiosInstance().post('users/admin/add-users', obj);
    return response;
  } catch (e) {
    errorLogger(e, false, 'Caught while adding new users in addNewUsers');
  }
};

export const getUsersAPI = async () => {
  try {
    const response = await axiosInstance().get('users/admin/get-users');
    return response;
  } catch (e) {
    errorLogger(e, false, 'Caught while getting users in getUsers');
  }
};

export const getTeamListAPI = async () => {
  try {
    const response = await axiosInstance().get('manage-teams/team-list');
    return response;
  } catch (e) {
    errorLogger(e, false, 'Caught while getting team list in getTeamList');
  }
};

export const addUsersToCollectionAPI = async (obj: any) => {
  try {
    const response = await axiosInstance().post('answer-lib-collection/add-users-to-collections', obj);
    return response;
  } catch (e) {
    errorLogger(e, false, 'Caught while adding users to collection in addUsersToCollectionAPI');
  }
};

export const getUserPoliciesAPI = async () => {
  try {
    const response = await axiosInstance().get('users/admin/get-all-user-policies');
    return response;
  } catch (e) {
    errorLogger(e, false, 'Caught while getting user policies in getUserPoliciesAPI');
  }
};

export const submitV2Feedback = async (obj: { feedback: string; module: string }) => {
  try {
    const response = await axiosInstance().post('users/submit-ui-v2-feedback', obj);
    return response;
  } catch (e) {
    errorLogger(e, false, 'Caught while sending feedback from module:' + obj.module);
  }
};

export const getCrmClientDetails = async (obj: { [k: string]: string }) => {
  try {
    const results: any = await axiosInstance().post('crm/get-client-details', obj);
    return results;
  } catch (e) {
    errorLogger(e, false, 'Caught in getCrmClientDetails' + obj);
  }
};

export const getUserAccount = async (type: string) => {
  try {
    const results: any = await axiosInstance().get('users/get-user-account', { params: { type: type } });
    return results;
  } catch (e) {
    errorLogger(e, false, 'Caught in getCrmClientDetails' + type);
  }
};

export const getUserInfoList = async (users: string[], callback: (users: UserVO[]) => void) => {
  try {
    const response: any = await axiosInstance().post('users/get-uservo-list', users);
    if (response?.status === 200 && response.data) {
      callback(response.data);
    }
  } catch (e) {
    errorLogger(e, false, 'Caught in getUserInfoList in GeneralService.ts');
  }
};

export const switchUXExperience = async (uxVersion: string, module: string, feedback?: string) => {
  try {
    const result = await axiosInstance().post('users/switch-ux-preference', { feedback: feedback, preference: uxVersion, module: module });
    return result;
  } catch (e) {
    errorLogger(e, false, 'Caught while switching ux version');
  }
};

export const getViewPreferences = async (obj: string, callBackFunc: (userViewPrefData?: UserPreferenceDataVO) => void, localPreferenceKey?: string) => {
  try {
    const response = await axiosInstance().get(`/users/get-view-preferences?type=${obj}`);
    if (response?.status === 200 && response.data) {
      if (localPreferenceKey) {
        localStorage.setItem(localPreferenceKey, JSON.stringify(response.data));
      }
      callBackFunc(response.data);
    } else {
      callBackFunc(undefined);
    }
  } catch (e) {
    callBackFunc(undefined);
    errorLogger(e, false, 'caught from getViewPreferences method in GeneralService.ts');
  }
};

export const updateUserUipreferences = async (data: any, callback?: () => void) => {
  try {
    await axiosInstance().post('users/update-uipreferences', { uiPreferences: data });
    callback && callback();
  } catch (e) {
    errorLogger(e, false, 'Caught from GeneralService.ts in the method getUiPreferences');
  }
};

export const getBasicCompanyDetails = async (companyIds: string[]) => {
  try {
    return axiosInstance().post('guest_v3/vendor/company/get-companyinfo-by-ids', companyIds);
  } catch (e) {
    errorLogger(e, false, 'Caught from GeneralService.ts in the method getBasicCompanyDetails');
  }
};

interface addAnsLibUsedHistoryType {
  id: string;
  operationUsed: string;
  usedProjectName: string;
  questionText: string;
  projectId: string;
  questionId: string;
  partnerCompanyID: string | undefined;
  savedContentSearch: any;
  manualSearchKeyword: string;
  setRecommendationResponseVO?: any;
  incomingVO?: any;
}
export const addAnsLibUsedHistoryUpdate = async (
  id: string,
  operationUsed: string,
  usedProjectName: string,
  questionText: string,
  projectId: string,
  questionId: string,
  partnerCompanyID: string | undefined,
  savedContentSearch: string,
  manualSearchKeyword: string
) => {
  const payLoad = {
    answerId: id,
    projectName: usedProjectName,
    operationUsed: operationUsed,
    questionText: operationUsed === 'ADD' ? '' : questionText,
    projectId: projectId,
    questionId: operationUsed === 'ADD' ? undefined : questionId,
    partnerCompany: partnerCompanyID !== undefined,
    partnerCompanyData: { partnerCompanyId: partnerCompanyID },
    savedContentSearchId: savedContentSearch,
    manualSearchKeyword: manualSearchKeyword
  };
  const url = 'answer-lib/update-answer-lib-used';
  try {
    const response = await axiosInstance().post(url, payLoad);
    return response;
  } catch (e) {
    errorLogger(e, false, 'Caught from GeneralService.ts in the method addAnsLibUsedHistoryUpdate');
  }
};

interface UpdateDocUsedCountAndRecord {
  projectId?: string;
  sectionId?: string;
  questionId?: string;
  docId: string;
  operationType: string;
  operationModule: string;
}

export const updateDocUsedCountAndRecord = async (payload: UpdateDocUsedCountAndRecord) => {
  const url = 'documents/update-ai-doc-used-history';
  try {
    const response = await axiosInstance().post(url, payload);
    return response;
  } catch (e) {
    errorLogger(e, false, 'Caught from GeneralService.ts in the method updateDocUsedCountAndRecord');
  }
};

export const submitFeedBack = async (obj: { module: string; description: string; detailedFeedback: string }) => {
  try {
    return await axiosInstance().post('users/send-feedback', obj);
  } catch (e) {
    errorLogger(e, false, 'Caught while sending feedback from module:' + obj.module);
  }
};

export const enableTrialFeature = async (moduleName: string) => {
  try {
    return await axiosInstance().post(`users/enable/${moduleName}_trial`);
  } catch (e) {
    errorLogger(e, false, 'caught from GeneralService.ts from the method enableTrialFeature');
  }
};

export const getTrialFeatureDetails = async () => {
  try {
    return await axiosInstance().get('users/get-trial-feature-details');
  } catch (e) {
    errorLogger(e, false, 'Caught while getting trial feature details in getTrialFeatureDetails');
  }
};

export const getTrialFeatureThreshold = async () => {
  try {
    return await axiosInstance().get('users/get-trial-feature-threshold');
  } catch (e) {
    errorLogger(e, false, 'Caught while getting trial feature threshold in getTrialFeatureThreshold');
  }
};
