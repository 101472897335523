import CustomFieldVO, { CustomFieldVOValue } from '@interfaces/Models/CustomFieldVO';
import { IOrgCustomFields } from '@org/interfaces/store/response-projects/OrgProjectStore';
import store from '@store/index';
import { groupByGroupName } from '@utils/CustomFieldUtils';
import { getHourMinute, setConvertedDate, setModelValueForDatePicker } from '@utils/DayjsUtils';
import { getProjectPrimaryContacts, isFeatureEnabled } from '@utils/UserLoadDetailUtil';
import cloneDeep from 'clone-deep';

export const configureCustomField = (customFieldItem: CustomFieldVO & Record<string, boolean>, tempCustomFields: CustomFieldVOValue = {}, groupByIndexed: string[], customFields: IOrgCustomFields) => {
  customFieldItem.mandatory = false;
  customFieldItem.editable = true;

  if (!tempCustomFields[customFieldItem?.name]) {
    tempCustomFields[customFieldItem?.name] = '';
  }

  switch (customFieldItem.type) {
    case 'CHECKBOX':
      configureCheckboxField(customFieldItem, tempCustomFields);
      break;
    case 'DATE':
      customFieldItem.modelVal = setModelValueForDatePicker(tempCustomFields[customFieldItem.name]);
      break;
    case 'SELECT_USER':
      configureSelectUserField(customFieldItem, tempCustomFields);
      break;
    case 'DATE_AND_TIME':
      if (customFieldItem.entityType === 'REQUEST') {
        customFieldItem.modelVal = tempCustomFields[customFieldItem.name];
      } else {
        configureDateAndTimeField(customFieldItem, tempCustomFields);
      }
      break;
    default:
      customFieldItem.modelVal = tempCustomFields[customFieldItem.name];
      break;
  }

  if (groupByGroupName(groupByIndexed, customFieldItem)) {
    const hasNoDefaultFields = customFields.companyCustomFieldList.filter(item => {
      if (customFieldItem.entityType === 'REQUEST') {
        return item?.groupName.toLowerCase() === customFieldItem.groupName.toLowerCase() && (!item.defaultMergeTag || item.groupName === 'Request Timeline' || item.groupName === 'Client Details');
      } else {
        return item?.groupName.toLowerCase() === customFieldItem.groupName.toLowerCase() && !item.defaultMergeTag;
      }
    });
    if (Array.isArray(hasNoDefaultFields) && hasNoDefaultFields.length > 0) {
      customFields.groupList[customFieldItem.groupName] = hasNoDefaultFields as unknown as CustomFieldVO[];
    }
  }
};

export const configureCheckboxField = (customFieldItem: CustomFieldVO & Record<string, boolean>, tempCustomFields: CustomFieldVOValue) => {
  setCheckboxModel(customFieldItem);
  const values = tempCustomFields[customFieldItem.name];
  customFieldItem.modelVal = values;
  customFieldItem.values.forEach((val, index) => {
    customFieldItem[`modelVal${index}`] = values ? values.includes(val) : '';
  });
};

export const configureSelectUserField = (customFieldItem: CustomFieldVO & Record<string, boolean>, tempCustomFields: CustomFieldVOValue) => {
  customFieldItem.modelVal = Array.isArray(tempCustomFields[customFieldItem.name])
    ? (tempCustomFields[customFieldItem.name] as (string | string[])[])
    : tempCustomFields[customFieldItem.name]
    ? [tempCustomFields[customFieldItem.name]]
    : [];
};

export const configureDateAndTimeField = (customFieldItem: CustomFieldVO & Record<string, boolean>, tempCustomFields: CustomFieldVOValue) => {
  const userDetails = store.getState().userDetails.userDetails;
  const timeZone = userDetails.userInfoVO.timeZone ? userDetails.userInfoVO.timeZone : (userDetails.companyInfoVO.timeZone as string);

  customFieldItem.dueDate = setConvertedDate(tempCustomFields[customFieldItem.name]);
  customFieldItem.dueDate = setModelValueForDatePicker(customFieldItem.dueDate);
  customFieldItem.modelVal = customFieldItem.dueDate;
  customFieldItem.time = getHourMinute(tempCustomFields[customFieldItem.name]);
  customFieldItem.time = customFieldItem.time.toLowerCase() === 'invalid date' ? '00:00' : customFieldItem.time;
  customFieldItem.zone = timeZone;
};

export const setCheckboxModel = (list: Record<string, any>) => {
  list.$$searchKeyword = '';
  list.$$tempModel = {};
  list.values.forEach((chk: string, index: number) => {
    list.$$tempModel[chk] = 'modelVal' + index;
  });
};

export const setUsersListForMetadata = (businessUnits: string, isRequest?: boolean) => {
  const isBuEnabled = isFeatureEnabled('business_units');
  const primaryBU = isRequest ? businessUnits : store.getState().userDetails.userDetails.userInfoVO?.primaryBU;
  const buMap = store.getState().businessUnitDetails.businessUnitIdMap;
  const businessUnitList = Object.values(buMap);

  let buIds: string[] = [];
  if (isBuEnabled) {
    if (businessUnits) {
      buIds = [businessUnits];
    } else {
      let buId = businessUnitList[0].id;
      if (primaryBU) {
        buId = primaryBU;
      }
      buIds = [buId];
    }
  }

  return getProjectPrimaryContacts(buIds, isRequest);
};

export const getCustomFieldByEntityType = (entityType: string, type: string, needAutoIncrement?: boolean, customFieldMapVO?: { [key: string]: { key: string } }) => {
  const customFieldDetails = store.getState().customFieldDetails.customFields;
  let companyCustomFields = cloneDeep(customFieldDetails.companyCustomFieldList);
  if (customFieldMapVO) {
    companyCustomFields = [...Object.values(customFieldMapVO)];
  }
  let customFields: any = {};
  if (companyCustomFields) {
    customFields.companyCustomFieldList = companyCustomFields.filter((d: any) => {
      if (needAutoIncrement || d.type !== 'AUTO_INCREMENTAL') {
        return (d.projectTypes.length === 0 || d.projectTypes.indexOf(type) >= 0) && d.entityType === entityType;
      } else if (type) {
        return d.defaultMergeTag === true && d.entityType === entityType;
      }
    });
  }
  return customFields;
};
