import React from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import FooterLogo from '@components/FooterLogo';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import safariIcon from '@images/icons/safari.svg';
import firefox from '@images/icons/Logo.png';
import chrome from '@images/icons/chrome.svg';
import microsoftEdge from '@images/icons/microsoft-edge.svg';
import opera from '@images/icons/opera.svg';

export default function NoSupportProvided(props: any) {
  const classes = headerStyles();
  let appAppend = '';
  if (window.location.hostname) {
    appAppend = '/app';
  }
  const v1Url = window.location.protocol + '//' + window.location.hostname + appAppend + '/#/page' + window.location.pathname.substring(3);
  return (
    <>
      <div className='h-100p position-r'>
        <Grid className={`${classes.fontFamily} h-100p`} container direction='column' justify='center' alignItems='center'>
          {props.loading && props.noQuestionsAssigned && (
            <>
              <span className={`pl-30 ${classes.rfpioLogo}`}>
                <FooterLogo height='100' footerStyles={footerStyles} />
              </span>
              {!props.isIE && (
                <>
                  <CancelIcon className={`${classes.cancelIcon}`} />
                  <div className='font-28 pb-5'>No questions assigned to you!</div>
                  <div className='font-18-imp'>Please contact your Responsive Administrator</div>
                </>
              )}
            </>
          )}
          {props.isIE && (
            <>
              <span className={`pl-30 ${classes.rfpioLogo}`}>
                <FooterLogo height='100' footerStyles={footerStyles} />
              </span>
              <div className={`${classes.pb30} ${classes.font40}`}>Unsupported browser</div>
              <div className={`pb-5 ${classes.font21}`}>You are using a browser we no longer support.</div>
              <div className={` ${classes.font21} ${classes.pb30}`}>Please use one of these browser to improve your experience</div>
              <div>
                <span className={`${classes.pr30}`}>
                  <img alt='chrome' src={chrome} width='80' height='80' />
                </span>
                <span className={`${classes.pr30}`}>
                  <img alt='firefox' src={firefox} width='80' height='80' />
                </span>
                <span className={`${classes.pr30}`}>
                  <img alt='microsoftEdge' src={microsoftEdge} width='80' height='80' />
                </span>
                <span className={`${classes.pr30}`}>
                  <img alt='opera' src={opera} width='80' height='80' />
                </span>
                <span className={`${classes.pr30}`}>
                  <img alt='safariIcon' src={safariIcon} width='85' height='85' />
                </span>
              </div>
              <div className='pt-20'>
                Alternatively you can use the older version of this page.{' '}
                <a className={`${classes.clickHereStyle}`} href={v1Url}>
                  Click Here
                </a>
              </div>
            </>
          )}
        </Grid>
      </div>
    </>
  );
}

const footerStyles = makeStyles(theme => ({
  footerLogoIcon: {
    fill: '#1C3443'
  },
  pl: {
    paddingLeft: '10px'
  }
}));

const headerStyles = makeStyles(theme => ({
  pr30: {
    paddingRight: '30px'
  },
  font40: {
    fontSize: '45px',
    fontWeight: 600
  },
  font21: {
    fontSize: 21
  },
  pb30: {
    paddingBottom: '30px'
  },
  rfpioLogo: {
    top: '10px',
    left: '0',
    position: 'absolute'
  },
  cancelIcon: {
    fontSize: '80px',
    color: 'red'
  },
  clickHereStyle: {
    color: 'black',
    fontWeight: 700,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  fontFamily: {
    fontFamily: '"Source Sans Pro", "Arial", sans-serif',
    background: '#f4f4f4'
  }
}));
