import { action } from 'easy-peasy';
import BusinessUnitDetailsStore from '@interfaces/BusinessUnitDetailsStore';
import { businessUnitVOList } from '@interfaces/Models/BussinessUnitVO';

const BusinessUnitDetailsStoreImpl: BusinessUnitDetailsStore = {
  businessUnitIdMap: {},
  businessUnitNameMap: {},

  setBusinessUnitDetails: action((state, entry) => {
    entry.push(businessUnitVOList[0]);
    entry.forEach(e => {
      state.businessUnitIdMap[e.id] = e;
      state.businessUnitNameMap[e.id] = e.name;
    });
  }),
  updateBusinessUnitDetails: action((state, entry) => {
    if (entry.index >= 0) {
      state.businessUnitIdMap[entry.currentValue.id] = entry.currentValue;
      state.businessUnitNameMap[entry.currentValue.id] = entry.currentValue.name;
    }
  })
};

export default BusinessUnitDetailsStoreImpl;
