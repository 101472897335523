import { createStore, action, thunk } from 'easy-peasy';
import Store from '@interfaces/Store';
import AutoFillDetailsStoreImpl from './AutoFillDetailsStoreImpl';
import BusinessUnitDetailsStoreImpl from './BusinessUnitDetailsStoreImpl';
import CompanyCollectionStoreImpl from './CompanyCollectionStoreImpl';
import crmStoreImpl from './crmStoreImpl';
import CustomFieldsDetailsStoreImpl from './CustomFieldsDetailsStoreImpl';
import DialogStoreImpl from './DialogStoreImpl';
import ProjectMasterDataStoreImpl from './ProjectMasterDataStoreImpl';
import StyleStoreImpl from './StyleStoreImple';
import ThemeStoreImpl from './ThemeStoreImpl';
import UserDataStoreImpl from './UserDataStoreImpl';
import UserDetailsStoreImpl from './UserDetailsStoreImpl';
import AdditionalInfoStoreImpl from './AdditionalInfoStoreImpl';
import DuplicateProjectStoreImpl from './DuplicateProjectStoreImpl';
import TimerInfoStoreImpl from './TimerInfoStoreImpl';
import DockTinyMCEStoreImpl from './DockTinyMCEStoreImpl';
import SessionTimeoutStoreImpl from './SessionTimeoutStoreImpl';
import contentMasterDataStoreImpl from './manage-content-store/ContentMasterDataStoreImpl';
import eventTrackStoreImpl from './eventTrackStoreImpl';
import ApplicationFrameWorkStoreImpl from './header-store/ApplicationFrameWorkStoreImpl';
import { getUserLoadDetails } from '@services/GeneralService';
import { processUserLoadDetails } from '@utils/user-load-details/UserLoadProcessors';
import { setRoleDisplayName } from '@utils/UserLoadDetailUtil';
import AutoRespondDataStoreImpl from './AutoRespondDataStoreImpl';

const storeData: Store = {
  dialog: DialogStoreImpl,
  theme: ThemeStoreImpl,
  userDetails: UserDetailsStoreImpl,
  userData: UserDataStoreImpl,
  projectMasterData: ProjectMasterDataStoreImpl,
  contentMasterData: contentMasterDataStoreImpl,
  companyCollection: CompanyCollectionStoreImpl,
  businessUnitDetails: BusinessUnitDetailsStoreImpl,
  autoFillDetailsStore: AutoFillDetailsStoreImpl,
  customFieldDetails: CustomFieldsDetailsStoreImpl,
  styleStore: StyleStoreImpl,
  crmDetails: crmStoreImpl,
  additionalInfoStore: AdditionalInfoStoreImpl,
  autoRespondDataStore: AutoRespondDataStoreImpl,
  duplicateProjectStore: DuplicateProjectStoreImpl,
  timerInfoStore: TimerInfoStoreImpl,
  tinyMCEInteractiveStore: DockTinyMCEStoreImpl,
  sessionTimeout: SessionTimeoutStoreImpl,
  eventTrack: eventTrackStoreImpl,
  applicationFrameWorkStore: ApplicationFrameWorkStoreImpl,
  //single dispatch for user load(initial) to the site
  setMasterData: action((state, entry) => {
    state.userData = { ...entry.userLoadDetails, displayNameForRolesMap: {} };
    state.customFieldDetails = { ...entry.customFieldMapData };
    state.companyCollection = { ...entry.collectionData };
    state.businessUnitDetails = { ...entry.buDetails };
    state.styleStore = { ...entry.fontDetails };
    state.projectMasterData.projectStageMap = entry.projectMasterData?.projectStageMap || {};
    state.projectMasterData.projectTypeMap = entry.projectMasterData?.projectTypeMap || {};
    state.projectMasterData.projectStageList = entry.projectMasterData?.projectStageList || [];
    state.projectMasterData.projectTypeList = entry.projectMasterData?.projectTypeList || [];
    state.projectMasterData.projectFlagMap = entry.projectMasterData?.projectFlagMap || {};
    state.projectMasterData.projectFlagList = entry.projectMasterData?.projectFlagList || [];
    state.projectMasterData.requestStageMap = entry.requetMasterData?.requestStageMap || {};
    state.projectMasterData.requestStageList = entry.requetMasterData?.requestStageList || [];
    state.projectMasterData.vendorStageList = entry.requetMasterData?.vendorStageList || [];
    state.projectMasterData.requestTypeList = entry.requetMasterData?.requestTypeList || [];
    state.projectMasterData.requestTypeMap = entry.requetMasterData?.requestTypeMap || {};
    state.contentMasterData.contentFlagList = entry.contentFlagList;
    state.contentMasterData.contentFlagIdMap = entry.contentFlagIdMap;
    state.contentMasterData.contentFlagMap = entry.contentFlagMap;
    state.contentMasterData.flagList = entry.flagList;

    const userDetails = (({ userVOList, teamsList, customFieldVOList, ...rest }) => rest)(entry.userDetails || {});
    state.userDetails.userDetails = userDetails;
  }),
  refreshUserLoadDetails: thunk(async (action, input, { getStoreState, getStoreActions }) => {
    const data = await getUserLoadDetails();
    if (data) {
      const oldUserDetails = getStoreState().userDetails.userDetails;
      const displayNameForRolesMap = getStoreState().userData.displayNameForRolesMap;
      const obj: { [key: string]: any } = {};
      if (oldUserDetails.tabId) {
        obj.tabId = oldUserDetails.tabId;
      }
      if (oldUserDetails.masterSettingsMetrics) {
        obj.masterSettingsMetrics = oldUserDetails.masterSettingsMetrics;
      }
      if (oldUserDetails.featurePreferencesMetrics) {
        obj.featurePreferencesMetrics = oldUserDetails.featurePreferencesMetrics;
      }
      const details = processUserLoadDetails(data, false, true);
      action.setMasterData({ ...details, userDetails: { ...data, ...obj } });
      getStoreActions().userData.setDisplayNameForRolesMap(displayNameForRolesMap);
    }
  })
};

const store = createStore<Store>(storeData, { name: 'Global Store' });

export default store;
