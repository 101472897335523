import axiosInstance from '@helpers/Axios';
import AnswerVO from '@interfaces/Models/AnswerVO';
import QuestionDetailsVO, { AnnotationObj } from '@interfaces/Models/QuestionDetailsVO';
import { UserPreferenceDataVO } from '@interfaces/Models/UserPreferenceDataVO';
import store from '../store';
import { getCurrentUser, getUser, isSupport, isSuperAdmin } from './UserLoadDetailUtil';
import { getBusinessUnitList, getProjectCustomFieldsMergeTagList } from './CustomFieldUtils';
import { isFeatureEnabled } from '@utils/UserLoadDetailUtil';
import i18next from 'i18next';
import $ from 'jquery';
import Mark from 'mark.js';
import SnackBarUtils from './SnackBarUtils';
import CompanyCollectionVO from '@interfaces/Models/CompanyCollectionVO';
import MasterDataVO from '@interfaces/Models/MasterDataVO';
import AutoFillDetailsVO from '@interfaces/Models/AutoFillDetailsVO';
import { getTinymce } from '../../src/vendors/tinymce/TinyMCE';
import { checkIsAfterDayJs } from './DayjsUtils';
const cloneDeep = require('clone-deep');
var orderby = require('lodash.orderby');
import { checkIfArraysHasContainsAny } from '@utils/ArrayUtils';
import { getJson } from '@utils/JsonUtils';
import { errorLogger } from './ErrorLogging';
import CustomFieldVO from '@interfaces/Models/CustomFieldVO';
import { updateMergeTagObjectForPopover } from './MergeTagUtils';
import { projectVO } from '@interfaces/Models/ProjectVO';
import { getUserList } from './user-load-details/ManageUserUtils';
import UserVO from '@interfaces/Models/UserVO';

export const fontFamily =
  'Andale Mono=andale mono,monospace;Arial=arial,helvetica,sans-serif;Arial Black=arial black,sans-serif;Book Antiqua=book antiqua,palatino,serif;Calibri=calibri, sans-serif;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier,monospace;Cambria=Cambria,georgia,palatino,serif;Franklin Gothic Book=Franklin Gothic Book,arial,sans-serif;Georgia=georgia,palatino,serif;Helvetica=helvetica,arial,sans-serif;Helvetica Neue=Helvetica Neue,arial,sans-serif;Impact=impact,sans-serif;Lucida Sans=Lucida Sans,sans-serif;Segoe Print=Segoe Print,sans-serif;Segoe UI=Segoe UI,sans-serif;Source Sans Pro=Source Sans Pro,sans-serif;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco,monospace;Times New Roman=times new roman,times,serif;Trebuchet MS=trebuchet ms,geneva,sans-serif;Verdana=verdana,geneva,sans-serif;Webdings=webdings;Wingdings=wingdings,zapf dingbats';
export const fontSizeList = [12, 14, 16, 18, 20, 22, 24];
export const lineHeightList = ['unset', '1', '1.15', '1.5', '2', '2.5', '3', '3.5', '4'];
export const fontColorList1 = ['inherit', '#ffa500', '#00ffff', '#008000', '#0000ff'];
export const fontColorList2 = ['#808080', '#ff00ff', '#ffff00', '#ff0000'];
export const alphabetList = ['#', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

export const permissionsByUsageMap = {
  PROJECT_RESPOND: ['RESPOND_ALL_SECTION', 'RESPOND_MY_SECTION', 'UPDATE_ALL_SECTION', 'UPDATE_MY_SECTION'],
  REQUEST_TEAM_MEMBERS: ['MANAGE_ALL_SECTION', 'MANAGE_MY_SECTION', 'VIEW_MY_REQUESTS', 'SCORING_MY_SCORECARD'],
  CONTENT_LIBRARY_MODERATOR: ['MODERATE_MY_CONTENT'],
  CONTENT_LIBRARY_OWNER: ['EDIT_MY_CONTENT'],
  CONTENT_LIBRARY_USER: ['EDIT_MY_CONTENT', 'MODERATE_MY_CONTENT']
};

export const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
export const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
export const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
const fontStyleObjList = [
  'font-size',
  'font-weight',
  'font-variant',
  'font-style',
  'background-color',
  'color',
  'r-font-family',
  'rfpio-list-type',
  'list-style-type',
  'list-style',
  'text-decoration',
  'text-transform'
];

const createCustomStyleMap = (styleList: { [key: string]: string }[], skipStoreUpdate?: boolean) => {
  const list = orderby(styleList, 'name');
  const tinymceStyleMap: { [key: string]: { [key: string]: string } } = {};
  styleList.forEach(style => {
    if (style.styleId) {
      tinymceStyleMap[style.styleId] = style;
    }
  });
  if (skipStoreUpdate) {
    return { tinyMCEStyleList: list, tinyMCEStyleMap: tinymceStyleMap };
  }
  store.getActions().styleStore.setTinyMceStyle({ tinyMCEStyleList: list, tinyMCEStyleMap: tinymceStyleMap });
};

export const checkArrayContainsAll = (superset: any[], subset: any[]) => {
  return subset.every(i => superset.includes(i));
};
export const checkArrayIntersection = (list1: any, list2: any) => {
  if (!list1 || !list2) {
    return [];
  }
  return list1.filter((value: any, index: number) => list2.indexOf(value) !== -1);
};
export const arraySubtract = (list1: any, list2: any) => {
  if (!list1 || !list2) {
    return [];
  }
  return list1.filter((value: any, index: number) => list2.indexOf(value) < 0);
};

export const getPlainText = (htmlText: string) => {
  return htmlText ? $('<p>').html(htmlText).text().trim() : '';
};

export function getCurrentUserDetails() {
  return store.getState().userDetails.userDetails.userInfoVO;
}

export function getCompanyId() {
  const companyId = store.getState().userDetails.userDetails.companyInfoVO.companyId;
  return companyId ? companyId : '';
}

export function getCompanyName() {
  return store.getState().userDetails.userDetails.companyInfoVO.companyShortName;
}

export const getBadgeContent = (featureName: string) => {
  const trialFeatureName = featureName.concat('_trial');
  const featureDetails = store.getState().userDetails.userDetails.companyPreferencesVO?.featureDetails;
  const trialFeatureDetails = store.getState().userDetails?.userTrialFeatureDetailsMap?.[trialFeatureName];
  const trialFeatureThreshold = store.getState().userDetails?.trialFeatureThresholdMap?.[trialFeatureName];
  if (featureDetails && featureDetails[featureName] && featureDetails[featureName].firstTimeEnabled) {
    let newBadgeDisableDate = new Date(featureDetails[featureName].firstTimeEnabled);
    newBadgeDisableDate.setDate(newBadgeDisableDate.getDate() + 15);
    //show 'NEW' badge only for 15 days from the first time enabled date.
    if (checkIsAfterDayJs(newBadgeDisableDate, new Date())) {
      return 'NEW';
    }
  } else if (!isFeatureEnabled(featureName)) {
    //For company user show 'TRIAL' badge if content_analysis_trial key is enabled and created count is less than trialFeatureThreshold else show 'EXPIRED' badge
    //For support user there is no limit for creation when trial feature is enabled so skipping createdCount check in trialFeatureDetails
    if (isFeatureEnabled(trialFeatureName)) {
      if ((trialFeatureDetails && trialFeatureDetails.createdCount < trialFeatureThreshold) || isSupport()) {
        return 'TRIAL';
      } else {
        return 'EXPIRED';
      }
    } else {
      return 'EXPIRED';
    }
  } else {
    return '';
  }
};

export function checkPermissionEnabled(permission: string) {
  const permissionData: any = store.getState().userData.permissionData;
  return permissionData.isPermissionEnabled[permission] ? true : false;
}

export function getPermissionMap() {
  return store.getState().userData.permissionData;
}

export function currencyFormat(num: number) {
  const symbol = store.getState().userDetails.userDetails.companyInfoVO.currency.symbol;
  return symbol + num.toFixed(2)?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}
export const titlecase = function (s: string) {
  s = s === undefined || s === null ? '' : s;
  return s
    .toString()
    .toLowerCase()
    ?.replace(/\b([a-z])/g, function (ch) {
      return ch.toUpperCase();
    })
    .replace(/'\S/g, function (ch) {
      return ch.toLowerCase();
    });
};

export const removeUnderscore = function (s: string) {
  return s ? s.replace(/[^A-Z0-9a-z]/gi, ' ') : null;
};

export const getFileSize = function (bytes: any, precision?: any) {
  if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-';

  if (typeof precision === 'undefined') precision = 1;
  const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
  if (bytes === 0) {
    return '0 ' + units[0];
  }
  const number = Math.floor(Math.log(bytes) / Math.log(1024));

  return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + units[number];
};

export const getAnnotationsCount = function (quest: QuestionDetailsVO, ans: AnswerVO) {
  var ansNode = null;
  if (ans == null) {
    return 0;
  }
  if (ans.answer) {
    try {
      ansNode = $(ans.answer);
    } catch (err) {
      ansNode = $('<p>');
      ansNode.html(ans.answer);
    }
  } else if (quest && quest.contentType === 'DESCRIPTION' && quest.sortingOrder === -1) {
    try {
      ansNode = $(quest.question);
    } catch (err) {
      ansNode = $('<p>');
      ansNode.html(quest.question);
    }
  }
  if (ansNode != null) {
    return ansNode.find('[data-annotation-value]').length;
  } else {
    return 0;
  }
};

export const getAnnotations = function (quest: QuestionDetailsVO, ans: AnswerVO) {
  quest.annotations = [];
  quest.annotationGroup = [];
  let selector;
  if (quest.contentType === 'QUESTION') {
    selector = ans.answer;
    // $rootScope.activeAnnotationAnswer = _.cloneDeep(ans);
  } else if (quest.contentType === 'DESCRIPTION') {
    selector = quest.question;
    // $rootScope.activeAnnotationAnswer = _.cloneDeep(quest);
  }

  const element = new DOMParser().parseFromString(selector, 'text/html');

  if (element) {
    $(element)
      .find('[data-annotation-value]')
      .each(function () {
        const val = $(this).attr('data-annotation-value');
        const id = $(this).attr('data-annotation-id');
        const text = $(this).text();
        const obj: AnnotationObj = {
          id: id,
          value: val,
          text: text,
          user: undefined,
          type: 'internal',
          date: undefined
        };
        const user = $(this).attr('data-annotation-user');
        if (user) {
          obj.user = user;
        }
        const type = $(this).attr('data-annotation-type');
        if (type && type != '') {
          obj.type = type;
        }
        if (quest.annotationGroup.indexOf(obj.type) < 0) {
          quest.annotationGroup.push(obj.type);
        }
        const date = $(this).attr('data-annotation-date');
        if (date) {
          obj.date = date;
        }
        quest.annotations.push(obj);
      });
  }
  return quest;
};
export const removeSolrSpecialChar = function (text: string) {
  var returnText = text.replace(/[\&\+\:\;\?\_\|\"\[\]\{\}\~\/\^\*\(\)\!\-\“\”]/gi, ' ');
  returnText = returnText.toLowerCase();
  returnText = returnText.replace(/\band\b/gi, '');
  returnText = returnText.replace(/\bor\b/gi, '');
  returnText = returnText.replace(/\bnot\b/gi, '');
  returnText = returnText.replace(/(\s)+/gi, ' ');
  returnText = returnText.replace(/^[0-9\.\s]+\b/gi, '');
  returnText = returnText.trim();
  return returnText;
};

export const wordToHighlight = function (searchKeyword: string, highlightWords: string[]) {
  let keyWords: string[] = [];
  if (highlightWords && Array.isArray(highlightWords) && highlightWords.length > 0) {
    keyWords = highlightWords;
  }
  if (searchKeyword !== null) {
    const regex = /[\u3000-\u303F]|[\u3040-\u309F]|[\u30A0-\u30FF]|[\uFF00-\uFFEF]|[\u4E00-\u9FAF]|[\u2605-\u2606]|[\u2190-\u2195]|\u203B/g;
    const keyword = searchKeyword;
    const result = keyword.match(regex);
    if (result && result.length > 0) {
      options.accuracy.value = 'partially';
    } else {
      options.accuracy.value = 'exactly';
    }
    const exp = /\".*?\"/gm;
    let word = '';
    let match = null;
    const lowercaseKey = searchKeyword.toLowerCase();
    while ((match = exp.exec(lowercaseKey))) {
      const phrase: string = match[0].substring(1, match[0].length - 1);
      const trimmedPhrase: string = phrase.trim();
      if (!keyWords.includes(trimmedPhrase)) {
        keyWords.push(trimmedPhrase);

        if (trimmedPhrase.includes("'")) {
          const replacedWord = trimmedPhrase.replaceAll("'", '’');
          !keyWords.includes(replacedWord) && keyWords.push(replacedWord);
        }
      }
    }
    word = word.replace(/\".*?\"/gm, ' ');
    let tempKeyword = word.split(/[\s\,\.\;\-:\_\/\[\]\"\~]/g);
    if (highlightWords && Array.isArray(highlightWords)) {
      tempKeyword = tempKeyword.concat(highlightWords);
    }
    tempKeyword.forEach((key: any) => {
      if (key.length > 0 && keyWords.indexOf(key) < 0 && !keyWords.includes(key)) {
        keyWords.push(key);

        if (key.includes("'")) {
          const replacedWord: string = key.replaceAll("'", '’');
          !keyWords.includes(replacedWord) && keyWords.push(replacedWord);
        }
      }
    });
  }
  return keyWords;
};

export const getFontFamilyObject = (fontFamilyOrg: { text: string; value: string }[] | null, styleList?: { [key: string]: string }[]) => {
  let fontObjectList: { text: string; value: string }[] = [];
  const fontFamilyMap: { [key: string]: string } = {}; // Key is a font value
  let customFonts = '';
  var fonts = fontFamily.split(';');
  fonts.forEach(font => {
    var ary = font.split('=');
    var obj = {
      text: ary[0],
      value: ary[1]
    };
    fontFamilyMap[ary[1]] = ary[0];
    if (fontObjectList.indexOf(obj) < 0) {
      fontObjectList.push(obj);
    }
  });
  fontFamilyOrg &&
    fontFamilyOrg.forEach(font => {
      fontFamilyMap[font.value.replace(/['"]+/g, '').replace(/,\s*/g, ',')] = font.text;
      if (fontObjectList.indexOf(font) < 0) {
        fontObjectList.push(font);
      }
    });
  // fontObjectList = $filter('orderBy')(fontObjectList, 'text');
  fontObjectList.forEach(f => {
    customFonts += f.text + '=' + f.value + ';';
  });
  let tinymceObj: any = {};
  if (styleList) {
    tinymceObj = createCustomStyleMap(styleList, true);
  }

  // if(tinyCommonOptions){
  //     tinyCommonOptions.font_formats = customFonts;
  // }
  return { fontObjectList, fontFamilyMap, ...tinymceObj };
};

export function getStyleMap(styleValue: string | null, isForTextStyleCompare?: boolean) {
  const styleMap: { [key: string]: string } = {};
  if (styleValue) {
    const styles: string[] = styleValue.split(';');
    styles.forEach(style => {
      const ary = style.split(':');
      if (ary[0] && (!isForTextStyleCompare || fontStyleObjList.includes(ary[0])) && ary[1]) {
        styleMap[ary[0].trim()] = ary[1].trim();
      }
    });
  }
  return styleMap;
}

export function getStyleString(styleMap: { [key: string]: string }) {
  let styleString = '';
  Object.keys(styleMap).forEach(key => {
    styleString += key + ': ' + styleMap[key] + '; ';
  });
  return styleString;
}

export function getUniqueList(list: string[]) {
  const tempList: string[] = [];
  list.forEach(l => {
    if (tempList.indexOf(l) < 0) {
      tempList.push(l);
    }
  });
  return tempList;
}

export const setAndGetUniqueItems = (list: string[], item: string) => {
  const inputList: string[] = cloneDeep(list);
  const idx: number = inputList.indexOf(item);
  if (idx < 0) {
    inputList.push(item);
  }
  return Array.from(new Set(inputList));
};

export function getParentData(questions: any, id: any) {
  let invalidQuestions = id ? [id] : [];
  let hasMoreRecords = true;
  while (hasMoreRecords) {
    let invalidNewSet = questions.filter((question: any) => {
      if (invalidQuestions.indexOf(question.id) >= 0) {
        return true;
      }
      if (!(question.contentType === 'QUESTION')) {
        return true;
      }
      if (question.parentId == null) {
        return false;
      }
      if (question.hidden) {
        return true;
      }
      return invalidQuestions.indexOf(question.parentId) >= 0;
    });
    if (invalidNewSet.length > invalidQuestions.length) {
      invalidQuestions = [];
      invalidNewSet.forEach((quest: any) => {
        invalidQuestions.push(quest.id);
      });
    } else {
      hasMoreRecords = false;
    }
  }
  let validQuestion = questions.filter((quest: any) => {
    return invalidQuestions.indexOf(quest.id) < 0;
  });
  return validQuestion;
}

export function stripEnd(str: string, stripChars: string) {
  let end = str.length;
  if (!str || end === 0) {
    return str;
  }

  if (!stripChars) {
    while (end !== 0 && str[end - 1] === ' ') {
      end--;
    }
  } else {
    while (end !== 0 && stripChars.indexOf(str[end - 1]) !== -1) {
      end--;
    }
  }
  return str.substring(0, end);
}

export function getHeaderSuffix(answers: any, header?: string, fromOne?: boolean) {
  if (!answers) {
    return 'Response';
  }
  if (Array.isArray(answers)) {
    let j = 1;
    let temp = 'Response';
    let headerVal = 'Response';
    if (fromOne) {
      temp += ' ' + j;
    }
    if (header) {
      headerVal = header;
      temp = header;
      if (fromOne) {
        temp += ' ' + j;
      }
    }

    if (answers.filter(answer => checkIfHeaderIsNumbered(answer.answerHeader || answer.key, headerVal) && answer.answerType != 'NONE').length === 0) {
      temp = header ? header : 'Response';
    }

    let found = false;
    while (!found) {
      let loopCount = 0;
      if (answers.length == 0) {
        found = true;
      }
      for (let i = 0; i < answers.length; i++) {
        let ans = answers[i];
        if ((ans.answerType && ans.answerType != 'NONE' && temp === ans.answerHeader) || (ans.type && ans.type != 'NONE' && temp === ans.key)) {
          temp = headerVal + ' ' + j;
          j++;
          break;
        } else {
          loopCount++;
        }
        if (loopCount == answers.length) {
          //looped all,
          found = true;
        }
      }
    }
    return temp;
  } else {
    const ansrs: any[] = [];
    Object.entries(answers).forEach(([key, value]) => {
      ansrs.push(value);
    });
    let j = 1;
    let temp = 'Response';
    let found = false;
    while (!found) {
      let loopCount = 0;

      if (ansrs.length == 0) {
        found = true;
        temp = 'Response';
      }

      for (let i = 0; i < ansrs.length; i++) {
        const ans = ansrs[i];
        if (ans.type != 'NONE' && temp === ans.header) {
          j++;
          temp = 'Response ' + j;
          break;
        } else {
          loopCount++;
        }
        if (loopCount == ansrs.length) {
          //looped all,
          found = true;
        }
      }
      return temp;
    }
  }
}

const isNumberOrEmptyForHeader = (str: string) => {
  if (!str) {
    return true;
  }
  for (let i = 0; i < str.length; i++) {
    if (str[i] < '0' || str[i] > '9') {
      return false;
    }
  }
  return true;
};

/**
 * To update existing Headers to Response 1, Reponse 2 and "Response 1" to "Response" if only one header is present. To be called before updating response headers.
 * @param answers
 * @param isAdd
 * @param fromCCL
 * @returns answers array or null
 */
export const updateExistingHeaders = (answers: any, isAdd: boolean, fromCCL?: boolean, header?: string): any => {
  if (!answers) {
    return;
  }

  if (Array.isArray(answers)) {
    let temp = 'Response';
    if (header) {
      temp = header;
    }

    const answerTypeKey = fromCCL ? 'type' : 'answerType';
    const answerHeaderKey = fromCCL ? 'key' : 'answerHeader';

    const noOfValidHeaders = answers.filter(answer => checkIfHeaderIsNumbered(answer[answerHeaderKey], temp) && answer[answerTypeKey] !== 'NONE').length;

    answers = answers.map(answer => {
      if (isAdd) {
        const tempAnswerHeader = answer[answerHeaderKey].split(temp);
        if ((isNumberOrEmptyForHeader(tempAnswerHeader[1]) || answer[answerHeaderKey].trim() === temp) && checkIfHeaderIsNumbered(answer[answerHeaderKey], temp) && answer[answerTypeKey] !== 'NONE') {
          tempAnswerHeader[1] = checkDuplicateHeaderAndGetNumber(answers, temp, answerHeaderKey, answerTypeKey);
          answer[answerHeaderKey] = temp + (' ' + tempAnswerHeader[1]);
        } else {
          // do nothing
        }
      } else {
        if (noOfValidHeaders === 1 && answer[answerTypeKey] !== 'NONE' && checkIfHeaderIsNumbered(answer[answerHeaderKey], temp)) {
          answer[answerHeaderKey] = temp;
        } else {
          // do nothing
        }
      }
      return answer;
    });
    return answers;
  }
};

export const checkIfHeaderIsNumbered = (responseHeader: string, header: string): boolean => {
  const tempHeaders = responseHeader.split(header);
  return !tempHeaders[0] && isNumberOrEmptyForHeader(tempHeaders[1]?.trim());
};

const checkDuplicateHeaderAndGetNumber = (answers: any, header: string, answerHeaderKey: string, answerTypeKey: string): number => {
  const totalAnswers = answers.length;
  for (let i = 1; i <= totalAnswers; i++) {
    const newHeader = header + ' ' + i;
    const duplicateAnswerHeaders = answers.filter((ans: any) => ans[answerHeaderKey] === newHeader && ans[answerTypeKey] !== 'NONE');
    if (duplicateAnswerHeaders.length === 0) {
      return i;
    } else {
      // do nothing
    }
  }
  return totalAnswers;
};

export const getDisplayFriendlyAnswerType: { [key: string]: string } = {
  DROPDOWN: 'Dropdown',
  MULTI_COLUMN_DROPDOWN: 'Multi-column Dropdown',
  MULTI_COLUMN_RADIO: 'Multi-column Radio',
  RADIO: 'Radio',
  CHECKBOX: 'Checkbox',
  RICH_TEXT: 'Full Rich Text',
  TEXT: 'Text',
  BASIC_RICH_TEXT: 'Basic Rich Text',
  DATE: 'Date',
  TABLE: 'Table',
  LABEL: 'Label'
};
export const answersFill = (question: any) => {
  for (var i = 0; i < question.answers.length; i++) {
    var ans = question.answers[i];
    if (!ans.optional) {
      if (ans.answerType !== 'LABEL' && (ans.answer == undefined || ans.answer == '' || ans.answer == null || ans.answer == '<div></div>' || ans.answer == '<div>&nbsp;</div>')) {
        return false;
      } else if (ans.answerType !== 'LABEL' && ans.answerType !== 'CHECKBOX') {
        if (ans.answer === '') {
          return false;
        }
      } else if (ans.answerType == 'CHECKBOX') {
        var tempAns = JSON.parse(ans.answer);
        if (tempAns.length == 0) {
          return false;
        }
      }
    }
  }
  return true;
};

export const changeAnswerStatus = (question: any, onload: Boolean) => {
  if (answersFill(question)) {
    if (isFeatureEnabled('answer_completion') && !onload) {
      question.tempStatus = 'ANSWERED';
    }
    question.isRequired = false;
  } else {
    question.isRequired = true;
    question.tempStatus = 'NOT_ANSWERED';
  }
};

export const answerTypeOptionsWithText = [
  { key: 'TEXT', value: 'Text' },
  { key: 'BASIC_RICH_TEXT', value: 'Basic Rich Text' },
  { key: 'RICH_TEXT', value: 'Full Rich Text' },
  { key: 'DATE', value: 'Date' },
  { key: 'DROPDOWN', value: 'Dropdown' },
  { key: 'RADIO', value: 'Radio' },
  { key: 'CHECKBOX', value: 'Checkbox' },
  { key: 'MULTI_COLUMN_DROPDOWN', value: 'Multi-column Dropdown' },
  { key: 'MULTI_COLUMN_RADIO', value: 'Multi-column Radio' },
  { key: 'LABEL', value: 'Label' }
];

export const answerTypeOptions = [
  { key: 'BASIC_RICH_TEXT', value: 'Basic Rich Text' },
  { key: 'RICH_TEXT', value: 'Full Rich Text' },
  { key: 'DATE', value: 'Date' },
  { key: 'DROPDOWN', value: 'Dropdown' },
  { key: 'RADIO', value: 'Radio' },
  { key: 'CHECKBOX', value: 'Checkbox' },
  { key: 'MULTI_COLUMN_DROPDOWN', value: 'Multi-column Dropdown' },
  { key: 'MULTI_COLUMN_RADIO', value: 'Multi-column Radio' },
  { key: 'LABEL', value: 'Label' }
];

export const newAnswerTypeOptions = [
  { key: 'TEXT', value: 'Text' },
  { key: 'SCALE', value: 'Scale' },
  { key: 'NUMBER_WITH_UNITS', value: 'Number With Units' },
  { key: 'FILE_ATTACHMENT', value: 'File Attachment' },
  { key: 'TABLE', value: 'Table' }
];

export const requestAnswerTypes = [
  { key: 'BASIC_RICH_TEXT', value: 'Basic Rich Text' },
  { key: 'RICH_TEXT', value: ' Full Rich Text' },
  { key: 'DATE', value: 'Date' },
  { key: 'DROPDOWN', value: 'Dropdown' },
  { key: 'RADIO', value: 'Radio' },
  { key: 'CHECKBOX', value: 'Checkbox' },
  { key: 'FILE_ATTACHMENT', value: 'File Attachment' },
  { key: 'SCALE', value: 'Scale' },
  { key: 'TABLE', value: 'Table' },
  { key: 'LABEL', value: 'Label' }
];

export const addBulkIntakeSurveyAnswerTypes = [
  { key: 'BASIC_RICH_TEXT', value: 'Basic Rich Text' },
  { key: 'RICH_TEXT', value: 'Full Rich Text' },
  { key: 'DROPDOWN', value: 'Dropdown' },
  { key: 'RADIO', value: 'Radio' }
];

export const newAnswerTypes = ['TEXT', 'SCALE', 'NUMBER_WITH_UNITS', 'FILE_ATTACHMENT', 'TABLE'];

export const getAnsEditKey = (ans: any) => {
  var key = null;
  if (ans.answerType === 'TEXT') {
    key = ans.answerHeader + ' (Text)';
  } else if (ans.answerType === 'RICH_TEXT') {
    key = ans.answerHeader + ' (Full Rich Text)';
  } else if (ans.answerType === 'BASIC_RICH_TEXT') {
    key = ans.answerHeader + ' (Basic Rich Text)';
  } else if (ans.answerType === 'DATE') {
    key = ans.answerHeader + ' (Date)';
  } else if (ans.answerType.indexOf('DROPDOWN') > -1) {
    key = ans.answerHeader + ' (Dropdown)';
  } else if (ans.answerType.indexOf('RADIO') > -1) {
    key = ans.answerHeader + ' (Radio)';
  } else if (ans.answerType.indexOf('CHECKBOX') > -1) {
    key = ans.answerHeader + ' (Checkbox)';
  } else if (ans.answerType.indexOf('LABEL') > -1) {
    key = ans.answerHeader + ' (Label)';
  } else if (ans.answerType.indexOf('TABLE') > -1) {
    key = ans.answerHeader + ' (Table)';
  } else if (ans.answerType.indexOf('SCALE') > -1) {
    key = ans.answerHeader + ' (Scale)';
  } else if (ans.answerType.indexOf('FILE_ATTACHMENT') > -1) {
    key = ans.answerHeader + ' (File Attachment)';
  } else if (ans.answerType.indexOf('NUMBER_WITH_UNITS') > -1) {
    key = ans.answerHeader + ' (Number With Units)';
  }
  return key;
};

export const modifyFilteredStandardResponses = (answerOptions: any, recommendationStandardResponses: any) => {
  var masterClone = cloneDeep(recommendationStandardResponses);
  var usedResponses: [] = [];
  answerOptions.forEach((opt: any) => {
    if (opt.standardResponse) {
      if (usedResponses.indexOf(opt.standardResponse) < 0) {
        masterClone.splice(masterClone.indexOf(opt.standardResponse), 1);
        usedResponses.push(opt.standardResponse);
      } else {
        opt.standardResponse = '';
      }
    }
  });
  answerOptions.forEach((opt: any) => {
    opt.$$filteredStandardResponses = cloneDeep(masterClone);
    if (opt.standardResponse && recommendationStandardResponses.indexOf(opt.standardResponse) >= 0) {
      opt.$$filteredStandardResponses.push(opt.standardResponse);
      const sortedList: [] = [];
      recommendationStandardResponses.forEach((resp: any) => {
        if (opt.$$filteredStandardResponses.indexOf(resp) >= 0) {
          sortedList.push(resp);
        }
      });
      opt.$$filteredStandardResponses = cloneDeep(sortedList);
      if (!opt.key || (opt.key && opt.key != opt.standardResponse && opt.$$filteredStandardResponses.includes(opt.key))) {
        opt.key = opt.standardResponse;
      }
      if (!opt.value || (opt.value && opt.value != opt.standardResponse && opt.$$filteredStandardResponses.includes(opt.value))) {
        opt.value = opt.standardResponse;
      }
    } else {
      opt.standardResponse = '';
    }
  });
};

export const checkIsUniqueHeader = (ans: any) => {
  for (let i = 0, len = ans.length; i < len; i++) {
    const ansHeader = ans.filter((a: any) => {
      return (
        (a.answerHeader && a.answerHeader === ans[i].answerHeader && a.answerType !== 'NONE' && a.answerType === ans[i].answerType) ||
        (a.header && a.header === ans[i].header && a.type != 'NONE' && a.type === ans[i].type)
      );
    });
    if (ansHeader.length > 1) {
      return true;
    }
  }
  return false;
};

export const getItemsPerPage = (context: string, defaultLimit?: number) => {
  let localData: any = localStorage.getItem('itemsPerPage') || '{}';
  localData = JSON.parse(localData);
  if (localData && localData[context]) {
    return localData[context];
  }
  localData[context] = defaultLimit || 25;
  localStorage.setItem('itemsPerPage', JSON.stringify(localData));
  return defaultLimit || 25;
};

export const setItemsPerPageLocal = (context: string, itemsPerPage: number) => {
  let localData: any = localStorage.getItem('itemsPerPage') || '{}';
  localData = JSON.parse(localData);
  localData[context] = itemsPerPage;
  localStorage.setItem('itemsPerPage', JSON.stringify(localData));
};

export const getUserPreference = (key: string) => {
  const localData: any = localStorage.getItem('userPreference') || '{}';
  return JSON.parse(localData);
};

export const getUserPreferenceForKey = (key: string) => {
  const data = getUserPreference(key);
  if (data && data[key]) {
    return data[key];
  }
  return {};
};

export const setUserPreference = (data: any) => {
  localStorage.setItem('userPreference', JSON.stringify(data));
};

export const setUserPreferenceForKey = (key: string, value: any) => {
  let data = getUserPreference(key);
  if (!data) {
    data = {};
  }
  if (!data[key]) {
    data[key] = {};
  }
  data[key] = value;
  setUserPreference(data);
};

export const saveUserViewPreference = async (userViewPrefData: UserPreferenceDataVO) => {
  const response = await axiosInstance().post('/users/save-view-preferences', userViewPrefData);
  if (response && response.status === 200) {
    return response.data;
  }
};

export const swapPositions = (colArray: { [x: string]: any }, from: number, to: number) => {
  colArray.splice(to, 0, colArray.splice(from, 1)[0]);
};

export let batchDetails: any = {};
export const getBatchProcessDetails = async (id: string, callBackFunc?: (batchDetails: any) => void) => {
  const response = await axiosInstance().get('batch-process/get-batch-details', { params: { id: id } });
  if (response.status === 200) {
    batchDetails = response.data;
    batchDetails.batchId = id;
    callBackFunc && callBackFunc(batchDetails);
  }
};

let batchInfoInterval: any;
export const batchInfoIntervalInvoke = (callBackFunc: any, callBackParam: any, intervalDuration?: number) => {
  batchInfoInterval = setInterval(function () {
    if (batchDetails.status === 'ERROR') {
      if (batchDetails.type === 'uploadExportPackageProcess') {
        SnackBarUtils.error(i18next.t('ERROR_WHILE_PUBLISHING_PACKAGE'));
      } else if (batchDetails.type === 'liveDocGenerationProcess') {
        SnackBarUtils.error(i18next.t('ERROR_WHILE_GENERATING_LIVEDOCS'));
      } else {
      }
      // showBulkErrorToast();
      clearInterval(batchInfoInterval);
      batchDetails = {};
    } else if (batchDetails.status === 'COMPLETED') {
      const message = batchDetails.data;
      batchDetails.notUpdated = message.total - message.updatedCount;
      // showCompletedToast($rootScope.message, $rootScope.batchType);
      clearInterval(batchInfoInterval);
      if (callBackFunc) {
        callBackFunc(callBackParam, batchDetails);
      }
      batchDetails = {};
    } else if (batchDetails.batchId && batchDetails.status != 'COMPLETED' && batchDetails.status != 'ERROR') {
      getBatchProcessDetails(batchDetails.batchId);
    }
  }, intervalDuration || 10 * 1000);
};

// Quick sort Algorithm
export const quickSort = function (arr: any[], low: number, high: number) {
  if (low < high) {
    const pi = partition();

    quickSort(arr, low, pi - 1);
    quickSort(arr, pi + 1, high);
  }

  function partition() {
    const pivot = arr[high];
    let i = low - 1;
    for (let j = low; j < high; j++) {
      if (arr[j].localeCompare(pivot) <= 0) {
        i++;

        const tmp = arr[i];
        arr[i] = arr[j];
        arr[j] = tmp;
      }
    }

    const temp = arr[i + 1];
    arr[i + 1] = arr[high];
    arr[high] = temp;

    return i + 1;
  }
};

// Binary Search array Subset Algorithm
export const isArraySubset = function (arr1: any[], arr2: any[], m: number, n: number) {
  let i = 0;

  quickSort(arr1, 0, m - 1);
  for (; i < n; i++) {
    if (binarySearch(arr1, 0, m - 1, arr2[i]) == -1) {
      return false;
    }
  }

  function binarySearch(arr: any[], low: number, high: number, x: any) {
    if (high >= low) {
      const mid = Math.floor((low + high) / 2);
      if ((mid == 0 || x.localeCompare(arr[mid - 1]) > 0) && arr[mid].localeCompare(x) == 0) {
        return mid;
      } else if (x.localeCompare(arr[mid]) > 0) {
        return binarySearch(arr, mid + 1, high, x);
      } else {
        return binarySearch(arr, low, mid - 1, x);
      }
    }
    return -1;
  }

  return true;
};

export const setUnsetListItem = function (list: any[], item: any) {
  const idx = list.indexOf(item);
  if (idx < 0) {
    list.push(item);
  } else {
    list.splice(idx, 1);
  }
};
export const setRecentProject = (projectId: string, companyId: string, viewType?: boolean) => {
  const user = getCurrentUser().username;
  const recentType = viewType ? 'recentRequests' : 'recentProjects';
  let recentProject = JSON.parse(localStorage.getItem(recentType) || '{}');
  if (!recentProject[user]) {
    recentProject[user] = {};
    recentProject[user][companyId] = [projectId];
  } else if (!recentProject[user][companyId]) {
    recentProject[user][companyId] = [projectId];
  } else {
    if (recentProject[user] && recentProject[user][companyId] && recentProject[user][companyId].includes(projectId)) {
      const index = recentProject[user][companyId].indexOf(projectId);
      recentProject[user][companyId].splice(index, 1);
    }
    recentProject[user][companyId].unshift(projectId);
  }
  if (recentProject[user][companyId].length > 5) {
    recentProject[user][companyId].pop();
  }
  localStorage.setItem(recentType, JSON.stringify(recentProject));
};
export const getBusinessUnitForEntity = function (entityBU: string[], tempCurrentUserInfo?: { [key: string]: any }) {
  let currentUserInfo: { [key: string]: any } = store.getState().userDetails.userDetails.userInfoVO;
  currentUserInfo = currentUserInfo ? currentUserInfo : tempCurrentUserInfo;
  let businessUnits: string[] = [];
  const isBuEnabled = store.getState().userDetails.userDetails.companyPreferencesVO.featurePreferences.business_units === '1';
  const permissionData = store.getState().userData.permissionData;
  if (entityBU && isBuEnabled) {
    const checkUserHasAll = permissionData.isSupport || permissionData.isSuperAdmin || checkIfArraysHasContainsAny(currentUserInfo?.businessUnits, ['ALL']);
    if (checkIfArraysHasContainsAny(entityBU, ['ALL'])) {
      if (checkUserHasAll) {
        const businessUnitsList = getBusinessUnitList();
        for (let i = 0; i < businessUnitsList.length; i++) {
          businessUnits.push(businessUnitsList[i].id);
        }
        return businessUnits;
      }
      return cloneDeep(currentUserInfo?.businessUnits);
    }
    if (checkUserHasAll) {
      return cloneDeep(entityBU);
    }
    return cloneDeep(checkArrayIntersection(entityBU, currentUserInfo?.businessUnits));
  }
  return businessUnits;
};

export const getKeysFromObjList = (list: any[], key: string) => {
  const keys: string[] = [];
  list &&
    list.forEach(item => {
      if (item[key]) {
        keys.push(item[key]);
      }
    });
  return keys;
};

export const options: any = {
  accuracy: {
    value: 'exactly',
    limiters: [',', '.', ';', '-', ':', '_', '/', '[', ']', '"', '~', '?', '<', '>', '$', "'", '\\', '(', ')', '*', '%', '`', '^', '&', '#', '{', '}', '|', '!', '@', '+', '=', '’', '„']
  },
  element: 'span',
  className: 'highlight',
  acrossElements: true,
  ignoreJoiners: true,
  separateWordSearch: false
};

export const highlightWordsForDiv = (keywords: string[], highlightOptions?: any, isCardView?: boolean) => {
  keywords = getFinalKeywordList(keywords);
  let mInstance: Mark;
  if (isCardView) {
    mInstance = new Mark('div.highlight_card');
  } else {
    mInstance = new Mark('div.context');
  }
  mInstance.unmark({
    done: () => {
      mInstance.mark(keywords, highlightOptions ? highlightOptions : options);
    }
  });
};

export const getFinalKeywordList = (keywords: string[]) => {
  let finalKeywordList: string[] = [];
  for (let i = 0; i < keywords.length; i++) {
    const keyword: string = keywords[i];
    if (keyword.length > 3000) {
      let splitKeywords: RegExpMatchArray | null = keyword.match(/.{1,3000}(?:\s|$)/g);
      if (splitKeywords) {
        finalKeywordList = [...finalKeywordList, ...splitKeywords];
      }
    } else {
      finalKeywordList.push(keyword.trim());
    }
  }
  return finalKeywordList;
};

export const highlightWordsForContext = (keywords: string[], context: string, highlightOptions: any) => {
  const mInstance = new Mark(context);
  mInstance.unmark({
    done: () => {
      mInstance.mark(keywords, highlightOptions);
    }
  });
};

export const highlightRow = (rowId: string) => {
  removeHighlightRow();
  $(`#agGrid [row-id="${rowId}"]`).addClass('grid-row-focus');
};

export const removeHighlightRow = () => {
  $('.grid-row-focus').removeClass('grid-row-focus');
};

export const removeUnderscoreAndCapitalize = (value: string) => {
  const splitString = value.toLowerCase().split('_');
  for (let i = 0; i < splitString.length; i++) {
    splitString[i] = splitString[i].charAt(0).toUpperCase() + splitString[i].substring(1);
  }
  return splitString.join(' ');
};

export const capitalizeFirstLetter = (value: string): string => {
  if (!value || value.trim().length === 0) return '';
  return value.trim().charAt(0).toUpperCase() + value.trim().slice(1);
};

export const removeTranslateType = (value: string) => {
  const splitString = value.split('.');
  return splitString[1];
};

export const getActivityCustomFields = (contentType?: string) => {
  const alCustomFields = store.getState().customFieldDetails.customFields;
  const activityCustomFieldsObj: any = { fieldsList: [], fieldsMap: {}, questionId: '', alternateQuestionsId: '' };
  const questionFieldsList = ['question', 'alternateQuestions'];
  const isSectionTemplate: boolean = contentType === 'SECTION_TEMPLATE' || contentType === 'SUB_SECTION';
  const isCatalog: boolean = contentType === 'CATALOG';
  alCustomFields.companyCustomFieldList.forEach((customField: any) => {
    if ((!isSectionTemplate && questionFieldsList.includes('question') && customField.name === 'question') || (isSectionTemplate && customField.name === 'sectionTemplateName')) {
      activityCustomFieldsObj.questionId = customField.id;
    } else if (questionFieldsList.includes('alternateQuestions') && customField.name === 'alternateQuestions') {
      activityCustomFieldsObj.alternateQuestionsId = customField.id;
    } else {
      //do nothing
    }
    if (
      (!isSectionTemplate && !isCatalog) ||
      (['SECTION_TEMPLATE', 'CATALOG', 'ALL'].includes(customField.entityType) && customField.name !== 'approvers') ||
      ['reviewFlag', 'myFavorites'].includes(customField.name)
    ) {
      const obj: any = { id: '', name: '' };
      obj.id = customField.id;
      obj.name = customField.name;
      activityCustomFieldsObj.fieldsList.push(obj);
      activityCustomFieldsObj.fieldsMap[customField.id] = customField.displayName;
    }
  });
  if (!isSectionTemplate) {
    const answersObj: any = { id: 'answers', name: 'Answers' };
    activityCustomFieldsObj.fieldsList.push(answersObj);
    activityCustomFieldsObj.fieldsList.push({ id: 'responsemergetag', name: 'Response Mergetag' });
    activityCustomFieldsObj.fieldsMap['answers'] = 'Answers';
    activityCustomFieldsObj.fieldsMap['responsemergetag'] = 'Response Mergetag';
  }
  activityCustomFieldsObj.fieldsList.push({ id: 'sharedbusinessunits', name: 'Shared Business Units' });
  activityCustomFieldsObj.fieldsList.push({ id: 'primarybusinessunit', name: 'Primary Business Unit' });
  activityCustomFieldsObj.fieldsList.push({ id: 'attachedfiles', name: 'Attached Files' });
  activityCustomFieldsObj.fieldsList.push({ id: 'reviewcompletedby', name: 'Review Completed By' });
  activityCustomFieldsObj.fieldsMap['sharedbusinessunits'] = 'Shared Business Units';
  activityCustomFieldsObj.fieldsMap['primarybusinessunit'] = 'Primary Business Unit';
  activityCustomFieldsObj.fieldsMap['attachedfiles'] = 'Attached Files';
  activityCustomFieldsObj.fieldsMap['reviewcompletedby'] = 'Review Completed By';
  return activityCustomFieldsObj;
};

export const removeDuplicatesFromArray = (arr: any[]) => {
  const uniqueArr = [];
  for (let i = 0, len = arr.length; i < len; i++) {
    if (uniqueArr.indexOf(arr[i]) === -1) {
      uniqueArr.push(arr[i]);
    }
  }
  return uniqueArr;
};
export const removeSpecificItem = (items: any[], id: string) => {
  for (let i = 0, len = items.length; i < len; i++) {
    if (items[i].id === id) {
      items.splice(i, 1);
      break;
    }
  }
};

export const getCustomfieldKeyByType = (type: string) => {
  let fieldKey: string = '';
  const customFieldMap: { [key: string]: any } = store.getState().customFieldDetails.libraryCustomFieldMap;
  if (customFieldMap && Object.keys(customFieldMap).length > 0) {
    Object.keys(customFieldMap).forEach((customFieldId: string) => {
      const customFieldVO: CustomFieldVO = customFieldMap[customFieldId];
      if (customFieldVO?.type === type) {
        fieldKey = customFieldId;
      }
    });
  }
  return fieldKey;
};

export const customPriorityComparator = (valueA: any, valueB: any) => {
  const priority = ['NONE', 'LOW', 'MEDIUM', 'HIGH'];
  const A = priority.indexOf(valueA);
  const B = priority.indexOf(valueB);
  return A > B ? 1 : A < B ? -1 : 0;
};
export const customLowerCaseComparator = (valueA: any, valueB: any) => {
  if (valueA[0].localeCompare(valueB[0]) === 0) {
    const len = valueA.length > valueB.length ? valueB.length : valueA.length;
    for (var i = 0; i < len; i++) {
      if (len - 1 === i && valueA[i].localeCompare(valueB[i]) === 0) return valueA.length > valueB.length ? 1 : -1;
      else if (valueA[i].localeCompare(valueB[i]) === 0) continue;
      else return valueA[i].localeCompare(valueB[i]);
    }
  }
  return valueA[0].localeCompare(valueB[0]);
};

export const addLineBreakInContentEditableElement = () => {
  let selected: any = document.getSelection();
  let content: string = selected.anchorNode.data ? selected.anchorNode.data : '';
  // if (!content) {
  //     return;
  // }
  const totalLengthOfStr: number = content && content.length ? content.length : 0;
  const anchorOffset: number = selected.anchorOffset;
  //if cursor is end of the line
  if (totalLengthOfStr === anchorOffset) {
    document.execCommand('insertHTML', true, '<br/>&#8203;');
    document.execCommand('delete', true);
  } else {
    const newLineString: string = content.substring(anchorOffset, totalLengthOfStr);
    const stringBeforeCursor: string = content.substring(0, anchorOffset);
    selected.anchorNode.data = newLineString;
    document.execCommand('insertHTML', true, stringBeforeCursor + '<br/>&#8203;');
    document.execCommand('delete', true);
  }
};

export const isJson = (jsonStr: string): boolean => {
  return getJson(jsonStr)[0];
};

export const parseHtmlEntities = (str: string) => {
  return str.replace(/&#([0-9]{1,5});/gi, function (match, numStr) {
    const num = parseInt(numStr, 10); // read num as normal number
    return String.fromCharCode(num);
  });
};

export const pasteText = (event: React.ClipboardEvent<HTMLDivElement>) => {
  const clipboard = event.clipboardData;
  if (clipboard) {
    event.preventDefault();
    event.stopPropagation();
    // let text = clipboard.getData('text/html');
    // text = $('<span>').html(text)[0].outerHTML;
    let text = clipboard.getData('text/plain');
    text = text.replaceAll('\n', '<br/>');
    document.execCommand('insertHTML', false, text);
  }
};

export const translateAttachmentKeyword = () => {
  const attachmentKeyword: string[] = [];
  const attachmentValues = i18next.t('FIND_ATTACHMENT_VALUES');
  const attachmentValuesHTML = $.parseHTML(attachmentValues);
  const listItems = $(attachmentValuesHTML).find('li');
  listItems.each(function () {
    attachmentKeyword.push($(this).text().toLowerCase());
  });
  return attachmentKeyword;
};

export const isAttachmentKeywordAvailable = (answerText: string) => {
  if (!answerText || !answerText.trim()) {
    return '';
  }
  answerText = cloneDeep(answerText.toLowerCase());
  const attachmentKeywords: string[] = translateAttachmentKeyword();
  for (let i = 0, len = attachmentKeywords.length; i < len; i++) {
    if (answerText.includes(attachmentKeywords[i])) {
      return attachmentKeywords[i];
    }
  }
  return '';
};

export const isNewAnswerType = (answerType: string) => {
  return newAnswerTypes.includes(answerType);
};

export const getDisplayValue = (answerType: string) => {
  let displayValue: { key: string; value: string }[] = newAnswerTypeOptions.filter(e => {
    return e.key === answerType;
  });
  return displayValue;
};

export const _highlightQuery = function (name: string, query: string) {
  query = query?.replace(/[.*()\[\]\\]/g, '\\$&');
  const regex = new RegExp('(' + query + ')', 'gi');
  return '<span>' + name.replace(regex, '<strong class="highlighted-blue">$1</strong>') + '</span>';
};

export const sortByObjectKeys = function (data: any) {
  return Object.keys(data)
    .sort()
    .reduce((obj: any, key: string) => {
      obj[key] = data[key];
      return obj;
    }, {});
};

export const kFormatter = function (num: number) {
  return num > 999 ? (num / 1000).toFixed(1) + 'k' : num;
};

export const animateButton = (ev: any) => {
  ev.preventDefault();
  ev.target.parentElement.classList?.remove('animate');
  ev.target.parentElement.classList.add('animate');
  setTimeout(function () {
    document.getElementsByClassName('bubblyButton')[0].classList?.remove('animate');
  }, 700);
};

export const focusOnElementById = (id: string) => {
  document.getElementById(id)?.focus();
};

export const getUXPreference = () => {
  const uxPref = localStorage.getItem('uxPreference') || '';
  return isJson(uxPref) ? JSON.parse(uxPref) : {};
};

export const getContentLibUxVersion = () => {
  const cclMode = store.getState().userDetails.userDetails.companyInfoVO.cclMode;
  const consolidatedContentLibrary = store.getState().userDetails.userDetails.companyPreferencesVO.featurePreferences?.consolidated_content_library;
  const userUXPref = (store.getState().userDetails.userDetails.userInfoVO.uiPreferences && store.getState().userDetails.userDetails.userInfoVO.uiPreferences.ccl_version) || 'v1';
  if (consolidatedContentLibrary && consolidatedContentLibrary === '1' && (cclMode === 'UI_V2_STRICT' || (cclMode === 'UI_V2_OPTIONAL' && userUXPref && userUXPref === 'v2'))) {
    return 'V2';
  } else {
    return 'V1';
  }
};

export const getProjectUxVersion = () => {
  const projectMode = store.getState().userDetails.userDetails.companyInfoVO.uiMode;
  const userUXPref = (store.getState().userDetails.userDetails.userInfoVO.uiPreferences && store.getState().userDetails.userDetails.userInfoVO.uiPreferences.version) || 'v1';
  if (
    store.getState().userDetails.userDetails.companyPreferencesVO.featurePreferences.ux_v2 === '1' &&
    (projectMode === 'UI_V2_STRICT' || (projectMode === 'UI_V2_OPTIONAL' && userUXPref && userUXPref === 'v2'))
  ) {
    return 'V2';
  } else {
    return 'V1';
  }
};

export const hasFileAttachmentType = (answers: AnswerVO[]) => {
  let hasFileAttachmentType = false;
  for (let i = 0; i < answers.length; i++) {
    if (answers[i].answerType === 'FILE_ATTACHMENT') {
      hasFileAttachmentType = true;
      break;
    }
  }
  return hasFileAttachmentType;
};
export const showMentions = (callBack: (data: any) => void) => {
  const data = document.querySelector("[data-cy='pending']");
  if (data) {
    data?.setAttribute('id', '@mention-id-' + new Date().getTime());
    callBack(data);
  }
};

export const sortAlphabetical = (data: string[]) => {
  if (data?.length > 0) {
    return data.sort((a: string, b: string) => a?.localeCompare(b));
  }
  return [];
};

// Handle Merge Tag In a Container
const findTagValue = (tag: string, mergeTagObject: any, skipLabel?: boolean) => {
  const usedMergeTagMap = store.getState().contentMasterData.usedMergeTagMap;
  let tagName = tag;
  let tagValue = '[' + tagName + ']';
  if (!tagName) {
    return;
  }
  if (mergeTagObject?.usedmergeTagsMap?.[tagName]) {
    tagValue = mergeTagObject.usedmergeTagsMap[tagName.toLowerCase()];
  } else if (usedMergeTagMap?.[tagName]) {
    tagValue = usedMergeTagMap[tagName.toLowerCase()];
  } else {
    //do nothing
  }
  if (mergeTagObject && mergeTagObject.projectMergeTags && mergeTagObject.projectMergeTags[tagName.toLowerCase()]) {
    tagValue = mergeTagObject.projectMergeTags[tagName.toLowerCase()];
  } else if (mergeTagObject && mergeTagObject.customFields && mergeTagObject.customFields[tagName.toLowerCase()] && !skipLabel) {
    tagValue = mergeTagObject.customFields[tagName.toLowerCase()];
    if (mergeTagObject && mergeTagObject.systemDefinedCustomFieldsMap && mergeTagObject.systemDefinedCustomFieldsMap[tagName]) {
      if (mergeTagObject.systemDefinedCustomFieldsMap[tagName].prefix) {
        tagValue = mergeTagObject.systemDefinedCustomFieldsMap[tagName].prefix + tagValue;
      }
      if (mergeTagObject.systemDefinedCustomFieldsMap[tagName].suffix) {
        tagValue = tagValue + mergeTagObject.systemDefinedCustomFieldsMap[tagName].suffix;
      }
    }
  } else if (
    mergeTagObject &&
    mergeTagObject.systemDefinedCustomFieldsMap &&
    mergeTagObject.systemDefinedCustomFieldsMap[tagName] &&
    mergeTagObject.systemDefinedCustomFieldsMap[tagName].type === 'LABEL' &&
    !skipLabel
  ) {
    tagValue = mergeTagObject.systemDefinedCustomFieldsMap[tagName].values[0];
  } else if (mergeTagObject && mergeTagObject.mergeTags && mergeTagObject.mergeTags.length > 0) {
    const tempVal = mergeTagObject.mergeTags.filter(function (f) {
      return f.tag === '[' + tag + ']';
    });
    if (tempVal && tempVal.length > 0) {
      tagValue = tempVal[0].value;
    }
  } else if (mergeTagObject && mergeTagObject.customFields && mergeTagObject.customFields[tagName.split('#')[0]] && mergeTagObject.customFields[tagName.split('#')[0]].length === 1) {
    if (mergeTagObject && mergeTagObject.systemDefinedCustomFieldsMap && mergeTagObject.systemDefinedCustomFieldsMap[tagName.split('#')[0]]) {
      const tagValObject = mergeTagObject.systemDefinedCustomFieldsMap[tagName.split('#')[0]];
      if (tagValObject.type === 'SELECT_USER' && mergeTagObject.usersMap) {
        const opt = tagName.split('#')[1];
        const tempVal = mergeTagObject.usersMap[mergeTagObject.customFields[tagName.split('#')[0]]][opt];
        if (tempVal && tempVal.length > 0) {
          tagValue = tempVal;
        }
      }
    }
  } else {
    //do nothing
  }
  return tagValue;
};

export const handleResponseInRecommendation = (
  elemContent: string,
  mergeTagObject: any,
  fileList: string[],
  containerId?: string,
  skipLabel?: boolean,
  sourceMergeTagValue?: string,
  contentLibrarId?: string,
  skipReplace?: boolean
) => {
  const currentModule = store.getState().additionalInfoStore.additionalInfo.currentModule?.moduleName;
  const cleanedContent = cleanMergeTagSpan(elemContent);
  const fileName: string = fileList.length > 0 ? fileList.join('; ') : '';
  let processedElemContent = cleanedContent.replace(/\[([^\]\s]+)\]/gm, (spanTag: any) => {
    return spanTag.replace(/<[^>]*>/gm, '');
  });
  let count = 0;
  const questionAttachmentText: string = getQuestionAttachmentText();
  // const parentEle = contentLibrarId ? $(`#context-card-${contentLibrarId}`) : null;
  const parentEle = $('<div/>').html(processedElemContent);
  if (parentEle && parentEle?.length > 0 && fileName === '' && !contentLibrarId) {
    parentEle.find('#question_attachment_merge_tag').remove();
    processedElemContent = parentEle.html();
  }
  const elemHash = containerId ? containerId : '';
  const finalElement = processedElemContent.replace(/\[([^\]\s]+)\]/gm, (mergeTagName: any) => {
    let processedMergeTagName: string = mergeTagName.replace(/[[\]]/g, '') || '';
    if (currentModule === 'proactive_proposal_builder' && !processedMergeTagName.startsWith('catalog#')) {
      processedMergeTagName = processedMergeTagName.toLowerCase();
    }

    if (sourceMergeTagValue && processedMergeTagName.startsWith('c-')) {
      return mergeTagName;
    }
    let mergeTagValue: string | undefined = '';
    if (!skipReplace) {
      mergeTagValue = findTagValue(processedMergeTagName, mergeTagObject, skipLabel);
    }

    let isDiv: boolean = false;
    let hasNestedMergeTags: boolean = false;

    if (processedMergeTagName.startsWith('c-') && mergeTagValue) {
      // To handle the nested merge tags in content reference tags
      if (mergeTagValue.includes('rfpio-autocomplete')) {
        hasNestedMergeTags = true;
        const finalElement: string = mergeTagValue.replace(/\[([^\]\s]+)\]/gm, (mergeTag: string) => {
          const processedMergeTag: string = mergeTag.replace(/[[\]]/g, '');
          const mergeValue: string | undefined = findTagValue(processedMergeTag, mergeTagObject);
          return `<span class="child-merge-tag" tag-id=${elemHash}${count++} merge-tag=${processedMergeTag}>${
            processedMergeTag.toLowerCase() === '#filename#' ? fileName || mergeTagName : mergeValue
          }</span>`;
        });
        mergeTagValue = finalElement;
      }

      const div = document.createElement('div');
      div.id = `${elemHash}${count++}`;
      div.innerHTML = mergeTagValue || '';
      isDiv = $('<div />').html(mergeTagValue)?.find('img,table, p[style], div[style]')?.length > 0 || div.innerText?.includes('\n');

      if (!isDiv) {
        mergeTagValue = hasNestedMergeTags ? div.firstChild?.innerHTML : div.innerText;
      }
    }
    if (questionAttachmentText && fileName === '' && mergeTagName === '[#filename#]') {
      return '';
    } else {
      return isDiv
        ? `<div class="display-merge-element" tag-id=${elemHash}${count++} merge-tag=${processedMergeTagName}>${
            processedMergeTagName.toLowerCase() === '#filename#' ? fileName || mergeTagName : mergeTagValue
          }</div>`
        : `<span tag-id=${elemHash}${count++} merge-tag=${processedMergeTagName}>${
            processedMergeTagName.toLowerCase() === '#filename#' ? fileName || mergeTagName : mergeTagValue || mergeTagName
          }</span>`;
    }
  });
  return finalElement;
};

export const updateContentMergeTagValue = (clipData: string) => {
  if (!clipData) {
    return clipData;
  }
  const systemDefinedMergeTagList = getProjectCustomFieldsMergeTagList();
  const mergeTagObject = updateMergeTagObjectForPopover(projectVO, [], systemDefinedMergeTagList);
  const finalElement = handleResponseInRecommendation(clipData, mergeTagObject, []);
  return finalElement;
};

export const bindUserDefinedMergeTags = (
  elemContent: string,
  mergeTagObject: any,
  fileList: string[],
  containerId: string,
  skipLabel?: boolean,
  skipBindMergeTags?: boolean,
  skipReplace?: boolean
) => {
  const finalElement = handleResponseInRecommendation(elemContent, mergeTagObject, fileList, containerId, skipLabel, '', '', skipReplace);
  if (finalElement && document) {
    const temp = document.querySelector("div[contenteditable][id='" + containerId + "']");
    if (temp && getTinymce()) {
      getTinymce().get(containerId).setContent(finalElement);
      !skipBindMergeTags && getTinymce().get(containerId).execCommand('rebindmergeTags');
    }
  }
};

export const handleMergeTagShow = (id: string | null, idName: string) => {
  const mergeTagElem = $(`#${id}${idName}`).find('span[merge-tag], div[merge-tag]');
  if (mergeTagElem) {
    unbindMergeTag(id, idName);
    mergeTagElem.bind('mouseenter', mTagOver).bind('mouseleave', mTagOut);
  }
};

const mTagOver = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
  const popoverLayout = $('#tooltip_layout');
  const popoverElem = $('#merge_tag_tooltip');
  const mergeText = $('#merge_tag_tooltip_text');
  const mergeActions = $('#merge_tag_tooltip_action');

  const isTarget: boolean = Boolean(event.target.getAttribute('merge-tag'));

  const mergetag = event.target.getAttribute('merge-tag') || event.currentTarget.getAttribute('merge-tag');
  const displayElem = '<span " tag="' + mergetag + '" class="mt-sm mb-sm text-bold">' + '[' + mergetag + ']' + '</span>';
  const elmPosition = isTarget ? event.target.getBoundingClientRect() : event.currentTarget.getBoundingClientRect();
  mergeText.empty().append(displayElem).css({ display: 'inline' });
  mergeActions.css({ display: 'none' });
  popoverElem.css({ position: 'absolute', width: 'max-content' });
  popoverLayout.css({ display: 'block', height: 'fit-content', width: 'fit-content', left: elmPosition.left, top: elmPosition.top - popoverElem[0].offsetHeight - 23 });
};

const mTagOut = () => {
  const popoverLayout = $('#tooltip_layout');
  popoverLayout.css({ display: 'none' });
};

export const bindMergeTag = function (id: string | null, idName: string) {
  $(`#${id}${idName}`)
    .find('span[merge-tag], div[merge-tag]')
    .each(function () {
      $(this).bind('mouseenter', mTagOver).bind('mouseleave', mTagOut);
    });
};

export const unbindMergeTag = (id: string | null, idName: string) => {
  const mergeTagElem = $(`#${id}${idName}`).find('span[merge-tag], div[merge-tag]');
  mergeTagElem.unbind();
};

export const cleanMergeTagSpan = function (elemContent: string) {
  let tempElem = $('<p>').html(elemContent);
  tempElem.find('span[merge-tag], div[merge-tag]').each(function () {
    if (this.classList.contains('rfpio-autocomplete')) {
      $(this)
        .empty()
        .append('[' + $(this).attr('merge-tag') + ']'); //confirm
      $(this).removeAttr('merge-tag');
    } else {
      $(this).replaceWith('[' + $(this).attr('merge-tag') + ']'); //replace all span with just content
    }
  });
  return tempElem.html();
};

export const addAnnotateTooltip = (id: string | null, idName: string) => {
  const aElem = $(`#${id}${idName}`).find('.annotation');
  if (aElem) {
    aElem.bind('mouseenter', annotationPopOver).bind('mouseleave', annotationPopOut);
  }
};
const annotationPopOver = (e: any) => {
  const annotationLayout = $('#annotation_tooltip_layout');
  const popoverElem = $('#annotation_tooltip');
  const annotationText = $('#annotation_tooltip_text');
  const annotationValue = e.currentTarget.getAttribute('data-annotation-value');
  const displayElem = '<span " annValue="' + annotationValue + '" class="mt-sm mb-sm text-bold">' + annotationValue + '</span>';
  annotationText.empty().append(displayElem).css({ display: 'inline' });
  popoverElem.css({ position: 'absolute' });
  const position = e.target.getBoundingClientRect();
  const top = position.top + position.height;
  const left = position.left;
  annotationLayout.css({ display: 'block', height: 'fit-content', width: 'fit-content', left: left, top: top });
};

const annotationPopOut = () => {
  const annotationLayout = $('#annotation_tooltip_layout');
  annotationLayout.css({ display: 'none' });
};

export const preprocessUnAuthorizedCollections = (collectionIdNameMap: { [key: string]: string }): { [key: string]: string } => {
  const collectionMap: { [key: string]: string } = {};
  const companyCollectionMap = store.getState().companyCollection.collectionMap;
  const isSuperAdminOrSupport = store.getState().userData.permissionData.isSuperAdmin || store.getState().userData.permissionData.isSupport;
  const userName = getCurrentUser().username;
  const currentUserInfo: { [key: string]: any } = getUser(userName);
  const isBUEnabled = isFeatureEnabled('business_units');

  Object.keys(collectionIdNameMap || {}).forEach((collectionId: string) => {
    if (collectionId !== '0' && companyCollectionMap[collectionId]) {
      const collectionVO: CompanyCollectionVO = companyCollectionMap[collectionId];
      if (
        isSuperAdminOrSupport ||
        collectionVO.users.includes(userName) ||
        (collectionVO.users.includes('ALL_TEAM_MEMBERS') &&
          (!isBUEnabled ||
            checkIfArraysHasContainsAny(currentUserInfo?.businessUnits, collectionVO.businessUnits) ||
            currentUserInfo?.businessUnits.includes('ALL') ||
            collectionVO.businessUnits.includes('ALL')))
      ) {
        collectionMap[collectionId] = collectionVO.name;
      }
    } else {
      collectionMap[collectionId] = collectionIdNameMap[collectionId];
    }
  });
  return collectionMap;
};

export const scaleOptions = (from: number, to: number, answerOptions: any) => {
  const list: { key: string; label: string; value: string }[] = [];
  for (let idx: number = from; idx <= to; idx++) {
    const label = answerOptions[idx] && answerOptions[idx].key ? answerOptions[idx].key : '';
    list.push({ key: '', label: label, value: idx.toString() });
  }
  return list;
};

export const markOptions = (from: number, to: number) => {
  const list: { label: number; value: number }[] = [];
  to = to ? to : 10;
  from = from && from < to ? from : 0;
  const step = to - from < 10 ? 1 : Math.ceil((to - from) / 10);
  for (let idx = from; idx < to; idx += step) {
    list.push({ label: idx, value: idx });
  }
  list.push({ label: to, value: to });
  if (list[list.length - 1].value - list[list.length - 2].value < step) {
    list.splice(list.length - 2, 1);
  }
  return list;
};

export const processTagList = (data: AutoFillDetailsVO) => {
  const setTagMap = store.getActions().autoFillDetailsStore.setTagMap;
  const setMasterTagList = store.getActions().autoFillDetailsStore.setMasterTagList;
  const setMasterBUTagsMap = store.getActions().autoFillDetailsStore.setMasterBUTagsMap;
  const setBuTags = store.getActions().autoFillDetailsStore.setBuTags;
  const currentUserInfo = store.getState().userDetails.userDetails.userInfoVO;

  const tagMap: { [key: string]: MasterDataVO } = {};
  const tagList: string[] = [];
  const facetTags: string[] = [];
  const masterBUTagsMap: { [key: string]: { [key: string]: MasterDataVO } } = {};
  data.tagList.dataList.forEach(tag => {
    if (tagList.indexOf(tag.value) === -1) {
      tagMap[tag.value] = tag;
      tagList.push(tag.value);
    }
    const primaryBU: string = tag.primaryBU ? tag.primaryBU : 'default';
    if (!masterBUTagsMap[primaryBU]) {
      masterBUTagsMap[primaryBU] = {};
    }
    masterBUTagsMap[primaryBU][tag.value] = {
      bgColor: tag.backgroundColor,
      fgColor: tag.foregroundColor,
      primaryBU: tag.primaryBU,
      value: tag.value,
      id: tag.id,
      parentTagId: tag.parentTagId
    };
  });
  data.tagFacets.forEach((tag: { key: string; value: string }) => {
    if (!masterBUTagsMap['default']) {
      masterBUTagsMap['default'] = {};
    }
    if (tagList.indexOf(tag.key) === -1 && tag.key !== '') {
      tagList.push(tag.key);
      masterBUTagsMap['default'][tag.key] = { bgColor: '#23b7e5', fgColor: '#fff', primaryBU: 'default', value: tag.key };
      tagMap[tag.key] = { backgroundColor: '#23b7e5', foregroundColor: '#fff', primaryBU: 'default', value: tag.key };
    }
    if (tag.key && !facetTags.includes(tag.key)) {
      facetTags.push(tag.key);
    }
  });
  setTagMap(tagMap);
  setMasterTagList(tagList);
  setMasterBUTagsMap(masterBUTagsMap);
  if (masterBUTagsMap && masterBUTagsMap[currentUserInfo?.primaryBU]) {
    let tagsList: string[] = Object.keys(masterBUTagsMap[currentUserInfo?.primaryBU]);
    if (facetTags.length > 0) {
      facetTags.forEach((tag: string) => {
        if (!tagsList.includes(tag)) {
          tagsList.push(tag);
        }
      });
    }
    setBuTags(tagsList);
  } else {
    setBuTags([...tagList]);
  }
};

export const getNotifyStatusForUser = () => {
  const notifyStatus = localStorage.getItem('notifyStatus');
  if (notifyStatus) {
    return JSON.parse(notifyStatus);
  } else {
    localStorage.setItem('notifyStatus', JSON.stringify({ queue: !isFeatureEnabled('invited_vendor'), notify: 'NOTIFY_NEW' }));
    return { queue: !isFeatureEnabled('invited_vendor'), notify: 'NOTIFY_NEW' };
  }
};

export const setNotifyStatusInLocal = (notifyStatus: any) => {
  if (notifyStatus) {
    localStorage.setItem('notifyStatus', JSON.stringify(notifyStatus));
  }
};

export const businessUnitsToShow = (contentBuList: string[], userBuList: string[], businessUnitsMap: any) => {
  let buListToShow: string[] = [];
  if (!businessUnitsMap) {
    return [];
  }
  const allBuList = Object.keys(businessUnitsMap).filter(eachkey => eachkey !== 'ALL');
  if (contentBuList.indexOf('ALL') > -1 && userBuList.indexOf('ALL') > -1) {
    const buIdList = [...allBuList];
    buListToShow = [...buIdList];
    return buListToShow;
  }
  // ContentBuList.length === 0 ---> Considering non bu pages
  if (contentBuList.length === 0 || contentBuList.indexOf('ALL') > -1) {
    // Incase of super admin or support user there is no bu List
    buListToShow = userBuList.length > 0 ? [...userBuList] : [...allBuList];
    return buListToShow;
  }
  // userBuList.length === 0 ---> Considering super admin or support user
  if (userBuList.length === 0 || userBuList.indexOf('ALL') > -1) {
    buListToShow = [...contentBuList];
    return buListToShow;
  }
  buListToShow = userBuList.filter(eachBu => contentBuList.indexOf(eachBu) > -1);
  return buListToShow;
};
export const validateEmail = (email: any) => {
  return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
};

export const currencyList: { [key: string]: { cc: string; symbol: string; name: string } } = {
  AED: { cc: 'AED', symbol: '\u062f.\u0625;', name: 'UAE dirham' },
  AFN: { cc: 'AFN', symbol: 'Afs', name: 'Afghan afghani' },
  ALL: { cc: 'ALL', symbol: 'L', name: 'Albanian lek' },
  AMD: { cc: 'AMD', symbol: 'AMD', name: 'Armenian dram' },
  ANG: { cc: 'ANG', symbol: 'NA\u0192', name: 'Netherlands Antillean gulden' },
  AOA: { cc: 'AOA', symbol: 'Kz', name: 'Angolan kwanza' },
  ARS: { cc: 'ARS', symbol: '$', name: 'Argentine peso' },
  AUD: { cc: 'AUD', symbol: '$', name: 'Australian dollar' },
  AWG: { cc: 'AWG', symbol: '\u0192', name: 'Aruban florin' },
  AZN: { cc: 'AZN', symbol: 'AZN', name: 'Azerbaijani manat' },
  BAM: { cc: 'BAM', symbol: 'KM', name: 'Bosnia and Herzegovina konvertibilna marka' },
  BBD: { cc: 'BBD', symbol: 'Bds$', name: 'Barbadian dollar' },
  BDT: { cc: 'BDT', symbol: '\u09f3', name: 'Bangladeshi taka' },
  BGN: { cc: 'BGN', symbol: 'BGN', name: 'Bulgarian lev' },
  BHD: { cc: 'BHD', symbol: '.\u062f.\u0628', name: 'Bahraini dinar' },
  BIF: { cc: 'BIF', symbol: 'FBu', name: 'Burundi franc' },
  BMD: { cc: 'BMD', symbol: 'BD$', name: 'Bermudian dollar' },
  BND: { cc: 'BND', symbol: 'B$', name: 'Brunei dollar' },
  BOB: { cc: 'BOB', symbol: 'Bs.', name: 'Bolivian boliviano' },
  BRL: { cc: 'BRL', symbol: 'R$', name: 'Brazilian real' },
  BSD: { cc: 'BSD', symbol: 'B$', name: 'Bahamian dollar' },
  BTN: { cc: 'BTN', symbol: 'Nu.', name: 'Bhutanese ngultrum' },
  BWP: { cc: 'BWP', symbol: 'P', name: 'Botswana pula' },
  BYR: { cc: 'BYR', symbol: 'Br', name: 'Belarusian ruble' },
  BZD: { cc: 'BZD', symbol: 'BZ$', name: 'Belize dollar' },
  CAD: { cc: 'CAD', symbol: '$', name: 'Canadian dollar' },
  CDF: { cc: 'CDF', symbol: 'F', name: 'Congolese franc' },
  CHF: { cc: 'CHF', symbol: 'Fr.', name: 'Swiss franc' },
  CLP: { cc: 'CLP', symbol: '$', name: 'Chilean peso' },
  CNY: { cc: 'CNY', symbol: '\u00a5', name: 'Chinese/Yuan renminbi' },
  COP: { cc: 'COP', symbol: 'Col$', name: 'Colombian peso' },
  CRC: { cc: 'CRC', symbol: '\u20a1', name: 'Costa Rican colon' },
  CUC: { cc: 'CUC', symbol: '$', name: 'Cuban peso' },
  CVE: { cc: 'CVE', symbol: 'Esc', name: 'Cape Verdean escudo' },
  CZK: { cc: 'CZK', symbol: 'K\u010d', name: 'Czech koruna' },
  DJK: { cc: 'DJF', symbol: 'Fdj', name: 'Djiboutian franc' },
  DKK: { cc: 'DKK', symbol: 'Kr', name: 'Danish krone' },
  DOP: { cc: 'DOP', symbol: 'RD$', name: 'Dominican peso' },
  DZD: { cc: 'DZD', symbol: '\u062f.\u062c', name: 'Algerian dinar' },
  EEK: { cc: 'EEK', symbol: 'KR', name: 'Estonian kroon' },
  EGP: { cc: 'EGP', symbol: '\u00a3', name: 'Egyptian pound' },
  ERN: { cc: 'ERN', symbol: 'Nfa', name: 'Eritrean nakfa' },
  ETP: { cc: 'ETB', symbol: 'Br', name: 'Ethiopian birr' },
  EUR: { cc: 'EUR', symbol: '\u20ac', name: 'European Euro' },
  FJD: { cc: 'FJD', symbol: 'FJ$', name: 'Fijian dollar' },
  FKP: { cc: 'FKP', symbol: '\u00a3', name: 'Falkland Islands pound' },
  GBP: { cc: 'GBP', symbol: '\u00a3', name: 'British pound' },
  GEL: { cc: 'GEL', symbol: 'GEL', name: 'Georgian lari' },
  GHS: { cc: 'GHS', symbol: 'GH\u20b5', name: 'Ghanaian cedi' },
  GIP: { cc: 'GIP', symbol: '\u00a3', name: 'Gibraltar pound' },
  GMD: { cc: 'GMD', symbol: 'D', name: 'Gambian dalasi' },
  GNF: { cc: 'GNF', symbol: 'FG', name: 'Guinean franc' },
  GQE: { cc: 'GQE', symbol: 'CFA', name: 'Central African CFA franc' },
  GTQ: { cc: 'GTQ', symbol: 'Q', name: 'Guatemalan quetzal' },
  GYD: { cc: 'GYD', symbol: 'GY$', name: 'Guyanese dollar' },
  HKD: { cc: 'HKD', symbol: 'HK$', name: 'Hong Kong dollar' },
  HNL: { cc: 'HNL', symbol: 'L', name: 'Honduran lempira' },
  HRK: { cc: 'HRK', symbol: 'kn', name: 'Croatian kuna' },
  HTG: { cc: 'HTG', symbol: 'G', name: 'Haitian gourde' },
  HUF: { cc: 'HUF', symbol: 'Ft', name: 'Hungarian forint' },
  IDR: { cc: 'IDR', symbol: 'Rp', name: 'Indonesian rupiah' },
  ILS: { cc: 'ILS', symbol: '\u20aa', name: 'Israeli new sheqel' },
  INR: { cc: 'INR', symbol: '\u20B9', name: 'Indian rupee' },
  IQD: { cc: 'IQD', symbol: '\u062f.\u0639', name: 'Iraqi dinar' },
  IRR: { cc: 'IRR', symbol: 'IRR', name: 'Iranian rial' },
  ISK: { cc: 'ISK', symbol: 'kr', name: 'Icelandic kr\u00f3na' },
  JMD: { cc: 'JMD', symbol: 'J$', name: 'Jamaican dollar' },
  JOD: { cc: 'JOD', symbol: 'JOD', name: 'Jordanian dinar' },
  JPY: { cc: 'JPY', symbol: '\u00a5', name: 'Japanese yen' },
  KES: { cc: 'KES', symbol: 'KSh', name: 'Kenyan shilling' },
  KGS: { cc: 'KGS', symbol: '\u0441\u043e\u043c', name: 'Kyrgyzstani som' },
  KHR: { cc: 'KHR', symbol: '\u17db', name: 'Cambodian riel' },
  KMF: { cc: 'KMF', symbol: 'KMF', name: 'Comorian franc' },
  KPW: { cc: 'KPW', symbol: 'W', name: 'North Korean won' },
  KRW: { cc: 'KRW', symbol: 'W', name: 'South Korean won' },
  KWD: { cc: 'KWD', symbol: 'KWD', name: 'Kuwaiti dinar' },
  KYD: { cc: 'KYD', symbol: 'KY$', name: 'Cayman Islands dollar' },
  KZT: { cc: 'KZT', symbol: 'T', name: 'Kazakhstani tenge' },
  LAK: { cc: 'LAK', symbol: 'KN', name: 'Lao kip' },
  LBP: { cc: 'LBP', symbol: '\u00a3', name: 'Lebanese lira' },
  LKR: { cc: 'LKR', symbol: 'Rs', name: 'Sri Lankan rupee' },
  LRD: { cc: 'LRD', symbol: 'L$', name: 'Liberian dollar' },
  LSL: { cc: 'LSL', symbol: 'M', name: 'Lesotho loti' },
  LTL: { cc: 'LTL', symbol: 'Lt', name: 'Lithuanian litas' },
  LVL: { cc: 'LVL', symbol: 'Ls', name: 'Latvian lats' },
  LYD: { cc: 'LYD', symbol: 'LD', name: 'Libyan dinar' },
  MAD: { cc: 'MAD', symbol: 'MAD', name: 'Moroccan dirham' },
  MDL: { cc: 'MDL', symbol: 'MDL', name: 'Moldovan leu' },
  MGA: { cc: 'MGA', symbol: 'FMG', name: 'Malagasy ariary' },
  MKD: { cc: 'MKD', symbol: 'MKD', name: 'Macedonian denar' },
  MMK: { cc: 'MMK', symbol: 'K', name: 'Myanma kyat' },
  MNT: { cc: 'MNT', symbol: '\u20ae', name: 'Mongolian tugrik' },
  MOP: { cc: 'MOP', symbol: 'P', name: 'Macanese pataca' },
  MRP: { cc: 'MRO', symbol: 'UM', name: 'Mauritanian ouguiya' },
  MUR: { cc: 'MUR', symbol: 'Rs', name: 'Mauritian rupee' },
  MVR: { cc: 'MVR', symbol: 'Rf', name: 'Maldivian rufiyaa' },
  MWK: { cc: 'MWK', symbol: 'MK', name: 'Malawian kwacha' },
  MXN: { cc: 'MXN', symbol: '$', name: 'Mexican peso' },
  MYR: { cc: 'MYR', symbol: 'RM', name: 'Malaysian ringgit' },
  MZM: { cc: 'MZM', symbol: 'MTn', name: 'Mozambican metical' },
  NAD: { cc: 'NAD', symbol: 'N$', name: 'Namibian dollar' },
  NGN: { cc: 'NGN', symbol: '\u20a6', name: 'Nigerian naira' },
  NIO: { cc: 'NIO', symbol: 'C$', name: 'Nicaraguan c\u00f3rdoba' },
  NOK: { cc: 'NOK', symbol: 'kr', name: 'Norwegian krone' },
  NPR: { cc: 'NPR', symbol: 'NRs', name: 'Nepalese rupee' },
  NZD: { cc: 'NZD', symbol: 'NZ$', name: 'New Zealand dollar' },
  OMR: { cc: 'OMR', symbol: 'OMR', name: 'Omani rial' },
  PAB: { cc: 'PAB', symbol: 'B./', name: 'Panamanian balboa' },
  PEN: { cc: 'PEN', symbol: 'S/.', name: 'Peruvian nuevo sol' },
  PGK: { cc: 'PGK', symbol: 'K', name: 'Papua New Guinean kina' },
  PHP: { cc: 'PHP', symbol: '\u20b1', name: 'Philippine peso' },
  PKR: { cc: 'PKR', symbol: 'Rs.', name: 'Pakistani rupee' },
  PLN: { cc: 'PLN', symbol: 'z\u0142', name: 'Polish zloty' },
  PYG: { cc: 'PYG', symbol: '\u20b2', name: 'Paraguayan guarani' },
  QAR: { cc: 'QAR', symbol: 'QR', name: 'Qatari riyal' },
  RON: { cc: 'RON', symbol: 'L', name: 'Romanian leu' },
  RSD: { cc: 'RSD', symbol: 'din.', name: 'Serbian dinar' },
  RUB: { cc: 'RUB', symbol: 'R', name: 'Russian ruble' },
  SAR: { cc: 'SAR', symbol: 'SR', name: 'Saudi riyal' },
  SBD: { cc: 'SBD', symbol: 'SI$', name: 'Solomon Islands dollar' },
  SCR: { cc: 'SCR', symbol: 'SR', name: 'Seychellois rupee' },
  SDG: { cc: 'SDG', symbol: 'SDG', name: 'Sudanese pound' },
  SEK: { cc: 'SEK', symbol: 'kr', name: 'Swedish krona' },
  SGD: { cc: 'SGD', symbol: 'S$', name: 'Singapore dollar' },
  SHP: { cc: 'SHP', symbol: '\u00a3', name: 'Saint Helena pound' },
  SLL: { cc: 'SLL', symbol: 'Le', name: 'Sierra Leonean leone' },
  SOS: { cc: 'SOS', symbol: 'Sh.', name: 'Somali shilling' },
  SRD: { cc: 'SRD', symbol: '$', name: 'Surinamese dollar' },
  SYP: { cc: 'SYP', symbol: 'LS', name: 'Syrian pound' },
  SZL: { cc: 'SZL', symbol: 'E', name: 'Swazi lilangeni' },
  THB: { cc: 'THB', symbol: '\u0e3f', name: 'Thai baht' },
  TJS: { cc: 'TJS', symbol: 'TJS', name: 'Tajikistani somoni' },
  TMT: { cc: 'TMT', symbol: 'm', name: 'Turkmen manat' },
  TND: { cc: 'TND', symbol: 'DT', name: 'Tunisian dinar' },
  TRY: { cc: 'TRY', symbol: 'TRY', name: 'Turkish new lira' },
  TTD: { cc: 'TTD', symbol: 'TT$', name: 'Trinidad and Tobago dollar' },
  TWD: { cc: 'TWD', symbol: 'NT$', name: 'New Taiwan dollar' },
  TZS: { cc: 'TZS', symbol: 'TZS', name: 'Tanzanian shilling' },
  UAH: { cc: 'UAH', symbol: 'UAH', name: 'Ukrainian hryvnia' },
  UGX: { cc: 'UGX', symbol: 'USh', name: 'Ugandan shilling' },
  USD: { cc: 'USD', symbol: '$', name: 'United States dollar' },
  UYU: { cc: 'UYU', symbol: '$U', name: 'Uruguayan peso' },
  UZS: { cc: 'UZS', symbol: 'UZS', name: 'Uzbekistani som' },
  VEB: { cc: 'VEB', symbol: 'Bs', name: 'Venezuelan bolivar' },
  VND: { cc: 'VND', symbol: '\u20ab', name: 'Vietnamese dong' },
  VUV: { cc: 'VUV', symbol: 'VT', name: 'Vanuatu vatu' },
  WST: { cc: 'WST', symbol: 'WS$', name: 'Samoan tala' },
  XAF: { cc: 'XAF', symbol: 'CFA', name: 'Central African CFA franc' },
  XCD: { cc: 'XCD', symbol: 'EC$', name: 'East Caribbean dollar' },
  XDR: { cc: 'XDR', symbol: 'SDR', name: 'Special Drawing Rights' },
  XOF: { cc: 'XOF', symbol: 'CFA', name: 'West African CFA franc' },
  XPF: { cc: 'XPF', symbol: 'F', name: 'CFP franc' },
  YER: { cc: 'YER', symbol: 'YER', name: 'Yemeni rial' },
  ZAR: { cc: 'ZAR', symbol: 'R', name: 'South African rand' },
  ZMK: { cc: 'ZMK', symbol: 'ZK', name: 'Zambian kwacha' },
  ZWR: { cc: 'ZWR', symbol: 'Z$', name: 'Zimbabwean dollar' }
};

export const getQuestionAttachmentText = () => {
  const currentModule = store.getState().additionalInfoStore.additionalInfo.currentModule?.moduleName;
  const companyPrefereneceData: { [key: string]: any } = store.getState().userDetails.userDetails.companyPreferencesVO.data;
  let questionAttachmentText = currentModule === 'CONTENT_LIBRARY' ? companyPrefereneceData?.question_attachment_answer?.text : companyPrefereneceData?.question_attachment?.text;
  questionAttachmentText = questionAttachmentText && questionAttachmentText.replace('[filename]', '');
  return questionAttachmentText ? questionAttachmentText : '';
};

export const removeFileMergeTag = (questionData: { [key: string]: any }, value: string, id: string, restrictFocus?: boolean) => {
  const questionAttachmentText: string = getQuestionAttachmentText();
  if (value && (value.includes('#filename#') || value.includes('question_attachment_merge_tag'))) {
    let parentEle = $(`#${id} #question_attachment_merge_tag`);
    if (parentEle && questionData?.files?.length === 0) {
      parentEle.remove();
    } else {
      if (parentEle && parentEle.length === 0) {
        parentEle = $(`#${id}`);
      }
      const childFileMergeEl = $(`#${id}`).find('[merge-tag="#filename#"]');
      handleRemoveElement(childFileMergeEl, parentEle, questionAttachmentText, id);
    }
    if (getTinymce() && !restrictFocus) {
      getTinymce()?.activeEditor?.focus();
    }
  }
};

export const handleRemoveElement = (childFileMergeEl: any, parentEle: any, questionAttachmentText: string, id?: string) => {
  if (childFileMergeEl && childFileMergeEl[0]) {
    childFileMergeEl.remove();
  }
  if (parentEle && parentEle[0]) {
    const parentInnerText = parentEle[0]?.innerHTML.replace(`${questionAttachmentText ? `${questionAttachmentText}[#filename#]` : '[#filename#]'}`, '');
    if (parentInnerText === questionAttachmentText) {
      parentEle.remove();
    } else {
      getTinymce()?.activeEditor.id !== id && parentEle.removeAttr('id');
      if (questionAttachmentText) {
        const replaceParentEle = parentEle.html().replace(`${questionAttachmentText ? `${questionAttachmentText}[#filename#]` : '[#filename#]'}`, '');
        parentEle.html(replaceParentEle);
      }
    }
  }
};

export const getFileList = (questionData: { [key: string]: any }, needAllFiles?: boolean) => {
  const files: string[] = [];
  const skipFileGroups: string[] = ['PINNED'];
  questionData?.files?.length > 0 &&
    questionData.files.forEach((fileData: { [key: string]: any }) => {
      if (!skipFileGroups.includes(fileData?.fileGroup)) {
        files.push(fileData?.fileName || fileData?.name);
      }
    });
  if (needAllFiles) {
    questionData?.cloudFiles?.length > 0 &&
      questionData.cloudFiles.forEach((fileData: { [key: string]: any }) => {
        if (!skipFileGroups.includes(fileData?.fileGroup)) {
          files.push(fileData?.fileName || fileData?.name);
        }
      });
    questionData?.documentFiles?.length > 0 &&
      questionData.documentFiles.forEach((fileData: { [key: string]: any }) => {
        if (!skipFileGroups.includes(fileData?.fileGroup)) {
          files.push(fileData?.fileName || fileData?.name);
        }
      });
  }
  return files;
};

export const showFileMergeTag = (questionData: { [key: string]: any }, value: string, id: string) => {
  const files: string[] = getFileList(questionData);
  if (value && (value.includes('#filename#') || value.includes('question_attachment_merge_tag')) && files.length > 0) {
    const parentEle = $(`#${id} #question_attachment_merge_tag`);
    const childFileMergeEl = $(`#${id}`).find('[merge-tag="#filename#"]');
    const mainParent = $(`#${id}`);
    if (childFileMergeEl && childFileMergeEl[0]) {
      if (files.length > 0) {
        childFileMergeEl[0].innerHTML = files?.join('; ');
      }
    } else if (parentEle && parentEle[0]) {
      let elemContent = parentEle[0]?.innerHTML;
      if (!elemContent) {
        return;
      }
      elemContent =
        elemContent &&
        elemContent.replace(/\[#filename#]/gm, function () {
          return '<span  merge-tag="#filename#">' + files?.join('; ') + '</span>';
        });
      parentEle[0].innerHTML = elemContent;
    } else if (mainParent && mainParent[0] && mainParent[0].innerText.includes('[#filename#]')) {
      const elemContent = mainParent[0].innerHTML.replace(/\[#filename#]/gm, function () {
        return '<span  merge-tag="#filename#">' + files?.join('; ') + '</span>';
      });
      mainParent[0].innerHTML = elemContent;
    } else {
      // do nothing
    }
  } else {
    removeFileMergeTag(questionData, value, id);
  }
};

export const processAnswerValue = (contentVO: { [key: string]: any }, isProject?: boolean) => {
  contentVO.answers.forEach((answer: any, index: number) => {
    const data: string = isProject ? answer.answer : answer.value;
    const answerText: string = isProject ? 'answer' : 'value';
    if (data?.includes('#filename#') || data?.includes('#question_attachment_merge_tag')) {
      const cloneContent: { [key: string]: any } = cloneDeep(contentVO);
      cloneContent['files'] = cloneContent?.projectFiles;
      const files: string[] = getFileList(cloneContent, true);
      const el = $('<div>').html(data);
      if (files.length > 0) {
        contentVO.answers[index][answerText] = data.replace('[#filename#]', '<span  merge-tag="#filename#">' + files?.join('; ') + '</span>');
      } else {
        const parentEle = el.find('#question_attachment_merge_tag');
        if (parentEle && files.length === 0) {
          parentEle.remove();
        }
        contentVO.answers[index][answerText] = el.html();
      }
    }
  });
};

export const compareObjects = (obj1: { [key: string]: any }, obj2: { [key: string]: any }) => {
  let len = null;
  if (!obj1 || !obj2 || Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  } else {
    len = Object.keys(obj1).length;
  }
  let match = 0;
  Object.keys(obj1).forEach((i: string) => {
    if (obj1[i] === obj2[i]) {
      match++;
    } else if (JSON.stringify(obj1[i]) === JSON.stringify(obj2[i])) {
      match++;
    } else {
      // do nothing
    }
  });
  if (match === len) {
    return true;
  }
  return false;
};

export const setViewPreference = (module: string, pref: string) => {
  const localData = localStorage.getItem('viewPreference');
  const tempViewPref = localData ? JSON.parse(localData) : {};
  tempViewPref[module] = pref;
  localStorage.setItem('viewPreference', JSON.stringify(tempViewPref));
};

const getDefaultViewPreference = (module: string) => {
  switch (module) {
    case 'Tasks':
      return 'MY';
    case 'Projects':
    case 'AnsweringPage':
    case 'Sections':
    case 'Timers':
    default:
      return 'ALL';
  }
};

export const getViewPreference = (module: string) => {
  const localData = localStorage.getItem('viewPreference');
  const tempViewPref = localData ? JSON.parse(localData) : {};
  if (tempViewPref[module]) {
    return tempViewPref[module];
  }
  const moduleViewPref = getDefaultViewPreference(module);
  setViewPreference(module, moduleViewPref);
  return moduleViewPref;
};

export const getMyWorkFilters = (pref: string) => {
  const defaultFilters = ['ALL_QUESTIONS', 'NOT_ANSWERED', 'PENDING_REVIEW_VAL', 'OPEN_COMMENTS', 'FLAGGED'];
  const localData = localStorage.getItem('myWorkFilters');
  const tempMyWorkFilters = localData ? JSON.parse(localData) : null;
  if (tempMyWorkFilters) {
    if (tempMyWorkFilters[pref]) {
      return tempMyWorkFilters[pref];
    }
    tempMyWorkFilters[pref] = defaultFilters;
    localStorage.setItem('myWorkFilters', JSON.stringify(tempMyWorkFilters));
    return defaultFilters;
  }
  localStorage.setItem('myWorkFilters', JSON.stringify({ ALL: defaultFilters, MY: defaultFilters }));
  return defaultFilters;
};

export const userCollectionsFromMaster = (userCollectionMap: any, masterCollectionMap: { [key: string]: any }) => {
  const userCollections: any[] = [];
  const userCollMaster = Object.keys(userCollectionMap);
  userCollMaster.length > 0 &&
    userCollMaster.forEach((collId: string) => {
      if (masterCollectionMap[collId]) {
        userCollections.push(masterCollectionMap[collId]);
      }
    });
  return userCollections;
};
export const setMyWorkFilters = (pref: string, filters: string[]) => {
  const localData = localStorage.getItem('myWorkFilters');
  const tempMyWorkFilters = localData ? JSON.parse(localData) : null;
  if (tempMyWorkFilters) {
    tempMyWorkFilters[pref] = filters;
    localStorage.setItem('myWorkFilters', JSON.stringify(tempMyWorkFilters));
  } else {
    localStorage.setItem('myWorkFilters', JSON.stringify({ [pref]: filters }));
  }
};

export const replaceNbsps = (input: string) => {
  const regex = new RegExp(String.fromCharCode(160), 'g');
  return input.replace(regex, ' ');
};

export const decimalToHex = (alpha: number) => {
  if (alpha && alpha === 0) {
    return '00';
  }
  let hex = Math.round(255 * alpha).toString(16);
  if (hex.length < 2) {
    hex = '0' + hex;
  }
  return hex;
};

export const removeSpecialCharAndSpaces = function (text: string) {
  let returnText: string = text.replace(/[^a-zA-Z0-9]/gm, '');
  return returnText.trim();
};

export const nFormatter = (num: number, defaultOption?: string) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(2).replace(/\.00$/, '') + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(2).replace(/\.00$/, '') + 'K';
  } else {
    //do nothing
  }
  return num || defaultOption || '-';
};

export const isContentLibraryV1 = () => {
  const userDetails = store.getState().userDetails.userDetails;
  const cclMode = userDetails.companyInfoVO.cclMode;
  return cclMode === 'CLASSICAL_V1' || !isFeatureEnabled('consolidated_content_library');
};

export const setFocusById = (id: string) => {
  document.getElementById(id)?.focus();
};
export const sortLanguage = (langArr: { [key: string]: any }[], key: string) => {
  langArr.sort(function (a, b) {
    const langA = a[key].toUpperCase();
    const langB = b[key].toUpperCase();
    if (langA < langB) {
      return -1;
    }
    if (langA > langB) {
      return 1;
    }
    return 0;
  });
};

export const rightPanelSize = (level?: number, isRightPanelOpen?: boolean) => {
  const navBarMenuWidth: number = 75;
  const expectCardWidth: number = 40;
  const widthValue: number = !isRightPanelOpen
    ? parseInt(window.innerWidth - navBarMenuWidth - expectCardWidth - level * 20)
    : parseInt(window.innerWidth - navBarMenuWidth - expectCardWidth - window.innerWidth * 0.33 - level * 20);
  return widthValue;
};

export const getTableSortPref = (module: string, sortQuery?: any) => {
  const localData = localStorage.getItem('tableSortPref');
  const sortBy = module === 'project' ? 'due_date' : 'create_ts';
  let sortQueryObj = { sortingOrder: 1, sortBy };
  if (localData) {
    const tableSortPref = JSON.parse(localData);
    if (tableSortPref[module]) {
      sortQueryObj = tableSortPref[module];
    } else {
      setTableSortPref(module, sortQueryObj);
    }
  }
  if (sortQuery) {
    sortQuery = { ...sortQuery, ...sortQueryObj };
    return sortQuery;
  }
  return sortQueryObj;
};

export const setTableSortPref = (module: string, sortObj: { sortBy: string; sortingOrder: number }) => {
  const localData = localStorage.getItem('tableSortPref');
  let tableSortPref: { [key: string]: { sortBy: string; sortingOrder: number } } = {};
  if (localData) {
    tableSortPref = JSON.parse(localData);
  }
  tableSortPref[module] = sortObj;
  localStorage.setItem('tableSortPref', JSON.stringify(tableSortPref));
};

export function parseQuery(str: any) {
  if (typeof str != 'string' || str.length === 0) return {};
  const s = str.split('&');
  let bit = [],
    query: { [key: string]: any } = {};
  let first, second;
  for (let i = 0, s_length = s.length; i < s_length; i++) {
    bit = s[i].split('=');
    first = decodeURIComponent(bit[0]);
    if (first.length === 0) {
      continue;
    }
    second = decodeURIComponent(bit[1]);
    if (typeof query[first] === 'undefined') {
      query[first] = second;
    } else if (query[first] instanceof Array) {
      query[first].push(second);
    } else {
      query[first] = [query[first], second];
    }
  }
  return query;
}

export const getSharedBUOptions = () => {
  return [
    {
      key: 'NONE',
      value: 'NONE',
      label: i18next.t('NONE')
    },
    {
      key: 'SELECTED',
      value: 'SELECTED',
      label: i18next.t('SELECTED')
    }
  ];
};

export const analyticCategory: { [key: string]: string } = {
  PROJECT: 'PROJECT',
  CONTENT_LIBRARY: 'CCL',
  MY_WORK: 'MY_WORK'
};

export const getAnswerWithMergeTagValue = (inputAns: string, question: any, mergeTagObject: any) => {
  if (inputAns?.length > 0) {
    const fileList: string[] = [];
    question?.files?.length > 0 &&
      question.files.forEach((fileData: { [key: string]: any }) => {
        fileList.push(fileData.fileName);
      });
    question?.cloudFiles?.length > 0 &&
      question.cloudFiles.forEach((fileData: { [key: string]: any }) => {
        fileList.push(fileData.fileName);
      });
    question?.documentFiles?.length > 0 &&
      question.documentFiles.forEach((fileData: { [key: string]: any }) => {
        fileList.push(fileData.name);
      });
    return handleResponseInRecommendation(inputAns, mergeTagObject, fileList, '');
  } else {
    return inputAns;
  }
};

const getCclValidCacheTab = (localTab?: string) => {
  const pathName: string = window.location.pathname;
  const isMainPage: boolean = pathName.includes('/v2/content-library/library');
  const validTabs: string[] = ['LIBRARY', 'MODERATION', 'REVIEW', 'TRASH', 'ARCHIVE'];
  localTab = localTab || localStorage.getItem('libraryCurrentTab');

  let validTab: string = localTab || 'LIBRARY';
  if (isMainPage && !validTabs.includes(validTab)) {
    validTab = 'LIBRARY';
  }
  return validTab;
};

export const getCclValidCurrentTab = (input?: string, localTab?: string): string => {
  const pathName: string = input || window.location.pathname;
  const isCCLTrash: boolean = pathName.includes('/v2/content-library/trash');
  const isLanguageReview: boolean = pathName.includes('/v2/content-library/answer-lib-language-review');
  const isReportPage: boolean = pathName.includes('/v2/content-library/insights');
  const isDedupePage: boolean = pathName.includes('/v2/content-library/dedupe');
  const isGuest: boolean = pathName.includes('/v2/guest-content');
  const isArchiveContentPage: boolean = pathName.includes('/v2/content-library/archive-content');
  const isObsoleteContentPage: boolean = pathName.includes('/v2/content-library/obsolete');
  const validTabs: string[] = ['LIBRARY', 'MODERATION', 'REVIEW'];

  if (isGuest) {
    return 'LIBRARY';
  }
  let currentTab: string = '';
  if (isCCLTrash) {
    currentTab = 'TRASH';
  } else if (isLanguageReview) {
    currentTab = 'LANGUAGE_REVIEW';
  } else if (isReportPage) {
    currentTab = 'REPORT';
  } else if (isDedupePage) {
    currentTab = 'DEDUPE';
  } else if (isArchiveContentPage) {
    currentTab = 'ARCHIVE';
  } else if (isObsoleteContentPage) {
    currentTab = 'OBSOLETE';
  } else {
    const params: URLSearchParams = new URLSearchParams(window.location.search);
    const tabToBeLoaded: string = params.get('currentTab') || '';
    const urlContainsTab: boolean = Boolean(tabToBeLoaded);
    if (validTabs.includes(tabToBeLoaded)) {
      currentTab = tabToBeLoaded;
    } else {
      currentTab = getCclValidCacheTab(localTab);
    }
    const isModerationEnabled: boolean = (isFeatureEnabled('answer_library_moderation') || isFeatureEnabled('document_library_moderation')) && checkPermissionEnabled('MODERATE_MY_CONTENT');
    const isReviewEnabled: boolean = isFeatureEnabled('answer_library_review') || isFeatureEnabled('on_demand_review');
    if ((!isModerationEnabled && currentTab === 'MODERATION') || (!isReviewEnabled && currentTab === 'REVIEW')) {
      currentTab = 'LIBRARY';
    }
    if (urlContainsTab && currentTab !== tabToBeLoaded) {
      params.set('currentTab', currentTab);
    }
  }
  return currentTab;
};

export const getDeleteFileOption = (isSection: boolean) => {
  const localData = localStorage.getItem('deleteFileOption');
  let option: string = isSection ? 'DELETE_FILE_ONLY_IN_SECTION' : 'DELETE_FILE_ONLY_IN_QUESTION';
  if (localData && localData === 'second') {
    option = 'DELETE_FILE_FROM_PROJECT';
  }
  return option;
};

export const getPasswordHint = (password_policy: { [key: string]: boolean | number }, password: string) => {
  const testArr: string[] = [];
  Object.keys(password_policy).forEach((item: string) => {
    switch (item) {
      case 'atleast_one_lowercase':
        if (!password.match(/(?=.*[a-z])/)) {
          testArr.push(item);
        }
        break;
      case 'atleast_one_number':
        if (!password.match(/.*\d.*/)) {
          testArr.push(item);
        }
        break;
      case 'atleast_one_special_character':
        if (!password.match(/.*[^a-zA-Z0-9].*/)) {
          testArr.push(item);
        }
        break;
      case 'atleast_one_uppercase':
        if (!password.match(/.*[A-Z].*/)) {
          testArr.push(item);
        }
        break;
      case 'begin_with_alphabet':
        if (!password.match(/^[a-zA-Z].*/)) {
          testArr.push(item);
        }
        break;
      case 'minimum_password_length':
        if (!(password.length >= Number(password_policy[item]))) {
          testArr.push(item);
        }
        break;

      default:
        break;
    }
  });
  return testArr;
};

export const columnResize = (columnApi: any) => {
  columnApi.columnController?.displayedColumns.forEach((col: any) => {
    if (['packageName', 'projectname', 'requestname'].indexOf(col.colId) > -1) {
      col.colDef['pinned'] = false;
      col.colDef['flex'] = 1;
      columnApi.resetColumnState();
    }
  });

  const timeOut = setTimeout(() => {
    columnApi.columnController?.displayedColumns.forEach((col: any) => {
      if (['packageName', 'projectname', 'requestname'].indexOf(col.colId) > -1) {
        col.colDef['pinned'] = true;
        columnApi.resetColumnState();
      }
    });
    clearTimeout(timeOut);
  }, 300);
};

export const padSingleDigitWithZero = (number: number) => {
  return number >= 1 && number <= 9 ? `0${number} ` : `${number} `;
};

export const sortObjectArraybyArray = (objectArray: any[], stringArray: string[], comparator: string, sortAlphabetical?: boolean, combineCase?: boolean) => {
  const normalize = (value: string) => (combineCase ? value.toLowerCase() : value);

  if (sortAlphabetical) {
    stringArray.sort((a, b) => normalize(a).localeCompare(normalize(b)));
  }

  const getIndex = (value: string) => {
    const normalizedValue = normalize(value);
    return stringArray.findIndex(item => normalize(item) === normalizedValue);
  };

  return objectArray.slice().sort((a, b) => {
    const aIndex = getIndex(a[comparator]);
    const bIndex = getIndex(b[comparator]);

    if (aIndex === -1) return 1;
    if (bIndex === -1) return -1;

    return aIndex - bIndex;
  });
};
export const isCharacterALetter = (char: string) => {
  return char.length === 1 && RegExp(/[a-z]/i).exec(char);
};

export const incrementString = (character: string) => {
  let carry: number = 1;
  let res: string = '';
  for (let i = character.length - 1; i >= 0; i--) {
    let char: number = character.toUpperCase().charCodeAt(i);
    char += carry;
    if (char > 90) {
      char = 65;
      carry = 1;
    } else {
      carry = 0;
    }
    res = String.fromCharCode(char) + res;
    if (!carry) {
      res = character.substring(0, i) + res;
      break;
    }
  }
  if (carry) {
    res = 'A' + res;
  }
  return res;
};

export const tableTypeIgnoreFieldList: string[] = ['sno', 'id', 'row', 'colSpan', 'style', 'isCustom', 'fullTable'];

export const getTableAnswerTypeText = (answer: string) => {
  let resultText: string = '';
  if (isJson(answer)) {
    const answerJSON = JSON.parse(answer);
    if (answerJSON.length > 0) {
      answerJSON.forEach((option: any) => {
        option &&
          Object.keys(option)?.forEach((key: string) => {
            if (!tableTypeIgnoreFieldList.includes(key) && option[key]) {
              resultText += option[key];
            }
          });
      });
    }
  } else {
    resultText = answer;
  }
  return resultText;
};

export const hasMergeTag = (value: string) => {
  const div = document.createElement('div');
  div.innerHTML = value;
  let mergeTagFound: boolean = false;
  if (div.querySelector('[merge-tag]')) {
    mergeTagFound = true;
  }
  return mergeTagFound;
};

export const searchText = (searchTerm: string, targetString?: string) => {
  return targetString ? new RegExp('.*' + searchTerm + '.*', 'gi').test(targetString) : false;
};

export const getBillingContacts = () => {
  const allUsers = getUserList({ restrictTeam: true }) as UserVO[];
  const permissionMap = store.getState().userData.permissionData;
  const temp = allUsers.filter(user => {
    if (isSuperAdmin(user)) {
      return true;
    } else {
      return user?.additionalRoles?.includes('BILLING');
    }
  });
  return temp;
};

let timer: NodeJS.Timeout;
export const debounceFunc = (func: any, delay: number) => {
  return function (...args: any) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const getMergeTagKeyMap = (content: string, usedMergeTagMap: { [key: string]: string }) => {
  const tempDiv: HTMLDivElement = document.createElement('div');
  tempDiv.innerHTML = content;
  const divsWithMergeTag: NodeListOf<Element> = tempDiv.querySelectorAll('span[merge-tag],div[merge-tag]');
  divsWithMergeTag?.forEach(element => {
    const mergeTagValue: string | null = element.getAttribute('merge-tag');
    const usedMergeTagList: string[] = Object.keys(usedMergeTagMap);
    const innerEleKey: string = '[' + mergeTagValue + ']';
    if (mergeTagValue?.startsWith('c-') && !usedMergeTagList.includes(mergeTagValue) && element.innerHTML !== innerEleKey) {
      usedMergeTagMap[mergeTagValue] = element.innerHTML;
    } else {
      // do nothing
    }
  });
};

export const setUsedMergeTagFromCopy = (content: string) => {
  const usedMergeTagMap = store.getState().contentMasterData.usedMergeTagMap;
  const preMergeTagLength: number = Object.keys(usedMergeTagMap).length;
  getMergeTagKeyMap(content, usedMergeTagMap);
  const currentMergeTagLength: number = Object.keys(usedMergeTagMap).length;
  if (currentMergeTagLength > preMergeTagLength) {
    const setUsedMergeTagMap = store.getActions().contentMasterData.setUsedMergeTagMap;
    setUsedMergeTagMap(usedMergeTagMap);
  }
};

export const setMergeTagMaps = async (mergeTagMapFacets: any, callBackFun?: (response: { [key: string]: string }) => void) => {
  try {
    const setUsedMergeTagMap = store.getActions().contentMasterData.setUsedMergeTagMap;
    const setContentMergeTagMap = store.getActions().contentMasterData.setContentMergeTagMap;

    const usedMergeTagArray: string[] = [];
    Object.values(mergeTagMapFacets).forEach(entry => {
      usedMergeTagArray.push(entry.key);
    });
    const myObj: { [key: string]: string[] } = {
      usedMergeTagNames: usedMergeTagArray
    };
    const mergeTagsResult = await axiosInstance().post('/answer-lib/get-content-merge-tag-map', myObj);
    if (callBackFun && mergeTagsResult?.data['contentMergeTagMap']) {
      callBackFun(mergeTagsResult?.data['contentMergeTagMap']);
    }
    if (mergeTagsResult && mergeTagsResult.status === 200 && mergeTagsResult.data) {
      if (callBackFun && mergeTagsResult?.data['contentMergeTagMap']) {
        callBackFun(mergeTagsResult?.data['contentMergeTagMap']);
      }
      const usedMergeTagsCorrectedResult: { [key: string]: string } = {};
      const contentMergeTagList: string[] = Object.keys(mergeTagsResult.data['contentMergeTagMap']);
      for (const key in mergeTagsResult.data['usedMergeTagMap']) {
        if (mergeTagsResult.data['usedMergeTagMap']?.hasOwnProperty(key) && mergeTagsResult.data['usedMergeTagMap']?.[key]?.length > 0) {
          usedMergeTagsCorrectedResult['c-' + key] = mergeTagsResult.data['usedMergeTagMap'][key];
        }
      }
      setUsedMergeTagMap(usedMergeTagsCorrectedResult);
      if (mergeTagsResult.data['contentMergeTagMap'] && contentMergeTagList?.length > 0) {
        setContentMergeTagMap(contentMergeTagList);
      }
    }
  } catch (e) {
    errorLogger(e, false, 'from setMergeTagMaps method GeneralUtils.ts');
  }
};

/** <<filter function for Object>>
 * filterFunction : function which takes [key, value] as param and return true if that key,value pair needs to be included in result Object
 */
export const filterObject = (inputObject: { [key: string]: any }, filterFunction: ([key, value]: [string, any]) => boolean) => {
  if (!inputObject || !filterFunction) {
    return {};
  }
  return Object.fromEntries(Object.entries(inputObject).filter(filterFunction));
};
export const getCclValidCurrentContentTypeTab = (inputCurrentTab?: string): string => {
  const currentTab: string = inputCurrentTab || localStorage.getItem('libraryCurrentTab') || 'LIBRARY';
  let currentContentType: any = localStorage.getItem('cclCurrentContentTypeTab') || '{}';
  currentContentType = currentContentType && isJson(currentContentType) ? JSON.parse(currentContentType) : {};
  if (currentTab === 'MODERATION' && currentContentType[currentTab]) {
    if (currentContentType[currentTab] === 'DOCUMENT' && !isFeatureEnabled('document_library_moderation')) {
      return 'ANSWER';
    } else if (currentContentType[currentTab] === 'ANSWER' && !isFeatureEnabled('answer_library_moderation')) {
      return 'DOCUMENT';
    } else if (['ANSWER', 'DOCUMENT'].includes(currentContentType[currentTab])) {
      return currentContentType[currentTab];
    } else {
      return 'ANSWER';
    }
  }
  return currentContentType[currentTab] || (currentTab === 'LIBRARY' ? 'TOP_RESULTS' : 'ANSWER');
};

export const updateCurrentContentTypeTab = (currentTab: string, inputTab: string): void => {
  let currentContentType: any = localStorage.getItem('cclCurrentContentTypeTab') || '{}';
  currentContentType = currentContentType && isJson(currentContentType) ? JSON.parse(currentContentType) : {};
  currentContentType[currentTab] = inputTab;
  localStorage.setItem('cclCurrentContentTypeTab', JSON.stringify(currentContentType));
};

//to check whether current user enabled trial feature
export const isUserEnabledTrialFeature = (trialFeatureName: string) => {
  return store.getState().userDetails?.userTrialFeatureDetailsMap?.hasOwnProperty(trialFeatureName) || isSupport();
};

//to check trial feature key is present in feature details available in companyPreferencesVO
export const isTrialFeaturePresentInFeatureDetails = (trialFeatureName: string) => {
  return store.getState().userDetails.userDetails.companyPreferencesVO?.featureDetails?.hasOwnProperty(trialFeatureName);
};

export const checkIsTrialExpired = (trialFeatureName: string) => {
  const paidFeatureName = trialFeatureName.replace('_trial', '');
  const threshold = store.getState().userDetails?.trialFeatureThresholdMap?.[trialFeatureName];
  const createdCount = store.getState().userDetails?.userTrialFeatureDetailsMap?.[trialFeatureName]?.createdCount;
  return !isFeatureEnabled(paidFeatureName) && (!isFeatureEnabled(trialFeatureName) || (createdCount >= threshold && !isSupport()));
};

export const isValidToAccessTrialFeature = (trialFeatureName: string) => {
  const paidFeatureName = trialFeatureName.replace('_trial', '');
  if (isFeatureEnabled(paidFeatureName)) {
    return true;
  }
  const threshold = store.getState().userDetails?.trialFeatureThresholdMap?.[trialFeatureName];
  const createdCount = store.getState().userDetails?.userTrialFeatureDetailsMap?.[trialFeatureName]?.createdCount;
  return isFeatureEnabled(trialFeatureName) && (createdCount < threshold || Boolean(isSupport()));
};

export const replaceUnicodeEscapes = (htmlString: string): string => {
  return htmlString.replace(/\\u([0-9a-fA-F]{4})/g, (match, hex) => String.fromCharCode(parseInt(hex, 16)));
};

export const preventTyping = (answer: AnswerVO, e: KeyboardEvent) => {
  if ((answer.answerType === 'BASIC_RICH_TEXT' || answer.answerType === 'TEXT') && (answer.wordLimit || answer.characterLimit) && answer.maxCharCountWarning) {
    const regex: RegExp = /[\w\W]/gm;
    const preventPaste = e.key !== 'v' && e.key !== 'V';
    const preventAlphaNumeric = !regex.test(e.key);
    const allowCtrlAltFunctions = e.key.length != 1 || e.ctrlKey || e.altKey;
    if (preventAlphaNumeric || (allowCtrlAltFunctions && preventPaste)) {
      return;
    }
    e.preventDefault();
  }
};
//get px value from px pt rem em
const baseFontSize = 16;
const ptToPxRatio = 1.3333;
export const convertToPixels = (value: string) => {
  if (!value) {
    return 0;
  }

  const parsedValue = parseFloat(value);
  const unitMatcher = value.match(/[a-z%]+/i);
  const unit = unitMatcher != null && unitMatcher.length > 0 ? unitMatcher[0] : '';
  let pixels;
  switch (unit) {
    case 'pt':
      pixels = parsedValue * ptToPxRatio;
      break;
    case 'em':
    case 'rem':
      pixels = parsedValue * baseFontSize;
      break;
    case 'px':
      pixels = parsedValue;
      break;
    default:
      pixels = 0;
  }

  return pixels;
};

export enum AskActivities {
  RESPONSIVE_ASK = 'RESPONSIVE_ASK',
  CHILD_WINDOW_ASK_READY = 'CHILD_WINDOW_ASK_READY'
}

export const isFilterItemHasValue = (item: any) => {
  return (typeof item === 'string' && item && item !== 'SELECT') || (Array.isArray(item) && item.length > 0 && (item.length > 1 || item[0] !== 'SELECT'));
};

export const isFilterHasValue = (filter: any) => {
  return Object.values(filter).some(val => isFilterItemHasValue(val));
};

export const getAnswerTypeOptions = (sectionTemplateType: string) => {
  if (sectionTemplateType === 'JAGGAER') {
    return answerTypeOptions.filter(item => item.key !== 'RICH_TEXT');
  } else {
    return answerTypeOptions;
  }
};
export const isFontAvailable = (font: string) => {
  const testFontAvailability = (fontFamily: string) => {
    const span = document.createElement('span');
    span.innerHTML = Array(100).join('wi');
    span.style.cssText = `
        position: absolute !important;
        width: auto !important;
        font-size: 128px !important;
        left: -99999px !important;
      `;
    span.style.fontFamily = fontFamily;
    document.body.appendChild(span);
    const width = span.clientWidth;
    document.body.removeChild(span);
    return width;
  };

  const monospaceWidth = testFontAvailability('monospace');
  const serifWidth = testFontAvailability('serif');
  const sansSerifWidth = testFontAvailability('sans-serif');

  return monospaceWidth !== testFontAvailability(`${font},monospace`) || sansSerifWidth !== testFontAvailability(`${font},sans-serif`) || serifWidth !== testFontAvailability(`${font},serif`);
};

export const updateTimeTracker = (updateLastRecordedTime?: boolean, userData?: any, currentModule?: any, entityId?: string, tabId?: string, isTemplate?: boolean) => {
  const skipUrlKeys = ['guest-section', 'guest-sign', 'profile-view', 'profile-center', 'vendor-registration'];
  if (userData.isSupport || checkIfArraysHasContainsAny(skipUrlKeys, window.location.href.split('/')) || (currentModule && !currentModule.needsAuth)) {
    return;
  }
  const skipTrackingModules = ['respondent-guest', 'Active Company'];
  let moduleName = currentModule?.moduleName || 'other';

  if (isTemplate) {
    moduleName = 'PROJECT_TEMPLATES';
  }
  const newEntityId = (moduleName === 'project' || moduleName === 'PROJECT_TEMPLATES' || moduleName.includes('request')) && entityId ? entityId : '';
  if (skipTrackingModules.includes(moduleName) || (!newEntityId && (moduleName === 'project' || moduleName.includes('request')))) {
    return;
  }
  const savedEvent = localStorage.getItem('timeTrackerObj');
  let event: { [key: string]: any } = {};
  if (savedEvent) {
    localStorage.removeItem('timeTrackerObj');
    event = JSON.parse(savedEvent);
  }
  const obj: { [key: string]: any } = Object.keys(event)?.length > 0 ? event : { tabId: tabId, moduleName: moduleName, entityId: newEntityId, updateLastRecordedTime: updateLastRecordedTime || false };
  axiosInstance().post('users/update-time-tracker', obj);
};
