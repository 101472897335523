import CompanyCollectionVO from '@interfaces/Models/CompanyCollectionVO';
import ManageTeamsVO from '@interfaces/Models/ManageTeamsVO';
import { checkIfArraysHasContainsAny } from '@utils/ArrayUtils';
import { checkIsValidTeamId } from '@utils/ValidationUtils';
import MasterDataVO from '@interfaces/Models/MasterDataVO';
import UserVO from '@interfaces/Models/UserVO';
import store from '../store';
import UserDetailsVO from '@interfaces/Models/UserDetailsVO';
import axiosInstance from '@helpers/Axios';
import ProjectVO from '@interfaces/Models/ProjectVO';
import { getUserList, getUserMap, validateExistingUsersWithBU, validateUser } from './user-load-details/ManageUserUtils';
import { getUrlParam } from './URLParamUtil';
import { getUserSpecificLandingPage } from '@components/header-wrapper/HeaderWrapperUtils';
import { getReturnTo } from '@containers/login/LoginUtils';
import { getZendeskUrlApi } from '@services/AppFrameWorkService';

let userStore: { [key: string]: any } | null = null;
let company: any | null = null;
let companyForAsk: any | null = null;
let companyId: string = '';

export const updateProjectMasterMap = (data: UserDetailsVO) => {
  const stageMap: { [key: string]: MasterDataVO } = {};
  data.projectStageList.dataList.forEach(stage => {
    stageMap[stage.value] = stage;
  });
  const typeMap: { [key: string]: MasterDataVO } = {};
  data.projectTypeList.dataList.forEach(type => {
    typeMap[type.value] = type;
  });
  const flagMap: { [key: string]: MasterDataVO } = {};
  data.projectFlagList?.dataList.forEach(flag => {
    flagMap[flag.id] = flag;
  });
  const masterData: any = {};
  masterData.projectStageMap = stageMap;
  masterData.projectTypeMap = typeMap;
  masterData.projectFlagMap = flagMap;
  masterData.projectStageList = data.projectStageList.dataList;
  masterData.projectTypeList = data.projectTypeList.dataList;
  masterData.projectFlagList = data.projectFlagList?.dataList || [];
  return masterData;
};

export const upadateProjectStageMap = (dataList: MasterDataVO[]) => {
  store.getActions().projectMasterData.setProjectStageList(dataList);
  const stageMap: { [key: string]: MasterDataVO } = {};
  dataList.forEach(stage => {
    stageMap[stage.value] = stage;
  });
  store.getActions().projectMasterData.setProjectStageMap(stageMap);
};

export const constructRequestMasterData = (data: UserDetailsVO) => {
  const stageMap: { [key: string]: MasterDataVO } = {};
  data.requestStageList?.dataList?.forEach(stage => {
    stageMap[stage.value] = stage;
  });
  const typeMap: { [key: string]: MasterDataVO } = {};
  data?.requestTypeList?.dataList.forEach(type => {
    typeMap[type.value] = type;
  });
  const vendorMap: { [key: string]: MasterDataVO } = {};
  data?.vendorStageList?.dataList.forEach(type => {
    vendorMap[type.value] = type;
  });
  const masterData: any = {};
  masterData.requestStageMap = stageMap;
  masterData.requestStageList = data.requestStageList?.dataList || [];
  masterData.requestTypeMap = typeMap;
  masterData.requestTypeList = data?.requestTypeList?.dataList || [];
  masterData.vendorTypeMap = vendorMap;
  masterData.vendorStageList = data?.vendorStageList?.dataList || [];

  return masterData;
};

export const updateContentFlagMap = (dataList: MasterDataVO[]) => {
  const flagList: string[] = dataList?.map((flag: MasterDataVO) => flag.value) || [];
  const flagMap: { [key: string]: MasterDataVO } = {};
  const flagIdMap: { [key: string]: MasterDataVO } = {};
  dataList?.forEach(flag => {
    flagMap[flag.value] = flag;
    flagIdMap[flag.id] = flag;
  });
  const contentMasterData = { contentFlagMap: flagMap, contentFlagIdMap: flagIdMap, contentFlagList: dataList, flagList };

  return contentMasterData;
};

export const updateProjectCategoriesList = (list: string[]) => {
  store.getActions().projectMasterData.setProjectCategoriesList(list);
};

export const setCollectionDetails = (collectionList: CompanyCollectionVO[], skipUpdate?: boolean) => {
  const collectionMap: { [key: string]: CompanyCollectionVO } = {};
  if (collectionList && collectionList.length > 0) {
    collectionList.forEach(col => {
      collectionMap[col.id] = col;
    });
  }
  const collectionDetails = { collectionList: collectionList, collectionMap: collectionMap };
  if (!skipUpdate) {
    store.getActions().companyCollection.setCompanyCollectionData(collectionDetails);
  }
  return collectionDetails;
};

export const getUser = (username: any) => {
  return store.getState().userData.companyUserMap[username];
};
export const getExtAppName = (userName: string): string | undefined => {
  if (userName?.startsWith(getCompanyId() + '.app') && userName?.endsWith('@responsive.io')) {
    return store.getState().additionalInfoStore.additionalInfo?.appDetails?.[userName];
  }
};
export const getUserIfPermission = (userName: string, accessModule: string, permissions: string[]) => {
  const user = getUser(userName);
  let isUserActive = user && user.userStatus !== 'IN_ACTIVE' && user.userStatus !== 'IN_ACTIVE';
  if (isUserActive) {
    return validateUser({ userVO: user, module: accessModule, permissions });
  }
  return false;
};

export const getUserForSupport = () => {
  return store.getState().userDetails.userDetails.userInfoVO;
};

export const getUserPodName = () => {
  return store.getState().userDetails.userDetails.companyInfoVO.podName;
};

export const getCurrentUser = (isGuest?: boolean) => {
  const localStorageKey = isGuest ? 'guestUserData' : window.matchMedia('(display-mode: standalone)').matches && window.location.pathname?.startsWith('/ask') ? 'userDataAsk' : 'userData';
  if (!userStore || isGuest) {
    const userData = localStorage.getItem(localStorageKey);
    if (userData) {
      userStore = JSON.parse(userData);
    }
  }
  return userStore ?? '';
};

export const updateCurrentUser = (userData: any) => {
  if (userData) {
    window.matchMedia('(display-mode: standalone)').matches && window.location.pathname?.startsWith('/ask')
      ? localStorage.setItem('userDataAsk', JSON.stringify(userData))
      : localStorage.setItem('userData', JSON.stringify(userData));
  }
};

export const getSelectedCompany = () => {
  const isAskPWA =
    (window.location.pathname?.startsWith('/ask') || localStorage.getItem('v2_redirect_url_ask')?.includes('?return_to_ask=') || window.location.search?.includes('return_to_ask=')) &&
    window.matchMedia('(display-mode: standalone)')?.matches;
  if (isAskPWA ? !companyForAsk : !company) {
    company = localStorage.getItem(isAskPWA ? 'companyForAsk' : 'company');
    if (company == null) {
      company = '';
    } else {
      company = JSON.parse(company);
    }
  }
  return isAskPWA ? companyForAsk : company;
};

export const setSelectedCompany = (companyData: any, isAskPWA?: boolean | undefined) => {
  localStorage.setItem(isAskPWA ? 'companyForAsk' : 'company', JSON.stringify(companyData));
  if (isAskPWA) {
    companyForAsk = companyData;
  } else {
    company = companyData;
    setCompanyId(companyData.id);
  }
  updateWindowInfo();
};

export const setCurrentUser = (userData: any, companyId?: string) => {
  const isAskPWA =
    (window.location.pathname?.startsWith('/ask') || localStorage.getItem('v2_redirect_url_ask')?.includes('?return_to_ask=') || window.location.search?.includes('return_to_ask=')) &&
    window.matchMedia('(display-mode: standalone)')?.matches;
  cleanupUser();
  if (isAskPWA) {
    userData.selectedCompanyId = companyId;
    localStorage.setItem('userDataAsk', JSON.stringify(userData));
  } else {
    localStorage.setItem('userData', JSON.stringify(userData));
  }
  try {
    if (window.opener !== null && window.opener.localStorage) {
      window.opener.localStorage.setItem(isAskPWA ? 'userDataAsk' : 'userData', JSON.stringify(userData));
    }
  } catch (error) {
    console.log(error);
  }
  if (!companyId && !userData.support) {
    companyId = userData['id_0'];
  }
  if (userData['company_name_1']) {
    let loop = true,
      i = 0;
    while (loop) {
      if (!userData['id_' + i]) {
        loop = false;
      }
      if (userData['id_' + i] === companyId) {
        const companyDetail = {
          company_name: userData['company_name_' + i],
          role: userData['role_' + i],
          status: userData['status_' + i],
          id: userData['id_' + i],
          addl_role: userData['addl_role_' + i],
          pod_name: userData['pod_name_' + i]
        };

        setSelectedCompany(companyDetail, isAskPWA);
        setCompanyId(companyDetail.id);
        loop = false;
      }
      i++;
    }
  } else if (userData['id_0'] === companyId) {
    const companyDetail = {
      company_name: userData['company_name_' + 0],
      role: userData['role_' + 0],
      status: userData['status_' + 0],
      id: userData['id_' + 0],
      addl_role: userData['addl_role_' + 0],
      pod_name: userData['pod_name_' + 0]
    };
    setSelectedCompany(companyDetail, isAskPWA);
  }
  userData?.landingPage && localStorage.setItem('landingPage', userData.landingPage);
  localStorage.setItem('userLoggedIn', 'true');
  updateWindowInfo();

  //Need to check whether need this code.
  // serviceWorkerUser = userData;
  // serviceWorkerService.sendMessage({ type: 'rfpioData', methodType: 'SET', dataKey: 'user-data', data: userData });
};

export const updateWindowInfo = () => {
  window.username = null;
  window.rfpioUserData = null;
  if (userStore != null) {
    window.rfpioUserData = {};
    window.rfpioUserData.userName = userStore.username;
    const currCompany = getCurrentCompany();
    window.rfpioUserData.companyId = currCompany.id;
    window.rfpioUserData.companyName = currCompany.company_name;
  }
};

export const getCurrentCompany = () => {
  const currentCompany = getSelectedCompany();
  if (currentCompany != null && currentCompany !== '') {
    return currentCompany;
  }
  const userData: { [key: string]: any } = getCurrentUser() || {};
  const companyDetail = {
    company_name: userData['company_name_0'],
    role: userData['role_0'],
    status: userData['status_0'],
    id: userData['id_0'],
    addl_role: userData['addl_role_0']
  };
  return companyDetail;
};

export const cleanupUser = () => {
  userStore = null;
  company = null;
  window.username = null;
  window.rfpioUserData = null;
  const isAskPWA =
    window.matchMedia('(display-mode: standalone)')?.matches && (localStorage.getItem('v2_redirect_url_ask')?.includes('return_to_ask=') || window.location.pathname?.startsWith('/ask'));
  if (isAskPWA) {
    localStorage.removeItem('userDataAsk');
    localStorage.removeItem('companyForAsk');
  } else {
    localStorage.removeItem('userData');
    localStorage.removeItem('company');
    localStorage.removeItem('supportAccess');
    localStorage.removeItem('clickedBookMark');
    localStorage.removeItem('searchSupport');
    localStorage.removeItem('rightPreference');
    localStorage.removeItem('viewPreference');
    localStorage.removeItem('facetPreference');
    localStorage.removeItem('isBeforeDestroyPrompt');
    localStorage.removeItem('redirectedFrom');
    localStorage.removeItem('CCL_SORT');
    localStorage.removeItem('libraryCurrentTab');
    localStorage.removeItem('cclCurrentContentTypeTab');
    localStorage.removeItem('sessionTimeLimit');
    localStorage.removeItem('ngStorage-sessionTimeoutTime');
    localStorage.removeItem('retainedSectionFilter');
  }
  updateWindowInfo();
};

export const setUserStore = (userData: any) => {
  if (userData) {
    userStore = userData;
  }
};

export const getUserTeams = (user: any, isArray?: boolean) => {
  let userTeamIds: string[] = [];
  let users;
  if (user) {
    users = isArray ? user : [user];
    if (store.getState().userDetails.userDetails.companyPreferencesVO.featurePreferences?.manage_teams === '1') {
      users.forEach((user: any) => {
        const userFilterInfo = getUser(user);
        if (userFilterInfo?.teamIds) {
          userTeamIds = [...userTeamIds, ...userFilterInfo.teamIds];
        }
      });
    }
  }
  return userTeamIds;
};

export const getOutOfOfficeUserList = () => {
  const activeUserList = store.getState().userData.activeUserList;
  const userVOMap = store.getState().userData.companyUserMap;
  const outOfOfficeUserList: string[] = [];

  activeUserList.forEach(userId => {
    if (userVOMap[userId] && userVOMap[userId].outOfOffice) {
      outOfOfficeUserList.push(userVOMap[userId].userName);
    }
  });

  return outOfOfficeUserList;
};

export const isFeatureEnabled = (feature: string) => {
  const companyPreferencesVO: { [key: string]: any } = store.getState().userDetails.userDetails.companyPreferencesVO;
  const featurePreferenceMap: { [key: string]: string } = companyPreferencesVO['featurePreferences'];
  return featurePreferenceMap && featurePreferenceMap[feature] === '1';
};

export const getCompanyPreferenceVO = () => {
  return store.getState().userDetails.userDetails.companyPreferencesVO;
};

export const getCompanyPreferenceDataIfEnabled = (preference: any) => {
  if (isFeatureEnabled(preference)) {
    const data = store.getState().userDetails.userDetails.companyPreferencesVO.data;
    return data && data[preference];
  }
  return {};
};

export const getLineHeightForFont = function (fontSz: string, lineSpacing: string) {
  let fontSize = null;
  if (fontSz.includes('pt')) {
    fontSize = Math.round(parseInt(fontSz.replace('pt', '').trim()) * 1.333);
  }
  if (fontSz.includes('px')) {
    fontSize = Math.round(parseFloat(fontSz.replace('px', '').trim()));
  }
  if (fontSz.includes('em') || fontSz.includes('rem')) {
    const replaceValue = fontSz.includes('em') ? 'em' : 'rem';
    fontSize = Math.round(parseFloat(fontSz.replace(replaceValue, '').trim()) * 16);
  }
  if (fontSize) {
    return parseFloat(lineSpacing) * (fontSize + 3) + 'px';
  }
  return lineSpacing;
};

export const getCompanyPreferenceData = (preference: any) => {
  const data = store.getState().userDetails.userDetails.companyPreferencesVO.data;
  return data && data[preference];
};
export const getCompanyInfo = () => {
  return store.getState().userDetails.userDetails.companyInfoVO;
};

export const raisedByUser = (props: { userName: string }) => {
  const { userName } = props;
  const displayNameForRolesMap = store.getState().userData.displayNameForRolesMap;
  const companyUserMap = store.getState().userData.companyUserMap;
  const role = companyUserMap[userName] && companyUserMap[userName].userRole ? companyUserMap[userName].userRole : null;
  const name = getMentionName(userName, userName);
  return { name: name, displayName: role ? displayNameForRolesMap[role] : '', role: role };
};

export const getMentionName = function (userName: string, defaultValue: string | null) {
  if (userName === 'team') {
    return userName;
  }
  const teamsMapDetails = store.getState().userData.teamsMap;
  if (teamsMapDetails[userName] && isFeatureEnabled('manage_teams')) {
    return teamsMapDetails[userName].teamName;
  }
  var output = null;
  var member = getUser(userName);
  if (member != null && member.userName != null) {
    if (member.firstName != null && member.firstName.length > 0) {
      output = member.firstName;
      if (member.lastName != null && member.lastName.length > 0) {
        output = output + ' ' + member.lastName;
      }
    } else {
      output = member.userName;
    }
  } else {
    output = defaultValue;
  }
  return output;
};

export const getCustomFields = function () {
  const result: any[] = [];
  const customFields = store.getState().customFieldDetails.customFields;

  if (customFields && customFields.companyCustomFieldList) {
    customFields.companyCustomFieldList.forEach((d: { entityType: string }) => {
      if (!['INTAKE_REQUEST', 'ANSWER_LIBRARY', 'DOCUMENT_LIBRARY', 'SECTION_TEMPLATE', 'CATALOG', 'ALL'].includes(d.entityType)) {
        result.push(d);
      }
    });
  }
  return result;
};

export const getSuperAdmin = () => {
  const activeUserList = store.getState().userData.activeUserList;
  const userVOMap = store.getState().userData.companyUserMap;
  const user = activeUserList.find((member: any) => {
    if (userVOMap[member]) {
      return isSuperAdmin(userVOMap[member]);
    }
  });
  if (user) {
    return userVOMap[user];
  }
  return;
};

export const getFirstName = (userName: string) => {
  let user = '';
  const member = getUser(userName);
  if (member != null && member.userName != null) {
    if (member.firstName != null && member.firstName.length > 0) {
      user = member.firstName;
    } else {
      user = member.userName;
    }
  }
  return user;
};

export const isAdminUser = () => {
  return store.getState().userDetails.userDetails.userInfoVO.userRole === 'SUPER_ADMIN';
};

export const isSuperAdmin = (member: UserVO) => {
  return member.userRole === 'SUPER_ADMIN';
};

export const isSuperAdminOrSupport = () => {
  const permissionData = store.getState().userData.permissionData;
  return permissionData.isSuperAdmin || permissionData.isSupport;
};

export const setRoleDisplayName = (companyPolicy: any, t: any) => {
  let displayNameForPolicies: any = {};
  displayNameForPolicies['SUPER_ADMIN'] = t('SUPER_ADMIN');
  Object.values(companyPolicy).forEach((policy: any) => {
    displayNameForPolicies[policy.id] = policy.roleName;
  });
  store.getActions().userData.setDisplayNameForRolesMap(displayNameForPolicies);
};

export const isSupport = () => {
  return store.getState().userData.permissionData.isSupport;
};

export const setAllUserPolicies = (companyPolicy: any, companyPreferences: any, permissionData?: any) => {
  const isSuperAdmin = permissionData ? permissionData.isSuperAdmin : store.getState().userData.permissionData.isSuperAdmin;
  const isSupport = permissionData ? permissionData.isSupport : store.getState().userData.permissionData.isSupport;
  const isIntakeDisabled = (companyPreferences.featurePreferences.intake_request || companyPreferences.featurePreferences.intake_request == '0') == undefined;
  const isProposalDisabled = (companyPreferences.featurePreferences.proposal_builder || companyPreferences.featurePreferences.proposal_builder == '0') == undefined;

  let allUserPolicies: any = [];
  let primaryRoles: any = [];
  let addlRole: any = [];
  let forOptions: any = [];
  for (const policy in companyPolicy) {
    const role = companyPolicy[policy];
    if (role.addlRole) {
      if ((role.id === 'BILLING' && (isSuperAdmin || isSupport)) || role.id !== 'BILLING') {
        addlRole.push(role);
      }
    } else {
      const intakeToggle = role.toggleModule.indexOf('INTAKE') >= 0;
      const catalogToggle = role.toggleModule.indexOf('CATALOG') >= 0;
      if (
        !(
          (role.toggleModule.length == 1 && ((isIntakeDisabled && intakeToggle) || (isProposalDisabled && catalogToggle))) ||
          (role.toggleModule.length == 2 && isIntakeDisabled && isProposalDisabled && intakeToggle && catalogToggle)
        )
      ) {
        forOptions.push(role.roleName);
        primaryRoles.push(role);
      }
    }
  }
  allUserPolicies = { primaryRoles, addlRole, forOptions };

  return allUserPolicies;
};

export const getUserName = (userName: string) => {
  const activeUserList = store.getState().userData.activeUserList;
  const userVOMap = store.getState().userData.companyUserMap;
  const user: UserVO = activeUserList.includes(userName) ? userVOMap[userName] : ({} as UserVO);
  if (!user || Object.keys(user).length <= 0) {
    return '';
  } else if (!user.firstName && !user.lastName) {
    return user.userName;
  }
  return user.firstName + ' ' + user.lastName;
};
export const getCurrentUserDisplayName = () => {
  return store.getState().userDetails.userDetails.userInfoVO.displayName;
};

export const getUserAndTeamFileteredByBU = (tempList: any, buList: any) => {
  const teamsMapDetails = store.getState().userData.teamsMap;
  if (!buList || buList.includes('ALL')) {
    return tempList;
  }
  let newTeamList: any[] = [];
  tempList &&
    tempList.forEach((item: any) => {
      if (checkIsValidTeamId(item) && teamsMapDetails[item] && buList.includes(teamsMapDetails[item].primaryBU)) {
        newTeamList.push(item);
      } else {
        if (item === 'PROJECT_CREATOR' || item === 'PROJECT_OWNER' || item === 'ADDITIONAL_PRIMARY_CONTACT') {
          newTeamList.push(item);
        } else if (!teamsMapDetails[item]) {
          const user = getUser(item);
          if (user && (user.userRole === 'SUPER_ADMIN' || user?.businessUnits?.includes('ALL') || checkIfArraysHasContainsAny(user?.businessUnits || [], buList))) {
            newTeamList.push(item);
          }
        }
      }
    });
  return newTeamList;
};

export const getUsersForBusinessUnits = (users: string[], businessUnits: string) => {
  const userList: string[] = [];
  users.forEach((user: string) => {
    const userDetails = getUser(user);
    if (validateUserVO(userDetails, businessUnits)) {
      userList.push(user);
    }
  });
  return userList;
};

export const getBusinessUnitByUsers = (users: any, filterBUOption: any) => {
  if (users && !Array.isArray(users)) {
    users = [users];
  }
  if (isFeatureEnabled('business_units') && Array.isArray(users) && users.length > 0) {
    let businessUnitList: any[] = [];
    users.forEach(user => {
      const tempUser = getUser(user);
      if (filterBUOption) {
        if (filterBUOption === 'PRIMARY' && tempUser?.primaryBU) {
          businessUnitList.push(tempUser.primaryBU);
        } else if (filterBUOption === 'SHARED' && tempUser?.sharedBUs) {
          businessUnitList = tempUser.sharedBUs;
        }
      } else if (tempUser?.businessUnits && tempUser?.businessUnits.length > 0) {
        businessUnitList = tempUser.businessUnits;
      }
    });
    return businessUnitList;
  }
};

export const isActiveUser = (user: string) => {
  const companyUserMap = store.getState().userData.companyUserMap;
  return companyUserMap[user] && companyUserMap[user].userStatus === 'ACTIVE';
};

export const getAllInactiveUsers = () => {
  const companyUserMap = store.getState().userData.companyUserMap;
  return Object.values(companyUserMap)
    .filter(user => user.userStatus !== 'ACTIVE')
    .map(user => user.userName);
};

export const getCompanyUserName = (userName: string) => {
  const usersMap = store.getState().userData.companyUserMap;

  const appName = getExtAppName(userName);
  if (appName) {
    return appName;
  }

  const user = usersMap[userName];
  if (!user) {
    return userName;
  } else if (!user.firstName && !user.lastName) {
    return user.userName;
  } else if (!user.firstName && user.lastName) {
    return user.lastName;
  } else if (user.firstName && !user.lastName) {
    return user.firstName;
  }
  return user.firstName + ' ' + user.lastName;
};

export const getProjectPrimaryContacts = (businessUnits: string[], isRequest?: boolean, projectStatus?: string) => {
  const userMap = {
    module: isRequest ? 'REQUEST' : 'PROJECT',
    permissions: isRequest ? ['VIEW_MY_REQUESTS'] : ['VIEW_MY_PROJECTS'],
    buList: businessUnits,
    restrictTeam: true,
    attributes: ['PROJECT_PRIMARY_CONTACT'],
    checkInactiveUser: projectStatus !== 'ARCHIVED'
  };
  const allPrimaryUsers = getUserList(userMap);
  return allPrimaryUsers;
};

export const validateContactEmail = (metaData: ProjectVO) => {
  if ('ownedBy' in metaData || (metaData.additionalContacts && metaData.additionalContacts.length > 0)) {
    const validUsers = getProjectPrimaryContacts(metaData.businessUnits, false, metaData.status);
    let isValidOwner = false;
    if (metaData.ownedBy) {
      validUsers.some((user: UserVO) => {
        if (metaData.ownedBy === user.userName) {
          isValidOwner = true;
        }
      });
    }
    const primaryContacts = isValidOwner && metaData.ownedBy ? [metaData.ownedBy] : [];
    if (metaData.additionalContacts && metaData.additionalContacts.length > 0) {
      const validAdditionalContacts: string[] = [];
      validUsers.forEach((user: UserVO) => {
        if (metaData.additionalContacts.includes(user.userName) && user.userStatus === 'ACTIVE') {
          validAdditionalContacts.push(user.userName);
        }
      });
      const idx = validAdditionalContacts && validAdditionalContacts.indexOf(metaData.ownedBy);
      if (idx >= 0) {
        validAdditionalContacts.splice(idx, 1);
      }
      if (validAdditionalContacts.length > 0) {
        primaryContacts.push(...validAdditionalContacts);
      }
    }
    return primaryContacts as string[];
  }
};

export const isTeamUser = (listOfItems: string[]) => {
  let userTeamIds;
  if (isFeatureEnabled('manage_teams')) {
    userTeamIds = getUserTeams(getCurrentUser().username);
  }
  return userTeamIds && listOfItems && checkIfArraysHasContainsAny(listOfItems, userTeamIds);
};

export const areUsersInCompanyList = (userList: string[]) => {
  const users: string[] = [];
  userList.forEach((user: string) => {
    const userObj: UserVO = getUser(user);
    const hasPermission = validateUser({ userVO: userObj, module: 'PROJECT', attributes: ['PROJECT_PRIMARY_CONTACT'], permissions: ['VIEW_MY_PROJECTS'] });
    if (hasPermission) {
      users.push(user);
    }
  });
  return users;
};

export const getDefaultPrimaryBUForUser = (): string => {
  if (!isFeatureEnabled('business_units')) {
    return '';
  }
  const buMap = store.getState().businessUnitDetails.businessUnitIdMap;
  const buList = Object.values(buMap);
  const userInfoVO = store.getState().userDetails.userDetails.userInfoVO;
  if (isSupport() || isSuperAdmin(userInfoVO)) {
    return buList[0].id;
  } else if (userInfoVO?.primaryBU) {
    return userInfoVO.primaryBU;
  }
  return '';
};

export const getBusinessUnitUsers = (allUsers: any[], businessUnits: string[], module?: string) => {
  const isBUEnabled = isFeatureEnabled('business_units');
  if (!isBUEnabled || !Array.isArray(businessUnits) || businessUnits.length === 0) {
    return allUsers || getUserList({ buList: businessUnits, module, restrictTeam: true });
  }

  // BU Enabled - all Users defined flow
  if (Array.isArray(allUsers)) {
    const validUserList = validateExistingUsersWithBU({ buList: businessUnits, userList: allUsers });
    return validUserList;
  }

  //BU Enabled - Shared with ALL BU
  if (Array.isArray(businessUnits) && businessUnits?.includes('ALL')) {
    return getUserList({ restrictTeam: true });
  }

  //BU Enabled - Shared with specific BU
  let allUserMap = {};
  if (businessUnits) {
    allUserMap = getUserMap({ buList: businessUnits, restrictTeam: true });
  }
  return Object.values(allUserMap);
};

export const getCollectionUsers = (collectionList: string[]) => {
  let collectionUsersMap: { [key: string]: UserVO } = {};
  if (collectionList) {
    const collectionMap = store.getState().companyCollection.collectionMap;
    collectionList.forEach(collection => {
      const coll = collectionMap[collection];
      if (coll) {
        if (coll.users.indexOf('ALL_TEAM_MEMBERS') >= 0) {
          const collectionBU = coll.businessUnits;
          const usersFromBu = getUserMap({ buList: collectionBU, restrictTeam: true }) as { [key: string]: UserVO };
          collectionUsersMap = { ...collectionUsersMap, ...usersFromBu };
        } else {
          coll.users.forEach((user: string) => {
            collectionUsersMap[user] = getUser(user);
          });
        }
      }
    });
  }
  return collectionUsersMap;
};

export const getVendorStageList = () => {
  const vendorStages = store.getState().userDetails.userDetails.vendorStageList.dataList;
  const vendorStageList: string[] = [];
  vendorStages.forEach((stage: any) => {
    vendorStageList.push(stage.value);
  });
  return vendorStageList;
};

export function getFilteredCompanyUsers(mentionUsers: string[]) {
  const activeUsersList = store.getState().userData.activeUserList;
  const userVOMap = store.getState().userData.companyUserMap;
  let companyUserList: UserVO[] = [];
  if (mentionUsers.length > 0 && activeUsersList.length > 0) {
    companyUserList = mentionUsers.map(user => {
      if (activeUsersList.includes(user)) {
        return userVOMap[user];
      }
    });
  }
  return companyUserList;
}

export const getTeamUsersMap = (user: string) => {
  const teamsMap = store.getState().userData.teamsMap;
  return teamsMap[user].members;
};

export const getCurrentUserRole = () => {
  const currentUserInfo = getCurrentUser() as { username: string };
  const userName = currentUserInfo && currentUserInfo.username;
  return getUser(userName)?.userRole;
};

export const updateMasterTagList = (updatedMembers?: { [key: string]: UserVO }) => {
  const updateUserLoadDetails = store.getActions().userData.updateUserLoadDetails;
  const updateUsers = Object.keys(updatedMembers || {});
  const userList = getUserList({ restrictTeam: true }) as UserVO[];
  let tags: string[] = [];
  userList?.forEach((user: UserVO) => {
    const userTag = updateUsers.includes(user.userName) ? updatedMembers?.[user.userName]?.tags : user.tags;
    tags = Array.from(new Set([...tags, ...(userTag || [])]));
  });
  updateUserLoadDetails({ usersTagList: tags });
};

export const updateTeamMemberDetails = async (payloadData: { [key: string]: string[] }, updateTags?: boolean) => {
  const addCompanyUserMap = store.getActions().userData.addCompanyUserMap;
  const addMemberToTeamsMap = store.getActions().userData.addMemberToTeamsMap;
  const data = await axiosInstance().post('users/get-bulk-user-details', payloadData);
  if (data?.status === 200 && data.data) {
    addCompanyUserMap(data.data.users);
    data.data.teamInfo && addMemberToTeamsMap(data.data.teamInfo);
    if (updateTags) {
      updateMasterTagList(data.data.users);
    }
  }
};
export const setCompanyId = (data: string) => {
  companyId = data;
};

export const getCompanyId = () => {
  return companyId || getUrlParam('companyId') || '';
};

const pageAndPreferenceNameMap: { [key: string]: string } = {
  'app.respondRfp': 'PROJECTS',
  'app.reports': 'REPORTS',
  'app.documents-lib': 'CONTENT_LIBRARY',
  'app.answer-lib': 'CONTENT_LIBRARY',
  'app.request': 'REQUEST',
  'app.profile': 'PROFILE'
};

const landingPageModuleName: string[] = ['PROJECTS', 'CONTENT_LIBRARY', 'REQUEST', 'REPORTS', 'PROFILE'];

/*handlePageRedirect method is used to handle below scenarios
1.Return to v2 if v2_redirect_url is in localStorage.
2.Default Landing page Logic.
3.User specific Landing page will be decided only if the module is enabled and user has permission to that module,
  if this logic not satified by default my work will be landed.
*/
export const handlePageRedirect = (userDetails: UserDetailsVO, history: any) => {
  const companyId = getCompanyId();
  const isASKPWA =
    (window?.location?.pathname?.startsWith('/ask') || localStorage?.getItem('v2_redirect_url_ask')?.includes('return_to_ask')) && window?.matchMedia('(display-mode: standalone)')?.matches;
  const returnToV2 = isASKPWA ? localStorage.getItem('v2_redirect_url_ask') : localStorage.getItem('v2_redirect_url');
  let userData = localStorage.getItem('userData');
  userData = userData && JSON.parse(userData);

  // vanilla community and zendeskUrl login redirection
  const returnTo = getUrlParam('return_to') || getUrlParam('target') || getReturnTo();
  if (returnTo) {
    redirecToExternalPage(returnTo, returnTo.indexOf('vanilla') > -1);
    if (isASKPWA) {
      localStorage.removeItem('v2_redirect_url_ask');
    } else {
      localStorage.removeItem('v2_redirect_url');
    }
    return;
  } // End
  //Redirect if return to v2 is in localStorage.
  if (returnToV2) {
    const queryList = returnToV2.includes(window.location.origin)
      ? returnToV2.split(window.location.origin)
      : returnToV2.includes('?return_to_ask')
      ? returnToV2.split('?return_to_ask')
      : returnToV2.split('?return_to_v2=');
    let href = '';
    if (queryList.length > 0) {
      queryList.forEach((element, index) => {
        if (element !== '') {
          element.split('&').forEach((element, index) => {
            if (index === 0) {
              href = element;
            } else if (index === 1) {
              href = href + '?' + element;
            } else {
              href = href + '&' + element;
            }
          });
          if (href.includes('/v2/respondent-view')) {
            let guestUserData = localStorage.getItem('guestUserData');
            if (userData && guestUserData) {
              guestUserData = guestUserData ? JSON.parse(guestUserData) : {};
              guestUserData['accessKey'] = userData?.access_token;
              localStorage.setItem('guestUserData', JSON.stringify(guestUserData));
            }
          }
        }
      });
    }
    if (!href?.includes('/v2/home')) {
      if (isASKPWA) {
        localStorage.removeItem('v2_redirect_url_ask');
      } else {
        localStorage.removeItem('v2_redirect_url');
      }
      if (href.includes('/v2/respondent-view') || href?.startsWith('/ask')) {
        history?.push(href);
        window.location?.reload();
      } else if (!href?.startsWith('/v2')) {
        window.location.href = (window.location.origin?.includes('localhost') ? '/app/#' : '/#') + href;
      } else {
        history.push(href, { from: href });
      }
      return;
    }
  }

  if (isSupport()) {
    window.location.href = window.location.origin + (window.location.origin?.includes('localhost') ? '/app/#' : '/#') + '/my-work';
  }

  let landingPage: string = userData?.landingPage;

  const userRole = getCurrentUserRole();
  const companyPolicy: { [key: string]: { [key: string]: any } } = userDetails.companyPolicyMap;
  const currentRole = companyPolicy[userRole];

  //check user has access for specific pages before redirect
  if (landingPageModuleName.includes(pageAndPreferenceNameMap[landingPage])) {
    for (let i = 0; i < landingPageModuleName.length; i++) {
      if (pageAndPreferenceNameMap[landingPage] === landingPageModuleName[i] && !checkAccess(pageAndPreferenceNameMap[landingPage], userRole, currentRole)) {
        landingPage = '';
        break;
      }
    }
  } else {
    landingPage = '';
  }
  const landingPageUrl: string = getUserSpecificLandingPage(landingPage);
  if (isASKPWA) {
    localStorage.removeItem('v2_redirect_url_ask');
  } else {
    localStorage.removeItem('v2_redirect_url');
  }
  localStorage.setItem('lastLoggedCompany', companyId);
  history.push(`${landingPageUrl}?companyId=${companyId}`);
};

const checkAccess = (module: string, userRole: string, currentRole: { [key: string]: string }) => {
  let isModuleEnabled: boolean;
  switch (module) {
    case 'CONTENT_LIBRARY':
      isModuleEnabled = isFeatureEnabled('consolidated_content_library');
      break;
    case 'REQUEST':
      isModuleEnabled = isFeatureEnabled('request');
      break;
    case 'PROFILE':
      isModuleEnabled = isFeatureEnabled('profiles');
      break;
    default:
      isModuleEnabled = true;
      break;
  }
  return isModuleEnabled && checkCurrnetRoleAccess(module, userRole, currentRole);
};

export const checkCurrnetRoleAccess = (module: string, userRole: string, currentRole: { [key: string]: string }) => {
  if (userRole === 'SUPER_ADMIN') {
    return true;
  }
  switch (module) {
    case 'PROJECTS':
      return currentRole?.toggleModule.includes('PROJECT');
    case 'CONTENT_LIBRARY':
      return currentRole?.toggleModule.includes('CONTENT_LIBRARY');
    case 'REQUEST':
      return currentRole?.toggleModule.includes('REQUEST');
    case 'REPORTS':
      return currentRole?.toggleModule.includes('REPORTS');
    default:
      return currentRole?.toggleModule.includes(module);
  }
};

const validateUserVO = (userDetails: UserVO, businessUnits: string, excludeUser?: string) => {
  return (
    userDetails &&
    (!excludeUser || userDetails?.userName !== excludeUser) &&
    (userDetails?.userRole === 'SUPER_ADMIN' || (userDetails?.businessUnits && (userDetails.businessUnits?.includes('ALL') || checkIfArraysHasContainsAny(userDetails.businessUnits, businessUnits))))
  );
};

export const getUsersListForBusinessUnits = (users: UserVO[], businessUnits: string, excludeUser: string) => {
  const userList: UserVO[] = [];
  users.forEach((userDetails: UserVO) => {
    if (validateUserVO(userDetails, businessUnits, excludeUser)) {
      userList.push(userDetails);
    }
  });
  return userList;
};

export const getUserEmail = (isGuest: boolean) => {
  const userData: any = getCurrentUser(isGuest);
  try {
    if (isGuest) {
      return userData.companyGuestVO.guest;
    }
    return userData.username;
  } catch (e) {
    return '';
  }
};

const redirecToExternalPage = async (returnTo: string, isVanilla: boolean) => {
  const response = await getZendeskUrlApi(isVanilla, { redirect_to: returnTo });
  if (response?.status === 200) {
    window.location = response.data.additional_message;
  }
};

export const getCompanyIdFromRedirectUrl = () => {
  let redirectUrl = localStorage.getItem('v2_redirect_url');
  if (!redirectUrl) {
    return;
  }
  if (redirectUrl.includes('return_to_v2=')) {
    redirectUrl = redirectUrl.replace('?', '&');
  }
  const searchParams = new URLSearchParams(redirectUrl);
  return searchParams.get('companyId');
};
