import { isFeatureEnabled } from '@utils/FeaturePreference';
import store from '../store';
import { getUrlParam } from '@utils/URLParamUtil';
import axiosInstance from '@helpers/Axios';
import { errorLogger } from './ErrorLogging';
var sessionTimeoutIntervalId: any = null;

export const sessionTimeoutCheck = (timeLimitUpdated: any) => {
  const userDetails = store.getState().userDetails.userDetails;
  const sessionTimeout = userDetails?.companyPreferencesVO?.data?.session_timeout;
  const reminderMin = store.getState().sessionTimeout.reminderMin;
  let currentCompany = localStorage.getItem('company') || '';

  if (currentCompany) {
    currentCompany = JSON.parse(currentCompany);
  }

  const urlCompany = getUrlParam('companyId');
  var checkSessionTimeout = (args: any) => {
    if (args != null) {
      let sessionLimit = args;
      if (sessionTimeout?.reminder) {
        store.getActions().sessionTimeout.setReminderMin(parseInt(sessionTimeout?.reminder));
      }
      let sessionTimeoutTime = new Date(new Date().getTime() + sessionLimit * 60000).getTime();
      localStorage.setItem('sessionTimeLimit', `${sessionTimeoutTime}`);
      checkSessionInterval((sessionLimit - reminderMin) * 60000);
    }
  };

  const checkSessionInterval = (interval: any) => {
    if (sessionTimeoutIntervalId) {
      clearTimeout(sessionTimeoutIntervalId);
    }

    sessionTimeoutIntervalId = setTimeout(() => {
      const sessionTimeLimit: any = localStorage.getItem('sessionTimeLimit');
      if (!sessionTimeLimit || sessionTimeLimit === null || !isFeatureEnabled('session_timeout') || urlCompany !== currentCompany?.id) {
        clearTimeout(sessionTimeoutIntervalId);
        return;
      }
      var diffTime = parseInt(sessionTimeLimit) - new Date().getTime();
      var minuteSpan = reminderMin * 60000;
      if (diffTime < minuteSpan) {
        const lastResponseTime = localStorage.getItem('LastResponseTime');
        const timeDiff = new Date().getTime() - parseInt(lastResponseTime + '');
        const reminderTime = parseInt(sessionTimeout?.reminder);
        if (timeDiff < reminderTime * 6000) {
          updateSession();
        } else {
          if (sessionTimeoutIntervalId) clearTimeout(sessionTimeoutIntervalId);
          store.getActions().sessionTimeout.setShowSessionWarningDialogue(true);
        }
      } else {
        diffTime = diffTime - minuteSpan;
        checkSessionInterval(diffTime);
      }
    }, interval);
  };

  isFeatureEnabled('session_timeout') && urlCompany === currentCompany?.id && timeLimitUpdated && sessionTimeout && checkSessionTimeout(timeLimitUpdated);
};

export const updateSession = async () => {
  const setShowSessionWarningDialogue = store.getActions().sessionTimeout.setShowSessionWarningDialogue;

  try {
    await axiosInstance().post('users/update-user-session', { currentTime: new Date().getTime() });
    setShowSessionWarningDialogue(false);
  } catch (e) {
    errorLogger(e, false);
  }
};
