import moment from 'moment-timezone';
import dayjs from 'dayjs';
import { isSafari, isFirefox } from 'react-device-detect';

export const getCurrentDt = () => {
  if (isSafari || isFirefox) {
    return moment();
  }
  return dayjs();
};
