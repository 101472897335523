import axiosInstance from '@helpers/Axios';
import stores from './../store/index';

export const isFeatureEnabled = (feature: string) => {
  const companyPreferencesVO: { [key: string]: any } = stores.getState().userDetails.userDetails.companyPreferencesVO;
  const featurePreferenceMap: { [key: string]: string } = companyPreferencesVO['featurePreferences'];
  return featurePreferenceMap && featurePreferenceMap[feature] === '1';
};

export const isFeatureAvailable = (feature: string) => {
  const companyPreferencesVO: { [key: string]: any } = stores.getState().userDetails.userDetails.companyPreferencesVO;
  const featurePreferenceMap: { [key: string]: string } = companyPreferencesVO['featurePreferences'];
  return featurePreferenceMap ? !!featurePreferenceMap[feature] : false;
};

export const getFeaturePreferenceData = (feature: string, dataKey: string) => {
  const companyPreferencesVO: { [key: string]: any } = stores.getState().userDetails.userDetails.companyPreferencesVO;
  if (companyPreferencesVO[feature]) {
    const preferenceObj: { [key: string]: string } = companyPreferencesVO[feature];
    return preferenceObj[dataKey];
  }
  return '';
};
export const getProjectNoteName = () => {
  const companyPreferencesVO: { [key: string]: any } = stores.getState().userDetails.userDetails.companyPreferencesVO;
  const displayNameMap = companyPreferencesVO['displayNameMap'];
  if (displayNameMap && displayNameMap['project_notes']) {
    return displayNameMap['project_notes'];
  }
};

export const getData = (feature: string, dataKey: string) => {
  const companyPreferencesVO: { [key: string]: any } = stores.getState().userDetails.userDetails.companyPreferencesVO;
  const featurePreferenceMap: { [key: string]: any } = companyPreferencesVO['featurePreferences'];
  if (featurePreferenceMap && featurePreferenceMap[feature] == '1') {
    const preferenceObj: { [key: string]: string } = companyPreferencesVO.data;
    return preferenceObj[dataKey];
  }
  return '';
};

export const isFeatureConnected = async (featureName: string) => {
  return await axiosInstance().get(`users/get-integration-data`, {
    params: {
      feature: featureName
    }
  });
};

export const isSpellCheckEnabled = (spellChecker: string) => {
  const companyPreferencesVO: { [key: string]: any } = stores.getState().userDetails.userDetails.companyPreferencesVO;
  return companyPreferencesVO?.featurePreferences?.spellCheckAndGrammar === '1' && companyPreferencesVO?.data?.spellCheckAndGrammar?.tool === spellChecker;
};
