import * as colors from '@material-ui/core/colors';
import { Color } from '@material-ui/core';
const maroon: Color = {
  50: '#f9e7e7',
  100: '#f0c2c2',
  200: '#e69999',
  300: '#db7070',
  400: '#d45252',
  500: '#cc3333',
  600: '#c72e2e',
  700: '#c02727',
  800: '#b92020',
  900: '#ad1414',
  A100: '#ffe0e0',
  A200: '#ffadad',
  A400: '#ff7a7a',
  A700: '#ff6060'
};
const limeGreen: Color = {
  50: '#eff5e5',
  100: '#d7e7be',
  200: '#bdd793',
  300: '#a3c768',
  400: '#8fbb47',
  500: '#7baf27',
  600: '#73a823',
  700: '#689f1d',
  800: '#5e9617',
  900: '#4b860e',
  A100: '#d9ffb7',
  A200: '#beff84',
  A400: '#a3ff51',
  A700: '#95ff37'
};
const deepBlue: Color = {
  50: '#e4e7e8',
  100: '#bbc2c7',
  200: '#8e9aa1',
  300: '#60717b',
  400: '#3e525f',
  500: '#1c3443',
  600: '#192f3d',
  700: '#142734',
  800: '#11212c',
  900: '#09151e',
  A100: '#5daeff',
  A200: '#1c3443',
  A400: '#007bf6',
  A700: '#006edd'
};
const royalBlue: Color = {
  50: '#e8edfb',
  100: '#c6d2f6',
  200: '#a0b4f0',
  300: '#7a96ea',
  400: '#5e80e6',
  500: '#4169e1',
  600: '#3b61dd',
  700: '#3256d9',
  800: '#2a4cd5',
  900: '#1c3bcd',
  A100: '#ffffff',
  A200: '#cfd6ff',
  A400: '#9cabff',
  A700: '#8395ff'
};
const steelBlue: Color = {
  50: '#e9f0f6',
  100: '#c8dae9',
  200: '#a3c1da',
  300: '#7ea8cb',
  400: '#6295bf',
  500: '#4682b4',
  600: '#3f7aad',
  700: '#376fa4',
  800: '#2f659c',
  900: '#20528c',
  A100: '#c9e0ff',
  A200: '#96c3ff',
  A400: '#63a7ff',
  A700: '#4998ff'
};
export const themeColors: { [key: string]: any } = { ...colors, limeGreen, steelBlue, royalBlue, deepBlue, maroon };

const getfilteredColorList = () => {
  const tempColors = { ...themeColors };
  const removedColors = ['lime', 'amber', 'yellow'];
  removedColors.forEach(item => {
    delete tempColors[item];
  });
  return tempColors;
};
export const filteredColorList = getfilteredColorList();

export const getColorName: { [key: string]: { [key: string]: string } } = {
  '#ffb300': { colorName: 'amber', contrastValue: '#000000' },
  '#2196f3': { colorName: 'blue', contrastValue: '#000000' },
  '#607d8b': { colorName: 'blue-grey', contrastValue: '#ffffff' },
  '#795548': { colorName: 'brown', contrastValue: '#ffffff' },
  '#00bcd4': { colorName: 'cyan', contrastValue: '#000000' },
  '#1c3443': { colorName: 'deep-blue', contrastValue: '#ffffff' },
  '#ff5722': { colorName: 'deep-orange', contrastValue: '#000000' },
  '#673ab7': { colorName: 'deep-purple', contrastValue: '#ffffff' },
  '#4caf50': { colorName: 'green', contrastValue: '#000000' },
  '#9e9e9e': { colorName: 'grey', contrastValue: '#000000' },
  '#3f51b5': { colorName: 'indigo', contrastValue: '#ffffff' },
  '#03a9f4': { colorName: 'light-blue', contrastValue: '#000000' },
  '#8bc34a': { colorName: 'light-green', contrastValue: '#000000' },
  '#cddc39': { colorName: 'lime', contrastValue: '#000000' },
  '#ad1414': { colorName: 'maroon', contrastValue: '#ffffff' },
  '#ff9800': { colorName: 'orange', contrastValue: '#000000' },
  '#e91e63': { colorName: 'pink', contrastValue: '#ffffff' },
  '#9c27b0': { colorName: 'purple', contrastValue: '#ffffff' },
  '#f44336': { colorName: 'red', contrastValue: '#000000' },
  '#4169e1': { colorName: 'royal-blue', contrastValue: '#ffffff' },
  '#4682b4': { colorName: 'steel-blue', contrastValue: '#ffffff' },
  '#009688': { colorName: 'teal', contrastValue: '#000000' },
  '#fbc02d': { colorName: 'yellow', contrastValue: '#000000' },
  '#7baf27': { colorName: 'lime-green', contrastValue: '#ffffff' }
};
export const colorsMap: { [key: string]: { [key: string]: number | string } } = {
  red: {
    light: 500,
    dark: 600,
    accent: 50
  },
  pink: {
    light: 500,
    dark: 500,
    accent: 50
  },
  purple: {
    light: 500,
    dark: 300,
    accent: 50
  },
  deepPurple: {
    light: 500,
    dark: 300,
    accent: '#F8F5FF'
  },
  indigo: {
    light: 500,
    dark: 300,
    accent: '#F0F3FF'
  },
  blue: {
    light: 500,
    dark: 300,
    accent: '#F4FAFF'
  },
  lightBlue: {
    light: 500,
    dark: 500,
    accent: '#F4FAFF'
  },
  cyan: {
    light: 500,
    dark: 500,
    accent: '#F5F5F5'
  },
  teal: {
    light: 500,
    dark: 500,
    accent: '#F5F5F5'
  },
  green: {
    light: 500,
    dark: 500,
    accent: '#F5F5F5'
  },
  lightGreen: {
    light: 500,
    dark: 500,
    accent: '#F5F5F5'
  },
  lime: {
    light: 500,
    dark: 500,
    accent: '#F5F5F5'
  },
  yellow: {
    light: 700,
    dark: 700,
    accent: '#F5F5F5'
  },
  amber: {
    light: 600,
    dark: 600,
    accent: '#F5F5F5'
  },
  orange: {
    light: 500,
    dark: 500,
    accent: 50
  },
  deepOrange: {
    light: 500,
    dark: 500,
    accent: '#FFF2EE'
  },
  brown: {
    light: 500,
    dark: 300,
    accent: '#F5F5F5'
  },
  grey: {
    light: 500,
    dark: 600,
    accent: 100
  },
  blueGrey: {
    light: 500,
    dark: 500,
    accent: '#F5F5F5'
  },
  maroon: {
    light: 900,
    dark: '#CD250A',
    accent: 50
  },
  limeGreen: {
    light: 500,
    dark: 500,
    accent: 50
  },
  deepBlue: {
    light: 500,
    dark: '#BBC2C7',
    accent: 50
  },
  royalBlue: {
    light: 500,
    dark: 400,
    accent: 50
  },
  steelBlue: {
    light: 500,
    dark: 400,
    accent: 50
  }
};
export const colorNameMap: { [key: string]: string } = {
  'deep-purple': 'deepPurple',
  'light-blue': 'lightBlue',
  'light-green': 'lightGreen',
  'lime-green': 'limeGreen',
  'deep-orange': 'deepOrange',
  'blue-grey': 'blueGrey',
  'deep-blue': 'deepBlue',
  'steel-blue': 'steelBlue',
  'royal-blue': 'royalBlue'
};
export const changedColorsMap: { [key: string]: string } = {
  '#ffeb3b': '#fbc02d',
  '#ffc107': '#ffb300',
  '#cc3333': '#ad1414'
};
export const getColorValue = (color: string, type: string) => {
  const colorName = getColorName[color.toLowerCase()]?.colorName || '#7baf27';
  const value = colorNameMap[colorName] || colorName;
  return themeColors[value][colorsMap[value][type]] || colorsMap[value][type];
};

export const getColorValueByName = (colorName: string, type: string) => {
  return themeColors[colorName][colorsMap[colorName][type]];
};

export const darkenColor = (color: string, factor: any) => {
  // Convert hex color to RGB
  let r = parseInt(color.substring(1, 3), 16);
  let g = parseInt(color.substring(3, 5), 16);
  let b = parseInt(color.substring(5, 7), 16);
  // Reduce each RGB component by the factor
  r = Math.floor(r * (1 - factor));
  g = Math.floor(g * (1 - factor));
  b = Math.floor(b * (1 - factor));
  // Ensure the values stay within the valid range [0, 255]
  r = Math.min(255, Math.max(0, r));
  g = Math.min(255, Math.max(0, g));
  b = Math.min(255, Math.max(0, b));
  // Convert back to hex and return the darker color
  return '#' + (r < 16 ? '0' : '') + r.toString(16) + (g < 16 ? '0' : '') + g.toString(16) + (b < 16 ? '0' : '') + b.toString(16);
};
