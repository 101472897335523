export default interface UserAccountInfoVO {
  accountType: UserAccountType;
  accessValueFlag: boolean;
  accessValue: { [key: string]: string };
  createTs: Date;
  lastUpdateTs: Date;
  additionalParams?: { requiredScopes: string[]; updatedScopes: string[] };
}

export enum UserAccountType {
  DROPBOX = 'DROPBOX',
  GOOGLE_DRIVE = 'GOOGLE_DRIVE',
  BOX = 'BOX',
  ONE_DRIVE = 'ONE_DRIVE',
  SALESFORCE = 'SALESFORCE',
  PIPEDRIVE = 'PIPEDRIVE',
  PIPELINE_DEALS = 'PIPELINE_DEALS',
  SHAREPOINT = 'SHAREPOINT',
  MICROSOFT_DYNAMICS_CRM = 'MICROSOFT_DYNAMICS_CRM',
  HUBSPOT = 'HUBSPOT',
  TWO_FACTOR_AUTHENTICATION = 'TWO_FACTOR_AUTHENTICATION',
  SLACK = 'SLACK',
  JIRA = 'JIRA',
  MS_ADDIN = 'MS_ADDIN',
  EXTERNAL_API = 'EXTERNAL_API',
  OFFLINE_SUPPORT = 'OFFLINE_SUPPORT',
  GOOGLE_EDITOR = 'GOOGLE_EDITOR',
  SEISMIC = 'SEISMIC',
  ZOHO = 'ZOHO',
  CONFLUENCE = 'CONFLUENCE'
}
