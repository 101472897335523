import PositionVO from './PositionVO';

export default interface GuestPageLayoutVO {
  position: PositionVO;
  noQuestionsAssigned: boolean;
  showSaved: boolean;
  showSaving: boolean;
  showHelpDemo: boolean;
  showSubmitPage: boolean;
  isCommentsOpen: boolean;
  isCommentsOpenClick: boolean;
  isSectionListOpen: boolean;
  activeQuestionState: { activeQuestionID: string; activeQuestionIndex: number; activeSectionID: string };
  stickySectionID: string;
}

export const GuestPageLayoutVODefault = {
  noQuestionsAssigned: false,
  showSaved: false,
  showSaving: false,
  showHelpDemo: false,
  showSubmitPage: false,
  isCommentsOpen: false,
  isCommentsOpenClick: false,
  isSectionListOpen: false,
  activeQuestionState: { activeQuestionID: '', activeQuestionIndex: 0, activeSectionID: '' },
  stickySectionID: ''
};
