import React from 'react';
import { Backdrop, CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import i18next from 'i18next';
import ResizeHeight from '@components/resizeHeight';

interface LoaderProps {
  loading: boolean;
  isAbsolute?: boolean;
}

export default function Loader(props: LoaderProps) {
  const classes = loaderStyles();
  return (
    <div>
      <Backdrop className={`${classes.backdrop} ${props.isAbsolute ? 'position-absolute' : ''}`} open={props.loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  );
}

export const LoadingCard = (props: any) => {
  const { phrase, customContent, width, reSizeClassName } = props;
  const classes = loaderStyles();
  return (
    <ResizeHeight className={reSizeClassName}>
      <Grid container justify='center' alignItems='center' className='h-100p'>
        <Grid container direction='column' justify='center' alignItems='center' className={`${width ? width : 'w-200'} p-15 ${classes.bgColor} border-radius-8`}>
          <Grid item className='w-100 text-align-center'>
            <CircularProgress size={20} style={{ color: 'white' }} />
          </Grid>
          <Grid item className='font-12 fontColor'>
            {customContent ? customContent : i18next.t('LOAD')} {`${i18next.t(phrase)}`}...
          </Grid>
        </Grid>
      </Grid>
    </ResizeHeight>
  );
};

const loaderStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  bgColor: {
    backgroundColor: theme.palette.type === 'light' ? '#1C3443' : '#202129'
  }
}));
