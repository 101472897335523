import store from '../store';

const mailIdPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const checkIsValidMailId = (mail: string) => {
  return mailIdPattern.test(mail);
};

export const checkIsValidTeamId = (userName: string) => {
  const teamsMapDetails = store.getState().userData.teamsMap;
  return teamsMapDetails[userName] ? true : false;
};

const searchPattern = /[\"\:\?\*]/gm;
export const isValidSearchTerm = (searchTerm: string) => {
  return !searchPattern.test(searchTerm);
};

export const isValidIpAddress = (ipAddress: string) => {
  return ipAddress.match(/^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/);
};

export const validEmailIdsList = (emailIdsList: string[]) => {
  let list: string[] = [];
  emailIdsList.forEach((id: any) => {
    if (checkIsValidMailId(id)) {
      list.push(id.toLowerCase());
    }
  });
  return list;
};
