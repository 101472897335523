import { CSSProperties } from 'react';
import { action } from 'easy-peasy';
import ThemeStore from '@interfaces/ThemeStore';
import theme from '../themes';
import { createMuiTheme, createTheme } from '@material-ui/core/styles';
import { darkenColor } from '@utils/ColorUtils';
import RFPIOPalette from '@utils/RFPIOPalette';
import { agGridStyles, commonStyles, defaultStyle } from '@utils/ThemeUtil';
import { CustomizedTheme, IAgGridStyles } from '@interfaces/ThemeInterface';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import { Overrides } from '@material-ui/core/styles/overrides';

export const constructAGgridStyles = (type: 'light' | 'dark' | 'darkV2'): IAgGridStyles => {
  return {
    agGridRowBorderBottom: agGridStyles.agGridRow.borderBottom[type],
    agGridRowBorderRight: agGridStyles.agGridRow.borderRight[type],
    agGridCellColor: agGridStyles.agGridCell.color[type],
    agGridHeaderBackgroundColor: agGridStyles.agGridHeader.backgroundColor[type],
    agGridHeaderBorderBottomColor: agGridStyles.agGridHeader.borderBottomColor[type],
    agGridCheckedAfter: agGridStyles.agGridChecked.after[type],
    agGridHeaderCellResize: agGridStyles.agGridHeader.cellResize[type],
    agGridRowHoverBoxShadow: agGridStyles.agGridRow.hoverBoxShadow[type],
    agGridRowHoverZIndex: agGridStyles.agGridRow.hoverZIndex[type],
    agGridThemeAlpineRowSelectedBg: agGridStyles.agGridThemeAlpine.rowSelectedBg[type],
    agGridThemeAlpineRowSelectedBorderBottom: agGridStyles.agGridThemeAlpine.rowSelectedBorderBottom[type],
    agGridHeaderCellBorderRight: agGridStyles.agGridHeader.cellBorderRight[type],
    agGridRowFocusBg: agGridStyles.agGridRow.focusBg[type],
    agGridWeblitscrollBg: agGridStyles.agGridWeblit.scrollBg[type],
    agGridPinnedBorderRight: agGridStyles.agGridPinned.right.borderRight[type],
    agGridPinnedRightBorderLeft: agGridStyles.agGridPinned.right.borderLeft[type],
    agGridPinnedLeftBorderRight: agGridStyles.agGridPinned.left.borderRight[type],
    agGridPaginationBg: agGridStyles.agPagination.paginationActionBg[type],
    agGridPaginationColor: agGridStyles.agPagination.paginationColor[type],
    agGridPaginationBorderColor: agGridStyles.agPagination.paginationBorderColor[type],
    agOnHoverColor: agGridStyles.agOnHoverColor[type],
    agGridBgColor: agGridStyles.agGridBgColor[type]
  };
};

export const constructCommonStyles = (type: 'light' | 'dark' | 'darkV2') => {
  return {
    border: commonStyles.border[type],
    color: commonStyles.color[type] || '',
    outlineBorder: commonStyles.borderOutline[type],
    avatarNumericColor: commonStyles.avatarNumericColor[type],
    avatarNumericBg: commonStyles.avatarNumericBg[type],
    avatarNumericBorder: commonStyles.avatarNumericBorder[type],
    tooltipBgColor: commonStyles.tooltipBgColor[type],
    tooltipTextColor: commonStyles.tooltipTextColor[type],
    cancelButtonColor: commonStyles.cancelButtonColor[type],
    alertBodyTextColor: commonStyles.alertBodyTextColor[type],
    alertBodyTitleColor: commonStyles.alertBodyTitleColor[type],
    grey800: commonStyles.grey800[type],
    grey900: commonStyles.grey900[type],
    borderBottom: commonStyles.borderBottom[type],
    radioIconColor: commonStyles.radioIconColor[type],
    tabTextColor: commonStyles.tabTextColor[type],
    textBoxBorderColor: commonStyles.textBoxBorderColor[type],
    selectBorder: commonStyles.selectBorder[type],
    paperTextColor: defaultStyle.paperTextColor[type],
    popOverPaperBg: defaultStyle.popOverPaperBg[type],
    dialogueTextColor: commonStyles.dialogueTextColor[type],
    addFilterBg: commonStyles.addFilterBg[type],
    advanceFilterColor: commonStyles.advanceFilterColor[type],
    searchFilterbg: commonStyles.searchFilterbg[type],
    searchIconColor: commonStyles.searchIconColor[type],
    searchBorderColor: commonStyles.searchBorderColor[type],
    scrollBarColor: commonStyles.scrollBarColor[type],
    advBorderColor: commonStyles.advBorderColor[type],
    closeIconColor: commonStyles.closeIconColor[type],
    commentBg: commonStyles.commentBg[type],
    commentBorder: commonStyles.commentBorder[type],
    commentIconColor: commonStyles.commentIconColor[type],
    commentAttachmentBorder: commonStyles.commentAttachmentBorder[type],
    commentClose: commonStyles.commentClose[type],
    attachmentBorder: commonStyles.attachmentBorder[type],
    autoCompleteOptionBorder: commonStyles.autoCompleteOptionBorder[type],
    transparentScroll: commonStyles.transparentScroll[type],
    headerBg: commonStyles.headerBg[type],
    loaderFill: commonStyles.loaderFill[type],
    accordionBg: commonStyles.accordionBg[type],
    deleteColor: commonStyles.deleteColor[type]
  };
};

const getRootStyles = (): CSSProperties | CreateCSSProperties<{}> | undefined => {
  return {
    borderRadius: 2,
    textTransform: 'capitalize',
    fontWeight: 600,
    padding: '5px 25px'
  };
};

const constructOverRideStyle = (entry: RFPIOPalette, type: 'light' | 'dark' | 'darkV2'): Overrides | undefined => {
  const popOverBgColor = defaultStyle.popOverPaperBg[type];
  const paperTextColor = defaultStyle.paperTextColor[type];
  const dialogPaperBgColor = defaultStyle.dialogPaperBg[type];
  const tableHeaderBgColor = defaultStyle.tableHeaderBg[type];
  const isDarkV2: boolean = 'darkV2' === type;
  const styles = {
    ...theme.overrides,
    MuiButton: {
      root: getRootStyles(),
      containedPrimary: {
        backgroundColor: entry.type === 'light' ? entry.primary : entry.primaryDark,
        '&:hover': {
          backgroundColor: darkenColor(entry.primary, 0.2)
        }
      },
      outlinedPrimary: {
        color: entry.type === 'light' ? entry.primary : entry.primaryDark,
        border: `1px solid ${entry.type === 'light' ? entry.primary : entry.primaryDark}`
      },
      textPrimary: {
        color: entry.type === 'light' ? entry.primary : entry.primaryDark
      }
    },
    MuiCheckbox: {
      root: {
        color: '#7f8286'
      },
      indeterminate: {
        color: entry.primary
      }
    },
    MuiIconButton: {
      root: {
        padding: '0px',
        color: paperTextColor
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 35,
        color: paperTextColor
      }
    },
    MuiDialog: {
      paper: {
        backgroundColor: dialogPaperBgColor
      }
    },
    MuiAccordion: {
      root: {
        backgroundColor: dialogPaperBgColor
      }
    },
    MuiList: {
      root: {
        backgroundColor: popOverBgColor
      }
    },
    MuiTableCell: {
      stickyHeader: {
        backgroundColor: tableHeaderBgColor
      }
    }
  };
  if (isDarkV2) {
    styles.MuiPaper = {
      elevation8: {
        boxShadow: '16px 16px 32px 0px rgba(0, 0, 0, 0.23)'
      },
      root: {
        backgroundColor: popOverBgColor,
        color: paperTextColor
      }
    };
    styles.MuiAvatar = {
      root: {
        border: '1px solid #36373C !important'
      }
    };
  }
  return styles;
};

const constructPaletteStyle = (entry: RFPIOPalette) => {
  let type: 'light' | 'dark' | 'darkV2' = entry.type;
  if (entry.type === 'dark' && entry.darkV2) {
    type = 'darkV2';
  }
  const textPrimary = defaultStyle.textPrimary[type];
  const textSecondary = '#ffffff';
  const paperBgColor = defaultStyle.paperBg[type];
  const bodyBgColor = defaultStyle.bodyBg[type];
  const styles = {
    primary: {
      main: entry.primary,
      dark: entry.primaryDark,
      light: entry.accentColor
    },
    secondary: {
      main: entry.secondary
    },
    accentColor: {
      main: entry.type == 'light' ? entry.accentColor : '#2D2F3A'
    },
    text: {
      primary: textPrimary,
      secondary: textSecondary
    },
    background: {
      paper: paperBgColor,
      default: bodyBgColor
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    type: entry.type
  };
  return styles;
};

export const constructCustomTheme = (entry: RFPIOPalette) => {
  let type: 'light' | 'dark' | 'darkV2' = entry.type;
  if (entry.type === 'dark' && entry.darkV2) {
    type = 'darkV2';
  }

  const customTheme: CustomizedTheme = createTheme({
    ...theme,
    palette: constructPaletteStyle(entry),
    overrides: constructOverRideStyle(entry, type)
  });

  customTheme.agGridStyles = constructAGgridStyles(type);
  customTheme.commonStyles = constructCommonStyles(type);
  customTheme.darkV2 = 'darkV2' === type;
  return customTheme;
};

const ThemeStoreImpl: ThemeStore = {
  theme: theme,
  setTheme: action((state, entry) => {
    state.theme = constructCustomTheme(entry);
  }),
  setOrgTheme: action((state, entry) => {
    const customTheme = createMuiTheme({
      ...theme,
      palette: constructPaletteStyle(entry),
      overrides: {
        ...theme.overrides,
        MuiCheckbox: {
          root: {
            color: '#7f8286'
          },
          indeterminate: {
            color: entry.primary
          }
        },
        MuiButton: {
          root: getRootStyles(),
          containedPrimary: {
            backgroundColor: entry.type === 'light' ? entry.primary : entry.primaryDark,
            '&:hover': {
              backgroundColor: darkenColor(entry.primary, 0.2)
            }
          },
          outlinedPrimary: {
            color: entry.type === 'light' ? entry.primary : entry.primaryDark,
            border: `1px solid ${entry.type === 'light' ? entry.primary : entry.primaryDark}`
          },
          textPrimary: {
            color: entry.type === 'light' ? entry.primary : entry.primaryDark
          }
        }
      }
    });
    state.theme = customTheme;
  }),
  updateThemeforDarkV2: action((state, entry) => {
    if (state.theme.palette.type === 'dark') {
      const obj: RFPIOPalette = {
        primary: theme.palette.primary.main,
        secondary: theme.palette.secondary.main,
        type: 'dark',
        darkV2: entry
      };
      const updatedTheme = constructCustomTheme(obj);
      updatedTheme.darkV2 = entry;
      state.theme = updatedTheme;
    }
  })
};

export default ThemeStoreImpl;
