import MasterDataVO from './MasterDataVO';
import { ProposalCatalogSolutionTemplateVO } from './ProposalCatlogVO';

export default interface AutoFillDetailsVO {
  importFileNameFacets: [];
  answerHeaderFacets: [];
  approversFacet: [];
  collectionFacets: [];
  collectionList: {};
  businessUnits: {};
  languageFacet: [];
  missedRequiredFieldCount: number;
  nextCursorMark: string;
  projectFacets: [];
  sectionFacets: [];
  projectNameList: [];
  sectionNameList: [];
  standardResponseList: { dataList: [] };
  tagFacets: [];
  tagList: { dataList: MasterDataVO[] };
  totalRecords: number;
  translatedIdFacets: [];
  translatedSrcLangFacets: [];
  catalogNameFacets: [];

  dlTagList: string[];
  dlFileTypeList: string[];
  translatedJobNames: string[];

  catalogStructures: ProposalCatalogSolutionTemplateVO[];
  catalogStructureFacet: string[];
  catalogMergeTagFacets: string[];
  catalogNameList: [];
  sectionGroupMap: { [key: string]: string };
  parentSectionNameMap: { [key: string]: any };
  subSectionIdMap: { [key: string]: string[] };
}

export const autoFillDetailsVO: AutoFillDetailsVO = {
  importFileNameFacets: [],
  answerHeaderFacets: [],
  approversFacet: [],
  collectionFacets: [],
  collectionList: {},
  businessUnits: {},
  languageFacet: [],
  missedRequiredFieldCount: 0,
  nextCursorMark: '*',
  projectFacets: [],
  sectionFacets: [],
  projectNameList: [],
  sectionNameList: [],
  standardResponseList: { dataList: [] },
  tagFacets: [],
  tagList: { dataList: [] },
  totalRecords: 0,
  translatedIdFacets: [],
  translatedSrcLangFacets: [],
  dlTagList: [],
  dlFileTypeList: [],
  translatedJobNames: [],
  catalogStructures: [],
  catalogStructureFacet: [],
  catalogMergeTagFacets: [],
  catalogNameList: [],
  sectionGroupMap: {},
  parentSectionNameMap: {},
  subSectionIdMap: {}
};
