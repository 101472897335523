import { action } from 'easy-peasy';
import { GuestCompanyStore } from '@interfaces/GuestStoreInterface';

const GuestCompanyStoreImpl: GuestCompanyStore = {
  datePreference: {
    dateFormat: '',
    timeFormat: '',
    timeZone: ''
  },
  setDatePreference: action((state, entry) => {
    state.datePreference = entry;
  })
};

export default GuestCompanyStoreImpl;
