import React, { useEffect, useRef, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Tooltip, TooltipProps, Typography } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { handleReplaceHTMLEntity } from '@utils/ActivityUtils';

const RfpioToolTip = withStyles(theme => ({
  arrow: {
    color: theme?.commonStyles?.tooltipBgColor
  },
  tooltip: {
    backgroundColor: theme?.commonStyles?.tooltipBgColor,
    color: theme?.commonStyles?.tooltipTextColor,
    padding: '4px 10px',
    border: `0.5px solid ${theme?.commonStyles?.tooltipBgColor}`
  }
}))(Tooltip);

const CustomTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}))(Tooltip);

const CustomDragTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#18a0fb',
    color: '#ffffff',
    maxWidth: 150,
    maxHeight: 40,
    border: '1px solid #dadde9'
  }
}))(Tooltip);

const MetadataTooltip = withStyles(theme => ({
  arrow: {
    '&:before': {
      border: '1px solid #b2b6c7'
    },
    color: theme.palette.type === 'light' ? '#ffffff' : '#383838',
    fontSize: 16
  },
  tooltip: {
    backgroundColor: theme.palette.type === 'light' ? '#ffffff' : '#383838',
    color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#fff',
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(14),
    boxShadow: '0px 3px 6px #00000029 !important',
    maxHeight: 200
  }
}))(Tooltip);

const InteractiveToolTip = withStyles(theme => ({
  tooltip: {
    border: '1px solid #dddddd',
    borderRadius: '5px',
    padding: '0px 5px',
    background: '#e9e9e9'
  },
  popperInteractive: (props: any) => ({
    top: props.top ? props.top : '0',
    left: props.left ? props.left : '0'
  })
}))(Tooltip);

const InteractiveCustomToolTip = withStyles(theme => ({
  tooltip: {
    borderRadius: '5px',
    background: '#FFF',
    color: '#000',
    padding: 8,
    fontSize: 14,
    lineHeight: 1.5
  },
  popperInteractive: (props: any) => ({
    top: props.top ? props.top : '0',
    left: props.left ? props.left : '0'
  })
}))(Tooltip);

interface RfpTooltip extends TooltipProps {
  title: any;
  top?: string;
  left?: string;
}

interface RfpEllipsisTooltip {
  text: any;
  title: any;
  className?: any;
  multiline?: any;
  maxWidth?: any;
  maxLine?: any;
  dataQa?: string;
  onClick?: () => void;
  additionalAttributes?: { [key: string]: any };
}

export default function RfpToolTip(props: RfpTooltip) {
  return (
    <RfpioToolTip {...props} title={props.title || ''} arrow>
      {props.children}
    </RfpioToolTip>
  );
}

export const CustomizedTooltip = (props: RfpTooltip) => {
  return (
    <CustomTooltip {...props} title={props.title} arrow>
      {props.children}
    </CustomTooltip>
  );
};

export const CustomizedDragTooltip = (props: RfpTooltip) => {
  return (
    <CustomDragTooltip {...props} title={props.title}>
      {props.children}
    </CustomDragTooltip>
  );
};

export const MetadataPopper = (props: RfpTooltip) => {
  return (
    <MetadataTooltip {...props} title={props.title} arrow>
      {props.children}
    </MetadataTooltip>
  );
};

export const InteractiveToolTipPopper = (props: RfpTooltip) => {
  return (
    <InteractiveToolTip {...props} title={props.title} interactive>
      {props.children}
    </InteractiveToolTip>
  );
};

export const InteractiveCustomToolTipPopper = (props: RfpTooltip) => {
  return (
    <InteractiveCustomToolTip {...props} title={props.title} interactive arrow>
      {props.children}
    </InteractiveCustomToolTip>
  );
};

export const RfpTextEllipsisTooltip = ({ title, text, className, multiline, maxWidth, maxLine, dataQa, onClick, additionalAttributes = {} }: RfpEllipsisTooltip) => {
  const textRef = useRef<any>(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const classes = textOverFlow({ maxWidth, maxLine });
  useEffect(() => {
    const { scrollWidth, clientWidth, scrollHeight, clientHeight } = textRef.current;
    if (scrollWidth > clientWidth) {
      setIsTooltipVisible(true);
    } else if (multiline && scrollHeight > clientHeight) {
      setIsTooltipVisible(true);
    } else {
      setIsTooltipVisible(false);
    }
  }, [text]);

  return (
    <RfpToolTip title={isTooltipVisible ? title : ''}>
      <Typography data-qa={dataQa} ref={textRef} className={`${className} ${multiline && classes.multiline}`} noWrap={!multiline} onClick={onClick} {...additionalAttributes}>
        {text}
      </Typography>
    </RfpToolTip>
  );
};
interface IRfpTooTipWithHtml extends RfpTooltip {
  parseHtml?: boolean;
  title: string | JSX.Element;
}
export const RfpTooTipWithHtml = (props: IRfpTooTipWithHtml) => {
  const { parseHtml, children, title } = props;
  const toolTipTitle = parseHtml && typeof title === 'string' ? ReactHtmlParser(handleReplaceHTMLEntity(title)) : title;

  return <RfpToolTip title={toolTipTitle}>{children}</RfpToolTip>;
};

const textOverFlow = makeStyles(() => ({
  multiline: {
    display: '-webkit-box',
    maxWidth: (props: any) => props?.maxWidth || 600,
    WebkitLineClamp: (props: any) => props?.maxLine || 4,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  }
}));
