import { action } from 'easy-peasy';
import ProjectMasterDataStore from '@interfaces/respond-stores/ProjectMasterDataStore';
import { getRequestDataKeys, requestDataListKey } from '@org/utils/request-management/OrgRequestTableUtils';

const ProjectMasterDataStoreImpl: ProjectMasterDataStore = {
  projectStageMap: {},
  projectTypeMap: {},
  projectFlagMap: {},
  projectStageList: [],
  projectTypeList: [],
  projectFlagList: [],
  projectCategoriesList: [],
  projectExportBatchId: '',
  requestStageMap: {},
  requestTypeMap: {},
  requestStageList: [],
  requestTypeList: [],
  vendorStageList: [],
  vendorStageMap: {},
  projectLockedStatus: {},
  projectTranslationData: {},
  projectOpenTranslatePopup: false,
  projectCategoryRefreshFlag: {
    REQUEST: false,
    RESPOND: false
  },

  setProjectTranslationData: action((state, entry) => {
    state.projectTranslationData = entry;
  }),
  setProjectStageMap: action((state, entry) => {
    state.projectStageMap = entry;
  }),
  setProjectTypeMap: action((state, entry) => {
    state.projectTypeMap = entry;
  }),
  setRequestStageMap: action((state, entry) => {
    state.requestStageMap = entry;
  }),
  setRequestTypeMap: action((state, entry) => {
    state.requestTypeMap = entry;
  }),
  setProjectFlagMap: action((state, entry) => {
    state.projectFlagMap = entry;
  }),
  setVendorStageMap: action((state, entry) => {
    state.vendorStageMap = entry;
  }),
  setProjectStageList: action((state, entry) => {
    state.projectStageList = entry;
  }),
  setProjectTypeList: action((state, entry) => {
    state.projectTypeList = entry;
  }),
  setRequestTypeList: action((state, entry) => {
    state.requestTypeList = entry;
  }),
  setRequestStageList: action((state, entry) => {
    state.requestStageList = entry;
  }),
  setVendorStageList: action((state, entry) => {
    state.vendorStageList = entry;
  }),
  setProjectFlagList: action((state, entry) => {
    state.projectFlagList = entry;
  }),
  setProjectCategoriesList: action((state, entry) => {
    state.projectCategoriesList = entry;
  }),
  setProjectExportBatchId: action((state, entry) => {
    state.projectExportBatchId = entry;
  }),
  setProjectLockedStatus: action((state, entry) => {
    const projectId = entry.split('_')[0] === 'TRIGGERED' ? entry.split('_')[1] : entry;

    // This TRIGGERED property is used to trigger the project locked popper when it's in Un-Locked state
    if (entry.split('_')[0] === 'TRIGGERED' && !state.projectLockedStatus[projectId].hasOwnProperty('triggered') && !state.projectLockedStatus[projectId].hasOwnProperty('alreadyClosed')) {
      state.projectLockedStatus[entry.split('_')[1]] = {
        triggered: true,
        locked: true
      };
      return;
    }
    if (!state.projectLockedStatus[projectId] || !state.projectLockedStatus[projectId].locked) {
      state.projectLockedStatus[projectId] = { locked: true };
    }
    if (state.projectLockedStatus[projectId] && !state.projectLockedStatus[projectId].locked) {
      state.projectLockedStatus[projectId]['locked'] = true;
    }
  }),
  setLockedCurrentProjectId: action((state, entry) => {
    // We can't get the project Id globally so we are setting over here
    state.projectLockedStatus.currentProjectId = entry;
  }),
  setUnlockLockedProject: action((state, entry) => {
    // If project is unlocked but the project is in locked state so we are unlocking over here
    const projectLockedMap = { ...state.projectLockedStatus };
    const currentProjectId = state.projectLockedStatus.currentProjectId;
    if (entry === 'NOT_LOCKED' && projectLockedMap[currentProjectId]) {
      state.projectLockedStatus[currentProjectId]['locked'] = false;
    }
  }),
  setAlreadyLockTriggered: action((state, entry) => {
    // To set whether the popper is already showed
    const projectLockedMap = { ...state.projectLockedStatus };
    const currentProjectId = state.projectLockedStatus.currentProjectId;
    if (projectLockedMap[currentProjectId]) {
      state.projectLockedStatus[currentProjectId]['alreadyClosed'] = true;
    }
  }),
  setAddendumProject: action((state, entry) => {
    const projectLockedMap = { ...state.projectLockedStatus };
    const currentProjectId = state.projectLockedStatus.currentProjectId;
    if (projectLockedMap[currentProjectId]) {
      state.projectLockedStatus[currentProjectId]['addendum'] = entry;
    }
  }),
  setComparisonBatchId: action((state, entry) => {
    const projectLockedMap = { ...state.projectLockedStatus };
    const currentProjectId = state.projectLockedStatus.currentProjectId;
    if (projectLockedMap[currentProjectId]) {
      state.projectLockedStatus[currentProjectId]['comparisonBatchId'] = entry;
    }
  }),
  setIsIssuedProject: action((state, entry) => {
    const projectLockedMap = { ...state.projectLockedStatus };
    const currentProjectId = state.projectLockedStatus.currentProjectId;
    if (projectLockedMap[currentProjectId]) {
      state.projectLockedStatus[currentProjectId]['isIssuedProject'] = entry;
    }
  }),
  setProjectOpenTranslatePopup: action((state, entry) => {
    state.projectOpenTranslatePopup = entry;
  }),
  setAddendumPublishedDetails: action((state, entry) => {
    const projectLockedMap = { ...state.projectLockedStatus };
    const currentProjectId = state.projectLockedStatus.currentProjectId;
    if (projectLockedMap[currentProjectId]) {
      state.projectLockedStatus[currentProjectId]['comparisonBatchId'] = entry['comparisonBatchId'];
      state.projectLockedStatus[currentProjectId]['isIssuedProject'] = entry['isIssuedProject'];
      state.projectLockedStatus[currentProjectId]['addendum'] = entry['addendum'];
      state.projectLockedStatus[currentProjectId]['locked'] = true;
    }
  }),
  updateprojectCategoryRefreshFlag: action((state, entry) => {
    state.projectCategoryRefreshFlag[entry] = true;
  }),
  modifyRequestMasterData: action((state, entry) => {
    const { requestDataStateListKey, requestDataStateMapKey } = getRequestDataKeys((entry.dataModification === 'DELETE' ? entry.type : entry?.data.type) as string);
    switch (entry.dataModification) {
      case 'ADD':
        state[requestDataStateListKey].push(entry.data);
        state[requestDataStateMapKey][entry.data?.value] = entry.data;
        break;
      case 'EDIT':
        state[requestDataStateListKey][entry.index] = entry.data;
        state[requestDataStateMapKey][entry.data?.value] = entry.data;
        break;
      case 'DELETE':
        state[requestDataStateListKey].splice(entry.index, 1);
        delete state[requestDataStateMapKey][entry?.type];
      default:
        break;
    }
  }),
  setRequestDataList: action((state, entry) => {
    const { requestDataStateListKey, requestDataStateMapKey } = getRequestDataKeys(entry.type);
    state[requestDataStateListKey] = entry.data;
    state[requestDataStateMapKey][entry.data.value] = entry.data;
  })
};

export default ProjectMasterDataStoreImpl;
