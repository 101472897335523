import { action, thunk, computed } from 'easy-peasy';
import DialogStore from '@interfaces/DialogStore';

const DialogStoreImpl: DialogStore = {
  dialog: false,
  setDialogState: action((state, entry) => {
    state.dialog = entry;
  })
};

export default DialogStoreImpl;
