import moment from 'moment-timezone';
import store from '../store';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { getCurrentDt } from '../utils/DateUtils';
import CompanyInfoVO from '@interfaces/Models/CompanyInfoVO';

//Extending Dayjs Plugin in order to support some functionalities
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(localeData);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);
dayjs.extend(duration);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

let defaultTimeZone: any = null;
let defaultDateFormat: any = null;
let defaultTimeFormat: any = null;
const isSafari = navigator.userAgent.indexOf('Chrome') < 0 && navigator.userAgent.indexOf('Safari') >= 0;
const isFireFox = navigator.userAgent.indexOf('Firefox') > 0;
interface TimeFormatInterface {
  hours: number;
  minutes: number;
  seconds: number;
}

interface TimeSourceInterface {
  from: TimeFormatInterface;
  to: TimeFormatInterface;
}

const timesBySource: TimeSourceInterface = {
  from: { hours: 0, minutes: 0, seconds: 0 },
  to: { hours: 23, minutes: 59, seconds: 59 }
};

export const timeZoneDisplaymap = {
  'Pacific/Midway': '(UTC-11:00) Midway Island, American Samoa',
  'America/Adak': '(UTC-10:00) Aleutian Islands',
  'Pacific/Honolulu': '(UTC-10:00) Hawaii',
  'Pacific/Marquesas': '(UTC-09:30) Marquesas Islands',
  'America/Anchorage': '(UTC-09:00) Alaska',
  'America/Tijuana': '(UTC-08:00) Baja California',
  'America/Los_Angeles': '(UTC-08:00) Pacific Time (US and Canada)',
  'America/Phoenix': '(UTC-07:00) Arizona',
  'America/Chihuahua': '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
  'America/Denver': '(UTC-07:00) Mountain Time (US and Canada)',
  'America/Belize': '(UTC-06:00) Central America',
  'America/Chicago': '(UTC-06:00) Central Time (US and Canada)',
  'Pacific/Easter': '(UTC-06:00) Easter Island',
  'America/Mexico_City': '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
  'America/Regina': '(UTC-06:00) Saskatchewan',
  'America/Bogota': '(UTC-05:00) Bogota, Lima, Quito',
  'America/Cancun': '(UTC-05:00) Chetumal',
  'America/New_York': '(UTC-05:00) Eastern Time (US and Canada)',
  'America/Port-au-Prince': '(UTC-05:00) Haiti',
  'America/Havana': '(UTC-05:00) Havana',
  'America/Indiana/Indianapolis': '(UTC-05:00) Indiana (East)',
  'America/Asuncion': '(UTC-04:00) Asuncion',
  'America/Halifax': '(UTC-04:00) Atlantic Time (Canada)',
  'America/Caracas': '(UTC-04:00) Caracas',
  'America/Cuiaba': '(UTC-04:00) Cuiaba',
  'America/Manaus': '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
  'America/Santiago': '(UTC-04:00) Santiago',
  'America/Grand_Turk': '(UTC-04:00) Turks and Caicos',
  'America/St_Johns': '(UTC-03:30) Newfoundland',
  'America/Fortaleza': '(UTC-03:00) Araguaina',
  'America/Sao_Paulo': '(UTC-03:00) Brasilia',
  'America/Cayenne': '(UTC-03:00) Cayenne, Fortaleza',
  'America/Buenos_Aires': '(UTC-03:00) City of Buenos Aires',
  'America/Godthab': '(UTC-03:00) Greenland',
  'America/Montevide': '(UTC-03:00) Montevideo',
  'America/Miquelon': '(UTC-03:00) Saint Pierre and Miquelon',
  'America/Bahia': '(UTC-03:00) Salvador',
  'America/Noronha': '(UTC-02:00) Fernando de Noronha',
  'Atlantic/Azores': '(UTC-01:00) Azores',
  'Atlantic/Cape_Verde': '(UTC-01:00) Cabo Verde Islands',
  'frica/Casablanca': '(UTC) Casablanca',
  'Europe/London': '(UTC) Dublin, Edinburgh, Lisbon, London',
  'Africa/Monrovia': '(UTC) Monrovia, Reykjavik',
  'Europe/Amsterdam': '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  'Europe/Belgrade': '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  'Europe/Brussels': '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
  'Europe/Warsaw': '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
  'Africa/Algiers': '(UTC+01:00) West Central Africa',
  'Africa/Windhoek': '(UTC+01:00) Windhoek',
  'Asia/Amman': '(UTC+02:00) Amman',
  'Europe/Athens': '(UTC+02:00) Athens, Bucharest',
  'Asia/Beirut': '(UTC+02:00) Beirut',
  'Africa/Cairo': '(UTC+02:00) Cairo',
  'Asia/Damascus': '(UTC+02:00) Damascus',
  'Asia/Gaza': '(UTC+02:00) Gaza, Hebron',
  'Africa/Harare': '(UTC+02:00) Harare, Pretoria',
  'Europe/Helsinki': '(UTC+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
  'Asia/Jerusalem': '(UTC+02:00) Jerusalem',
  'Europe/Kaliningrad': '(UTC+02:00) Kaliningrad',
  'Africa/Tripoli': '(UTC+02:00) Tripoli',
  'Asia/Baghdad': '(UTC+03:00) Baghdad',
  'Asia/Istanbul': '(UTC+03:00) Istanbul',
  'Asia/Kuwait': '(UTC+03:00) Kuwait, Riyadh',
  'Europe/Minsk': '(UTC+03:00) Minsk',
  'Europe/Moscow': '(UTC+03:00) Moscow, St. Petersburg, Volgograd',
  'Africa/Nairobi': '(UTC+03:00) Nairobi',
  'Asia/Tehran': '(UTC+03:30) Tehran',
  'Asia/Muscat': '(UTC+04:00) Abu Dhabi, Muscat',
  'Europe/Astrakhan': '(UTC+04:00) Astrakhan, Ulyanovsk',
  'Asia/Baku': '(UTC+04:00) Baku',
  'Europe/Samara': '(UTC+04:00) Izhevsk, Samara',
  'Indian/Mauritius': '(UTC+04:00) Port Louis',
  'Asia/Tbilisi': '(UTC+04:00) Tbilisi',
  'Asia/Yerevan': '(UTC+04:00) Yerevan',
  'Asia/Kabul': '(UTC+04:30) Kabul',
  'Asia/Tashkent': '(UTC+05:00) Tashkent, Ashgabat',
  'Asia/Yekaterinburg': '(UTC+05:00) Ekaterinburg',
  'Asia/Karachi': '(UTC+05:00) Islamabad, Karachi',
  'Asia/Kolkata': '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
  'Asia/Colombo': '(UTC+05:30) Sri Jayawardenepura',
  'Asia/Katmandu': '(UTC+05:45) Kathmandu',
  'Asia/Almaty': '(UTC+06:00) Astana',
  'Asia/Dhaka': '(UTC+06:00) Dhaka',
  'Asia/Rangoon': '(UTC+06:30) Yangon (Rangoon)',
  'Asia/Novosibirsk': '(UTC+07:00) Novosibirsk',
  'Asia/Bangkok': '(UTC+07:00) Bangkok, Hanoi, Jakarta',
  'Asia/Barnaul': '(UTC+07:00) Barnaul, Gorno-Altaysk',
  'Asia/Hovd': '(UTC+07:00) Hovd',
  'Asia/Krasnoyarsk': '(UTC+07:00) Krasnoyarsk',
  'Asia/Tomsk': '(UTC+07:00) Tomsk',
  'Asia/Chongqing': '(UTC+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi',
  'Asia/Irkutsk': '(UTC+08:00) Irkutsk',
  'Asia/Kuala_Lumpur': '(UTC+08:00) Kuala Lumpur, Singapore',
  'Australia/Perth': '(UTC+08:00) Perth',
  'Asia/Taipei': '(UTC+08:00) Taipei',
  'Asia/Ulaanbaatar': '(UTC+08:00) Ulaanbaatar',
  'Asia/Pyongyang': '(UTC+08:30) Pyongyang',
  'Australia/Eucla': '(UTC+08:45) Eucla',
  'Asia/Chita': '(UTC+09:00) Chita',
  'Asia/Tokyo': '(UTC+09:00) Osaka, Sapporo, Tokyo',
  'Asia/Seoul': '(UTC+09:00) Seoul',
  'Asia/Yakutsk': '(UTC+09:00) Yakutsk',
  'Australia/Adelaide': '(UTC+09:30) Adelaide',
  'Australia/Darwin': '(UTC+09:30) Darwin',
  'Australia/Brisbane': '(UTC+10:00) Brisbane',
  'Australia/Canberra': '(UTC+10:00) Canberra, Melbourne, Sydney',
  'Pacific/Guam': '(UTC+10:00) Guam, Port Moresby',
  'Australia/Hobart': '(UTC+10:00) Hobart',
  'Asia/Vladivostok': '(UTC+10:00) Vladivostok',
  'Australia/Lord_Howe': '(UTC+10:30) Lord Howe Island',
  'Pacific/Bougainville': '(UTC+11:00) Bougainville Island',
  'Asia/Srednekolymsk': '(UTC+11:00) Chokirdakh',
  'Asia/Magadan': '(UTC+11:00) Magadan',
  'Pacific/Norfolk': '(UTC+11:00) Norfolk Island',
  'Asia/Sakhalin': '(UTC+11:00) Sakhalin',
  'Pacific/Guadalcanal': '(UTC+11:00) Solomon Islands, New Caledonia',
  'Asia/Anadyr': '(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky',
  'Pacific/Auckland': '(UTC+12:00) Auckland, Wellington',
  'Pacific/Fiji': '(UTC+12:00) Fiji Islands',
  'Pacific/Chatham': '(UTC+12:45) Chatham Islands',
  'Pacific/Tongatapu': "(UTC+13:00) Nuku'alofa",
  'Pacific/Apia': '(UTC+13:00) Samoa',
  'Pacific/Kiritimati': '(UTC+14:00) Kiritimati Island'
};

export const timeZones = Object.keys(timeZoneDisplaymap).map(key => {
  return {
    text: timeZoneDisplaymap[key],
    value: key
  };
});

export const timeFormats: { value: string; text: string }[] = [
  {
    value: 'h:mm a',
    text: 'h:mm a'
  },
  {
    value: 'h:mm:ss a',
    text: 'h:mm:ss a'
  },
  {
    value: 'HH:mm:ss',
    text: 'HH:mm:ss'
  },
  {
    value: 'h:mm a Z',
    text: 'h:mm a Z'
  },
  {
    value: 'h:mm:ss a Z',
    text: 'h:mm:ss a Z'
  },
  {
    value: 'HH:mm:ss Z',
    text: 'HH:mm:ss Z'
  }
];

export const dateFormats: { value: string; text: string }[] = [
  {
    value: 'DD/MM/YYYY',
    text: 'DD/MM/YYYY'
  },
  {
    value: 'DD.MM.YYYY',
    text: 'DD.MM.YYYY'
  },
  {
    value: 'DD-MM-YYYY',
    text: 'DD-MM-YYYY'
  },
  {
    value: 'MM/DD/YYYY',
    text: 'MM/DD/YYYY'
  },
  {
    value: 'MM.DD.YYYY',
    text: 'MM.DD.YYYY'
  },
  {
    value: 'MM-DD-YYYY',
    text: 'MM-DD-YYYY'
  },
  {
    value: 'YYYY/MM/DD',
    text: 'YYYY/MM/DD'
  },
  {
    value: 'YYYY-MM-DD',
    text: 'YYYY-MM-DD'
  },
  {
    value: 'YYYY.MM.DD',
    text: 'YYYY.MM.DD'
  },
  {
    value: 'DD-MMM-YY',
    text: 'DD-MMM-YY'
  },
  {
    value: 'MMMM DD, YYYY',
    text: 'MMMM DD, YYYY'
  },
  {
    value: 'DD MMMM YYYY',
    text: 'DD MMMM YYYY'
  }
];
export const setFormats = () => {
  const { userInfoVO, companyInfoVO } = store.getState().userDetails.userDetails;

  defaultDateFormat = userInfoVO?.uiPreferences?.dateFormat || companyInfoVO?.uiPreferences?.dateFormat || 'YYYY-MM-DD';

  defaultTimeFormat = userInfoVO?.uiPreferences?.timeFormat || companyInfoVO?.uiPreferences?.timeFormat || 'h:mm:ss a';

  defaultTimeZone = userInfoVO?.timeZone || companyInfoVO?.timeZone || 'America/New_York';
};

export const setCompanyFormats = (companyInfoVO: CompanyInfoVO) => {
  if (companyInfoVO.uiPreferences && companyInfoVO.uiPreferences.dateFormat && companyInfoVO.uiPreferences.timeFormat && companyInfoVO.timeZone) {
    defaultDateFormat = companyInfoVO.uiPreferences.dateFormat;
    defaultTimeFormat = companyInfoVO.uiPreferences.timeFormat;
    defaultTimeZone = companyInfoVO.timeZone;
  } else {
    defaultDateFormat = 'YYYY-MM-DD';
    defaultTimeFormat = 'h:mm:ss a';
    defaultTimeZone = 'America/New_York';
  }
};

export const checkDateisExpiry = (date: any, timeZone?: string) => {
  let REFERENCE = dayjs().tz(timeZone || defaultTimeZone);
  let TODAY = REFERENCE.clone().startOf('day');
  if (date) {
    let dateWithTimezone = convertToDate(date, '00:00', timeZone);
    if (getDayjsDateWithTimeZone(dateWithTimezone, timeZone).isAfter(TODAY, 'd') === true) {
      return 'upcomming';
    } else if (getDayjsDateWithTimeZone(dateWithTimezone, timeZone).isBefore(TODAY, 'd') === true) {
      return 'expired';
    } else if (getDayjsDateWithTimeZone(dateWithTimezone, timeZone).isSame(TODAY, 'd') === true) {
      return 'today';
    }
  }
};

export const getDisplayDateFormat = () => {
  return defaultDateFormat;
};

export const getDisplayTimeFormat = () => {
  return defaultTimeFormat;
};

export const getTimeZone = () => {
  return defaultTimeZone;
};

export function getUserDataTimeZone() {
  const userData = localStorage.getItem('userData');
  if (userData) {
    const data = JSON.parse(userData);
    defaultDateFormat = data.dateFormat ? data.dateFormat : 'YYYY-MM-DD';
    defaultTimeFormat = data.timeFormat ? data.timeFormat : 'h:mm:ss a';
    defaultTimeZone = data.timeZone ? data.timeZone : 'America/New_York';
    return;
  }
  setFormats();
}

var reference: any;
export const getShortDate = (inDate: any) => {
  return getDayjsDateWithTimeZone(inDate).format(defaultDateFormat);
};

export const getDayjsDate = (inDate: any) => {
  return getDayjsDateWithTimeZone(inDate);
};

export const getDayjsDateWithTimeZone = (inDate: any, timeZone?: any) => {
  if (!timeZone) {
    timeZone = defaultTimeZone;
  }

  if (isSafari || isFireFox) {
    return getMomentDateWithTimeZone(inDate, timeZone);
  }

  var tempDayjs: any;
  if (inDate instanceof dayjs) {
    tempDayjs = inDate;
  } else if (dayjs(inDate, 'YYYY-MM-DD', true).isValid()) {
    //input is in proper format
    const newTz = dayjs().tz(timeZone).format('Z');
    tempDayjs = dayjs(inDate + ' ' + newTz, 'YYYY-MM-DD Z');
  } else if (dayjs(inDate, 'DD-MMM-YYYY', true).isValid()) {
    //input is in proper format
    const newTz = dayjs().tz(timeZone).format('Z');
    tempDayjs = dayjs(inDate + ' ' + newTz, 'DD-MMM-YYYY Z');
  } else if (dayjs(inDate, 'YYYY-MM-DDTHH:mm:ss.000Z', true).isValid()) {
    tempDayjs = dayjs(inDate, 'YYYY-MM-DDTHH:mm:ss.000Z');
  } else if (dayjs(inDate, 'YYYY-MM-DD HH:mm:ss Z', isFireFox ? false : true).isValid()) {
    tempDayjs = dayjs(inDate, 'YYYY-MM-DD HH:mm:ss Z');
  } else {
    const split = inDate && typeof inDate === 'string' ? inDate.split(' ') : inDate;
    if (split && split.length === 3) {
      split[0] = split[0].replaceAll('-', ' ');
      const modifiedDate = split.join(' ');
      tempDayjs = dayjs(modifiedDate).isValid() ? dayjs(modifiedDate) : dayjs(inDate);
    } else {
      tempDayjs = dayjs(inDate);
    }
  }
  return tempDayjs.tz(timeZone);
};

export const checkIsDateValid = (date: string) => {
  if (
    dayjs(date, `YYYY-MM-DD`, true).isValid() ||
    dayjs(date, `DD-MMM-YYYY`, true).isValid() ||
    dayjs(date, `YYYY-MM-DDTHH:mm:ss.000Z`, true).isValid() ||
    dayjs(date, `YYYY-MM-DD HH:mm:ss[Z]`).isValid()
  ) {
    return true;
  } else {
    return false;
  }
};

export const getMomentDate = (inDate: any) => {
  return getMomentDateWithTimeZone(inDate);
};

export const getMomentDateFormat = (inDate: any) => {
  return getMomentDateWithTimeZone(inDate).format(defaultDateFormat);
};

//This method is used for rfpDatepicker and rangePicker
export const getMomentDateWithTimeZone = (inDate: any, timeZone?: any) => {
  if (!timeZone) {
    timeZone = defaultTimeZone;
  }
  let tempMoment: any;
  if (inDate instanceof moment) {
    tempMoment = inDate;
  } else if (moment(inDate, 'YYYY-MM-DD', true).isValid()) {
    //input is in proper format
    const newTz = moment().tz(timeZone).format('Z');
    tempMoment = moment(inDate + ' ' + newTz, 'YYYY-MM-DD Z', true);
  } else if (moment(inDate, 'DD-MMM-YYYY', true).isValid()) {
    //input is in proper format
    const newTz = moment().tz(timeZone).format('Z');
    tempMoment = moment(inDate + ' ' + newTz, 'DD-MMM-YYYY Z', true);
  } else if (moment(inDate, 'YYYY-MM-DDTHH:mm:ss.000Z', true).isValid()) {
    tempMoment = moment(inDate, 'YYYY-MM-DDTHH:mm:ss.000Z', true);
  } else if (moment(inDate, 'YYYY-MM-DD HH:mm:ss Z', true).isValid()) {
    tempMoment = moment(inDate, 'YYYY-MM-DD HH:mm:ss Z', true);
  } else if (moment(inDate, defaultDateFormat, true).isValid()) {
    //input is in proper format
    const newTz = moment().tz(timeZone).format('Z');
    tempMoment = moment(inDate + ' ' + newTz, `${defaultDateFormat} Z`, true);
  } else {
    tempMoment = moment(inDate);
  }
  return tempMoment.tz(timeZone);
};

export const displayDateFormatFriendly = (displayDate: any) => {
  var out = getDayjsDate(displayDate).fromNow();
  return out;
};

export const displayDateForComments = (displayDate: any) => {
  const dayJsDate = getDayjsDate(displayDate);
  const year = dayJsDate.year();
  const current_year = dayjs().year();
  const displayFormat = year === current_year ? 'hh:mm A MMM DD' : 'hh:mm A MMM DD, YYYY';
  return dayJsDate.format(displayFormat);
};

export const displayDateUsingFromNow = (displayDate: any) => {
  const out: string = getDayjsDate(displayDate).fromNow(true);
  if (out.includes('second')) {
    return 'now';
  } else if (out.includes('minute')) {
    let num: string = out.split(' ')[0];
    if (num === 'a') {
      num = '1';
    }
    return num + (num !== '1' ? ' mins ' : ' min ') + 'ago';
  } else if (out.includes('hour')) {
    let num: string = out.split(' ')[0];
    if (num === 'an') {
      num = '1';
    }
    return num + (num !== '1' ? ' hrs ' : ' hr ') + 'ago';
  } else {
  }
  return getDateForFormat(displayDate, 'lll').split(',')[0];
};

export const displayDateDifferences = (displayDate: string) => {
  const out: string = getDayjsDate(displayDate).fromNow(true);
  const currentDate = dayjs();
  if (out.includes('second')) {
    return 'Just now';
  } else if (out.includes('minute')) {
    let num: string = out.split(' ')[0];
    if (num === 'a') {
      num = '1';
    }
    return num + (num !== '1' ? ' mins ' : ' min ') + 'ago';
  } else if (out.includes('hour')) {
    let num: string = out.split(' ')[0];
    if (num === 'an') {
      num = '1';
    }
    return num + (num !== '1' ? ' hours ' : ' hour ') + 'ago';
  } else {
    const num: number = Math.ceil(currentDate.diff(displayDate, 'days', true));
    return num + (num !== 1 ? ' days ' : ' day ') + 'ago';
  }
};

export const displayDateForDiscussions = (displayDate: string) => {
  const getDayjsDate = (date: string) => dayjs(date);
  const out: string = getDayjsDate(displayDate).fromNow(true);
  const currentDate = dayjs();

  if (out.includes('second')) {
    return 'Just now';
  } else if (out.includes('minute')) {
    let num: string = out.split(' ')[0];
    if (num === 'a') {
      num = '1';
    }
    return num + (num !== '1' ? ' mins ' : ' min ') + 'ago';
  } else if (out.includes('hour')) {
    let num: string = out.split(' ')[0];
    if (num === 'an') {
      num = '1';
    }
    return num + (num !== '1' ? ' hours ' : ' hour ') + 'ago';
  } else if (out.includes('day')) {
    const num: number = Math.ceil(currentDate.diff(displayDate, 'days', true));
    return num + (num !== 1 ? ' days ' : ' day ') + 'ago';
  } else if (out.includes('month')) {
    const num: number = Math.ceil(currentDate.diff(displayDate, 'months', true));
    return num + (num !== 1 ? ' months ' : ' month ') + 'ago';
  } else if (out.includes('year')) {
    const num: number = Math.ceil(currentDate.diff(displayDate, 'years', true));
    return num + (num !== 1 ? ' years ' : ' year ') + 'ago';
  } else {
    return getDayjsDate(displayDate).format('YYYY-MM-DD');
  }
};
export const getDateFormat = (displayDate: any) => {
  return getDayjsDate(displayDate).format(defaultDateFormat);
};

export const getCustomDateFormat = (displayDate: any) => {
  return getDayjsDate(displayDate).format(defaultDateFormat);
};

export const getCommentTooltip = (displayDate: any, timeZone?: any) => {
  return getDayjsDateWithTimeZone(displayDate, timeZone || defaultTimeZone).format('MMM DD, YYYY [at] hh:mm:ss A');
};

export const getCustomDateTimeFormat = (displayDate: any, timeZone?: any, dateFormat?: string, timeFormat?: string, seperator?: string) => {
  timeZone = timeZone || defaultTimeZone;
  dateFormat = dateFormat || defaultDateFormat;
  timeFormat = timeFormat || defaultTimeFormat;
  seperator = seperator || ' | ';

  return getDayjsDateWithTimeZone(displayDate, timeZone).format(dateFormat + seperator + timeFormat);
};

export const getCustomizedDateTimeFormat = (displayDate: any, timeZone?: any, dateFormat?: string, timeFormat?: string) => {
  timeZone = timeZone || defaultTimeZone;
  dateFormat = dateFormat || defaultDateFormat;
  timeFormat = timeFormat || defaultTimeFormat;
  return getMomentDateWithTimeZone(displayDate, timeZone).format(dateFormat + ' | ' + timeFormat);
};

export const changeFormat = (displayDate: any) => {
  return getDayjsDate(displayDate).format(defaultTimeFormat);
};

export const getFilterDateFormat = (displayDate: dayjs.ConfigType, format: string, timeZone: string) => {
  if (dayjs(displayDate, 'YYYY-MM-DD HH:mm:ss Z', true).isValid()) {
    return dayjs(displayDate, 'YYYY-MM-DD HH:mm:ss Z').format(format);
  } else {
    return displayDate ? dayjs(displayDate).tz(timeZone).format(format) : '';
  }
};

export const setModelValueForDatePicker = function (modelValue: any) {
  if (!modelValue) {
    return modelValue;
  }
  if (modelValue instanceof Date) {
    const m = dayjs(modelValue).tz(defaultTimeZone).format('YYYY-MM-DD');
    return m;
    //convert this to user timezone
  } else if (dayjs.isDayjs(modelValue)) {
    const m = modelValue.tz(defaultTimeZone).format('YYYY-MM-DD');
    return m;
  } else if (dayjs(modelValue, 'DD-MMM-YYYY', true).isValid()) {
    var m = dayjs(modelValue, 'DD-MMM-YYYY').format('YYYY-MM-DD');
    return m;
  }
  return modelValue;
};

export const setConvertedDate = function (date: any) {
  if (date) {
    return getDayjsDateWithTimeZone(date).toDate();
  } else {
    return new Date(dayjs());
  }
};

export const getHourMinute = function (displayDate: any, timezone?: any) {
  var dayjsObj = getDayjsDateWithTimeZone(displayDate, timezone);
  var tempmin = dayjsObj.minute() % 30;
  var roundOfDate = tempmin < 15 ? -tempmin : 30 - tempmin;
  return dayjsObj.add(roundOfDate, 'minute').format('HH:mm');
};

export const displayDateFormat = (displayDate: any) => {
  reference = dayjs().tz(defaultTimeZone);
  if (getDayjsDateWithTimeZone(displayDate).isSame(reference.clone().startOf('day'), 'd') == true) {
    return 'TODAY';
  } else if (getDayjsDateWithTimeZone(displayDate).isSame(reference.clone().subtract(1, 'day').startOf('day'), 'd') == true) {
    return 'YESTERDAY';
  } else if (getDayjsDateWithTimeZone(displayDate).isSame(reference.clone().add(1, 'day'), 'd') == true) {
    return 'TOMORROW';
  } else {
    return getDayjsDateWithTimeZone(displayDate).format(defaultDateFormat);
  }
};

export const getDateForFormat = (displayDate: any, format: string) => {
  if (dayjs(displayDate, 'YYYY-MM-DD HH:mm:ss Z', true).isValid()) {
    return dayjs(displayDate, 'YYYY-MM-DD HH:mm:ss Z').format(format);
  } else {
    return displayDate ? dayjs(displayDate).format(format) : '';
  }
};

export const getDateYear = (displayDate: any) => {
  return dayjs(displayDate, 'YYYY-MM-DD HH:mm:ss Z').format('YYYY');
};

export const getMonthDate = (displayDate: any) => {
  return dayjs(displayDate, 'YYYY-MM-DD HH:mm:ss Z').format('DD MMM');
};

export const getMonthDateMyTask = (displayDate: any) => {
  return dayjs(displayDate, 'YYYY-MM-DD').format('DD MMM');
};

export const getMonthYear = (displayDate: any) => {
  return getDayjsDateWithTimeZone(displayDate).format('MMM YYYY');
};

export const getMonthDateYearWithHyphen = (displayDate: any) => {
  return getDayjsDateWithTimeZone(displayDate).format('DD-MMM-YYYY');
};

export const getYearMonthDateWithHyphen = (displayDate: any) => {
  return getDayjsDateWithTimeZone(displayDate).format('YYYY-MM-DD');
};

export const getMonthDateYear = (displayDate: any) => {
  return getDayjsDateWithTimeZone(displayDate).format('DD MMM YYYY');
};

export const getMonthDateYearSeparated = (displayDate: any) => {
  return getDayjsDateWithTimeZone(displayDate).format('MMMM D, YYYY');
};

export const getTimeWithColon = (displayDate: any) => {
  return getDayjsDateWithTimeZone(displayDate).format('HH:mm:ss a');
};

export const getTimeWithMinute = (displayDate: any, timeFormat?: string) => {
  return getDayjsDateWithTimeZone(displayDate).format(timeFormat ? timeFormat : 'h:mm A');
};

export const getDueDateFormat = (displayDate: any) => {
  return displayDate ? dayjs(displayDate).format(defaultDateFormat) : '';
};

export const getFormatForDateType = (displayDate: any, format: string) => {
  return dayjs(displayDate).format(format);
};

export const getDatedifferenceinSecForDateType = (displayDate: any, format: string) => {
  return dayjs(new Date()).diff(dayjs(displayDate, format), 'seconds');
};

// Send as milliseconds and get format as 6m 5s if hours 1h 2m 8s
export const getMinutesAndHoursFormat = (milliseconds: number) => {
  if (milliseconds) {
    const hours = dayjs.duration(milliseconds).hours();
    const minutes = dayjs.duration(milliseconds).minutes();
    const format = hours > 0 && minutes > 0 ? 'H[h] m[m] s[s]' : hours > 0 && minutes === 0 ? 'H[h] s[s]' : minutes > 0 ? 'm[m] s[s]' : 's[s]';
    return dayjs.duration(milliseconds).format(format);
  }
};

export const getDateFirefox = (displayDate: any, type: any) => {
  let format;
  if (type === 'DATE') {
    format = defaultDateFormat;
  } else {
    format = defaultDateFormat + ' ' + defaultTimeFormat;
  }
  if (dayjs(displayDate, `YYYY-MM-DD`, true).isValid()) {
    return dayjs(displayDate, `YYYY-MM-DD`).format(format);
  } else if (dayjs(displayDate, `DD-MMM-YYYY`, true).isValid()) {
    return dayjs(displayDate, `DD-MMM-YYYY`).format(format);
  } else if (dayjs(displayDate, `YYYY-MM-DDTHH:mm:ss.000Z`, true).isValid()) {
    return dayjs(displayDate, `YYYY-MM-DDTHH:mm:ss.000Z`).format(format);
  } else if (dayjs(displayDate, `YYYY-MM-DD HH:mm:ss[Z]`).isValid()) {
    return dayjs(displayDate, `YYYY-MM-DD HH:mm:ss[Z]`).format(format);
  } else {
    return `-`;
  }
};

export const changeDateToUserFormat = (displayDate: any, type?: any) => {
  if (isFireFox) {
    return getDateFirefox(displayDate, type);
  } else {
    return dayjs(displayDate);
  }
};

export const changeDateToUserDateFormat = (displayDate: any, type?: any) => {
  if (isFireFox) {
    return getDateFirefox(displayDate, type);
  }
  if (displayDate) {
    if (type === 'DATE') {
      return getDayjsDateWithTimeZone(displayDate).format(defaultDateFormat);
    } else if (type === 'DATE_FORMAT') {
      return dayjs(displayDate).format(defaultDateFormat);
    } else {
      return dayjs(displayDate).format(defaultDateFormat + ' ' + defaultTimeFormat);
    }
  }
};

export const getFullDateTime = (displayDate: any, seperator?: string) => {
  const seperation = seperator ? seperator : ' ';
  return getDayjsDateWithTimeZone(displayDate).format(defaultDateFormat + seperation + defaultTimeFormat);
};

export const getFullDateTimeFormat = (displayDate: any) => {
  return getDayjsDate(displayDate).format(defaultDateFormat + ' | ' + defaultTimeFormat);
};

export const getDueDateFontColor = (dueDate: Date, status: string) => {
  reference = dayjs().tz(defaultTimeZone);
  const today = reference.clone().startOf('day');
  const displayDate = getDateFormat(dueDate);
  const dayjsDate = getDayjsDateWithTimeZone(displayDate);
  const isDark = localStorage.getItem('themeMode');
  if (dayjsDate.isBefore(today, 'd')) {
    return isDark && isDark === 'dark' ? '#FF711F' : 'red';
  }
};

export const getCurrentDateTime = () => {
  return new Date().toLocaleString();
};

export const getCurrentDate = () => {
  return getCurrentDt();
};

export const getTimeZoneFromDateTimeZone = (date: any) => {
  return getMomentDateWithTimeZone(date)._z.name;
};

export const convertToDate = function (date: any, time: any, zone?: any, seconds?: any, isUserDateFormat?: boolean) {
  const dateFormat = isUserDateFormat && defaultDateFormat ? defaultDateFormat : 'YYYY-MM-DD';
  if (!date) {
    return;
  }
  if (!zone) {
    zone = defaultTimeZone;
  }
  let newDate = null;
  if (dayjs.isDayjs(date)) {
    newDate = date.tz(zone).format(dateFormat);
  } else if (date instanceof Date) {
    newDate = dayjs(date).format(dateFormat);
  } else if (dayjs(date, dateFormat, true).isValid()) {
    newDate = date;
  } else if (dayjs(date, 'x', true).isValid()) {
    newDate = dayjs(date, 'x', true).format(dateFormat);
  } else {
    let tempDayjs = getDayjsDate(date);
    if (!tempDayjs || !tempDayjs.isValid()) {
      tempDayjs = dayjs(date);
    }
    newDate = tempDayjs.format(dateFormat);
  }
  if (newDate) {
    return newDate + ' ' + time + ':' + (seconds || '00') + ' ' + dayjs(newDate, dateFormat).tz(zone).format('Z');
  }
  return null;
};

export const getBusinessDay = (date: any, action: string) => {
  if (!date || !action) {
    return date;
  }
  const companyPreferencesVO = store.getState().userDetails.userDetails.companyPreferencesVO;
  if (companyPreferencesVO?.featurePreferences?.nonbusiness_days === '1' && companyPreferencesVO.data && companyPreferencesVO.data.nonbusiness_days) {
    const nonBusinessDays = companyPreferencesVO.data.nonbusiness_days.non_business_days_data;
    let hasBusinessDay = true;
    while (hasBusinessDay) {
      const dayOfWeek = date.day ? date.day() + 1 : moment(date).day() + 1;
      if (action === 'next' && nonBusinessDays.includes(dayOfWeek)) {
        date = date.add(1, 'days');
        hasBusinessDay = true;
      } else if (action === 'previous' && nonBusinessDays.includes(dayOfWeek)) {
        date = moment(date).subtract(parseInt('1'), 'days');
        hasBusinessDay = true;
      } else {
        hasBusinessDay = false;
      }
    }
  }
  return date;
};

export const getPreviousBusinessDay = (date: any) => {
  return getBusinessDay(date, 'previous');
};

export const getNextBusinessDay = (date: any) => {
  return getBusinessDay(date, 'next');
};

export const converDateToBackendFormat = (e: any) => {
  return dayjs(e).tz(defaultTimeZone).format('YYYY-MM-DD HH:mm:ss Z');
};

export const converDateToBackendFormatByDateformat = (e: any, dateFormat: string) => {
  if (isSafari || isFireFox) {
    if (dayjs(e, `YYYY-MM-DD`, true).isValid()) {
      const dateTz = dayjs(e, `YYYY-MM-DD`).tz(defaultTimeZone).local().toDate();
      return dayjs(dateTz).format(dateFormat);
    } else if (dayjs(e, `DD-MMM-YYYY`, true).isValid()) {
      const dateTz = dayjs(e, `DD-MMM-YYYY`).tz(defaultTimeZone).local().toDate();
      return dayjs(dateTz).format(dateFormat);
    } else if (dayjs(e, `YYYY-MM-DDTHH:mm:ss.000Z`, true).isValid()) {
      const dateTz = dayjs(e, `YYYY-MM-DDTHH:mm:ss.000Z`).tz(defaultTimeZone).local().toDate();
      return dayjs(dateTz).format(dateFormat);
    } else if (dayjs(e, `YYYY-MM-DD HH:mm:ss Z`).isValid()) {
      const dateTz = dayjs(e, `YYYY-MM-DD HH:mm:ss Z`).tz(defaultTimeZone).local().toDate();
      return dayjs(dateTz).format(dateFormat);
    } else if (dayjs(e, `YYYY-MM-DD HH:mm:ss[Z]`).isValid()) {
      const dateTz = dayjs(e, `YYYY-MM-DD hh:mm:ss[Z]`).tz(defaultTimeZone).local().toDate();
      return dayjs(dateTz).format(dateFormat);
    }
  }
  const dateTz = dayjs(e).tz(defaultTimeZone).local().toDate();
  return dayjs(dateTz).format(dateFormat);
};

export const checkIfDateIsValid = (e: any) => {
  const date = dayjs(e).format('YYYY-MM-DD HH:mm:ss Z');
  return dayjs(date, 'YYYY-MM-DD HH:mm:ss Z', true).isValid();
};

export const getDifference = (fromDate: any, toDate: any, format: any) => {
  const dueDate = dayjs(toDate);
  return dueDate.diff(fromDate, format);
};

export const checkDateIsAfterByTime = (date1: any, date2: any) => {
  return getDayjsDate(date1).isAfter(getDayjsDate(date2));
};

export const checkDateIsAfter = (date1: any, date2: any) => {
  return getDayjsDate(date1).isAfter(getDayjsDate(date2), 'day');
};

export const checkDateIsAfterOrEqual = (date1: any, date2: any) => {
  return getDayjsDate(date1).isAfter(getDayjsDate(date2), 'day') || getDayjsDate(date1).isSame(getDayjsDate(date2), 'day');
};

export const getDuration = (duration: any) => {
  return duration ? dayjs.duration(parseInt(duration)).format('HH:mm:ss') : '00:00:00';
};

export const getDurationInFormat = (duration: any) => {
  if (!duration || duration < 1000) {
    return '0s';
  }
  const updatedDuration = dayjs.duration(parseInt(duration));
  const hours = Math.floor(updatedDuration.asHours());
  const min = updatedDuration.minutes();
  const sec = updatedDuration.seconds();
  let formattedValue: string = '';
  if (hours > 0) {
    formattedValue = `${hours}h `;
  }
  if (min > 0) {
    formattedValue += `${min}m `;
  }
  if (sec > 0) {
    formattedValue += `${sec}s`;
  }
  return formattedValue;
};

export const getDurationReport = (duration: any) => {
  const tempDuration = dayjs.duration(parseInt(duration));
  const hours = Math.floor(tempDuration.asHours());
  const minutes = Math.floor(tempDuration.minutes());
  const seconds = Math.floor(tempDuration.seconds());
  const result = duration ? `${getDigitFormate(hours)} : ${getDigitFormate(minutes)} : ${getDigitFormate(seconds)}` : '00:00:00';
  return result;
};
export const getDigitFormate = (time: any) => {
  time = time.toFixed();
  return time.length === 1 ? '0' + time : time;
};

export const getDateIsBeforeToday = (dueDate: string) => {
  const today = dayjs().tz(defaultTimeZone);
  return getDayjsDate(dueDate).isBefore(getDayjsDate(today), 'day');
};

export const isExpiredDateWithTime = (date: string) => {
  const today = dayjs().tz(defaultTimeZone);
  return getDayjsDate(date).isBefore(getDayjsDate(today));
};

export const isExpiredDate = (dueDate: string) => {
  const today = dayjs().startOf('day');
  return dayjs(dueDate).isBefore(today, 'd');
};

export const getDateIsBeforeTodayOrEqual = (dueDate: string) => {
  const today = dayjs().tz(defaultTimeZone);
  return getDayjsDate(dueDate).isBefore(getDayjsDate(today), 'day') || getDayjsDate(dueDate).isSame(getDayjsDate(today), 'day');
};

export const checkDateIsBefore = (date1: any, date2: any) => {
  return getDayjsDate(date1).isBefore(getDayjsDate(date2), 'day');
};

export const checkDateIsBeforeOrEqual = (date1: any, date2: any) => {
  return getDayjsDate(date1).isBefore(getDayjsDate(date2), 'day') || getDayjsDate(date1).isSame(getDayjsDate(date2), 'day');
};

export const isDateBetween = (date: any, from?: any, to?: any) => {
  const fromDate = from ? dayjs(from).startOf('day') : undefined;
  const toDate = to ? dayjs(to).endOf('day') : undefined;
  if (fromDate && toDate && (dayjs(date).isAfter(fromDate, 'd') || dayjs(date).isSame(fromDate, 'd')) && (dayjs(date).isBefore(toDate, 'd') || dayjs(date).isSame(toDate, 'd'))) {
    return true;
  }
  if (fromDate && !toDate && (dayjs(date).isAfter(fromDate, 'd') || dayjs(date).isSame(fromDate, 'd'))) {
    return true;
  }
  if (toDate && !fromDate && (dayjs(date).isBefore(toDate, 'd') || dayjs(date).isSame(toDate, 'd'))) {
    return true;
  }
  return false;
};

export const setMomentModelValueForDatePicker = function (modelValue: any) {
  if (!modelValue) {
    return modelValue;
  }
  if (modelValue instanceof Date) {
    const m = moment(modelValue).tz(defaultTimeZone).format('YYYY-MM-DD');
    return m;
    //convert this to user timezone
  } else if (moment.isMoment(modelValue)) {
    const m = modelValue.tz(defaultTimeZone).format('YYYY-MM-DD');
    return m;
  } else if (moment(modelValue, 'DD-MMM-YYYY', true).isValid()) {
    const m = moment(modelValue, 'DD-MMM-YYYY').format('YYYY-MM-DD');
    return m;
  }
  return modelValue;
};

export const formateDate = (filter: any) => {
  const timeZone = moment.tz(getTimeZone()).format('Z');
  const { createdDate } = filter;
  const [fDate, tDate] = createdDate;
  let fromDate = '';
  let toDate = '';
  if (fDate) {
    fromDate = moment(fDate).format('YYYY-MM-DD');
    fromDate = fromDate + ' 00:00:00 ' + timeZone;
  }
  if (tDate) {
    toDate = moment(tDate).format('YYYY-MM-DD');
    toDate = toDate + ' 23:59:59 ' + timeZone;
  }
  filter.fromDate = fromDate;
  filter.toDate = toDate;

  delete filter['createdDate'];
};

export const getDateByTimeSource = (date: any, source: string) => {
  return moment(getMomentDateWithTimeZone(date)).set(timesBySource[source]);
};
export const checkIsSameDate = (date1: string, date2: string) => {
  return dayjs(date1).isSame(date2, 'day');
};

export const displayDateForMyWork = (displayDate: any) => {
  const dayjsOb = getDayjsDateWithTimeZone(displayDate);
  reference = dayjs().tz(defaultTimeZone);
  if (dayjsOb.isSame(reference.clone().startOf('day'), 'd')) {
    return 'Today';
  } else if (dayjsOb.isSame(reference.clone().add(1, 'day'), 'd')) {
    return 'Tomorrow';
  } else if (
    dayjsOb.isSame(reference.clone().add(2, 'day'), 'd') ||
    dayjsOb.isSame(reference.clone().add(3, 'day'), 'd') ||
    dayjsOb.isSame(reference.clone().add(4, 'day'), 'd') ||
    dayjsOb.isSame(reference.clone().add(5, 'day'), 'd') ||
    dayjsOb.isSame(reference.clone().add(6, 'day'), 'd') ||
    dayjsOb.isSame(reference.clone().add(7, 'day'), 'd')
  ) {
    return dayjs(displayDate).format('dddd');
  } else {
    return dayjsOb.format('DD MMMM YYYY');
  }
};

export const sortDates = (arr: any[]) => {
  return arr.sort((a, b) => (dayjs(a).isAfter(dayjs(b)) ? 1 : -1));
};

export const rangeDateConvertToBackendFormat = (date: any, dateFormat: any, dateSource: any) => {
  const dateTz = dayjs(date)
    .set('hour', dateSource === 'to' ? 23 : 0)
    .set('minute', dateSource === 'to' ? 59 : 0)
    .set('second', dateSource === 'to' ? 59 : 0)
    .tz(defaultTimeZone)
    .local()
    .toDate();
  return dayjs(dateTz).format(dateFormat);
};

export const isDateTodayOrTomorrow = (displayDate: string) => {
  reference = dayjs();
  if (dayjs(displayDate).isSame(reference.clone().startOf('day'), 'd')) {
    return 'TODAY';
  } else if (dayjs(displayDate).isSame(reference.clone().add(1, 'day'), 'd')) {
    return 'TOMORROW';
  }
};

export const getDateDifferenceinDays = (displayDate: any) => {
  return dayjs(displayDate).diff(dayjs(new Date()), 'day');
};

export const getDateDifferenceinDaysUsingMomentjs = (displayDate: any, format?: any) => {
  const dueDate = moment(displayDate, format);
  const currentDate = moment();
  return dueDate.diff(currentDate, 'day');
};

export const addDaysToCurrentDate = (days: number) => {
  return dayjs().add(days, 'days');
};

export const getBusinessDayByDaysJs = (date: any, action: any) => {
  if (!date || !action) {
    return date;
  }
  const companyPreferencesVO = store.getState().userDetails.userDetails.companyPreferencesVO;
  if (companyPreferencesVO?.featurePreferences?.nonbusiness_days === '1' && companyPreferencesVO.data && companyPreferencesVO.data.nonbusiness_days) {
    const nonBusinessDays = companyPreferencesVO.data.nonbusiness_days.non_business_days_data;
    let hasBusinessDay = true;
    while (hasBusinessDay) {
      const dayOfWeek = date.day ? date.day() + 1 : dayjs(date).day() + 1;
      if (action === 'next' && nonBusinessDays.includes(dayOfWeek)) {
        date = dayjs(date).add(1, 'day');
        date = date.$d;
        hasBusinessDay = true;
      } else if (action === 'previous' && nonBusinessDays.includes(dayOfWeek)) {
        date = dayjs(date).subtract(1, 'day');
        date = date.$d;
        hasBusinessDay = true;
      } else {
        hasBusinessDay = false;
      }
    }
  }
  return date;
};

export const dateRangeConvertToBackendFormat = (date: any, dateSource: string) => {
  const timeZone = dayjs().tz(defaultTimeZone).format('Z');
  return getFilterDateFormat(date, 'YYYY-MM-DD', defaultTimeZone) + (dateSource === 'to' ? ' 23:59:59 ' : ' 00:00:00 ') + timeZone;
};

export const getDateRangeConvertToBackendFormat = (date: dayjs.ConfigType) => {
  return getFilterDateFormat(date, 'YYYY-MM-DD', defaultTimeZone);
};

// Use this function before giving dates to date pickers
export const getDueDateValue = (dueDate: any, ifNullGetToday?: boolean) => {
  if (dueDate) {
    const time = getHourMinute(dueDate);
    const dueDateObj = { time: time, zone: '', date: dueDate };
    return dueDateObj;
  }
  if (ifNullGetToday) {
    const today = convertToDate(dayjs(), '18:00', '');
    return { time: '18:00', zone: '', date: today };
  }
  return { date: '', time: '', zone: '' };
};

export const dueTimes = [
  {
    value: '00:00',
    text: '12:00 am'
  },
  {
    value: '00:30',
    text: '12:30 am'
  },
  {
    value: '01:00',
    text: '01:00 am'
  },
  {
    value: '01:30',
    text: '01:30 am'
  },
  {
    value: '02:00',
    text: '02:00 am'
  },
  {
    value: '02:30',
    text: '02:30 am'
  },
  {
    value: '03:00',
    text: '03:00 am'
  },
  {
    value: '03:30',
    text: '03:30 am'
  },
  {
    value: '04:00',
    text: '04:00 am'
  },
  {
    value: '04:30',
    text: '04:30 am'
  },
  {
    value: '05:00',
    text: '05:00 am'
  },
  {
    value: '05:30',
    text: '05:30 am'
  },
  {
    value: '06:00',
    text: '06:00 am'
  },
  {
    value: '06:30',
    text: '06:30 am'
  },
  {
    value: '07:00',
    text: '07:00 am'
  },
  {
    value: '07:30',
    text: '07:30 am'
  },
  {
    value: '08:00',
    text: '08:00 am'
  },
  {
    value: '08:30',
    text: '08:30 am'
  },
  {
    value: '09:00',
    text: '09:00 am'
  },
  {
    value: '09:30',
    text: '09:30 am'
  },
  {
    value: '10:00',
    text: '10:00 am'
  },
  {
    value: '10:30',
    text: '10:30 am'
  },
  {
    value: '11:00',
    text: '11:00 am'
  },
  {
    value: '11:30',
    text: '11:30 am'
  },
  {
    value: '12:00',
    text: '12:00 pm'
  },
  {
    value: '12:30',
    text: '12:30 pm'
  },
  {
    value: '13:00',
    text: '01:00 pm'
  },
  {
    value: '13:30',
    text: '01:30 pm'
  },
  {
    value: '14:00',
    text: '02:00 pm'
  },
  {
    value: '14:30',
    text: '02:30 pm'
  },
  {
    value: '15:00',
    text: '03:00 pm'
  },
  {
    value: '15:30',
    text: '03:30 pm'
  },
  {
    value: '16:00',
    text: '04:00 pm'
  },
  {
    value: '16:30',
    text: '04:30 pm'
  },
  {
    value: '17:00',
    text: '05:00 pm'
  },
  {
    value: '17:30',
    text: '05:30 pm'
  },
  {
    value: '18:00',
    text: '06:00 pm'
  },
  {
    value: '18:30',
    text: '06:30 pm'
  },
  {
    value: '19:00',
    text: '07:00 pm'
  },
  {
    value: '19:30',
    text: '07:30 pm'
  },
  {
    value: '20:00',
    text: '08:00 pm'
  },
  {
    value: '20:30',
    text: '08:30 pm'
  },
  {
    value: '21:00',
    text: '09:00 pm'
  },
  {
    value: '21:30',
    text: '09:30 pm'
  },
  {
    value: '22:00',
    text: '10:00 pm'
  },
  {
    value: '22:30',
    text: '10:30 pm'
  },
  {
    value: '23:00',
    text: '11:00 pm'
  },
  {
    value: '23:30',
    text: '11:30 pm'
  }
];
export const getNotificationDateWithTime = (displayDate: any, seperator?: string) => {
  reference = dayjs().tz(defaultTimeZone);
  const dayjsDateWithTimeZone = getDayjsDateWithTimeZone(displayDate);
  if (dayjsDateWithTimeZone.isSame(reference.clone().startOf('day'), 'd') == true) {
    return dayjsDateWithTimeZone.format(getDisplayTimeFormat());
  } else if (dayjsDateWithTimeZone.isSame(reference.clone().subtract(1, 'day').startOf('day'), 'd') == true) {
    return 'Yesterday' + ' ' + getTimeWithColon(displayDate);
  } else {
    return getFullDateTime(displayDate, seperator);
  }
};

export const checkDateAndTimeIsAfter = (currentDate: any, selectedDate: any, time: string) => {
  let selectedDateAndTime = selectedDate;
  if (selectedDateAndTime) {
    selectedDateAndTime = selectedDateAndTime instanceof String ? selectedDateAndTime : converDateToBackendFormat(selectedDateAndTime);
    const splitDate = selectedDateAndTime.split(' ');
    splitDate[1] = time + ':00';
    selectedDateAndTime = splitDate.join(' ');
  }
  return getDayjsDate(currentDate).isAfter(getDayjsDate(selectedDateAndTime));
};

export const checkIsAfterDayJs = (date1: Date, date2: Date) => {
  return dayjs(date1).tz(defaultTimeZone).isAfter(dayjs(date2).tz(defaultTimeZone));
};

export const checkDateIsOverDue = (date: string) => {
  return dayjs().isAfter(dayjs(date).tz(defaultTimeZone).add(24, 'hour'));
};

export const formatDate = (createdTs: string) => {
  const date = dayjs(createdTs);
  const dayOfMonth = date.date();
  const suffixes = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];
  const suffix = dayOfMonth > 10 && dayOfMonth < 14 ? 'th' : suffixes[dayOfMonth % 10];

  return date.format(`MMM D[${suffix}] [at] h:mm A`);
};

export const getGreetings = () => {
  const now = new Date();
  const hours = now.getHours();
  if (hours < 12) {
    return 'GOOD_MORNING';
  }
  if (hours < 18) {
    return 'GOOD_AFTERNOON';
  }
  return 'GOOD_EVENING';
};
