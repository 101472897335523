import { Theme } from '@material-ui/core';
import RFPIOPalette from './RFPIOPalette';
import { getColorValue } from './ColorUtils';
import { isFeatureEnabled } from './FeaturePreference';
const mModules: string[] = ['PROJECT', 'E_SIGNATURE', 'INTAKE', 'RESPONSE_TEMPLATES', 'MY_ACCOUNT', 'MY_WORK', 'ORGANIZATION_SETTINGS', 'CONTENT_ANALYSIS'];
export const darkModeV2Modules: string[] = ['CONTENT_ANALYSIS'];

export const ApplyLightTheme = (theme: Theme, setTheme: any, module: string) => {
  const isRequest = isFeatureEnabled('request');
  const isInvitedVendor = isFeatureEnabled('invited_vendor');

  const isDarkModeEnabled = mModules.includes(module) && !(isInvitedVendor || isRequest);
  if (isDarkModeEnabled) {
    const tempTheme: any = localStorage.getItem('themeMode');
    theme.palette.type = tempTheme ? tempTheme : 'light';
  } else {
    theme.palette.type = 'light';
  }
  const palette: RFPIOPalette = {
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
    primaryDark: getColorValue(theme.palette.primary.main, 'dark'),
    accentColor: getColorValue(theme.palette.primary.main, 'accent'),
    type: theme.palette.type,
    darkV2: darkModeV2Modules.includes(module)
  };
  setTheme(palette);
};

export const changeTheme = (theme: Theme, setTheme: any, module?: string) => {
  const tempMode = theme.palette.type;
  theme.palette.type = theme.palette.type === 'light' ? 'dark' : 'light';
  const palette: RFPIOPalette = {
    primary: theme.palette.primary.main,
    primaryDark: getColorValue(theme.palette.primary.main, 'dark'),
    accentColor: getColorValue(theme.palette.primary.main, 'accent'),
    secondary: theme.palette.secondary.main,
    type: theme.palette.type,
    darkV2: module && darkModeV2Modules.includes(module)
  };
  setTheme(palette);
  localStorage.setItem('themeMode', palette.type);
  document.body.classList.remove(`rfp-${tempMode}`);
  document.body.classList.add(`rfp-${theme.palette.type}`);
};

export const userAvatarImg = (key?: string) => {
  return `${window.constants.baseUrl}login/download/${key}/pic.png`;
};

export const getUserAvatarColor = (users: any) => {
  if (!users) {
    return '';
  }
  let allUserColors: string[] = [];

  if (users && users.length != 0) {
    users.forEach((user: any) => {
      let hash = 0;
      for (let i = 0; i < user.length; i++) {
        hash = user.charCodeAt(i) + ((hash << 5) - hash);
      }

      const c = (hash & 0x00ffffff).toString(16).toUpperCase();
      const hex = '#' + '00000'.substring(0, 6 - c.length) + c;

      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      const mod = user.length % 3;
      let r = parseInt(result[((mod + 1) % 3) + 1], 16);
      let g = parseInt(result[((mod + 2) % 3) + 1], 16);
      let b = parseInt(result[((mod + 3) % 3) + 1], 16);
      const max = Math.max(r, g, b);
      r = max === r && r > 150 ? r % 150 : r;
      g = max === g && g > 150 ? g % 150 : g;
      b = max === b && b > 150 ? b % 150 : b;
      const rgb = `rgb(${r},${g},${b})`;

      allUserColors.push(rgb);
    });
    return allUserColors;
  }
};

export enum ThemeMode {
  DARK = 'dark',
  LIGHT = 'light',
  darkV2 = 'darkV2'
}

export const agGridStyles = {
  agGridRow: {
    borderBottom: {
      light: '1px solid #dee2eb',
      dark: '1px solid #64676A',
      darkV2: '1px solid #494A52'
    },
    hoverBoxShadow: {
      light: '0px 2px 10px #0000001A',
      dark: 'none',
      darkV2: 'none'
    },
    hoverZIndex: {
      light: 1,
      dark: 'none',
      darkV2: 'none'
    },
    focusBg: {
      light: '#DCF0FF',
      dark: '#31333E',
      darkV2: '#335362'
    },
    borderRight: {
      light: '',
      dark: '',
      darkV2: '1px solid #494A52'
    }
  },
  agGridCell: {
    color: {
      light: '#181D1F',
      dark: '#E4E4E4',
      darkV2: '#ECECEE'
    }
  },
  agGridHeader: {
    backgroundColor: {
      light: '#F6F8FA !important',
      dark: '#282828',
      darkV2: '#36373C'
    },
    borderBottomColor: {
      light: '#DEE5EE',
      dark: '#505050 !important',
      darkV2: '#60616E !important'
    },
    cellResize: {
      light: '#dee5ee !important',
      dark: '#494A52 !important',
      darkV2: '#505050 !important'
    },
    cellBorderRight: {
      light: '1px solid #ECF0F5',
      dark: '1px solid #505050 !important',
      darkV2: '0.8px solid #494A52 !important'
    }
  },
  agGridChecked: {
    after: {
      light: '#1C3443 !important',
      dark: '#E4E4E4 !important',
      darkV2: '#E4E4E4 !important'
    }
  },
  agGridThemeAlpine: {
    rowSelectedBg: {
      light: '#cde5f7 !important',
      dark: '#31333e !important',
      darkV2: '#335362 !important'
    },
    rowSelectedBorderBottom: {
      light: '1px solid #BBCDDB',
      dark: '1px solid rgba(255,255,255,0.3)',
      darkV2: '1px solid rgba(255,255,255,0.3)'
    }
  },
  agGridWeblit: {
    scrollBg: {
      light: '#BBBBBB !important',
      dark: '#6a6a6a !important',
      darkV2: '#6a6a6a !important'
    }
  },
  agGridPinned: {
    right: {
      borderRight: {
        light: '1px solid #dde2eb',
        dark: '1px solid #64676A',
        darkV2: '1px solid #64676A'
      },
      borderLeft: {
        darkV2: '1px solid #60616E !important',
        light: '',
        dark: ''
      }
    },
    left: {
      borderRight: {
        darkV2: '#60616E !important',
        light: '',
        dark: ''
      }
    }
  },
  agPagination: {
    paginationActionBg: {
      light: 'white',
      dark: '#31333E',
      darkV2: '#494A52'
    },
    paginationColor: {
      light: '#2F4354',
      dark: '#C2C2C7',
      darkV2: '#ECECEE'
    },
    paginationBorderColor: {
      light: '1px solid #A5ADBA',
      dark: '1px solid #64676A',
      darkV2: '1px solid #7B7D8C'
    }
  },
  agOnHoverColor: {
    light: '',
    dark: '',
    darkV2: '#202129'
  },
  agGridBgColor: {
    light: '#E1E5EA',
    dark: '#121212',
    darkV2: '#28292F'
  }
};

export const commonStyles = {
  border: {
    darkV2: '1px solid #36373C',
    light: '',
    dark: '1px solid #64676A'
  },
  color: {
    darkV2: '#ECECEE',
    light: '#000000',
    dark: '#E4E4E4'
  },
  borderOutline: {
    darkV2: '1px solid #494A52',
    light: '',
    dark: '1px solid #64676A'
  },
  avatarNumericColor: {
    darkV2: '#C2C2C7',
    light: '#565353',
    dark: '#565353'
  },
  avatarNumericBg: {
    darkV2: '#36373C',
    light: '#E0E7F1',
    dark: '#E0E7F1'
  },
  avatarNumericBorder: {
    darkV2: '',
    light: '1px solid #FFF',
    dark: '1px solid #FFF'
  },
  tooltipBgColor: {
    light: '#1C3443',
    dark: '#d6d6d6',
    darkV2: '#494A52'
  },
  tooltipTextColor: {
    light: 'none',
    dark: '#121212',
    darkV2: '#ECECEE'
  },
  cancelButtonColor: {
    light: '#172B4D',
    dark: '#9C9DA7',
    darkV2: '#9C9DA7'
  },
  alertBodyTextColor: {
    light: '`#172B4D`, ',
    dark: '#FFF',
    darkV2: '#C2C2C7'
  },
  alertBodyTitleColor: {
    light: '`#172B4D`, ',
    dark: '#FFF',
    darkV2: '#ECECEE'
  },
  grey800: {
    light: '#3d3d3d',
    dark: '',
    darkV2: '#ECECEE'
  },
  borderBottom: {
    light: '1px solid #ededed',
    dark: '',
    darkV2: '1px solid #494A52'
  },
  grey900: {
    light: '#1f1f1f',
    dark: '',
    darkV2: '#ECECEE'
  },
  radioIconColor: {
    light: 'none',
    dark: '#e4e4e4 !important',
    darkV2: 'none'
  },
  tabTextColor: {
    light: '#000',
    dark: '#fff',
    darkV2: '#ECECEE'
  },
  textBoxBorderColor: {
    light: '#D9D9D9',
    dark: 'rgba(255, 255, 255, 0.23)',
    darkV2: '#36373C'
  },
  selectBorder: {
    darkV2: '1px solid #494A52',
    light: '1px solid #ced4da',
    dark: '1px solid #64676A'
  },
  dialogueTextColor: {
    light: '#2F4354 !important',
    dark: '#ECECEE !important',
    darkV2: '#C2C2C7 !important'
  },
  addFilterBg: {
    light: '#ffffff !important',
    dark: '#121212 !important',
    darkV2: '#494A52 !important'
  },
  advanceFilterColor: {
    light: 'none',
    dark: '#2a2b33',
    darkV2: '#28292F'
  },
  searchFilterbg: {
    light: '#ffffff',
    dark: '#31333E',
    darkV2: '#28292F'
  },
  searchIconColor: {
    light: '#0000008a',
    dark: '#E4E4E4',
    darkV2: '#9C9DA7'
  },
  searchBorderColor: {
    light: '1px solid #DEE5EE',
    dark: '1px solid rgba(255, 255, 255, 0.23)',
    darkV2: '1px solid #36373C'
  },
  scrollBarColor: {
    light: '#bbbbbb white',
    dark: '#494A52 transparent',
    darkV2: '#494A52 transparent'
  },
  advBorderColor: {
    light: '#D9D9D9',
    dark: 'rgba(255,255,255,0.23)',
    darkV2: '#36373C'
  },
  closeIconColor: {
    light: '#D62D2D',
    dark: '#FF711F',
    darkV2: '#E66B6B'
  },
  commentBg: {
    light: '#ffffff',
    dark: '#31333E',
    darkV2: '#36373C'
  },
  commentBorder: {
    darkV2: '1px solid #60616E',
    light: '1px solid silver',
    dark: '1px solid #64676A'
  },
  commentIconColor: {
    light: '#2F4354',
    dark: '#E4E4E4',
    darkV2: '#9C9DA7'
  },
  commentAttachmentBorder: {
    light: '#B5B5B5',
    dark: '#434348',
    darkV2: '#60616E'
  },
  commentClose: {
    light: '#1C3443',
    dark: '#ffffff',
    darkV2: '#9C9DA7'
  },
  attachmentBorder: {
    light: '1px solid #efefef !important',
    dark: '1px solid #efefef !important',
    darkV2: '1px solid #36373C !important'
  },
  autoCompleteOptionBorder: {
    light: '1px solid #f3f3f3',
    dark: '',
    darkV2: '1px solid #494A52'
  },
  transparentScroll: {
    light: '',
    dark: 'transparent transparent',
    darkV2: 'transparent transparent'
  },
  headerBg: {
    light: '#fff',
    dark: '#121212',
    darkV2: '#191B27'
  },
  loaderFill: {
    light: '#000',
    dark: '#fff',
    darkV2: '#60616E'
  },
  accordionBg: {
    light: 'none',
    dark: '#434654',
    darkV2: '#28292F'
  },
  deleteColor: {
    light: '#C30000',
    dark: '#FF711F',
    darkV2: '#E66B6B'
  }
};

export const defaultStyle = {
  textPrimary: {
    light: '#2F4354',
    dark: '#E4E4E4',
    darkV2: '#C2C2C7'
  },
  paperBg: {
    light: '#ffffff',
    dark: '#202129',
    darkV2: '#191B27'
  },
  dialogPaperBg: {
    light: '#ffffff',
    dark: '#31333E',
    darkV2: '#191B27'
  },
  popOverPaperBg: {
    light: '#ffffff',
    dark: '#31333E',
    darkV2: '#28292F'
  },
  bodyBg: {
    light: '#E1E5EA',
    dark: '#121212',
    darkV2: '#121212'
  },
  tableHeaderBg: {
    light: '#E1E5EA',
    dark: '#282828',
    darkV2: '#36373C'
  },
  paperTextColor: {
    light: '#2F4354',
    dark: '#E4E4E4',
    darkV2: '#ECECEE'
  }
};
