import { createContextStore } from 'easy-peasy';
import GuestSectionStoreImpl from './GuestSectionStoreImpl';
import GuestIdStoreImpl from './GuestIdStoreImpl';
import GuestPageLayoutStoreImpl from './GuestPageLayoutStoreImpl';
import GuestStore from '@interfaces/GuestStoreInterface';
import GuestCompanyStoreImpl from './GuestCompanyStoreImpl';
import GuestSaveAnswerThunkImpl from './GuestSaveAnswerThunkImpl';

const store: GuestStore = {
  guestId: GuestIdStoreImpl,
  guestSection: GuestSectionStoreImpl,
  guestPageLayout: GuestPageLayoutStoreImpl,
  companyDatePreferences: GuestCompanyStoreImpl,
  guestSaveAnswerThunkImpl: GuestSaveAnswerThunkImpl
};

export default createContextStore<GuestStore>(store, { name: 'Guest Store' });
