import { DialogTitleProps, Grid, GridJustification, Typography } from '@material-ui/core';
import RfpButton from '@rfp-components/RfpButton';
import { makeStyles } from '@material-ui/core/styles';
import RfpDialog, { RfpDialogContent, RfpDialogTitle } from '@rfp-components/RfpDialog';
import warningImage from '@images/profile-images/profile_nousers.svg';
import { useTranslation } from 'react-i18next';
import React from 'react';
import clsx from 'clsx';

export interface dialogContentVO {
  title?: HTMLElement | string | Element;
  header?: HTMLElement | string;
  message?: HTMLElement | string | React.JSX.Element;
  option1?: string;
  option2: string;
  option1Callback?: () => void;
  option2Callback?: () => void;
  isImgNeeded?: boolean;
  styleClass?: string;
  isButtonStyleNeeded?: boolean;
  isSecondaryBtn?: boolean;
  isDangerBtn?: boolean;
  disableButton?: boolean;
  isSecondRedBtnOutLine?: boolean;
  headerIconClass?: string;
  actionButtonAlignment?: GridJustification;
  messageClass?: string;
  contentClass?: string;
  isSecondaryGreenBtn?: boolean;
  secondBtnVariant?: 'outlined' | 'contained';
}

interface ProposalWarningDialogProps {
  openDialog: boolean;
  onClose: (check?: boolean, isOption2CallBack?: boolean) => void;
  dialogContentMap: dialogContentVO;
  hideOption1?: boolean;
  needTextAlign?: boolean;
}

/**
 * @typedef {Object} dialogContentVO
 * @property {HTMLElement | string | Element} [title] - The title of the dialog.
 * @property {HTMLElement | string} [header] - The header of the dialog.
 * @property {HTMLElement | string} [message] - The message content of the dialog.
 * @property {string} [option1] - Text for the first option button.
 * @property {string} option2 - Text for the second option button.
 * @property {() => void} [option1Callback] - Callback function for the first option button.
 * @property {() => void} [option2Callback] - Callback function for the second option button.
 * @property {boolean} [isImgNeeded] - Flag to indicate if an image is needed.
 * @property {string} [styleClass] - Custom style class for the dialog.
 * @property {boolean} [isButtonStyleNeeded] - Flag to indicate if button styles are needed.
 * @property {boolean} [isSecondaryBtn] - Flag to indicate if there is a secondary button.
 * @property {boolean} [isDangerBtn] - Flag to indicate if the button is a danger button.
 * @property {boolean} [disableButton] - Flag to disable the button.
 * @property {boolean} [isSecondRedBtnOutLine] - Flag to indicate if the second button is an outlined red button.
 * @property {string} [headerIconClass] - Custom class for the header icon.
 * @property {GridJustification} [actionButtonAlignment] - Alignment for the action buttons.
 * @property {string} [messageClass] - Custom class for the message content.
 * @property {string} [contentClass] - Custom class for the dialog content.
 * @property {boolean} [isSecondaryGreenBtn] - Flag to indicate if there is a secondary green button.
 * @property {'outlined' | 'contained'} [secondBtnVariant] - Variant for the second button.
 
 * Renders a warning dialog with custom content and actions.
 * 
 * @param {ProposalWarningDialogProps & DialogTitleProps} props - The props for the warning dialog component.
 * @returns {JSX.Element} The rendered warning dialog component.
 */
export default function RfpNewWarningDialog(props: ProposalWarningDialogProps & DialogTitleProps) {
  const { openDialog, dialogContentMap, onClose, hideOption1, needTextAlign, children } = props;
  const { t } = useTranslation('translations');
  const classes = useStyles();

  return (
    <>
      <RfpDialog
        dataQa='WARNING_DAILOG'
        maxWidth={dialogContentMap.isImgNeeded ? 'md' : 'sm'}
        classes={{ paper: classes.dialogPaper, root: classes.dialogRoot }}
        onClick={event => event.stopPropagation()}
        open={openDialog}>
        <RfpDialogTitle
          dataQa='WARNING_DAILOG_TITLE'
          className={clsx('pb-0-imp pr-20', dialogContentMap.header ? '' : classes.noBorderBottom)}
          onClose={() => {
            onClose(true);
          }}>
          <Grid container={dialogContentMap.headerIconClass} alignItems='center'>
            {dialogContentMap.headerIconClass && <span className={dialogContentMap.headerIconClass} />}
            {dialogContentMap.header ?? ''}
          </Grid>
        </RfpDialogTitle>
        <RfpDialogContent className={clsx('pt-6', dialogContentMap.contentClass ?? 'pb-40')}>
          {dialogContentMap.isImgNeeded && (
            <Grid alignItems='center' container direction='column'>
              <img src={warningImage} alt='PPB_warning' />
            </Grid>
          )}
          <Grid alignItems='center' container direction='column' className={`mt-10 pl-40 pr-40 ${dialogContentMap.messageClass}`}>
            <span className='inter-semi-bold font-18-imp w-500 font600 text-align-center'>{dialogContentMap.title}</span>
            {dialogContentMap.message && <span className='inter-regular display-flex text-align-center justifyContentCenter w-500 font-16-imp pt-10'>{dialogContentMap.message}</span>}
          </Grid>
          {children && <Grid className='font-inter pt-5 pl-40'>{children}</Grid>}
          <Grid className={clsx(dialogContentMap.styleClass, 'pt-25')} justify={dialogContentMap.actionButtonAlignment ? dialogContentMap.actionButtonAlignment : 'center'} container direction='row'>
            <Grid item>
              {!hideOption1 && (
                <RfpButton
                  dataQa='SAVE_DRAFT'
                  className={clsx(
                    'border-radius-4 pl-16-imp inter-regular-imp pt-8 pr-16 pb-8',
                    classes.buttonLeftSize,
                    dialogContentMap.isButtonStyleNeeded ? classes.BtnGreenOutline : dialogContentMap.isSecondaryBtn ? classes.secondaryButton : classes.BtnRedOutline,
                    hideOption1 && 'display-none-imp'
                  )}
                  variant={'outlined'}
                  color={'primary'}
                  disabled={false}
                  onClick={() => {
                    dialogContentMap.option1Callback && dialogContentMap.option1Callback();
                    onClose();
                  }}>
                  <span className='font-14 font600'>{t(dialogContentMap.option1)}</span>
                </RfpButton>
              )}
            </Grid>
            <Grid item className='pl-10'>
              <RfpButton
                dataQa='DISCARD'
                className={clsx(
                  'border-radius-4 inter-regular-imp pb-10-imp',
                  dialogContentMap.isDangerBtn && classes.buttonDanger,
                  dialogContentMap.isSecondaryGreenBtn
                    ? [classes.BtnGreenOutline, classes.padding_8_32]
                    : dialogContentMap.isSecondRedBtnOutLine
                    ? `${classes.BtnRedOutline} ${classes.padding_8_32}`
                    : `white pt-10 ${classes.buttonRightSize}`
                )}
                color='primary'
                variant={dialogContentMap.secondBtnVariant || (dialogContentMap.isSecondRedBtnOutLine ? 'outlined' : 'contained')}
                disabled={dialogContentMap.disableButton}
                onClick={() => {
                  dialogContentMap.option2Callback && dialogContentMap.option2Callback();
                  onClose(true, true);
                }}>
                <span className='font-14 font600'>{t(dialogContentMap.option2)}</span>
              </RfpButton>
            </Grid>
          </Grid>
        </RfpDialogContent>
      </RfpDialog>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    padding: '10px 0px 0px 0px'
  },
  noBorderBottom: {
    borderBottom: 'none !important'
  },
  BtnRedOutline: {
    '&.MuiButton-outlinedPrimary': {
      color: '#E03F3C',
      border: `2px solid #E03F3C`,
      '&:hover': {
        backgroundColor: `#E03F3C30`
      }
    }
  },
  BtnGreenOutline: {
    '&.MuiButton-outlinedPrimary': {
      color: '#9CC83B',
      border: `2px solid #9CC83B`,
      '&:hover': {
        backgroundColor: `#E6FFE6`
      }
    }
  },
  buttonRightSize: {
    padding: '10px 32px !important'
  },
  buttonLeftSize: {
    padding: '8px 16px !important'
  },
  buttonDanger: {
    background: '#E03F3C',
    '&:hover': {
      backgroundColor: `#E03F4C`
    }
  },
  secondaryButton: {
    '&.MuiButton-outlinedPrimary': {
      color: '#3D3D3D',
      border: '2px solid #3D3D3D'
    }
  },
  padding_8_32: {
    padding: '8px 32px !important'
  }
}));
