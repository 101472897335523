import { action } from 'easy-peasy';
import StyleStore from '@interfaces/StyleStore';

const StyleStoreImpl: StyleStore = {
  fontObjectList: [],
  fontFamilyMap: {},
  tinyMCEStyleList: [],
  tinyMCEStyleMap: {},
  setfontObjectList: action((state, entry) => {
    state.fontObjectList = entry;
  }),
  setfontFamilyMap: action((state, entry) => {
    state.fontFamilyMap = entry;
  }),
  setTinyMCEStyleList: action((state, entry) => {
    state.tinyMCEStyleList = entry;
  }),
  setTinyMCEStyleMap: action((state, entry) => {
    state.tinyMCEStyleMap = entry;
  }),
  setTinyMceStyle: action((state, entry) => {
    state.tinyMCEStyleList = entry.tinyMCEStyleList;
    state.tinyMCEStyleMap = entry.tinyMCEStyleMap;
  }),
  setStyleData: action((state, entry) => {
    state.tinyMCEStyleMap = entry.tinyMCEStyleMap;
    state.tinyMCEStyleList = entry.tinyMCEStyleList;
    state.fontFamilyMap = entry.fontFamilyMap;
    state.fontObjectList = entry.fontObjectList;
  })
};

export default StyleStoreImpl;
