import UserDetailsStore from '@interfaces/UserDetailsStore';
import { action, thunk } from 'easy-peasy';
import { userDetailsVO } from '@interfaces/Models/UserDetailsVO';
import { getUserInfoList } from '@services/GeneralService';
import { TRIAL } from '../constants/TrialFeatureConstants';
import CompanyCollectionVO from '@interfaces/Models/CompanyCollectionVO';

const UserDetailsStoreImpl: UserDetailsStore = {
  userDetails: userDetailsVO,
  reloadCompanyDetails: false,
  isUserDetailsLoaded: false,
  userTrialFeatureDetailsMap: {},
  trialFeatureThresholdMap: {},
  setUserDetails: action((state, entry) => {
    state.userDetails = entry;
  }),
  setSavedSearchVOList: action((state, entry) => {
    state.userDetails.savedSearchVOList = entry;
    if (!state.userDetails.alSavedSearchVOList) {
      state.userDetails.alSavedSearchVOList = [];
    }
    if (!state.userDetails.moderationSavedSearchVOList) {
      state.userDetails.moderationSavedSearchVOList = [];
    }
    entry.forEach(savedSearch => {
      if ('ANSWER_LIBRARY' === savedSearch.type) {
        state.userDetails.alSavedSearchVOList?.push(savedSearch);
      } else if ('ANSWER_LIBRARY_MODERATION' === savedSearch.type) {
        state.userDetails.moderationSavedSearchVOList?.push(savedSearch);
      } else {
        // do nothing
      }
    });
  }),
  // setTeamsList: action((state, entry) => {
  //   state.userDetails.teamsList = entry;
  // }),
  // setUserVOList: action((state, entry) => {
  //   state.userDetails.userVOList.users = entry;
  // }),
  trialFeatureRefreshFlag: {
    featureDetails: false,
    featureThreshold: false
  },
  setTrialFeatureRefreshFlag: action((state, entry) => {
    state.trialFeatureRefreshFlag[entry] = true;
  }),
  setCollectionList: action((state, entry) => {
    state.userDetails.collectionList = entry;
  }),
  addCompanyCollectionData: action((state, entry) => {
    state.userDetails.collectionList.push(entry);
  }),
  updateCompanyCollectionData: action((state, entry) => {
    const updateIds = Object.keys(entry);
    state.userDetails.collectionList = state.userDetails.collectionList.map((coll: CompanyCollectionVO) => {
      return updateIds.includes(coll.id) ? entry[coll.id] : coll;
    });
  }),
  setCompanyPolicyMap: action((state, entry) => {
    state.userDetails.companyPolicyMap = entry;
  }),
  setVendorStageList: action((state, entry) => {
    state.userDetails.vendorStageList.dataList = entry;
  }),
  setReloadCompanyDetails: action((state, entry) => {
    state.reloadCompanyDetails = entry;
  }),
  setIsUserDetailsLoaded: action((state, entry) => {
    state.isUserDetailsLoaded = entry;
  }),
  setUxPref: action((state, entry) => {
    state.userDetails.userInfoVO.uiPreferences[entry] = 'V2';
  }),
  setUserInfoVO: action((state, entry) => {
    state.userDetails.userInfoVO = entry;
  }),
  updateCompanyPreferencesVO: action((state, entry) => {
    state.userDetails.companyPreferencesVO = entry;
  }),
  setCompanyInfo: action((state, entry) => {
    state.userDetails.companyInfoVO = entry;
  }),
  updateCompanyLogo: action((state, entry) => {
    const logo = (entry.modelVal + 'Logo') as 'smallLogo' | 'largeLogo';
    state.userDetails.companyInfoVO[logo] = entry.value;
  }),
  setBusinessUnits: action((state, entry) => {
    state.userDetails.businessUnits = entry;
  }),
  setFeaturePreference: action((state, entry) => {
    state.userDetails.companyPreferencesVO.featurePreferences = {
      ...state.userDetails.companyPreferencesVO.featurePreferences,
      ...entry
    };
  }),
  setUiPreference: action((state, entry) => {
    state.userDetails.userInfoVO.uiPreferences = entry;
  }),
  setPreferenceData: action((state, entry) => {
    state.userDetails.companyPreferencesVO.data = {
      ...state.userDetails.companyPreferencesVO.data,
      ...entry
    };
  }),
  setFeaturePreferenceWithData: action((state, entry) => {
    state.userDetails.companyPreferencesVO.featurePreferences = {
      ...state.userDetails.companyPreferencesVO.featurePreferences,
      ...entry.featurePreference
    };
    state.userDetails.companyPreferencesVO.data = {
      ...state.userDetails.companyPreferencesVO.data,
      ...entry.data
    };
  }),
  addFeaturePreference: action((state, entry) => {
    state.userDetails.companyPreferencesVO.featurePreferences[entry] = '1';
  }),
  setUserTrialFeatureDetailsMap: action((state, entry) => {
    state.userTrialFeatureDetailsMap = entry;
  }),
  setTrialFeatureThresholdMap: action((state, entry) => {
    state.trialFeatureThresholdMap = entry;
  }),
  updateTrialCount: action((state, entry) => {
    const { trialFeatureName, type } = entry;
    if (trialFeatureName) {
      const paidFeatureName = trialFeatureName.replace(TRIAL, '');
      const isPaidFeatureEnabled = state.userDetails.companyPreferencesVO.featurePreferences[paidFeatureName] === '1';
      const isUserEnabledTrialFeature = state.userTrialFeatureDetailsMap?.hasOwnProperty(trialFeatureName);
      if (!isPaidFeatureEnabled && isUserEnabledTrialFeature) {
        if (type === 'INCREASE') {
          state.userTrialFeatureDetailsMap[trialFeatureName].createdCount = state.userTrialFeatureDetailsMap[trialFeatureName].createdCount + 1;
        } else {
          state.userTrialFeatureDetailsMap[trialFeatureName].createdCount = state.userTrialFeatureDetailsMap[trialFeatureName].createdCount - 1;
        }
      }
    }
  })
};

export default UserDetailsStoreImpl;
