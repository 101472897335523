import UserVO from '@interfaces/Models/UserVO';

const updateList = (dataList: string[] | undefined, teamId: string, type: string) => {
  if (!dataList) {
    dataList = [];
  }
  const idx = dataList.indexOf(teamId);
  if (type === 'ADD' && idx < 0) {
    dataList.push(teamId);
  } else if (type === 'REMOVE' && idx >= 0) {
    dataList.splice(idx, 1);
  } else {
    //do nothing
  }
};

export const updateTeamIds = (user: UserVO, teamId: string, type: string) => {
  if (user) {
    updateList(user.teamIds, teamId, type);
  }
};

export const updateTeamIdInBUMap = (buBaseduserList: { [key: string]: string[] }, keystoBeUpdated: string[], teamId: string, type: string) => {
  keystoBeUpdated.forEach(key => {
    let buUsersList = buBaseduserList[key];
    updateList(buUsersList, teamId, type);
  });
};
