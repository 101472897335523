import { IMetaData, MasterDataVO } from '@org/interfaces/request-management/OrgRequests';
import React from 'react';
import { IOrgCustomFields } from '@org/interfaces/store/response-projects/OrgProjectStore';
import { configureCustomField, getCustomFieldByEntityType } from '../OrgMetaDataUtils';
import CustomFieldVO from '@interfaces/Models/CustomFieldVO';
import i18next from 'i18next';

export const requestColumnData = {
  value: {
    name: 'value',
    displayName: i18next.t('TYPE'),
    cellRenderer: 'TypeRenderer',
    enable: true,
    lockPinned: true,
    lockPosition: true,
    minWidth: 200,
    flex: 1,
    rowDrag: true,
    resizable: true
  },
  status: {
    name: 'status',
    displayName: i18next.t('STATUS'),
    cellRenderer: 'StatusRenderer',
    enable: true,
    flex: 1,
    minWidth: 100,
    lockPosition: true
  },
  updatedBy: {
    name: 'updatedBy',
    displayName: i18next.t('UPDATED_BY'),
    cellRenderer: 'UserAvatarRenderer',
    enable: true,
    flex: 1,
    minWidth: 200,
    lockPosition: true
  },
  updatedDate: {
    name: 'updatedDate',
    displayName: i18next.t('UPDATED_DATE'),
    cellRenderer: 'DateRenderer',
    enable: true,
    flex: 1,
    minWidth: 200,
    lockPosition: true
  },
  RequestTableMenuRenderer: {
    name: 'RequestTableMenuRenderer',
    displayName: '',
    cellRenderer: 'RequestTableMenuRenderer',
    enable: true,
    flex: 1,
    width: 90,
    minWidth: 90,
    pinned: 'right',
    resizable: false,
    lockPinned: true
  }
};

export const getIndexValue = (prevIndex: number, currentIndex: number) => {
  const val: boolean = prevIndex < currentIndex;
  return val ? [currentIndex, currentIndex + 1] : [currentIndex - 1, currentIndex];
};

export interface IDeleteRequestData {
  type: string;
  primaryBU?: string | undefined;
  id: string;
  value: string;
  index: number;
}

export const evaluationOptions: { key: string; value: string }[] = [
  {
    key: 'WEIGHTED_SCORE',
    value: 'WEIGHTED_SCORE'
  },
  {
    key: 'NON_WEIGHTED_SCORE',
    value: 'NON_WEIGHTED_SCORE'
  }
];

export const scoringOptions: { key: string; value: string }[] = [
  {
    key: 'INDIVIDUAL_SCORING',
    value: 'INDIVIDUAL'
  },
  {
    key: 'SHARED_SCORING',
    value: 'SHARING'
  }
];

export const requestTableOptions: { [key: string]: { [key: string]: string } } = {
  request_type: {
    type: 'TYPE',
    value: 'REQUEST_TYPE',
    addMessage: 'REQUEST_TYPE_ADDED',
    editMessage: 'REQUEST_TYPE_EDITED',
    deleteMessage: 'REQUEST_TYPE_DELETED',
    deleteAlertMessage: 'DELETE_REQUEST_TYPE_ALERT',
    headerText: 'EDIT_REQUEST_TYPE',
    createText: 'CREATE_REQUEST_TYPE',
    placeHolderText: 'ENTER_NAME_OF_THE_TYPE',
    emptyRequestDataMessage: 'NO_REQUEST_TYPES_AVAILABLE'
  },
  request_stage: {
    type: 'STAGE',
    value: 'REQUEST_STAGE',
    addMessage: 'REQUEST_STAGE_ADDED',
    editMessage: 'REQUEST_STAGE_EDITED',
    deleteMessage: 'REQUEST_STAGE_DELETED',
    deleteAlertMessage: 'DELETE_STAGE_ALERT',
    headerText: 'EDIT_REQUEST_STAGE',
    createText: 'CREATE_REQUEST_STAGE',
    placeHolderText: 'ENTER_NAME_OF_THE_STAGE',
    emptyRequestDataMessage: 'NO_REQUEST_STAGES_AVAILABLE'
  },
  vendor_stage: {
    type: 'STAGE',
    value: 'RESPONDENT_STAGE',
    addMessage: 'VENDOR_STAGE_ADDED',
    editMessage: 'VENDOR_STAGE_EDITED',
    deleteMessage: 'VENDOR_STAGE_DELETED',
    deleteAlertMessage: 'DELETE_VENDOR_STAGE_ALERT',
    headerText: 'EDIT_VENDOR_STAGE',
    createText: 'CREATE_RESPONDER_STAGE',
    placeHolderText: 'ENTER_NAME_OF_THE_STAGE',
    emptyRequestDataMessage: 'NO_RESPONDER_STAGES_AVAILABLE'
  }
};

export const getRequestMasterData = (data: MasterDataVO, sortOrder: number, type: string, isRequestSettingsNeeded: boolean) => {
  return {
    id: data?.id,
    status: data?.status || 'ACTIVE',
    value: data?.value || '',
    type: data?.type || type,
    lowerCase: data?.lowerCase,
    projectFormat: data?.projectFormat || 'QUESTION_AND_ANSWER',
    sortingOrder: data?.sortingOrder || sortOrder,
    foregroundColor: data?.foregroundColor || '#fff',
    backgroundColor: data?.backgroundColor || '#29B6F6',
    ...(isRequestSettingsNeeded && {
      settingsMap: {
        needQuestionnaire: data?.settingsMap?.needQuestionnaire ?? true,
        evaluationType: data?.settingsMap?.evaluationType || 'WEIGHTED_SCORE',
        needScoring: data?.settingsMap?.needScoring ?? true,
        scoringOption: data?.settingsMap?.scoringOption || 'INDIVIDUAL',
        evaluationCriteria: data?.settingsMap?.evaluationCriteria || 'INDIVIDUAL_SCORING',
        publicRFP: data?.settingsMap?.publicRFP ?? false
      }
    })
  };
};

export const requestStageColumnData = {
  value: {
    name: 'value',
    displayName: i18next.t('STAGE'),
    cellRenderer: 'TypeRenderer',
    enable: true,
    lockPinned: true,
    lockPosition: true,
    minWidth: 200,
    flex: 1,
    rowDrag: true,
    resizable: true
  },
  status: {
    name: 'status',
    displayName: i18next.t('STATUS'),
    cellRenderer: 'StatusRenderer',
    enable: true,
    flex: 1,
    minWidth: 100,
    lockPosition: true
  },
  updatedBy: {
    name: 'updatedBy',
    displayName: i18next.t('UPDATED_BY'),
    cellRenderer: 'UserAvatarRenderer',
    enable: true,
    flex: 1,
    minWidth: 200,
    lockPosition: true
  },
  updatedDate: {
    name: 'updatedDate',
    displayName: i18next.t('UPDATED_DATE'),
    cellRenderer: 'DateRenderer',
    enable: true,
    flex: 1,
    minWidth: 200,
    lockPosition: true
  },
  RequestTableMenuRenderer: {
    name: 'RequestTableMenuRenderer',
    displayName: '',
    cellRenderer: 'RequestTableMenuRenderer',
    enable: true,
    flex: 1,
    width: 90,
    minWidth: 90,
    pinned: 'right',
    resizable: false,
    lockPinned: true
  }
};

export interface ImenuItems {
  icon: React.JSX.Element;
  label: string;
  onClick: () => Promise<void>;
}

export const requestData: { [key: string]: string } = {
  request_type: 'requestTypeList',
  request_stage: 'requestStageList',
  vendor_stage: 'vendorStageList'
};

export const requestDataListKey: { [key: string]: string } = {
  REQUEST_TYPE: 'requestTypeList',
  REQUEST_STAGE: 'requestStageList',
  RESPONDENT_STAGE: 'vendorStageList'
};

export const getCustomFieldsByRequestType = (currentMetaData: IMetaData | undefined) => {
  const customFields: IOrgCustomFields = getCustomFieldByEntityType('REQUEST', currentMetaData?.rfpType, false);
  const groupByIndexed: string[] = [];

  customFields.companyCustomFieldList = (customFields.companyCustomFieldList || []).filter(item => {
    return item && (!item.defaultMergeTag || item.groupName === 'Request Timeline' || item.groupName === 'Client Details');
  });

  customFields.groupList = {};
  const tempCustomFields = currentMetaData?.customFields;

  customFields.companyCustomFieldList.forEach(customFieldItem => {
    if (customFieldItem) {
      configureCustomField(customFieldItem as CustomFieldVO & Record<string, boolean>, tempCustomFields, groupByIndexed, customFields);
    }
  });

  return customFields;
};

export const requestDataMapKey: { [key: string]: string } = {
  REQUEST_TYPE: 'requestTypeMap',
  REQUEST_STAGE: 'requestStageMap',
  RESPONDENT_STAGE: 'vendorStageMap'
};

export function getRequestDataKeys(type: String) {
  return {
    requestDataStateListKey: requestDataListKey[type as keyof typeof requestDataListKey],
    requestDataStateMapKey: requestDataMapKey[type as keyof typeof requestDataMapKey]
  };
}
