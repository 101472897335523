import React, { Suspense, useEffect } from 'react';
import RfpErrorBoundary from '@rfp-components/RfpErrorBoundary';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from '@utils/SnackBarUtils';
import { LoadingBar } from '../hooks/LoadingBar';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { CRMRoutes } from '../routes/crm';
import CRMRenderRoute from '../routes/crm/CRMRenderRoute';
import { generalUseStoreState } from '../hooks';
import TagManager from 'react-gtm-module';
import ToolTipComponents from './ToolTipComponents';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';

export default function CRMAppPage() {
  const theme = generalUseStoreState(state => state.theme.theme);

  useEffect(() => {
    if (window.constants.gtm_tracker && !window.isGTMLoaded) {
      const tagManagerArgs = {
        gtmId: window.constants.gtm_tracker
      };
      TagManager.initialize(tagManagerArgs);
      window.isGTMLoaded = true;
    }
  }, []);

  return (
    <RfpErrorBoundary>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider maxSnack={4} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
          <SnackbarUtilsConfigurator />
          <LoadingBar>
            <Router>
              <Suspense fallback={<p></p>}>
                <Switch>
                  {CRMRoutes.map((route, index) => (
                    <CRMRenderRoute {...route} key={index} />
                  ))}
                </Switch>
                <ToolTipComponents />
              </Suspense>
            </Router>
          </LoadingBar>
        </SnackbarProvider>
      </ThemeProvider>
    </RfpErrorBoundary>
  );
}
