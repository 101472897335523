import { styled } from '@material-ui/core/styles';
import { RfpButton } from '@rfp-components/RfpButton';

const LoginButton = styled(RfpButton)(({ theme }) => ({
  borderRadius: '40px',
  color: '#FFFFFF',
  backgroundColor: '#6EAE40',
  width: '100% !important',
  fontFamily: theme.mixins.interFontStack,
  '&:hover': {
    backgroundColor: '#628c1f !important'
  }
}));

export default LoginButton;
