import React from 'react';
import { createRoot } from 'react-dom/client';
import App from '@components/App';
import './styles/App.css';
import { StoreProvider } from 'easy-peasy';
import store from './store';
import { isIE, isLegacyEdge } from 'react-device-detect';
import NoSupportProvided from '@containers/guest/NoSupportProvided';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import CRMAppPage from '@components/CRMAppPage';
import { lazyLoadWithRetry } from '@rfp-components/RfpLazyWithRetry';
import CustomDomainPage from '@components/custom-domain/CustomDomainPage';

const SentryApp = lazyLoadWithRetry(() => import('@components/SentryApp'), 'SentryApp');

const AppPage = () => {
  return (
    <I18nextProvider i18n={i18n}>
      {(isIE || isLegacyEdge) && <NoSupportProvided isIE></NoSupportProvided>}
      {!isIE && !isLegacyEdge && <StoreProvider store={store}>{(window as any).fromCRM ? <CRMAppPage /> : (window as any).fromCustomDomain ? <CustomDomainPage /> : <RenderApp />}</StoreProvider>}
    </I18nextProvider>
  );
};

const RenderApp = () => {
  return <>{window.constants.environment === 'Development' ? <SentryApp /> : <App />}</>;
};

const UnsecuredPage = () => (
  <div>
    <h2>Please inform team with the reference of the application from where you clicked this link.</h2>
    <h2>
      Click{' '}
      <a href={window.self.location.href} title='Web Application' target='blank'>
        here
      </a>{' '}
      to access WebApp safely.
    </h2>
  </div>
);
if (window.location.href.indexOf('//v2/') > 0) {
  window.location.href = window.location.href.replaceAll('//v2/', '/v2/');
}
const root = createRoot(document.getElementById('app'));

if (window.self === window.top || (window as any).fromCRM || (window as any).fromMSTeams) {
  root.render(<AppPage />);
} else {
  root.render(<UnsecuredPage />);
}
