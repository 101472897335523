import React from 'react';
import Scrollbars, { ScrollbarProps } from 'react-custom-scrollbars';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface Iscroll extends ScrollbarProps {
  scrollwidth?: any;
  removeHeight?: number;
  skipHorizontalScroll?: boolean;
}

const RfpScrollbar = (props: Iscroll) => {
  const { removeHeight = -1, scrollwidth, skipHorizontalScroll, ...others } = props;
  const classes = ScrollbarStyles();
  const heightStyles = removeHeight > -1 ? { style: { height: `calc(100vh - 64px - ${removeHeight}px)` } } : {};
  return (
    <Scrollbars
      className={`scrollBars_scroller ${skipHorizontalScroll ? classes.skipScroll : ''}`}
      {...{ scrollwidth, ...others }}
      {...heightStyles}
      renderThumbHorizontal={({ style, ...props }) => <div {...props} id='track-vertical' className={`${classes.scrollbarColor} ${scrollwidth}`} />}
      renderThumbVertical={({ style, ...props }) => <div {...props} id='track-horizontal' className={`${classes.scrollbarColor} ${scrollwidth}`} />}>
      {props.children}
    </Scrollbars>
  );
};

export default RfpScrollbar;

const ScrollbarStyles = makeStyles((theme: Theme) => ({
  scrollbarColor: {
    position: 'relative',
    display: 'block',
    width: '8px',
    cursor: 'pointer',
    borderRadius: '5px',
    backgroundColor: `${theme.palette.type === 'light' ? '#BBBBBB !important' : '#6a6a6a !important'}`,
    '&:hover': {
      backgroundColor: '#999999 !important'
    }
  },
  skipScroll: {
    '& > :first-child': {
      overflowX: 'hidden !important'
    }
  }
}));
