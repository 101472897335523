import axiosInstance from '@helpers/Axios';
import { errorLogger } from '@utils/ErrorLogging';
export const getZendeskUrlApi = async (isVanilla: boolean, param: any) => {
  try {
    const response = await axiosInstance().get(`users/get-${isVanilla ? 'vanilla' : 'zendesk'}-url`, { params: param });
    return response;
  } catch (e) {
    errorLogger(e, false, 'caught from getZendeskUrlApi');
  }
};
export const getNotificationsCount = async () => {
  try {
    const response = await axiosInstance().get('email/user-notification-unread-count');
    return response;
  } catch (e) {
    errorLogger(e, false, 'caught in getNotificationsCount');
  }
};
export const getUserNotificationsAPI = async () => {
  try {
    const response = await axiosInstance().get('email/get-user-notification');
    return response;
  } catch (e) {
    errorLogger(e, false, 'caught in getUserNotificationsAPI');
  }
};
export const updateUserNotificationAPI = async (id: string, type: string) => {
  try {
    const response = await axiosInstance().post('email/update-user-notification?lastMessageId=' + id + '&type=' + type);
    return response;
  } catch (e) {
    errorLogger(e, false, 'Caught in updateUserNotificationAPI');
  }
};

export const getAcademyUrl = async () => {
  try {
    return await axiosInstance().get('users/get-academy-url');
  } catch (e) {
    errorLogger(e, false, 'caught from getAcademyUrlApi');
  }
};

export const getOnPremJwt = async () => {
  try {
    const response = await axiosInstance().get(`users/generate-onprem-jwt-token`);
    return response;
  } catch (e) {
    errorLogger(e, false, 'caught from generate-onprem-jwt-token');
  }
};
