import { action } from 'easy-peasy';
import CustomFieldsDetailsStore from '@interfaces/CustomFieldsDetailsStore';
import CustomFieldVOList, { customFieldVOList } from '@interfaces/Models/CustomFieldVOList';
import CustomFieldVO from '@interfaces/Models/CustomFieldVO';
import { updateCustomFieldDetailsList, updateCustomFieldsStoreKey } from '@utils/CustomFieldUtils';
import { OrgCCLStoreKeys, OrgCustomFieldEntityType } from '@org/interfaces/models/org-custom-fields/OrgCustomFieldType';

const CustomFieldsDetailsStoreImpl: CustomFieldsDetailsStore = {
  alCustomFields: customFieldVOList,
  customFields: customFieldVOList,
  libraryCustomFieldMap: {},
  customFieldMap: {},
  customFieldMapDL: {},
  sectionTemplateCustomFieldMap: {},
  projectCustomFieldMap: {},
  projectRequestCustomFieldMap: {},
  requestCustomFieldMap: {},
  proposalCustomFieldMap: {},
  systemDefinedCustomFieldsMap: {},
  customFieldMapCatalog: {},

  setCustomFields: action((state, entry) => {
    state.customFields = entry;
  }),

  setALCustomFields: action((state, entry) => {
    state.alCustomFields = entry;
  }),

  setLibraryCustomFieldMap: action((state, entry) => {
    state.libraryCustomFieldMap = entry;
  }),

  setCustomFieldMap: action((state, entry) => {
    state.customFieldMap = entry;
  }),

  setCustomFieldMapDL: action((state, entry) => {
    state.customFieldMapDL = entry;
  }),

  setProjectCustomFieldMap: action((state, entry) => {
    state.projectCustomFieldMap = entry;
  }),

  setProjectRequestCustomFieldMap: action((state, entry) => {
    state.projectRequestCustomFieldMap = entry;
  }),

  setRequestCustomFieldMap: action((state, entry) => {
    state.requestCustomFieldMap = entry;
  }),

  setSystemDefinedCustomFieldsMap: action((state, entry) => {
    state.systemDefinedCustomFieldsMap = entry;
  }),

  setCustomFieldMapCatalog: action((state, entry) => {
    state.customFieldMapCatalog = entry;
  }),

  setSectionTemplateCustomFieldMap: action((state, entry) => {
    state.sectionTemplateCustomFieldMap = entry;
  }),

  setCustomFieldsData: action((state, entry) => {
    state.customFields = entry.customFields;
    state.libraryCustomFieldMap = entry.libraryCustomFieldMap;
    state.userCustomFieldMap = entry.userCustomFieldMap;
    state.customFieldMap = entry.customFieldMap;
    state.customFieldMapDL = entry.customFieldMapDL;
    state.customFieldMapCatalog = entry.customFieldMapCatalog;
    state.sectionTemplateCustomFieldMap = entry.sectionTemplateCustomFieldMap;
    state.projectCustomFieldMap = entry.projectCustomFieldMap;
    state.projectRequestCustomFieldMap = entry.projectRequestCustomFieldMap;
    state.requestCustomFieldMap = entry.requestCustomFieldMap;
  }),

  setSyncCustomFieldVO: action((state, entry) => {
    const { customFieldVO, mapKey } = entry;
    if (!state[mapKey]) {
      return;
    }
    if (mapKey === 'alCustomFields') {
      let alCustomFieldVOMap = getCustomFieldVOMap(entry, state.libraryCustomFieldMap);
      state['libraryCustomFieldMap'] = alCustomFieldVOMap;
      if (customFieldVO.entityType === OrgCustomFieldEntityType.ALL) {
        OrgCCLStoreKeys.forEach((cclKey: string) => {
          let tempCustomFieldVOMap = getCustomFieldVOMap(entry, state[cclKey]);
          state[cclKey] = tempCustomFieldVOMap;
        });
      } else {
        const storeKey = updateCustomFieldsStoreKey(customFieldVO.entityType, customFieldVO.name);
        let tempCustomFieldVOMap = getCustomFieldVOMap(entry, state[storeKey]);
        state[storeKey] = tempCustomFieldVOMap;
      }
      //ccl end
    } else {
      let tempCustomFieldVOMap = getCustomFieldVOMap(entry, state[mapKey]);
      state[mapKey] = tempCustomFieldVOMap;
    }
  }),

  setSyncCustomFieldVOMap: action((state, entry) => {
    const { customFieldVOMap, stateKey } = entry;
    if (!state[stateKey]) {
      return;
    }
    if (stateKey !== 'alCustomFields') {
      state[stateKey] = customFieldVOMap;
    } else {
      state.alCustomFields.companyCustomFieldList = Object.values(customFieldVOMap);
    }
  }),

  setCompanyCustomFieldDetailsList: action((state, entry) => {
    const { customField, isDelete, currentMergeTagName, stateKey } = entry;
    if (!state[stateKey]) {
      return;
    }
    state.customFields.companyCustomFieldList = updateCustomFieldDetailsList(state.customFields.companyCustomFieldList, customField, !!isDelete, currentMergeTagName);

    if (stateKey && stateKey in state && state[stateKey].hasOwnProperty('companyCustomFieldList')) {
      (state[stateKey] as CustomFieldVOList).companyCustomFieldList = updateCustomFieldDetailsList(
        (state[stateKey] as CustomFieldVOList).companyCustomFieldList,
        customField,
        !!isDelete,
        currentMergeTagName
      );
    }
  })
};

const getCustomFieldVOMap = (
  entry: {
    customFieldVO: CustomFieldVO;
    mapKey: keyof CustomFieldsDetailsStore;
    isDelete?: boolean;
    currentMergeTagName?: string;
    isReorder?: boolean;
  },
  tempCustomFieldVOMap: {
    [mergeTagName: string]: CustomFieldVO;
  }
) => {
  const { customFieldVO, mapKey, isDelete, currentMergeTagName } = entry;

  const isProjectOrRequest = ['REQUEST', 'PROJECT_REQUEST', 'PROJECT'].includes(customFieldVO.entityType);
  const customFieldKey = isProjectOrRequest ? customFieldVO.id : customFieldVO.name;
  if (isDelete) {
    delete tempCustomFieldVOMap[customFieldKey];
  } else if (currentMergeTagName?.trim() && !isProjectOrRequest) {
    const entries = Object.entries(tempCustomFieldVOMap);
    tempCustomFieldVOMap = entries.reduce((acc, [key, value]) => {
      if (key === currentMergeTagName) {
        // Insert new key with the new value
        acc[customFieldVO.name] = customFieldVO;
      } else {
        // Copy the old key and value
        acc[key] = value;
      }
      return acc;
    }, {} as { [key: string]: CustomFieldVO });
  } else {
    tempCustomFieldVOMap[customFieldKey] = customFieldVO;
  }

  return tempCustomFieldVOMap;
};

export default CustomFieldsDetailsStoreImpl;
