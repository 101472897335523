import { any } from 'prop-types';
import CustomFieldVO from './CustomFieldVO';

export default interface CustomFieldVOList {
  companyCustomFieldList: CustomFieldVO[];
}

export const customFieldVOList = {
  companyCustomFieldList: []
};
