import { AxiosRequestConfig } from 'axios';

export const axiosRequestConfig: AxiosRequestConfig = {
  transformRequest: (data: any) => {
    const fd = new FormData();
    Object.keys(data).forEach((key: any) => {
      const value: any = data[key];
      if (Array.isArray(value)) {
        value.forEach(v => {
          fd.append(key, v, v.name);
        });
      } else {
        value?.name ? fd.append(key, value, value.name) : fd.append(key, value);
      }
    });
    return fd;
  },
  headers: { 'Content-Type': undefined }
};

export const serializedConfig: AxiosRequestConfig = {
  transformRequest: (data: any) => {
    return serializeData(data);
  },
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
  }
};

export const serializeData = (data: any) => {
  if (!(data !== null && typeof data === 'object')) {
    return data == null ? '' : data.toString();
  }
  const buffer = [];
  for (let name in data) {
    if (!data.hasOwnProperty(name)) {
      continue;
    }
    const value = data[name];
    buffer.push(encodeURIComponent(name) + '=' + encodeURIComponent(value == null ? '' : value));
  }
  const source = buffer.join('&').replace(/%20/g, '+');
  return source;
};
