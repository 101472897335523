import React, { useState, useEffect } from 'react';
import { Theme, useTheme, withStyles } from '@material-ui/core/styles';
import MuiDialog, { DialogProps } from '@material-ui/core/Dialog';
import MuiDialogTitle, { DialogTitleProps } from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Draggable from 'react-draggable';
import Paper, { PaperProps } from '@material-ui/core/Paper';
import RfpButton from './RfpButton';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@material-ui/core';
import { generalUseStoreActions } from '../hooks';
import clsx from 'clsx';
import RfpToolTip from './RfpToolTip';

export interface RfpDialogProps {
  dataQa: string;
  disableDrag?: boolean;
  id?: string;
}
interface RfpDialogTitleProps {
  dataQa: string;
  onClose?: () => void;
  onBackClick?: () => any;
  showBackButton?: boolean;
  isWarning?: string;
  warningIcon?: string;
  classes?: any;
  isWarningIconNeed?: boolean;
  isFlex?: boolean;
  isMinimizeIconNeeded?: boolean;
  onMinimize?: () => void;
  minimizeToolTip?: string;
  disableMinimize?: boolean;
}

const RfpStyleDialogTitle = withStyles(theme => ({
  root: {
    margin: 0,
    padding: '0px 0px 14px 0px',
    cursor: `move`,
    borderBottom: theme.palette.type === 'light' ? '1px solid rgba(0, 0, 0, 0.12)' : theme?.commonStyles?.border,
    '& .MuiIconButton-root': {
      '&.closeButton': {
        marginLeft: 10
      },
      '&.backButton': {
        marginRight: 10
      }
    },
    color: theme?.commonStyles?.color
  }
}))(MuiDialogTitle);

export const RfpDialogTitle = (props: DialogTitleProps & RfpDialogTitleProps) => {
  const {
    children,
    classes,
    onClose,
    onBackClick,
    showBackButton,
    isWarning,
    warningIcon,
    isWarningIconNeed,
    dataQa,
    isFlex,
    minimizeToolTip,
    isMinimizeIconNeeded,
    onMinimize,
    disableMinimize,
    ...other
  } = props;
  const onDialogClose = (event: any) => {
    onClose(event);
  };

  return (
    <RfpStyleDialogTitle {...other} data-qa={dataQa} id={dataQa}>
      <Grid container direction='row' justify='center' alignItems='center' id='dialog_title_container'>
        <Grid item>
          {showBackButton ? (
            <IconButton data-qa='ARROW_BUTTON_BACK' aria-label='close' className='backButton' onClick={onBackClick}>
              <span className='rfpio-font-icon icon-arrow-left-line font-24'></span>
            </IconButton>
          ) : null}
          {!isWarningIconNeed && (
            <>
              {isWarning && !warningIcon && (
                <IconButton disabled aria-label='close' className='backButton'>
                  <span className={`rfpio-font-icon ${isWarning === 'warning' ? 'icon-warning text-warning' : 'icon-information-fill delete-text'} font-24`}></span>
                </IconButton>
              )}
              {warningIcon && <span className={`rfpio-font-icon display-block ${warningIcon !== 'no-icon' && 'mr-10'} font-22 ${warningIcon} ${isWarning === 'delete' && 'delete-text'}`}></span>}
            </>
          )}
        </Grid>
        <Grid item xs className='txt-flow' id='dialog_title'>
          <span className={`font600 ${isFlex ? 'display-flex' : ''} ${isWarningIconNeed ? 'font-16' : 'font-20'}`}>{children}</span>
        </Grid>
        <Grid item>
          {isMinimizeIconNeeded && (
            <RfpToolTip title={minimizeToolTip}>
              <IconButton disabled={disableMinimize} data-qa='MINIMIZE_DIALOGUE' aria-label='close' className='minimize' onClick={onMinimize}>
                <span className='cursor rfpio-font-icon icon-subract-line font-20' id='minimizeIcon'></span>
              </IconButton>
            </RfpToolTip>
          )}
          {onClose ? (
            <IconButton data-qa='DIALOG_CLOSE_ICON' aria-label='close' className='closeButton' onClick={onDialogClose}>
              <span className={`rfpio-font-icon icon-close-line ${isWarningIconNeed ? 'font-20' : 'font-24'}`} id='closeIcon'></span>
            </IconButton>
          ) : null}
        </Grid>
      </Grid>
    </RfpStyleDialogTitle>
  );
};

export const RfpDialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: '16px 1px 1px 1px',
    fontSize: 14
  }
}))(MuiDialogContent);

export const RfpDialogActions = withStyles(() => ({
  root: {
    margin: 0,
    padding: '20px 0px 0px 0px'
  }
}))(MuiDialogActions);

function PaperComponent(props: PaperProps) {
  const isTabletView = useMediaQuery(useTheme().breakpoints.down('sm'));
  /*
   Cancels the drag operation if any element within the element which starts or ends with id 'skip_draggable' is clicked
   */
  return (
    <Draggable disabled={isTabletView} handle='#dialog_title' cancel={'[class*="MuiDialogContent-root"],[id*="skip_draggable"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const Dialog = withStyles(theme => ({
  paper: {
    padding: theme.spacing(3)
  },
  scrollPaper: {
    justifyContent: 'center',
    '& ::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.type === 'light' ? '#BBBBBB !important' : '#6a6a6a !important',
      '&:hover': {
        backgroundColor: '#999999 !important'
      }
    }
  },
  paperWidthXl: {
    maxWidth: '90%'
  }
}))(MuiDialog);

export default function RfpDialog(props: RfpDialogProps & DialogProps) {
  const { dataQa, disableDrag, open, id, ...other } = props;
  const setDialogState = generalUseStoreActions(state => state.dialog.setDialogState);
  const dialogsWithRichText = ['bulk_answering', 'ADD_DESCRIPTION_DIALOG', 'ADD_ANSWER_DIALOG', 'section_dialog', 'ADD_SECTION_DIALOG'];

  useEffect(() => {
    if (open && dialogsWithRichText.includes(dataQa)) {
      setDialogState(true);
    }
    return () => {
      dialogsWithRichText.includes(dataQa) && setDialogState(false);
    };
  }, []);

  return (
    <Dialog id={id} data-qa={dataQa} open={open} PaperComponent={disableDrag ? (props: PaperProps) => <Paper {...props} /> : PaperComponent} {...other}>
      {props.children}
    </Dialog>
  );
}

export function RfpWarningDialog(props: any) {
  const { t } = useTranslation('translations');
  const { dialogData, dataQa, open, maxWidth, warningIcon, classes, isSecondaryAction, className, isWarningIconNeed, id, useInterFont, ...other } = props;
  const [disabled, setDisabled] = useState<any>(dialogData.isDisable);

  useEffect(() => {
    setDisabled(dialogData.isDisable);
  }, [dialogData.isDisable]);

  const confirmFunc = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDisabled(true);
    dialogData.onConfirm(event);
  };

  useEffect(() => {
    !open && setDisabled(dialogData.isDisable);
  }, [open]);

  return (
    <RfpDialog
      dataQa={dataQa}
      open={open}
      maxWidth={maxWidth || 'xs'}
      className={`${useInterFont && 'font-inter'} ${dialogData.isDelete ? 'delete-dialog' : dialogData.isThemeColor ? `${className}` : 'warning-dialog'}`}
      fullWidth={true}
      aria-labelledby='confirm-dialog'
      onClose={dialogData.onClose}
      classes={classes}
      id={id}
      onClick={event => event.stopPropagation()}
      {...other}>
      <RfpDialogTitle isWarningIconNeed={isWarningIconNeed} dataQa={dataQa} warningIcon={warningIcon} isWarning={dialogData.isDelete ? 'delete' : 'warning'} onClose={dialogData.onClose}>
        {dialogData.title}
      </RfpDialogTitle>
      <RfpDialogContent>{props.children}</RfpDialogContent>
      <RfpDialogActions>
        {dialogData.needDontShowAgain && dialogData.context}
        {!dialogData.hideCancelButton && !isSecondaryAction && (
          <RfpButton
            className={clsx(dialogData.secondaryActionOnClose && 'tertiaryButton', dialogData.cancelButtonStyle)}
            dataQa='NO'
            variant={dialogData?.closeVariant || 'text'}
            onClick={e => {
              dialogData.onClose(e);
              dialogData.secondaryActionOnClose && dialogData.secondaryActionOnClose();
            }}>
            {t(dialogData.cancelText || 'NO')}
          </RfpButton>
        )}
        <RfpButton dataQa='YES' onClick={confirmFunc} disabled={disabled} variant={dialogData.confirmBtnVariant || 'contained'} color='primary' className={dialogData.primaryButtonStyle}>
          {t(dialogData.confirmText || 'YES')}
        </RfpButton>
      </RfpDialogActions>
    </RfpDialog>
  );
}

const RfpAlertStyleDialogTitle = withStyles(theme => ({
  root: {
    margin: 0,
    padding: '0px 0px 0px 0px',
    cursor: `move`,
    '& .MuiIconButton-root': {
      '&.closeButton': {
        marginLeft: 10
      },
      '&.backButton': {
        marginRight: 10
      }
    }
  }
}))(MuiDialogTitle);

export const RfpAlertDialogTitle = (props: DialogTitleProps & RfpDialogTitleProps) => {
  const { children, onClose, warningIcon, dataQa, ...other } = props;

  return (
    <RfpAlertStyleDialogTitle {...other} data-qa={dataQa} id={dataQa}>
      <Grid container direction='row' justify='center' alignItems='center'>
        <Grid item>
          <span className={`rfpio-font-icon display-block ${warningIcon ? warningIcon : 'icon-information-fill'} alert-info-color font-24 mr-10`}></span>
        </Grid>
        <Grid item xs className='txt-flow' id='dialog_title'>
          <span className='font600 font-20'>{children}</span>
        </Grid>
      </Grid>
    </RfpAlertStyleDialogTitle>
  );
};

export function RfpAlertDialog(props: any) {
  const { t } = useTranslation('translations');
  const { dialogData, dataQa, open, maxWidth, warningIcon, classes, className } = props;
  const [disabled, setDisabled] = useState<any>(dialogData.isDisable);

  useEffect(() => {
    setDisabled(dialogData.isDisable);
  }, [dialogData.isDisable]);

  const confirmFunc = () => {
    setDisabled(true);
    dialogData.onConfirm();
  };

  useEffect(() => {
    !open && setDisabled(dialogData.isDisable);
  }, [open]);

  return (
    <RfpDialog
      dataQa={dataQa}
      open={open}
      maxWidth={maxWidth || 'xs'}
      className={`${dialogData.isThemeColor ? `${className}` : 'warning-dialog'}`}
      fullWidth={true}
      aria-labelledby='confirm-dialog'
      onClose={dialogData.onClose}
      classes={classes}>
      <RfpAlertDialogTitle dataQa={dataQa} warningIcon={warningIcon} onClose={dialogData.onClose}>
        {dialogData.title}
      </RfpAlertDialogTitle>
      <RfpDialogContent>
        {props.children}
        <Grid container justify='flex-end'>
          <RfpButton dataQa='YES' onClick={confirmFunc} disabled={disabled}>
            {t(dialogData.confirmText || 'YES')}
          </RfpButton>
        </Grid>
      </RfpDialogContent>
    </RfpDialog>
  );
}
