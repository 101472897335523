import BusinessUnitVO from '@interfaces/Models/BussinessUnitVO';
import CompanyInfoVO from '@interfaces/Models/CompanyInfoVO';
import ManageTeamsVO from '@interfaces/Models/ManageTeamsVO';
import { PermissionData } from '@interfaces/userDataStore';
import UserDetailsVO, { uiPreferences } from '@interfaces/Models/UserDetailsVO';
import UserVO from '@interfaces/Models/UserVO';
import { IUserLoadDetails } from '@interfaces/userDataStore';
import { IprocessUserLoadDetails } from '@interfaces/UserLoadProcessor';
import { setCustomFields, updateCustomFieldsDisplayName } from '@utils/CustomFieldUtils';
import RFPIOPalette from '@utils/RFPIOPalette';
import { constructRequestMasterData, setAllUserPolicies, setCollectionDetails, updateContentFlagMap, updateProjectMasterMap } from '@utils/UserLoadDetailUtil';
import cloneDeep from 'clone-deep';
import TagManager from 'react-gtm-module';
import { UserData } from 'src/contexts/authContext';
import { customFieldVOList } from '@interfaces/Models/CustomFieldVOList';
import { getFontFamilyObject } from '@utils/GeneralUtils';
import { businessUnitVOList } from '@interfaces/Models/BussinessUnitVO';
import { changedColorsMap, getColorValue } from '@utils/ColorUtils';

const constructBusinessUnits = (buList: BusinessUnitVO[], hasBuFeature: boolean, baseBuUserMap: { [key: string]: string[] }) => {
  let buIds: string[] = ['ALL'];
  const buMap: { [key: string]: BusinessUnitVO } = {};
  const buNameMap: { [key: string]: string } = {};
  if (hasBuFeature) {
    buList.push(businessUnitVOList[0]);
    buList?.forEach(buData => {
      const buID = buData.id;
      buIds.push(buID);
      buMap[buID] = buData;
      buNameMap[buID] = buData.name;
    });
  }

  //remove duplicated BU id (only occurs when ALL available in businessUnits)
  buIds = Array.from(new Set(buIds));
  baseBuUserMap = buIds.reduce((acc, id) => ({ ...acc, [id]: [] }), {} as { [key: string]: string[] });

  return {
    buMap,
    buNameMap,
    baseBuUserMap
  };
};

export const constructPermissionMap = (companyPolicyMap: { [key: string]: any } = {}) => {
  const permissionMap =
    Object.entries(companyPolicyMap)?.reduce((acc, [roleId, roleDetails]) => {
      const moduleLevelPermissions: { [key: string]: any } = roleDetails?.permission || {};
      const roleLevelPermissions: string[] = Object.values(moduleLevelPermissions).flat() || [];
      acc[roleId] = roleLevelPermissions;
      return acc;
    }, {} as { [key: string]: string[] }) || {};

  return permissionMap;
};

export const constructUserSpecificDeatils = (userList: UserVO[], buBaseduserList: { [key: string]: string[] }) => {
  const userData: { [key: string]: UserVO } = {};
  const activeUserList: string[] = [];
  const availableTagList: string[] = [];

  userList?.forEach(user => {
    const updatedUser = cloneDeep(user);
    const userId = updatedUser.userName;
    const businessUnits = updatedUser.businessUnits || [];
    const hasAllBuAcces = businessUnits.includes('ALL');
    const isSuperAdmin = updatedUser?.userRole === 'SUPER_ADMIN';
    const tagList = updatedUser?.tags || [];

    if (tagList.length > 0) {
      availableTagList.push(...tagList);
    }

    Object.keys(buBaseduserList).forEach(buId => {
      if (buId === 'ALL' || hasAllBuAcces || isSuperAdmin || businessUnits.includes(buId)) {
        buBaseduserList[buId]?.push(userId);
      }
    });

    if (updatedUser.userStatus !== 'IN_ACTIVE') {
      activeUserList.push(userId);
    }

    userData[userId] = updatedUser;
  });

  return { userData, activeUserList, availableTagList: Array.from(new Set(availableTagList)) };
};

const constructTeam = (teamList: ManageTeamsVO[], buBaseduserList: { [key: string]: string[] }) => {
  const teamData: { [key: string]: ManageTeamsVO } = {};

  teamList?.forEach(team => {
    const teamId = team.id;
    teamData[team.id] = team;
    const businessUnits = team.businessUnits || [];

    Object.keys(buBaseduserList).forEach(buId => {
      if (buId === 'ALL' || businessUnits.includes(buId)) {
        buBaseduserList[buId]?.push(teamId);
      }
    });
  });

  return teamData;
};

const getloggedUserPermissionData = (response: UserDetailsVO, permission: { [key: string]: string[] }): PermissionData => {
  const { userInfoVO: user, userPermissionTypes = {} } = response;
  const companyPolicyMap = response.companyPolicyMap as { [key: string]: any };
  const userData = localStorage.getItem('userData') || '{}';
  const localUser = JSON.parse(userData) as UserData;
  const userRole = user?.userRole;
  const additionalRoles: string[] = user?.additionalRoles || [];
  const roles = [userRole].concat(additionalRoles);
  const permissionMap: { [key: string]: string[] } = cloneDeep(userPermissionTypes);
  //add other (additionl roles) into respective modules
  permissionMap.PROJECT.push('PROJECT_PRIMARY_CONTACT');
  permissionMap.ORGANIZATION_SETTINGS.push('BILLING');
  permissionMap.OTHERS ? permissionMap.OTHERS.push('INTERNAL_CONTACT') : (permissionMap.OTHERS = ['INTERNAL_CONTACT']);

  const isSuperAdmin = userRole === 'SUPER_ADMIN';
  const isSupport = !!localUser?.support;
  const basePermissionList = Object.values(cloneDeep(permissionMap)).flat() as string[];
  let availableUserPermissionList = roles.map(role => permission[role]).flat() || [];

  if (isSuperAdmin && basePermissionList.length > 0) {
    availableUserPermissionList = cloneDeep(basePermissionList);
    //remove PROJECT_PRIMARY_CONTACT if not assigned for super admin
    if (!additionalRoles.includes('PROJECT_PRIMARY_CONTACT')) {
      const index = availableUserPermissionList.indexOf('PROJECT_PRIMARY_CONTACT');
      availableUserPermissionList.splice(index, 1);
    }
  }
  const isPermissionEnabled: { [key: string]: boolean } = {};
  basePermissionList.forEach(permission => {
    isPermissionEnabled[permission] = availableUserPermissionList.includes(permission);
  });

  //module based permission only used for GTM
  const moduleBasePermission: { [key: string]: any } = {};
  if (!isSuperAdmin) {
    roles.forEach((roleId: string) => {
      if (roleId != 'SUPER_ADMIN' && companyPolicyMap[roleId]) {
        let tempPermission = companyPolicyMap[roleId].permission;
        Object.entries(tempPermission).map(([key, value]) => {
          let modulePermission = moduleBasePermission[key];
          if (!modulePermission) {
            modulePermission = [];
          }
          modulePermission = modulePermission.concat(value);
          moduleBasePermission[key] = modulePermission;
        });
      }
    });
  }
  return {
    isPermissionEnabled,
    isSuperAdmin,
    isSupport,
    userPermission: moduleBasePermission
  };
};

const themeUpdate = (companyInfoVO: CompanyInfoVO) => {
  const primaryColor = companyInfoVO.theme && companyInfoVO.theme.primaryColor ? changedColorsMap[companyInfoVO.theme.primaryColor.toLowerCase()] || companyInfoVO.theme.primaryColor : '#7baf27';
  const secondaryColor = companyInfoVO.theme && companyInfoVO.theme.secondary ? changedColorsMap[companyInfoVO.theme.secondary.toLowerCase()] || companyInfoVO.theme.secondary : '#1c3443';
  const palette: RFPIOPalette = {
    primary: primaryColor,
    secondary: secondaryColor,
    primaryDark: getColorValue(primaryColor, 'dark'),
    accentColor: getColorValue(primaryColor, 'accent'),
    type: localStorage.getItem('themeMode') === 'dark' ? 'dark' : 'light'
  };

  return palette;
};

const initalizeTagManager = (companyPreferencesVO: any) => {
  const userData = localStorage.getItem('userData') || '{}';
  const user = JSON.parse(userData) as UserData;
  const isValidUser = user && !user.support;
  const { featurePreferences, data } = companyPreferencesVO || {};

  if (isValidUser && featurePreferences?.google_tag_manager === '1') {
    if (data && data?.google_tag_manager && data.google_tag_manager?.id) {
      TagManager.initialize({
        gtmId: data.google_tag_manager?.id
      });
    }
  }
};

export const processUserLoadDetails = (response: UserDetailsVO, skipCustomField?: boolean, skipTagManagerInit?: boolean): IprocessUserLoadDetails => {
  let buBaseduserList: { [key: string]: string[] } = {};
  const hasBuFeature = response.companyPreferencesVO?.featurePreferences?.business_units === '1';
  //Business unit construction
  const { buMap, buNameMap, baseBuUserMap } = constructBusinessUnits(response?.businessUnits, hasBuFeature, buBaseduserList);
  buBaseduserList = { ...baseBuUserMap };
  //comapny level permission construction
  const permission = constructPermissionMap(response?.companyPolicyMap);

  //user details construction
  const userList = response?.userVOList?.users || [];
  const { userData: userVOMap, activeUserList, availableTagList } = constructUserSpecificDeatils(userList, buBaseduserList);

  //team details construction
  const teamDetails = constructTeam(response?.teamsList, buBaseduserList);

  //construct permission details of logged user
  const permissionData = getloggedUserPermissionData(response, permission);

  //construct company policy map
  const allUserPolicies = setAllUserPolicies(response?.companyPolicyMap || {}, response?.companyPreferencesVO || {}, permissionData);

  //initial Theme details construction
  const palette = themeUpdate(response.companyInfoVO);

  //construct contentMasterData
  const contentMasterData = updateContentFlagMap(response?.contentFlagList?.dataList || []) || {};

  //construct collection Details
  const collectionData = setCollectionDetails(response?.collectionList || [], true) || {};

  //update basic uiPreferences if not available
  if (!response.userInfoVO.uiPreferences) {
    response.userInfoVO.uiPreferences = {
      dateFormat: 'YYYY-MM-DD',
      timeFormat: 'h:mm:ss a'
    } as uiPreferences;
  }

  let projectMasterData: any = {};
  if (response.companyPreferencesVO.featurePreferences.respond === '1') {
    projectMasterData = updateProjectMasterMap(response);
  }

  let requetMasterData: any = {};
  if (response.companyPreferencesVO.featurePreferences.request === '1') {
    requetMasterData = constructRequestMasterData(response);
  }

  //construct custom field data
  let updatedCustomField = {};
  if (!skipCustomField) {
    const customFieldMapData = setCustomFields(response.customFieldVOList, true);
    const { customFieldsData, alCustomFields } = customFieldMapData;
    const systemDefinedCustomFieldsMap = updateCustomFieldsDisplayName(customFieldsData.customFields, null);
    updatedCustomField = { ...customFieldsData, alCustomFields, systemDefinedCustomFieldsMap };
  } else {
    updatedCustomField = {
      alCustomFields: customFieldVOList,
      customFields: customFieldVOList,
      libraryCustomFieldMap: {},
      customFieldMap: {},
      customFieldMapDL: {},
      projectCustomFieldMap: {},
      projectRequestCustomFieldMap: {},
      requestCustomFieldMap: {},
      proposalCustomFieldMap: {},
      systemDefinedCustomFieldsMap: {}
    };
  }

  const fontDetails = getFontFamilyObject(response.fontFamily, response.tinymceStyleList);

  if (!skipTagManagerInit) {
    initalizeTagManager(response.companyPreferencesVO);
  }

  const userDetails: IUserLoadDetails = {
    buBaseduserList,
    permissionMap: permission,
    companyUserMap: userVOMap,
    teamsMap: teamDetails,
    activeUserList,
    permissionData,
    usersTagList: availableTagList,
    allUserPolicies
  };
  //updated user load details
  return {
    userLoadDetails: userDetails,
    palette,
    customFieldMapData: updatedCustomField,
    collectionData,
    projectMasterData,
    requetMasterData,
    ...contentMasterData,
    ...collectionData,
    buDetails: {
      businessUnitIdMap: buMap || {},
      businessUnitNameMap: buNameMap || {}
    },
    fontDetails
  };
};

export const constructBuBasedUsers = (userData: Partial<UserVO>, buBaseduserList: { [key: string]: string[] }) => {
  const userId = userData.userName;
  const businessUnits = userData.businessUnits || [];
  const hasAllPermission = businessUnits.includes('ALL');
  Object.keys(buBaseduserList).forEach(buId => {
    if (buId === 'ALL') {
      return;
    }
    if (businessUnits.includes(buId) || hasAllPermission) {
      if (!buBaseduserList[buId].includes(userId)) {
        buBaseduserList[buId]?.push(userId);
      }
    } else if (!businessUnits.includes(buId) && buBaseduserList[buId].includes(userId)) {
      const idx = buBaseduserList[buId].indexOf(userId);
      buBaseduserList[buId].splice(idx, 1);
    } else {
      //do nothing
    }
  });
};
