import bfile from '@images/icons/file.svg';
import excel from '@images/icons/excel.svg';
import word from '@images/icons/word.svg';
import ppt from '@images/icons/powerpoint.svg';
import word_v2 from '../assets/images/icons/Word_v2.svg';
import excel_v2 from '../assets/images/icons/excel_v2.svg';
import others_v2 from '../assets/images/icons/others_v2.svg';
import pdf_v2 from '../assets/images/icons/pdf_v2.svg';
import ppt_v2 from '../assets/images/icons/powerpoint_v2.svg';
import image_v2 from '../assets/images/icons/image_v2.svg';
import excel_thumbnail from '../assets/images/icons/Excel_thumbnail.svg';
import word_thumbnail from '../assets/images/icons/Word_thumbnail.svg';
import others_thumbnail from '../assets/images/icons/others_thumbnail.svg';
import pdf_thumbnail from '../assets/images/icons/pdf_thumbnail.svg';
import ppt_thumbnail from '../assets/images/icons/Powerpoint_thumbnail.svg';
import image_thumbnail from '../assets/images/icons/image_thumbnail.svg';
import pdf from '@images/icons/pdf.svg';
import image from '@images/icons/image.svg';
import onenote from '@images/icons/one note.svg';
import bfile_linked from '@images/icons/file-linked.svg';
import excel_linked from '@images/icons/excel-linked.svg';
import word_linked from '@images/icons/word-linked.svg';
import ppt_linked from '@images/icons/powerpoint-linked.svg';
import pdf_linked from '@images/icons/pdf-linked.svg';
import image_linked from '@images/icons/image-linked.svg';
import onenote_linked from '@images/icons/one note-linked.svg';
import wordLine from '@images/icons/word_line.svg';
import pptLine from '@images/icons/ppt_line.svg';
import excelLine from '@images/icons/excel_line.svg';
import filerror from '@images/icons/file error.svg';
import FileDetailsVO from '@interfaces/Models/FileDetailsVO';
import { podName } from '@helpers/Axios';
import { podName as guestPodName } from '@helpers/GuestAxios';
import { errorLogger } from '@utils/ErrorLogging';
import SnackBarUtils from '@utils/SnackBarUtils';
import axiosInstance from '@helpers/Axios';
import i18next from 'i18next';
import { DLFilesVO } from '@interfaces/Models/DLFilesVO';
import ProjectFileVO from '@interfaces/Models/ProjectFileVO';
import { batchInfoIntervalInvoke, getBatchProcessDetails, getCompanyId, titlecase } from './GeneralUtils';
import ProjectVO from '@interfaces/Models/ProjectVO';
import { trackCustomEvent } from './analyticsUtil';
import gDrive from '@images/icons/google_drive.png';
import dropBox from '@images/icons/dropbox-fill.svg';
import door from '@images/icons/door.svg';
import cloudFill from '@images/icons/folders-fill.svg';
import highspot from '@images/icons/highspot.png';
import seismic from '@images/icons/seismic.png';
import box from '@images/icons/box-storage.svg';
import { isFeatureEnabled } from './FeaturePreference';
import { PdfFilePreviewProps } from '@interfaces/Models/CommonFilesVO';
import guestAxiosInstance from '@helpers/GuestAxios';
import { UserAccountType } from '@interfaces/Models/UserAccountInfoVO';
import googleSignIn from '@images/icons/signin-google.png';
import oneDriveSignIn from '@images/icons/signin-one-drive.png';
import boxSignIn from '@images/icons/signin-box.png';
import dropboxSignIn from '@images/icons/sign-in-with-dropbox.png';

export enum FILE_LOCATION {
  LOCAL = 'LOCAL',
  DOCUMENT_LIBRARY = 'DOCUMENT_LIBRARY',
  PROJECT_DOCS = 'PROJECT_DOCS',
  GOOGLE_DRIVE = 'GOOGLE_DRIVE',
  DROPBOX = 'DROPBOX',
  ONEDRIVE = 'ONEDRIVE',
  BOX = 'BOX',
  HIGHSPOT = 'HIGHSPOT',
  SEISMIC = 'SEISMIC',
  SIGNED_DOCUMENT = 'SIGNED_DOCUMENT_LIBRARY'
}

export enum FILE_EXPORT_TO {
  ZOHO = 'ZOHO',
  SALESFORCE = 'SALESFORCE'
}

export enum DOCUMENT_SOURCE {
  LOCAL_DRIVE,
  DROPBOX,
  GOOGLE_DRIVE,
  BOX,
  ONE_DRIVE,
  SALESFORCE,
  DOCUMENT_LIBRARY,
  SHAREPOINT,
  PIPEDRIVE,
  MICROSOFT_TEAMS,
  MICROSOFT_DYNAMICS_CRM,
  HUBSPOT,
  SLACK,
  PARTNER_COMPANY,
  HIGHSPOT,
  SEISMIC
}

export enum SOURCE_CONSTANTS {
  DROPBOX = 'Dropbox',
  GOOGLE_DRIVE = 'Drive',
  BOX = 'Box',
  ONE_DRIVE = 'One Drive',
  HIGHSPOT = 'Highspot',
  SEISMIC = 'Seismic',
  DOOR = 'Door',
  SLACK = 'Slack'
}
export enum FEATURE_CONSTANTS {
  DROPBOX = 'cloud_storage_dropbox',
  GOOGLE_DRIVE = 'cloud_storage_googledrive',
  BOX = 'cloud_storage_box',
  ONE_DRIVE = 'cloud_storage_one_drive',
  HIGHSPOT = 'highspot',
  SEISMIC = 'seismic'
}

export enum EXTERNAL_INTEGRATIONS {
  DOOR = 'Door'
}

export enum INTEGRATION_PREFERENCES {
  SLACK = 'SLACK'
}

export enum CheckPreviewBatchStatus {
  PREVIEW_AVAILABLE = 'preview available',
  RETRY = 'retry',
  NO_PREVIEW_AVAILABLE = 'no preview available',
  ERROR = 'error'
}

export const acceptableCloudFileExtensions = ['.doc', '.docx', '.pdf', '.xls', '.xlsx', '.xlsm'];

export const pptSourceList = ['.pptx', '.ppt'];

export const restrictedFileTypes = [
  'application/java-archive',
  'application/x-bat',
  'application/octet-stream',
  'text/x-java-source',
  'application/x-javascript',
  'application/javascript',
  'application/ecmascript',
  'text/javascript',
  'text/ecmascript',
  'text/html',
  'application/x-msdownload',
  'application/vnd.ms-cab-compressed',
  'text/csv',
  'audio/adpcm',
  'application/vnd.android.package-archive',
  'application/vnd.ms-htmlhelp',
  'application/x-ms-installer',
  'application/x-dosexe'
];

const guestList: string[] = ['GUEST', 'GUEST_V2'];

export const imageTypeList = [
  'image/bmp',
  'image/x-ms-bmp',
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/tiff',
  'image/x-emf',
  'image/emf',
  'image/wmf',
  'application/x-emf',
  'application/x-msmetafile'
];

export const pinSupportImages = ['image/bmp', 'image/x-ms-bmp', 'image/jpeg', 'image/png', 'image/gif', 'image/tiff', 'image/wmf', 'application/x-msmetafile'];

export const iconTypeMap: { [key: string]: { icon: string; svg: any; linked: any; v2_img?: any; v2_large?: any } } = {
  image: { icon: 'rfpio-font-icon icon-image', svg: image, linked: image_linked, v2_img: image_v2, v2_large: image_thumbnail },
  pdf: { icon: 'rfpio-font-icon icon-pdf', svg: pdf, linked: pdf_linked, v2_img: pdf_v2, v2_large: pdf_thumbnail },
  excel: { icon: 'rfpio-font-icon icon-excel', svg: excel, linked: excel_linked, v2_img: excel_v2, v2_large: excel_thumbnail },
  word: { icon: 'rfpio-font-icon icon-word', svg: word, linked: word_linked, v2_img: word_v2, v2_large: word_thumbnail },
  ppt: { icon: 'rfpio-font-icon icon-power-point', svg: ppt, linked: ppt_linked, v2_img: ppt_v2, v2_large: ppt_thumbnail },
  onenote: { icon: 'rfpio-font-icon icon-import-file-view-fill', svg: onenote, linked: onenote_linked, v2_img: onenote },
  infected: { icon: 'rfpio-font-icon icon-warning', svg: filerror, linked: filerror, v2_img: filerror },
  uploading: { icon: 'rfpio-font-icon icon-upload-cloud-2-line', svg: '', linked: '', v2_img: '' },
  binary: { icon: 'rfpio-font-icon icon-import-file-view-fill', svg: bfile, linked: bfile_linked, v2_img: others_v2, v2_large: others_thumbnail }
};
declare global {
  interface Window {
    constants: any;
  }
}

export const downloadFileByUrl = (href: string, fileName: string) => {
  const elem = document.createElement('a');
  elem.href = href;
  elem.setAttribute('download', fileName);
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
};

export const getFileDownloadURL = (file: any, context: any, isPdf?: boolean) => {
  const fileName = file !== null && (file?.fineName !== null || file?.name !== null) && getValidFileName(file.fileName || file.name);
  if (guestList.includes(context.type) && context.guestId) {
    if (context.page && context.page === 'E_SIGN') {
      return window.constants.baseUrl + 'esign-guest/comments/download/' + context.guestId + '/' + file.downloadAuthKey + '/' + fileName;
    }
    const guest = context.type === 'GUEST_V2' ? 'guest_v2' : 'guest';
    const url = window.constants.baseUrl + guest + '/download/' + context.guestId + '/' + file.downloadAuthKey + '/' + fileName;
    if (!podName) {
      return url;
    }
    return url + '?podName%3D' + podName;
  }
  if (context.type === 'TASK' || context.type === 'SECTION' || context.type === 'QUESTION' || (context.page && context.page === 'E_SIGN')) {
    let url = window.constants.baseUrl + 'projects/files/download/' + file.downloadAuthKey + '/' + fileName;
    if (file.partnerDownloadAuthKey) {
      if (isPdf) {
        url = url + '%3FpartnerAuthString%3D' + file.partnerDownloadAuthKey;
      } else {
        url = url + '?partnerAuthString=' + file.partnerDownloadAuthKey;
      }
    }
    return url;
  }
  if (context.type === 'GUEST_V3') {
    const guestPrefix = `guest_v3/${context.guestUrlPrefix}`;
    return `${window.constants.baseUrl}${guestPrefix}/download/${context.guestId ? `${context.guestId}/` : ''}${file.downloadAuthKey}/${fileName}?podName=${guestPodName}`;
  }
  return '';
  /* needs to be implemented
    
    if (file.isESign && file.guestKey) {
        return window.constants.baseUrl + 'guest/download-sign/' + file.guestKey + '/' + file.downloadAuthKey + '/' + fileName;
    }
    if (file.guestKey) {
        return window.constants.baseUrl + 'guest_v2/download/' + file.guestKey + '/' + file.downloadAuthKey + '/' + fileName;
    }
    if (file.partnerDownloadAuthKey) {
        if (isPdf === true) {
            url = url + '%3FpartnerAuthString%3D' + file.partnerDownloadAuthKey;
        } else {
            url = url + '?partnerAuthString=' + file.partnerDownloadAuthKey;
        }
    } */
};

export const evaluateSeiesmicFileUpload = (files: any[], docObject: { acceptedFiles: any[]; rejectedFiles: any[] }) => {
  files.forEach((file: any) => {
    const fileExt = getFileExtension(file);
    if (acceptableCloudFileExtensions.includes(fileExt)) {
      docObject.acceptedFiles.push(file);
    } else {
      docObject.rejectedFiles.push(file.fileName || file.name);
    }
  });
};

export const getFileExtension = (file: any) => {
  if (!file) {
    return '';
  }
  let fileType = '';
  if (file.name) {
    fileType = file.name.substring(file.name.lastIndexOf('.'));
  } else if (file.fileName) {
    fileType = file.fileName.substring(file.fileName.lastIndexOf('.'));
  }
  const type = fileType?.split('/');
  if (type && type[0] === 'image') {
    return image;
  }
  return fileType;
};

export const getAttachFileIcon = (file: any, iconType?: string, response?: any) => {
  if (!file) {
    return '';
  }
  let fileType = '';
  if (file && (file.fileType || file.type)) {
    fileType = file.fileType || file.type;
  }
  if (!fileType) {
    if (file.name) {
      fileType = file.name.substring(file.name.lastIndexOf('.') + 1);
    } else if (file.fileName) {
      fileType = file.fileName.substring(file.fileName.lastIndexOf('.') + 1);
    }
  }
  const isLinked: boolean = file.type === 'LINKED';
  const type = fileType?.split('/');
  if (type && type[0] === 'image') {
    return isLinked || response ? image_linked : iconType === 'v2_img' ? image_v2 : iconType === 'v2_large' ? image_thumbnail : image;
  }
  if (!iconType) {
    iconType = 'icon';
  }
  return isLinked || response
    ? iconTypeMap[getFileType(fileType)].linked
    : iconType === 'icon'
    ? iconTypeMap[getFileType(fileType)].icon
    : iconType === 'v2_img'
    ? iconTypeMap[getFileType(fileType)].v2_img
    : iconType === 'v2_large'
    ? iconTypeMap[getFileType(fileType)].v2_large
    : iconTypeMap[getFileType(fileType)].svg;
};

export const getFileTypeIcon = (fileType: string, iconType?: string) => {
  return iconType === 'icon' ? iconTypeMap[getFileType(fileType)].icon : iconTypeMap[getFileType(fileType)].svg;
};

export const getFileType = (fileType: string) => {
  const fileTypeInLowerCase: string = fileType ? fileType.toLowerCase() : '';
  switch (fileTypeInLowerCase) {
    case 'image/png':
    case 'image/jpeg':
    case 'image':
      return 'image';

    case 'application/pdf':
    case 'pdf':
      return 'pdf';

    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.ms-excel.sheet.macroenabled.12':
    case 'application/vnd.ms-excel':
    case 'xls':
    case 'xlsx':
    case 'xlsm':
    case 'xlsb':
    case 'excel_x':
      return 'excel';

    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.ms-word.document.macroenabled.12':
    case 'application/msword':
    case 'doc':
    case 'docx':
    case 'docm':
    case 'word_x':
      return 'word';

    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'application/vnd.ms-powerpoint':
    case 'pptx':
    case 'ppsx':
    case 'power_point_x':
      return 'ppt';

    case 'one':
      return 'onenote';

    case 'Infected':
      return 'infected';

    case 'Uploading':
      return 'uploading';

    case 'image/png':
    case 'image/jpeg':
      return 'png';

    default:
      return 'binary';
  }
};

export function trimFileExtension(fileName: any) {
  const name: string = fileName;
  const idx = name.lastIndexOf('.');
  if (idx > -1) {
    return name.substring(0, name.lastIndexOf('.'));
  }
  return name;
}

export function getValidFileName(fileName: string) {
  return fileName.replace(/[?!@$^&*:><"'|;#% \\[\]//,]/g, '_');
}

export function formatBytes(bytes: any, decimals = 2) {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const getFileDownloadUrlForPreview = function (file: FileDetailsVO, isPreview?: boolean, guestId?: string, urlPrefix?: string, guestType?: string) {
  let fileName = file.fileName || file.name;
  if (!fileName) {
    return;
  }
  fileName = getValidFileName(fileName);
  let url = '';
  if (guestId) {
    url = `${window.origin}/rfpserver/${guestType === 'guest_v3/profiles' ? guestType : 'guest_v2'}/download/${guestId}/${file.downloadAuthKey}/${fileName}`;

    if (isPreview) {
      url = url + '%3FisPreview%3Dtrue';
    }
    if (!podName) {
      return url;
    }
    // url = url + '&podName%3D' + podName;
  } else {
    fileName = getValidFileName(fileName);
    const tUrlPrefix = urlPrefix || 'projects/files';
    url = `${window.origin}/rfpserver/${tUrlPrefix}/download/${file.downloadAuthKey}/${fileName}`;
    const isImage: boolean = file.fileType && imageTypeList?.includes(file.fileType) ? true : false;
    const isPdf: boolean = ['pdf', 'application/pdf'].includes(file.fileType);
    if (file.partnerDownloadAuthKey) {
      url = url + (isImage || (!isPreview && !isPdf) ? '?partnerAuthString=' : '?partnerAuthString%3D') + file.partnerDownloadAuthKey;
    }
    if (isPreview) {
      url = url + (file.partnerDownloadAuthKey ? (isImage ? '&isPreview=true' : '%26isPreview%3Dtrue') : isImage ? '?isPreview=true' : '%3FisPreview%3Dtrue');
    }
    if (urlPrefix === 'guest_v3/request') {
      let tpodName = isImage || (!isPreview && !isPdf) ? `?podName=${guestPodName}` : `?podName%3D${guestPodName}`;
      if (file.partnerDownloadAuthKey || isPreview) {
        tpodName = isImage ? `&podName=${guestPodName}` : `%26podName%3D${guestPodName}`;
      }
      url += tpodName;
    }
  }
  return url;
};

export const downloadFile = (file: any, context: any, savedContentSearchId?: string) => {
  const link = document.createElement('a');
  link.href = getFileDownloadURL(file, context);
  if (savedContentSearchId) {
    link.href = link.href + '?savedContentSearchId=' + savedContentSearchId;
  }
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

//pass the download key and file name
export const downloadFileWithDownloadKey = (downloadKey: string, fileName: string) => {
  try {
    const link = document.createElement('a');
    link.href = '/rfpserver/report/download/' + downloadKey + '/' + fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const publishToSalesforce = async (projectId: string, packageId: string) => {
  SnackBarUtils.toast(i18next.t('PUBLISH_REQUESTED_SUBMITTED'));
  try {
    const response = await axiosInstance().post('projects/respond/publish-package', { projectId: projectId, packageId: packageId, crmType: FILE_EXPORT_TO.SALESFORCE });
    if (response && response.status === 200) {
      SnackBarUtils.success(response.data.additional_message);
      trackCustomEvent({ eventName: 'Project - Published To SalesForce', eventProperties: { projectId: projectId, packageId: packageId } });
    } else {
      SnackBarUtils.error(i18next.t('ERROR_WHILE_PUBLISHING_PACKAGE'));
    }
  } catch (e) {
    errorLogger(e, false);
  }
};

export const publishToZoho = async (projectId: string, packageId: string, projectVO: ProjectVO, callBackUpdateFnc: any) => {
  SnackBarUtils.success(i18next.t('PUBLISH_REQUESTED_SUBMITTED'));
  try {
    const response = await axiosInstance().post('projects/respond/publish-package', { projectId: projectId, packageId: packageId, crmType: FILE_EXPORT_TO.ZOHO });
    if (response && response.status === 200) {
      SnackBarUtils.success(response.data.additional_message);
      updatePublishedPackage({ project: projectVO, package: { id: packageId }, callBackFunc: callBackUpdateFnc, crmType: FILE_EXPORT_TO.ZOHO });
      trackCustomEvent({ eventName: 'Project - Published To Zoho CRM', eventProperties: { projectId: projectId, packageId: packageId } });
    } else {
      SnackBarUtils.error(i18next.t('ERROR_WHILE_PUBLISHING_PACKAGE'));
    }
  } catch (e) {
    errorLogger(e, false);
  }
};

export const publishToHighSpot = async (fileId: string, projectId: string, packageId: string, packageName: string, spotId: string) => {
  SnackBarUtils.toast(i18next.t('PUBLISH_REQUESTED_SUBMITTED'));
  const timeout = setTimeout(() => {
    SnackBarUtils.toast(i18next.t('PACKAGE_PUBLISH_IN_PROGRESS'));
  }, 120);
  try {
    const response = await axiosInstance().post('projects/respond/upload-package', { fileId: fileId, projectId: projectId, packageId: packageId, packageName: packageName, spotId: spotId });
    if (response && response.status === 200) {
      SnackBarUtils.success(i18next.t('PUBLISH_PACKAGE_HIGHSPOT_COMPLETED'));
      trackCustomEvent({ eventName: 'Project - Published To HighSpot', eventProperties: { projectId: projectId, packageId: packageId, spotId: spotId } });
    } else {
      SnackBarUtils.error(i18next.t('ERROR_WHILE_PUBLISHING_PACKAGE'));
    }
    clearTimeout(timeout);
  } catch (e) {
    errorLogger(e, false);
  }
};

export const publishToSeismic = async (selectedFolderId: string, source: string, packages: any, teamSiteId: string, project: ProjectVO, callBackFunc: any, parentFolderName?: string) => {
  SnackBarUtils.success(i18next.t('PUBLISH_REQUESTED_SUBMITTED'));
  const timeout = setTimeout(() => {
    SnackBarUtils.success(i18next.t('PACKAGE_PUBLISH_IN_PROGRESS'));
    clearTimeout(timeout);
  }, 1000);
  try {
    const obj = {
      folderId: selectedFolderId,
      source: source,
      projectId: project.id,
      packageId: packages.id,
      packageName: packages.packageName,
      fileId: packages.fileId,
      teamSiteId: teamSiteId,
      parentFolderName: parentFolderName
    };
    const response = await axiosInstance().post('projects/respond/upload-package-seismic', obj);
    if (response && response.status === 200) {
      const batchId = response.data.additional_message;
      getBatchProcessDetails(batchId);
      batchInfoIntervalInvoke(updatePublishedPackage, { project: project, package: packages, callBackFunc: callBackFunc, crmType: FILE_LOCATION.SEISMIC });
      trackCustomEvent({ eventName: 'Project - Published To Seismic', eventProperties: { projectId: project.id, packageId: packages.id } });
    } else {
      SnackBarUtils.error(i18next.t('ERROR_WHILE_PUBLISHING_PACKAGE'));
    }
  } catch (e) {
    errorLogger(e, false);
  }
};

const updatePublishedPackage = (projectParam: any) => {
  const packages = projectParam.package;
  const packageRequests = projectParam.project.packageRequests;
  for (let i = 0, leng = packageRequests.length; i < leng; i++) {
    if (packageRequests[i].id === packages.id) {
      if (Array.isArray(packageRequests[i].publishedTo) && (!packageRequests[i].publishedTo.includes(FILE_LOCATION.SEISMIC) || !packageRequests[i].publishedTo.includes(FILE_EXPORT_TO.ZOHO))) {
        if (projectParam.crmType === FILE_EXPORT_TO.ZOHO) {
          packageRequests[i].publishedTo.push(FILE_EXPORT_TO.ZOHO);
        } else if (projectParam.crmType === FILE_LOCATION.SEISMIC) {
          packageRequests[i].publishedTo.push(FILE_LOCATION.SEISMIC);
        } else {
          //do nothing
        }
      }
      packageRequests[i].publishedTo = packageRequests[i].publishedTo || (projectParam.crmType === FILE_EXPORT_TO.ZOHO ? [FILE_EXPORT_TO.ZOHO] : [FILE_LOCATION.SEISMIC]);
      break;
    }
  }
  projectParam.callBackFunc(packageRequests);
  projectParam.crmType === FILE_LOCATION.SEISMIC && SnackBarUtils.success(i18next.t('PUBLISH_PACKAGE_SEISMIC_COMPLETED'));
};

export const getDocIds = (selectedFiles: DLFilesVO[] | ProjectFileVO[]) => {
  const docIds: string[] = [];
  selectedFiles.forEach((file: any) => {
    docIds.push(file.id);
  });
  return docIds;
};

export const exportReport = (exportFileName: string, exportDownloadKey: string, type?: string) => {
  const str = exportFileName.replace(/[^A-Z0-9]/gi, '_');
  let fileName = str.replace(/(_+)/gi, '_');
  let fileType = type === 'EXPORT_TO_PDF' ? '.pdf' : '.xlsx';
  const elem = document.createElement('a');
  fileName = fileName + fileType;
  elem.href = window.constants.baseUrl + 'report/get/' + exportDownloadKey + '/' + fileName;
  if (type === 'EXPORT_TO_PDF') {
    elem.href = window.constants.baseUrl + 'projects/files/download/' + exportDownloadKey + '/' + fileName;
  }
  elem.setAttribute('download', fileName);
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
};

export const exportFileByFileNameAndUrl = (fileName: string, downloadKey: string, fileType: string, urlPrefix: string) => {
  const str = fileName.replace(/[^A-Z0-9]/gi, '_');
  let tFileName = str.replace(/(_+)/gi, '_');
  tFileName = tFileName + fileType;
  const elem = document.createElement('a');
  elem.href = window.constants.baseUrl + urlPrefix + downloadKey + '/' + tFileName;
  elem.setAttribute('download', tFileName);
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
};

export const wopiSupportedFileTypes = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel.sheet.binary.macroenabled.12',
  'application/vnd.ms-excel.sheet.macroenabled.12',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-word.document.macroenabled.12'
];

export const templateDuplicateSupportedFileTypes = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel.sheet.macroenabled.12',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-word.document.macroenabled.12',
  'application/pdf'
];

const lineIconMap: { [key: string]: string } = {
  word: wordLine,
  ppt: pptLine,
  excel: excelLine
};

export const getLineIcon = (fileName: string) => {
  return lineIconMap[getFileType(fileName)];
};

export const getFileFormatForUpdateSourceFile = (fileId: string) => {
  const fileType = fileId.split('.').at(-1)?.toLowerCase();
  if (fileType && fileType === 'docx') {
    return 'WORD';
  } else if (fileType && (fileType === 'xlsx' || fileType === 'xls' || fileType === 'xlsm')) {
    return 'EXCEL';
  } else {
    return '';
  }
};

export const getExternalIntegrations = () => {
  const features: { [key: string]: { value: string; logo: any; icon: boolean; enabled: boolean; showOptValue: boolean } } = {};
  Object.keys(EXTERNAL_INTEGRATIONS).forEach((source: string) => {
    const feature = EXTERNAL_INTEGRATIONS[source];
    features[source] = {
      value: feature,
      logo: getFeatureLogo(feature),
      icon: false,
      enabled: true,
      showOptValue: true
    };
  });
  return features;
};

export const getEnabledFeatures = () => {
  return Object.keys(FEATURE_CONSTANTS).filter(source => isFeatureEnabled(FEATURE_CONSTANTS[source]));
};

export const getPartnerContent = () => {
  const enabledFeatures: string[] = getEnabledFeatures();
  const features: { [key: string]: { value: string; logo: any; icon: boolean; enabled: boolean; showOptValue: boolean } } = {};
  Object.keys(FEATURE_CONSTANTS).forEach((source: string, index: number) => {
    const feature = SOURCE_CONSTANTS[source];
    features[source] = {
      value: feature,
      logo: getFeatureLogo(feature),
      icon: [SOURCE_CONSTANTS.BOX, SOURCE_CONSTANTS.SEISMIC].includes(feature),
      enabled: enabledFeatures.includes(source),
      showOptValue: true
    };
  });
  return features;
};

export function getFeatureLogo(source: string) {
  switch (source) {
    case SOURCE_CONSTANTS.BOX:
      return 'text-blue rfpio-font-icon icon-box';
    case FILE_LOCATION.BOX:
      return box;
    case SOURCE_CONSTANTS.DROPBOX:
    case FILE_LOCATION.DROPBOX:
      return dropBox;
    case SOURCE_CONSTANTS.GOOGLE_DRIVE:
    case FILE_LOCATION.GOOGLE_DRIVE:
      return gDrive;
    case SOURCE_CONSTANTS.HIGHSPOT:
      return highspot;
    case FILE_LOCATION.HIGHSPOT:
      return highspot;
    case SOURCE_CONSTANTS.ONE_DRIVE:
    case UserAccountType.ONE_DRIVE:
      return cloudFill;
    case SOURCE_CONSTANTS.SEISMIC:
      return 'font-12-imp seismic-logo-color rfpio-font-icon icon-seismic-logo';
    case EXTERNAL_INTEGRATIONS.DOOR:
      return door;
  }
}

export const getFileTypeName = (fileType: string) => {
  const type = getFileType(fileType);
  return type === 'ppt' ? 'Presentations' : titlecase(type);
};

export const excludeforSalesforce = [
  FILE_LOCATION.DOCUMENT_LIBRARY,
  FILE_LOCATION.PROJECT_DOCS,
  FILE_LOCATION.GOOGLE_DRIVE,
  FILE_LOCATION.DROPBOX,
  FILE_LOCATION.BOX,
  FILE_LOCATION.ONEDRIVE,
  FILE_LOCATION.SEISMIC,
  FILE_LOCATION.HIGHSPOT
];

export const downloadFileWithCustomType = (exportDownloadKey: string, fileName: string, type: string, urlPrefix: string) => {
  const str: string = fileName.replace(/[^A-Z0-9]/gi, '_');
  let name: string = str.replace(/(_+)/gi, '_');
  name = name + '.' + type;
  let guestUserData: { [key: string]: string } = localStorage.getItem('guestUserData') as unknown as { [key: string]: string };
  guestUserData = guestUserData && JSON.parse(guestUserData as unknown as string);
  const elem: HTMLAnchorElement = document.createElement('a');
  elem.href = `${window.constants.baseUrl}guest_v3/${urlPrefix}/${exportDownloadKey}/${name}?guestKey=${guestUserData.guestKey}`;
  elem.setAttribute('download', name);
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
};

let intervalIdForPDFPreview: any = null;
const clearBatchInterval = () => {
  clearInterval(intervalIdForPDFPreview);
  intervalIdForPDFPreview = null;
};

const getFinalPdfUrl = (props: PdfFilePreviewProps) => {
  const { isGuest, guestType, guestId, guestUrlPrefix, file, skipPdfViewerUrl, urlPrefix, skipPreview } = props;
  let url = skipPdfViewerUrl ? '' : getPdfUrl();
  url += isGuest ? getFileDownloadURL(file, { type: guestType || 'GUEST_V2', guestId: guestId, guestUrlPrefix: guestUrlPrefix }) : getFileDownloadUrlForPreview(file, undefined, undefined, urlPrefix);
  url += `${file.partnerDownloadAuthKey || (isGuest && podName) ? buildQueryParam(url, true, 'isPreview', 'true') : skipPreview ? '' : buildQueryParam(url, false, 'isPreview', 'true')}`;
  return url;
};

const buildQueryParam = (url: string, pdfPreviewer: boolean, querykey: string, queryParamValue: string) => {
  const queryParams: any = url.split('?')[1];
  if (queryParams && queryParams.trim()) {
    return pdfPreviewer ? '%26' + querykey + '%3D' + queryParamValue : '&' + querykey + '=' + queryParamValue;
  } else {
    let queryParam;
    if (url.indexOf('?') === -1 || url.indexOf('%3F') === -1) {
      queryParam = pdfPreviewer ? '%3F' : '?';
    }
    queryParam += pdfPreviewer ? querykey + '%3D' + queryParamValue : querykey + '=' + queryParamValue;
    return queryParam;
  }
};

const getAxiosInstance = (isGuest?: boolean) => {
  // Mapping the axiosInstance according to the user type
  return isGuest ? guestAxiosInstance : axiosInstance;
};

const clearAndUpdateBatchMap = (batchId: string, batchMap: { [key: string]: any }, setBatchMap: any) => {
  const value = batchMap.filter((x: any) => x.id === batchId);
  // In below we're checking whether previous batch and current batch same if matched we're clearing up the interval
  if (value.length > 0) {
    const index = batchMap.indexOf(value[0]);
    batchMap.splice(index, 1);
    setBatchMap(batchMap);
    clearInterval(value[0].interval);
  }
};

const getBatchProcessStatusForPdfPreview = async function (batchId: any, props: PdfFilePreviewProps) {
  const { batchMap, setBatchMap, callBackFunc, isGuest, guestId, guestType } = props;
  try {
    let queryParam = { id: batchId };
    const url = isGuest ? (guestType === 'GUEST_V3' ? 'guest_v3/batch-process/get-batch-details-guest' : `guest_v2/get-batch-details/${guestId}`) : 'batch-process/get-batch-details';
    const results = await getAxiosInstance(isGuest)().get(url, { params: queryParam });
    if (results.status === 200) {
      if (results.data.status === 'COMPLETED') {
        generatePdfFileForPreview(props);
        clearAndUpdateBatchMap(batchId, batchMap, setBatchMap);
      } else if (results.data.status === 'ERROR') {
        clearAndUpdateBatchMap(batchId, batchMap, setBatchMap);
        // 1st param is Error
        // 2nd param is Loading
        // 3rd param is preview url
        callBackFunc(true, false, '');
      }
    } else {
      clearAndUpdateBatchMap(batchId, batchMap, setBatchMap);
      // 1st param is Error
      // 2nd param is Loading
      // 3rd param is preview url
      callBackFunc(true, false, '');
    }
  } catch (e) {
    errorLogger(e, false);
    clearAndUpdateBatchMap(batchId, batchMap, setBatchMap);
    callBackFunc(true, false, '');
  }
};

const generatePreviewFile = async function (props: PdfFilePreviewProps) {
  const { batchMap, setBatchMap, file, isGuest, guestType, guestUrlPrefix, guestId, callBackFunc, urlPrefix } = props;
  try {
    const fileName = getValidFileName(file.fileName || file.name || '');
    const tempUrl: string = isGuest
      ? guestType === 'GUEST_V3' && guestUrlPrefix
        ? `guest_v3/${guestUrlPrefix}/preview`
        : `guest_v2/preview/${guestId}`
      : urlPrefix
      ? `${urlPrefix}/preview`
      : 'projects/files/preview';
    let url = `${window.origin}/rfpserver/${tempUrl}/${file.downloadAuthKey}/${fileName}`;
    if (file.partnerDownloadAuthKey) {
      url = url + isGuest && podName ? '&partnerAuthString=' : '?partnerAuthString=' + file.partnerDownloadAuthKey;
    }
    const results = await getAxiosInstance(isGuest)().get(url);
    if (results.data.additional_message) {
      if (results.data.additional_message === 'No Preview Available') {
        // 1st param is Error
        // 2nd param is Loading
        // 3rd param is preview url
        callBackFunc(true, false, '');
      } else if (results.data.additional_message === 'Preview Available') {
        const url = getFinalPdfUrl(props);
        callBackFunc(false, false, url);
      } else if (results.data.additional_message === 'Retry') {
        // In our below case batch retured retry so we are re-triggering the method to re-generate preview again
        setTimeout(function () {
          generatePreviewFile(props);
        }, 5000);
      } else {
        intervalIdForPDFPreview = setInterval(function () {
          getBatchProcessStatusForPdfPreview(results.data.additional_message, props);
        }, 1 * 5000);
        const obj = {
          id: results.data.additional_message,
          interval: intervalIdForPDFPreview
        };
        batchMap.push(obj);
        setBatchMap(batchMap);
      }
    } else {
      const url = getFinalPdfUrl(props);
      callBackFunc(false, false, url);
    }
  } catch (e) {
    errorLogger(e, false);
    callBackFunc(true, false, '');
  }
};

export const generatePdfFileForPreview = function (props: PdfFilePreviewProps) {
  const { file, callBackFunc, skipPreview, isGuest } = props;

  // Clearing the Interval below
  if (intervalIdForPDFPreview) {
    clearBatchInterval();
  }

  // If The file type is pdf we will be directly setting the url below
  if (file.fileType === 'application/pdf') {
    if (!isGuest) {
      props.skipPreview = true;
    }
    const url = getFinalPdfUrl(props);
    // 1st param is Error
    // 2nd param is Loading
    // 3rd param is preview url
    setTimeout(() => {
      // Made async if your callback function is updating store variables
      callBackFunc(false, false, url);
    });
  } else {
    // If the file type is other than PDF we'll be running our batch process and generate url
    generatePreviewFile(props);
  }
};

export const getPdfUrl = () => {
  let url = window.location.origin;
  url += window.location.hostname === 'localhost' ? '/app' : '';
  url = url + '/app/vendor/pdfjs/viewer.html?file=';
  return url;
};

export const CLOUD_UPDATE_SOURCE: string[] = ['SEISMIC', 'GOOGLE_DRIVE', 'ONE_DRIVE', 'BOX', 'HIGHSPOT', 'DROPBOX'];

export const CLOUD_UPDATE_SOURCE_MODULES: string[] = ['SEISMIC', 'GOOGLE_DRIVE'];

export const getCloudUpdateTitle = (source: string): string => {
  if (CLOUD_UPDATE_SOURCE.includes(source)) {
    return i18next.t('UPDATE_FROM_CLOUD_SOURCE', { source: i18next.t(['SEISMIC', 'HIGHSPOT'].includes(source) ? 'CLOUD_' + source : source) });
  }
  return '';
};

export function getFeatureIcon(source: string, isSignIn?: boolean) {
  if (!CLOUD_UPDATE_SOURCE.includes(source)) {
    return '';
  }
  switch (source) {
    case FILE_LOCATION.SEISMIC:
      return 'icon-seismic-logo';
    case FILE_LOCATION.GOOGLE_DRIVE:
      return isSignIn ? googleSignIn : 'icon-google-drive';
    case FILE_LOCATION.BOX:
      return isSignIn ? boxSignIn : 'icon-box';
    case FILE_LOCATION.HIGHSPOT:
      return 'icon-highspot1';
    case FILE_LOCATION.DROPBOX:
      return isSignIn ? dropboxSignIn : 'icon-drop-box';
    default:
      return isSignIn ? oneDriveSignIn : 'icon-one-drive';
  }
}
