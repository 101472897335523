import { SectionSummaryVO } from './SectionSummaryVO';
import { ProjectExportResponseVO } from './ProjectExportVO';
import MilestoneSummaryVO from './MilestoneSummaryVO';
import { CRMProjectFileVO } from './ProjectFileVO';

export default interface ProjectVO {
  mergeTags: any;
  projectMergeTags: any;
  description: string;
  issuerEmail: string;
  issuerName: string;
  issuerAddress1: string;
  issuerAddress2: string;
  issuerPhone: string;
  additionalContacts: string[];
  businessUnits: string[];
  category: string[];
  companyId: string;
  createdBy: string;
  createdDate: string;
  customFields?: any;
  dealSize: number;
  deletedSectionList: any[];
  diffTime: number;
  dueDate: string;
  entity: string;
  exported: boolean;
  id: string;
  parentProjectId?: string;
  lastUpdateTs: string;
  milestones: MilestoneSummaryVO[];
  name: string;
  ownedBy: string;
  packageRequests: ProjectExportResponseVO[];
  pendingMailUsers: {}[];
  percentage: number;
  processedForActorDataset: false;
  trackCostEnabled: false;
  buOption: string;
  isComingBack: boolean;
  projectFiles: any[];
  projectFormat: string;
  projectStage: { stage: string; updatedTs: string; stageMap?: any };
  projectType: string;
  primaryBU: string;
  projectOwners: any[];
  requestProjectName: string;
  rfpType: string;
  sampleRFPFlag: number;
  sectionCompletionCount: number;
  sectionCount: number;
  parentSectionCount: number;
  sections: SectionSummaryVO[];
  settings?: any;
  sharedBUs: string[];
  solutionIds: string[];
  status: string;
  supportedFeatures: string[];
  teamMembers: string[];
  totalAnsweredCount: number;
  totalCompletedTaskCount: number;
  totalQuestionCount: number;
  totalTaskCount: number;
  type: string;
  reviewAssignedQuestCount: number;
  reviewdQuestCount: number;
  updatedBy: string;
  updatedContentAvailable: boolean;
  archiveProjectId: string;
  additionalUIParams?: any;
  applicationLanguage?: any;
  displayId?: string;
  crmDetails?: any;
  guestAccessList?: any;
  isCRM: boolean;
  projectCustomFieldVOMap?: any;
  cloudAttachFilesUrls?: any[];
  workspaces?: any[];
  entityType?: string;
  templateOwners?: string[];
  usedCount?: number;
  sourceProjectDetails?: {
    sourceProjectId: string;
    attributesToBeCopied?: string[];
    sourceProjectName?: string;
    fileId?: string;
    sourceFileId?: string;
    isStandardTemplatesType?: boolean;
    selectedTemplateName?: string;
  };
  projectLockedStatus?: string;
  externalId?: string;
  issuerType?: string;
  tempAttachFiles: CRMProjectFileVO[];
  tempProjectFiles: CRMProjectFileVO[];
  crmFiles?: { crmFilesList: CRMProjectFileVO[] };
  crmAttachFiles?: { crmFilesList: CRMProjectFileVO[] };
  addendum?: boolean;
  intakeId?: string;
  migratedSource?: string;
  translationInProgress: boolean;
  fullProjectTranslated: boolean;
  sourceLanguageList: string[];
  translatedBy: string;
  translatedTs: string;
  issuerVendorDetail: {
    projectId: string;
  };
}

export const projectVO: ProjectVO = {
  additionalContacts: [],
  businessUnits: [],
  category: [],
  companyId: '',
  createdBy: '',
  createdDate: '',
  customFields: {},
  dealSize: 0,
  deletedSectionList: [],
  diffTime: 0,
  dueDate: '',
  entity: '',
  exported: false,
  id: '',
  lastUpdateTs: '',
  milestones: [],
  name: '',
  ownedBy: '',
  packageRequests: [],
  pendingMailUsers: [],
  percentage: 0,
  processedForActorDataset: false,
  projectFiles: [],
  projectFormat: '',
  projectStage: { stage: '', updatedTs: '' },
  trackCostEnabled: false,
  projectType: '',
  primaryBU: '',
  rfpType: '',
  sampleRFPFlag: 0,
  sectionCompletionCount: 0,
  sectionCount: 0,
  sections: [],
  settings: '',
  sharedBUs: [],
  buOption: '',
  solutionIds: [],
  status: '',
  supportedFeatures: [],
  teamMembers: [],
  totalAnsweredCount: 0,
  totalCompletedTaskCount: 0,
  totalQuestionCount: 0,
  totalTaskCount: 0,
  type: '',
  updatedBy: '',
  updatedContentAvailable: false,
  mergeTags: [],
  projectMergeTags: [],
  archiveProjectId: '',
  isComingBack: false,
  additionalUIParams: {},
  description: '',
  issuerEmail: '',
  issuerName: '',
  issuerAddress1: '',
  issuerAddress2: '',
  issuerPhone: '',
  isCRM: false,
  workspaces: [],
  entityType: '',
  templateOwners: [],
  usedCount: 0,
  projectOwners: [],
  requestProjectName: '',
  sourceProjectDetails: {
    sourceProjectId: '',
    attributesToBeCopied: [],
    sourceProjectName: '',
    sourceFileId: '',
    isStandardTemplatesType: false,
    selectedTemplateName: ''
  },
  projectLockedStatus: '',
  externalId: '',
  issuerType: '',
  crmFiles: { crmFilesList: [] },
  crmAttachFiles: { crmFilesList: [] },
  tempAttachFiles: [],
  tempProjectFiles: [],
  addendum: false,
  intakeId: '',
  translationInProgress: false,
  fullProjectTranslated: false,
  sourceLanguageList: [],
  translatedTs: '',
  translatedBy: '',
  parentSectionCount: 0,
  reviewAssignedQuestCount: 0,
  reviewdQuestCount: 0
};
