import axiosInstance from '@helpers/Axios';
import MergeTagVO from '@interfaces/Models/MergeTagVO';
import { errorLogger } from '@utils/ErrorLogging';

export const updateUserDefinedMergeTags = async (projectId: string, setUserDefinedMergeTags?: any) => {
  try {
    const response = await axiosInstance().get(`projects/respond/get-merge-tags?projectId=${projectId}`);
    if (response && response.data && response.status === 200) {
      const tags = [...response.data.tags];
      if (tags.length > 0) {
        if (setUserDefinedMergeTags) {
          setUserDefinedMergeTags({ userDefinedMergeTagList: [...tags] });
        } else {
          return [...tags];
        }
      }
    }
  } catch (e) {
    errorLogger(e, false, 'Caught in updateUserDefinedMergeTags');
  }
};

export const addMergeTag = async (url: string, obj: { projectId: string; tag: string; value: string }, userDefinedMergeTags: MergeTagVO[], setUserDefinedMergeTags: any) => {
  try {
    const res = await axiosInstance().post(url, obj);
    if (res && res.data && res.status === 200) {
      userDefinedMergeTags.push(res.data);
      const newArr = [...userDefinedMergeTags];
      const idx = newArr.findIndex((tag: MergeTagVO) => tag.id === res.data.id);
      if (idx < 0) {
        return;
      }
      newArr[idx] = res.data;
      setUserDefinedMergeTags({ userDefinedMergeTagList: [...newArr] });
    }
  } catch (e) {
    errorLogger(e, false, 'Caught in addMergeTag');
  }
};
