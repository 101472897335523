import React, { useMemo, Suspense, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import RouteProps from '../RouteProps';
import { useTranslation } from 'react-i18next';
import { isFeatureEnabled } from '@utils/FeaturePreference';
import { Button, Card } from '@material-ui/core';
import clsx from 'clsx';
import { generalUseStoreState, generalUseStoreActions } from '../../hooks';
import { setRoleDisplayName } from '@utils/UserLoadDetailUtil';
import CommonThemeStyles from '@styles/CommonThemeStyles';
import CRMLogin from '@containers/crm/crm-common-containers/CRMLogin';

const CRMRenderRoute = (route: RouteProps) => {
  const userDetails = generalUseStoreState(state => state.userDetails.userDetails);
  const setCurrentModule = generalUseStoreActions(state => state.additionalInfoStore.setAdditionalInfo);
  const companyPolicyMap = userDetails && userDetails.companyPolicyMap;
  const { t } = useTranslation('translations');
  const userData = localStorage.getItem('userData');
  document.title = route.title || 'Responsive';
  const Layout = route.layout;

  useEffect(() => {
    setCurrentModule({ currentModule: route });
    companyPolicyMap && setRoleDisplayName(companyPolicyMap, t);
  }, [companyPolicyMap]);

  return (
    <QueryParamProvider ReactRouterRoute={Route}>
      <Route
        path={route.path}
        exact
        render={props =>
          route.needsAuth && userData ? (
            !route.checkPermission || (route.checkPermission && isFeatureEnabled(route.checkPermission)) ? (
              <Layout route={route}>
                <>
                  {/* <CompanyIdParam companyId={userDetails.companyInfoVO.companyId} /> */}
                  <DynamicLoader component={route.component} />
                </>
              </Layout>
            ) : (
              <Route component={NotFound}></Route>
            )
          ) : (
            <CRMLogin route={route} />
          )
        }></Route>
    </QueryParamProvider>
  );
};

export default CRMRenderRoute;

const DynamicLoader = (props: any) => {
  const { component } = props;
  const LazyComponent = useMemo(() => component, [component]);
  return (
    <Suspense fallback={<div></div>}>
      <LazyComponent />
    </Suspense>
  );
};
const NotFound = () => {
  const classes = CommonThemeStyles();
  const { t } = useTranslation('translations');
  const handleChange = () => {
    history.back();
  };
  return (
    <div className='display-flex justifyContentCenter align-items-center h-100vh'>
      <Card className={clsx('p-30', classes.cardWidth, 'text-align-center')}>
        <div>
          <span className='rfpio-font-icon icon-warning font-70 text-warning'></span>
        </div>
        <div>
          <span className='text-warned font-35'>{t('YOU_DO_NOT_HAVE_ACCESS')} </span>
          <span className='text-warned font-35'>{t('CONTACT_ADMININSTRATOR')} </span>
        </div>
        <div className='pt-35'>
          <Button variant='contained' size='large' onClick={handleChange}>
            {t('BACK')}
          </Button>
        </div>
      </Card>
    </div>
  );
};
