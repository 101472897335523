import CompanyInfoVO from '@interfaces/Models/CompanyInfoVO';
import { crmFileVO, localFileVO } from '../crm-interface/CRMFilesVO';
import i18next from 'i18next';
import axiosInstance from '@helpers/Axios';
import { errorLogger } from '@utils/ErrorLogging';
import { getApitoken } from '@containers/my-account/MyAccountService';
import { UserAccountType } from '@interfaces/Models/UserAccountInfoVO';
import SnackBarUtils from '@utils/SnackBarUtils';

export const setPodNameForCompany = (companyList: any, companyId: string) => {
  const obj = { podName: '', selectedCompanyId: '' };
  for (let i = 0, len = companyList.length; i < len; i++) {
    if (companyList[i].companyId === companyId) {
      obj.podName = companyList[i].podName;
      obj.selectedCompanyId = companyId;
      break;
    }
  }
  return obj;
};

export const validateSelectedCompany = (companyList: CompanyInfoVO[], companyId: string | null) => {
  for (let i = 0, len = companyList.length; i < len; i++) {
    if (companyList[i].companyId === companyId) {
      return true;
    }
  }
  return false;
};

export const setLocalStore = (key: string, data: any) => {
  localStorage.setItem(key, data);
};

export const removeStore = (key: string) => {
  localStorage.remove(key);
};

export const getLocalStore = (key: string) => {
  return localStorage.getItem(key);
};

export const getFileName = (key: string) => {
  let fileName = key.replace(/(_+)/gi, '_');
  fileName = fileName + '.zip';
  return fileName;
};

export const handleRedirectToApp = (url: string, crmType: string) => {
  let newLocation = '';
  if (window.location.pathname.indexOf('/app') === 0) {
    newLocation = '/app';
  }
  if (crmType !== 'salesforce') {
    window.open(url, '_blank');
  } else {
    const path = url;
    const sendData = {
      type: 'OPEN_WINDOW',
      path: newLocation + path
    };
    window.parent.postMessage(JSON.stringify(sendData), '*');
  }
};

export function getterSetterUserRole(companyId: string, user: { [key: string]: any }, type: string, role?: string) {
  if (!user) {
    return '';
  } else {
    let hasMoreRecords = true;
    let i = 0;
    while (hasMoreRecords) {
      if (user['company_name_' + i]) {
        if (user['id_' + i] === companyId) {
          if (type === 'set') {
            user['role_' + i] = role;
            hasMoreRecords = false;
          } else {
            return user['role_' + i];
          }
        }
      } else {
        hasMoreRecords = false;
      }
      i++;
    }
  }
  type === 'set' && localStorage.setItem('userData', JSON.stringify(user));
}

export const handleIframeHeight = (height?: number) => {
  const iframeHeightTimer = setTimeout(() => {
    let docHeight = height ?? document.body.scrollHeight;
    if (!docHeight || docHeight < 200) {
      docHeight = 200;
    }
    const sendData = {
      type: 'STYLE',
      height: docHeight
    };
    window.parent.postMessage(JSON.stringify(sendData), '*');
    clearTimeout(iframeHeightTimer);
  }, 500);
};
export function updateCurrentUser(userData: { [key: string]: any }) {
  userData && localStorage.setItem('userData', JSON.stringify(userData));
}

export function validateCrmCustomFields(data: any[], object: string, instanceId: string) {
  for (let i = 0, len = data.length; i < len; i++) {
    if (data[i][object] && (!data[i].instanceId || data[i].instanceId === instanceId)) {
      return true;
    }
  }
  return false;
}

export function getCrmObjectValue(data: any[], object: string, instanceId: string) {
  for (let i = 0, len = data.length; i < len; i) {
    if (!data[i].instanceId || data[i].instanceId === instanceId) {
      return data[i][object];
    }
  }
  return null;
}

export function constructCRMData(data: object) {
  for (const [key, value] of Object.entries(data)) {
    if (value != null) {
      if (typeof value === 'object') {
        const res = Object.values(value)
          .map(value => value && value.toString())
          .join('');
        data[key] = res;
      }
      if (typeof value === 'number') {
        data[key] = value.toString();
      }
    }
  }
  return data;
}

export const handleCRMDialogScrollToTop = (id: string) => {
  const timer = setTimeout(() => {
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
    clearTimeout(timer);
  }, 100);
};

export const getDialogDataForError = (setOpen: (open: boolean) => void, message: string, title: string) => {
  const dialogData = {
    title,
    onConfirm: () => {
      setOpen(false);
    },
    confirmText: 'OK',
    hideCancelButton: true,
    content: message
  };
  return dialogData;
};

export const constructFile = (files: crmFileVO[] | localFileVO[]) => {
  if (files[0]?.path) {
    return files;
  }
  const tempFiles = files.map(file => {
    return { ...file, fileType: file.fileType?.toLowerCase() };
  });
  return tempFiles;
};

export enum crmTypes {
  MICROSOFT_DYNAMICS_CRM = 'microsoftdynamicscrm',
  SALESFORCE = 'salesforce'
}

export const adjustIframeHeight = () => {
  const adjustTabHeight = () => {
    handleIframeHeight(window.outerHeight - 100);
  };
  adjustTabHeight();
  window.addEventListener('resize', adjustTabHeight);
  return () => {
    window.removeEventListener('resize', adjustTabHeight);
  };
};

export const teamMemberCustomField = {
  name: 'teamMember',
  displayName: 'Team Member',
  groupName: 'Basic Details',
  type: 'DROPDOWN',
  values: [],
  searchable: false,
  mandatory: false,
  reportable: false,
  editable: true,
  facet: false,
  entityType: 'PROJECT',
  projectTypes: [],
  defaultMergeTag: true,
  includeInMail: false,
  autoIncValue: 0,
  visibleToRespondent: false,
  duplicated: false,
  enabled: false,
  entity: 'CUSTOM_FIELD'
};

export const redirectToManagePage = (recordId: string) => {
  const sendData = {
    type: 'BACK',
    recordId: recordId
  };
  window.parent.postMessage(JSON.stringify(sendData), '*');
};

export const getRecordType = (recordType: string, schemaMap: { [key: string]: { logicalCollectionName: String; schemaName: String } }) => {
  if (!['Account', 'Lead', 'Opportunity'].includes(recordType) && schemaMap) {
    for (const displayName in schemaMap) {
      if (schemaMap[displayName].schemaName.toLowerCase() === recordType) {
        return displayName;
      }
    }
  }
  return recordType;
};

export const getLogicalCollectionName = (displayName: string, schemaMap: { [key: string]: { logicalCollectionName: String; schemaName: String } }) => {
  if (!['Account', 'Lead', 'Opportunity'].includes(displayName) && schemaMap) {
    if (schemaMap.hasOwnProperty(displayName)) {
      return schemaMap[displayName].logicalCollectionName;
    }
  }
  return displayName;
};

export const authenticateUser = async (type: string, setUserData?: any, callBack?: (data: any) => void, onClose?: () => void) => {
  try {
    const results = await axiosInstance().get(`cloud/get-credentials-auth-url?source=${type}`);
    if (results && results.status === 200 && results.data) {
      const url = results.data;
      let newWindow = window.open(url);
      let code: any = null;
      const interval = setInterval(function () {
        // Clear the Interval if the Window is closed by the user
        if (newWindow?.closed) {
          clearInterval(interval);
        }
        // sometimes search will be empty when cancelling consent from user continuously
        if (code == null && newWindow?.location?.href && newWindow.location.href.indexOf('?') > -1) {
          const startIndex = newWindow.location.href.indexOf('?') + 1;
          const paramsString = newWindow.location.href.slice(startIndex);
          const searchParams = new URLSearchParams(paramsString);
          code = searchParams.get('code');
          if (code) {
            saveCloudCredentials(code, type, setUserData, callBack, onClose);
            newWindow.close();
            clearInterval(interval);
          }
          if (searchParams.get('error')) {
            newWindow.close();
            clearInterval(interval);
            SnackBarUtils.error(i18next.t('NO_SCOPES_PROVIDED'));
          }
        }
      }, 300);
    }
  } catch (e) {
    errorLogger(e, false, 'Caught from authenticateUser in CRMUtils for source' + 'GOOGLE_DRIVE');
  }
};

const saveCloudCredentials = async (code: string, type: string, setUserData: any, callBack?: (data: any) => void, onClose?: () => void) => {
  try {
    const response = await axiosInstance().post('cloud/save-credentials', { code: code, source: type });
    if (response && response.status === 204 && code != 'error=access_denied') {
      onClose && onClose();
      if (callBack) {
        callBack(UserAccountType.GOOGLE_EDITOR);
      } else if (setUserData) {
        getApitoken(setUserData);
      } else {
        //do nothing
      }
    }
  } catch (e) {
    errorLogger(e, false, 'Caught from saveCredentials in CRMUtils for source' + 'GOOGLE_DRIVE');
  }
};
