import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import GuestStore from '@store/GuestStore';
import RFPIOPalette from '@utils/RFPIOPalette';
import { generalUseStoreState, generalUseStoreActions } from '../hooks';
import { getColorValue } from '@utils/ColorUtils';

// import { Wrapper } from "./styles";

export default function GuestLayout(props: any) {
  return <GuestStore.Provider>{props.children}</GuestStore.Provider>;
}

GuestLayout.propTypes = {
  children: PropTypes.element.isRequired
};
