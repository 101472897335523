import { CustomizedTheme } from '@interfaces/ThemeInterface';
import { makeStyles } from '@material-ui/core/styles';

const CommonThemeStyles = makeStyles(
  (theme: CustomizedTheme) => ({
    root: {
      color: `${theme.palette.type === 'light' ? '#181D1F' : '#E4E4E4'}`
    },
    titleBar: {
      backgroundColor: theme?.commonStyles?.headerBg,
      '&.ask-responsive.MuiAppBar-root': {
        // zIndex: '10001 !important'
      }
    },
    projectHeader: {
      backgroundColor: `${theme.palette.type === 'light' ? '#fff' : '#121212'}`
    },
    card: {
      backgroundColor: `${theme.palette.type === 'light' ? 'white' : '#000000 !important'}`
    },
    tabBgColor: {
      backgroundColor: `${theme.palette.type === 'light' ? 'white' : 'black'}`
    },
    topToolBar: {
      backgroundColor: `${theme.palette.type === 'light' ? '#fff' : '#444444'}`
    },
    topToolBarGreaterthanone: {
      backgroundColor: `${theme.palette.type === 'light' ? '#fff' : '#121212'}`
    },
    buttonBgColor: {
      backgroundColor: `${theme.palette.type === 'light' ? '#fff' : '#434654'}`
    },
    tabColor: {
      color: `${theme.palette.type === 'light' ? 'black' : 'white'}`
    },
    activeTab: {
      background: `${theme.palette.type === 'light' ? '#D1E8FF' : '#333333'}`,
      color: `${theme.palette.type === 'light' ? '#000' : '#DCDCDC'}`
    },
    myWorkActiveTab: {
      background: `${theme.palette.type === 'light' ? '#D9EAB2' : '#333333'}`,
      color: `${theme.palette.type === 'light' ? '#000' : '#DCDCDC'}`
    },
    questHead: {
      backgroundColor: `${theme.palette.type === 'light' ? '#e9e9e9' : '#434654'}`
    },
    navButtonBgColor: {
      backgroundColor: `${theme.palette.type === 'light' ? '#fff' : '#2A2B33'}`
    },
    toolTipBgColor: {
      backgroundColor: `${theme.palette.type === 'light' ? '#fff' : '#404040'}`
    },
    buttonBg: {
      background: `${theme.palette.type === 'light' ? '#f7f7f7' : '#434654'}`
    },
    tabTextColor: {
      '& .MuiTab-textColorPrimary': {
        color: theme?.commonStyles?.tabTextColor
      }
    },
    commentBox: {
      backgroundColor: `${theme.palette.type === 'light' ? '#fff' : '#31333E'}`,
      color: `${theme.palette.type === 'light' ? '#000' : '#E4E4E4'}`,
      '&[contenteditable=true]:empty:before': {
        color: theme.palette.type === 'light' ? '#535353' : '#999999'
      }
    },
    cardHeaderTextColor: {
      color: `${theme.palette.type === 'light' ? '#333333' : '#E4E4E4'}`
    },
    chipBgColor: {
      backgroundColor: `${theme.palette.type === 'light' ? '#eef2f6' : '#202129'}`
    },
    agGridBgColor: {
      background: theme?.agGridStyles?.agGridBgColor,
      '&.ag-theme-alpine .ag-row.ag-row-selected': {
        backgroundColor: `${theme.palette.type === 'light' ? '#cde5f7 !important' : '#31333e !important'}`,
        borderBottom: theme.palette.type === 'light' ? '1px solid #BBCDDB' : '1px solid rgba(255,255,255,0.3)'
      }
    },
    bulkUpdateButtonBgColor: {
      border: theme.palette.type === 'light' ? '1px solid #CFD7DF' : 'none',
      backgroundColor: `${theme.palette.type === 'light' ? 'white' : '#2D3134'}`,
      color: theme.palette.type === 'light' ? '#1C3443' : '#E4E4E4',
      // '&.MuiButton-outlined': {
      //     border: theme.palette.type === 'light' ? '1px solid rgba(0, 0, 0, 0.23)' : 'none'
      // },
      '&.MuiButton-root:hover': {
        backgroundColor: theme.palette.type === 'light' ? '#1C3443' : '#434654',
        border: theme.palette.type === 'light' ? '1px solid #CFD7DF' : 'none',
        color: theme.palette.type === 'light' ? '#FFFFFF' : '#E4E4E4'
      }
    },
    buttonTextColor: {
      color: `${theme.palette.type === 'light' ? '#1c3443' : '#E4E4E4'}`
    },
    propertyBgColor: {
      backgroundColor: `${theme.palette.type === 'light' ? '#fff' : '#31333E'}`
    },
    customizeColumnHeader: {
      background: `${theme.palette.type === 'light' ? '#F6F8FA' : '#31333E'}`
    },
    customizeColumnHoverBgColor: {
      '&:hover': {
        background: `${theme.palette.type === 'light' ? '#E8F1FB' : '#31333E'}`
      }
    },
    addTaskIconBg: {
      color: `${theme.palette.type === 'light' ? '#fff' : '#484848'}`
    },
    mergeTagButton: {
      background: `${theme.palette.type === 'light' ? '#fff' : '#202129'}`
    },
    sectionBoardViewCardBgColor: {
      backgroundColor: `${theme.palette.type === 'light' ? '#e9ecef' : '#31333e'}`
    },
    bgColor: {
      backgroundColor: `${theme.palette.type === 'light' ? '#fff' : '#31333e'}`
    },
    cancelBtn: {
      border: `${theme.palette.type === 'light' ? '1px solid #1C3443' : '1px solid #64676A'}`,
      color: `${theme.palette.type === 'light' ? '#1C3443' : '#E4E4E4'}`
    },
    muiButtonColor: {
      color: `${theme.palette.type === 'light' ? '#2F4354' : '#fff'}`
    },
    manageUsersTab: {
      color: `${theme.palette.type === 'light' ? 'black' : '#fff'}`
    },
    nameTextColor: {
      color: `${theme.palette.type === 'light' ? '#333333' : '#E4E4E4'}`
    },
    reviewActiveTab: {
      background: `${theme.palette.type === 'light' ? '#cde5f7' : '#434654'}`,
      border: '1px solid #8BC34A'
    },
    lineNo: {
      background: `${theme.palette.type === 'light' ? '#ebeff3' : '#434654'}`
    },
    headerBgColor: {
      backgroundColor: `${theme.palette.type === 'light' ? '#f4f4f4' : '#434654'}`
    },
    tagBgColor: {
      background: `${theme.palette.type === 'light' ? '#e2e2e2' : '#444444'}`
    },
    tableBgColor: {
      backgroundColor: `${theme.palette.type === 'light' ? '#fff' : '#434654'}`
    },
    greyBgColor: {
      backgroundColor: `${theme.palette.type === 'light' ? 'rgba(238, 238, 238, 0.5) !important' : '#31333e'}`
    },
    lightGreyBgColor: {
      backgroundColor: `${theme.palette.type === 'light' ? '#efefef !important' : '#434654'}`
    },
    exportTableBgColor: {
      background: `${theme.palette.type === 'light' ? '#ebeff3' : '#121212'}`
    },
    exportTableHeader: {
      backgroundColor: `${theme.palette.type === 'light' ? '#dee5ee' : '#31333E'}`
    },
    labelcolor: {
      color: `${theme.palette.type === 'light' ? '#1C3443' : '#E4E4E4'}`
    },
    searchIconColor: {
      color: theme?.commonStyles?.searchIconColor
    },
    textColor: {
      color: `${theme.palette.type === 'light' ? '#1C3443' : '#E4E4E4'}`
    },
    borderBottom: {
      borderBottom: `${theme.palette.type === 'light' ? '1px solid #EFEFEF !important' : '1px solid #64676A'}`
    },
    bottomBorder: {
      borderBottom: theme.palette.type === 'light' ? '1px solid rgba(224, 224, 224, 1)' : '1px solid #64676A'
    },
    sortColor: {
      color: theme.palette.type === 'light' ? '#131414' : '#999999',
      '&:hover': {
        color: theme.palette.type === 'light' ? '#131414 !important' : '#D6D6D6 !important'
      }
    },
    dropDownIconProps: {
      '& .MuiSelect-icon': {
        marginRight: '5px',
        color: theme.palette.type === 'light' ? '#1C3443' : '#E4E4E4'
      }
    },
    buttonBorder: {
      border: theme.palette.type === 'light' ? '1px solid #cfd7df' : '1px solid #64676A'
    },
    colFilter: {
      color: theme.palette.type === 'light' ? '#ABACAD !important' : '#999999 !important',
      '&:hover': {
        color: theme.palette.type === 'light' ? '#3E4148 !important' : '#D6D6D6 !important',
        backgroundColor: 'transparent !important'
      }
    },
    tableBordered: {
      border: theme.palette.type === 'light' ? '1px solid #dee5ee' : '1px solid #64676A',
      '& th, td': {
        border: theme.palette.type === 'light' ? '1px solid #dee5ee' : '1px solid #64676A'
      }
    },
    lightGreyRightBorder: {
      borderRight: theme.palette.type === 'light' ? '1px solid #d6d6d6' : '1px solid #64676A'
    },
    lightGreyLeftBorder: {
      borderLeft: theme.palette.type === 'light' ? '1px solid #d6d6d6' : '1px solid #64676A'
    },
    headerIconHover: {
      '&:hover': {
        backgroundColor: theme.palette.type === 'light' ? '#DEE5EE' : '#303030'
      }
    },
    border: {
      border: theme.palette.type === 'light' ? '1px solid #EFEFEF !important' : '1px solid #64676A'
    },
    border1: {
      border: theme.palette.type === 'light' ? '1px solid #D6D6D6 !important' : ''
    },
    rfpioTable: {
      '& tr, td': {
        borderRight: theme.palette.type === 'light' ? '1px solid #cfcfcf !important' : '1px solid #64676A !important',
        borderTop: theme.palette.type === 'light' ? '1px solid #cfcfcf !important' : '1px solid #64676A !important'
      },
      '& thead>tr>td': {
        backgroundColor: theme.palette.type === 'light' ? '#e8e8e8' : '#444444',
        borderBottom: theme.palette.type === 'light' ? '1px solid #cfd2d2 !important' : '1px solid #64676A !important'
      },
      '& td:first-child': {
        backgroundColor: theme.palette.type === 'light' ? '#e8e8e8' : '#444444'
      },
      '& table': {
        borderLeft: theme.palette.type === 'light' ? '1px solid #cfd2d2' : '1px solid #64676A',
        borderBottom: theme.palette.type === 'light' ? '1px solid #cfd2d2 !important' : '1px solid #64676A !important'
      }
    },
    anchorTypeText: {
      color: theme.palette.type === 'light' ? '#0051d9' : '#FF711F',
      cursor: 'pointer'
    },
    hyperlink: {
      color: theme.palette.type === 'light' ? '#0051d9' : '#07ACFF',
      cursor: 'pointer'
    },
    textLink: {
      color: theme.palette.type === 'light' ? '#1051c1' : '#FF711F'
    },
    sequentialReview: {
      color: theme.palette.type === 'light' ? '#0052b0 !important' : '#07ACFF !important'
    },
    linkTextColor: {
      color: theme.palette.type === 'light' ? '#1051c1' : '#07ACFF'
    },
    headerBg: {
      backgroundColor: `${theme.palette.type === 'light' ? '#f4f4f4 !important' : '#434654 !important'}`
    },
    iconBorder: {
      border: theme.palette.type === 'light' ? '1px solid #DEE5EE' : '1px solid #64676A'
    },
    tableStyle: {
      '& thead, th': {
        backgroundColor: theme.palette.type === 'light' ? '#F6F8FA !important' : '#444444 !important',
        borderBottom: theme.palette.type === 'light' ? '1px solid #DEE5EE !important' : '1px solid #64676A !important'
      }
    },
    expiredDate: {
      color: theme.palette.type === 'light' ? 'red' : '#FF711F'
    },
    input: {
      backgroundColor: theme.palette.type === 'light' ? 'white' : '#434654'
    },
    borderRightHeader: {
      borderRight: theme.palette.type === 'light' ? '1px solid #cbd4e0 !important' : '1px solid #64676A !important'
    },
    bulkUpdateBorder: {
      border: theme.palette.type === 'light' ? '1px solid #dee5ee !important' : '1px solid #64676A !important'
    },
    textBlue: {
      color: theme.palette.type === 'light' ? '#1492e6' : '#07ACFF'
    },
    questPanelBgColor: {
      backgroundColor: theme.palette.type === 'light' ? '#f5f5f5' : '#31333E'
    },
    stickySectionHeader: {
      backgroundColor: theme.palette.type === 'light' ? 'white' : '#202129',
      '&:hover': {
        backgroundColor: theme.palette.type === 'light' ? '#F5F5F5' : '#31333E'
      }
    },
    questionHighlight: {
      backgroundColor: theme.palette.type === 'light' ? '#e9e9e9' : '#434654',
      padding: '5px 14px',
      borderRadius: '5px'
    },
    questionCardShadow: {
      border: '1px solid transparent',
      '&:hover': {
        border: theme.palette.type === 'light' ? '1px solid #c3c3c3 !important' : '1px solid #999999'
      }
    },
    iconColor: {
      color: theme.palette.type === 'light' ? '#2F4354' : '#E4E4E4'
    },
    iconBg: {
      color: theme.palette.type === 'light' ? 'white' : '#434654'
    },
    activeEditor: {
      backgroundColor: theme.palette.type === 'light' ? 'white' : '#2A2B33',
      color: theme.palette.type === 'light' ? '#000000de' : '#E4E4E4',
      width: '100%',
      border: theme.palette.type === 'light' ? '1px solid #0000003b' : '1px solid #64676A',
      position: 'relative',
      minHeight: '80px',
      borderRadius: '5px'
    },
    cardWidth: {
      minWidth: '450px',
      minHeight: '250px',
      [theme.breakpoints.down('sm')]: {
        minWidth: '100%',
        minHeight: '100%'
      }
    },
    activeState: {
      height: '34px',
      minWidth: '34px',
      cursor: 'pointer',
      backgroundColor: theme.palette.type === 'light' ? '#DEE5EE !important' : '#383D53 !important',
      color: theme.palette.type === 'light' ? '#1C3443' : '#E4E4E4'
    },
    inactiveState: {
      height: '34px',
      width: '34px',
      cursor: 'pointer',
      border: theme.palette.type === 'light' ? '1px solid lightgrey' : 'none',
      background: theme.palette.type === 'light' ? '#C3C3C3 !important' : '#303139 !important',
      color: theme.palette.type === 'light' ? '#878787 !important' : '#64676A !important'
    },
    toggleBorder: {
      border: theme.palette.type === 'light' ? '0.1px #dcdfe6 solid' : 'none',
      borderRight: 'none'
    },
    clarificationBg: {
      backgroundColor: theme.palette.type === 'light' ? theme.palette.background.paper : '#31333E'
    },
    numericInput: {
      backgroundColor: theme.palette.type === 'light' ? '#fff' : '#434654',
      color: theme.palette.type === 'light' ? '#3a3f51' : '#E4E4E4',
      border: theme.palette.type === 'light' ? '1px solid #dde6e9' : '1px solid #64676A'
    },
    dockIconBg: {
      backgroundColor: '#F1F1F1',
      color: '#000000',
      display: 'flex',
      borderRadius: '0 0 4px 0',
      alignItems: 'center'
    },
    lightGreyBottomBorder: {
      borderBottom: theme.palette.type === 'light' ? '1px solid #b5b3b3' : '1px solid #64676A'
    },
    activeStateSize: {
      height: '34px',
      width: '28px',
      cursor: 'pointer',
      border: theme.palette.type === 'light' ? '1px solid lightgrey' : 'none',
      background: theme.palette.type === 'light' ? '#C3C3C3 !important' : '#303139 !important',
      color: theme.palette.type === 'light' ? '#878787' : '#64676A'
    },
    activeStateCard: {
      height: '34px',
      minWidth: '28px',
      cursor: 'pointer',
      backgroundColor: theme.palette.type === 'light' ? '#1C3443' : '#3B3D53',
      color: 'white'
    },
    borderBottomCard: {
      borderBottom: theme.palette.type === 'light' ? '1px solid #d6d6d6 !important' : '1px solid #64676A'
    },
    editorFileListBg: {
      backgroundColor: theme.palette.type === 'light' ? '#E1E5EA' : '#31333E'
    },
    skeletonBg: {
      backgroundColor: theme.palette.type === 'light' ? 'white' : '#202129'
    },
    timerHover: {
      '&:hover': {
        color: theme.palette.type === 'light' ? '#1C3443' : '#E4E4E4'
      }
    },
    floatToolShadow: {
      boxShadow: '0px 6px 6px 1px #00000029',
      borderTop: '1px solid #F1F1F1'
    },
    iconUnlink: {
      backgroundColor: theme.palette.type === 'light' ? '#E9E9E9' : '#434654'
    },
    searchOutline: {
      border: theme.palette.type === 'light' ? '2px solid #dee5ee' : '2px solid #64676A'
    },
    headerBg1: {
      backgroundColor: theme.palette.type === 'light' ? '#DEE5EE' : '#434654',
      '&:hover': {
        backgroundColor: theme.palette.type === 'light' ? '#DEE5EE' : '#434654'
      }
    },
    descText: {
      color: theme.palette.type === 'light' ? '#707070' : '#E4E4E4'
    },
    borderTop: {
      borderTop: theme.palette.type === 'light' ? '1px solid rgba(224, 224, 224, 1)' : '1px solid #64676a'
    },
    clearBulSelection: {
      color: theme.palette.type === 'light' ? '#D9302A' : '#FF711F',
      cursor: 'pointer'
    },
    usersBgColor: {
      backgroundColor: theme.palette.type === 'light' ? '#F5F7F9' : '#434654',
      border: theme.palette.type === 'light' ? '1px solid #64676A' : '1px solid #d6d6d6'
    },
    accordion: {
      border: theme.palette.type === 'light' ? '1px solid #ececec !important' : '1px solid #64676A !important',
      borderRadius: '2px !important',
      opacity: '1'
    },
    iconHoverStyle: {
      '&:hover': {
        color: theme.palette.type === 'light' ? '#FFFFFF' : '#FFFFFF',
        backgroundColor: theme.palette.type === 'light' ? '#1C3443' : '#3b3d53',
        borderRadius: '3px'
      }
    },
    horizontalMenuStyle: {
      color: theme.palette.type === 'light' ? '#5C5C5C' : '#FFFFFF'
    },
    grey50Grey900: {
      color: theme.palette.type === 'light' ? '#111111' : '#E3E3E3'
    }
  }),
  { index: 1 }
);

export default CommonThemeStyles;
