import React, { Suspense, useMemo } from 'react';
import { Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import RouteProps from '../RouteProps';
import RfpLoader from '@rfp-components/RfpLoader';
import WrapperProvider from '@components/module-specific-data-wrappers/WrapperProvider';

export default function CustomDomainRenderRoute(route: RouteProps) {
  const Layout = route.layout;
  document.title = route.title || 'Responsive';

  return (
    <QueryParamProvider ReactRouterRoute={Route}>
      <Route
        path={route.path}
        exact
        render={props => (
          <Layout route={route}>
            <WrapperProvider wrapperList={route.wrapperList ?? []} skipLoader={false}>
              <DynamicLoader component={route.component} />
            </WrapperProvider>
          </Layout>
        )}></Route>
    </QueryParamProvider>
  );
}

const DynamicLoader = (props: any) => {
  const { component } = props;
  const LazyComponent = useMemo(() => component, [component]);
  return (
    <Suspense fallback={<RfpLoader loading={true} />}>
      <LazyComponent />
    </Suspense>
  );
};
