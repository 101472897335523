import React, { Fragment, useState } from 'react';
import RfpLazyLoad from '@rfp-components/RfpLazyLoad';
import { lazyLoadWithRetry } from '@rfp-components/RfpLazyWithRetry';
import { WRAPPER } from '@interfaces/WrapperTypes';
import RfpLoader from '@rfp-components/RfpLoader';

const AutoFillDataWrapper = lazyLoadWithRetry(() => import('@components/module-specific-data-wrappers/AutoFillDataWrapper'), 'AutoFillDataWrapper');
const HighspotDataWrapper = lazyLoadWithRetry(() => import('@components/module-specific-data-wrappers/HighspotDataWrapper'), 'HighspotDataWrapper');
const ProjectCategoriesListDataWrapper = lazyLoadWithRetry(() => import('@components/module-specific-data-wrappers/ProjectCategoriesListDataWrapper'), 'ProjectCategoriesListDataWrapper');
const SalesForceDataWrapper = lazyLoadWithRetry(() => import('@components/module-specific-data-wrappers/SalesForceDataWrapper'), 'SalesForceDataWrapper');
const SeismicDataWrapper = lazyLoadWithRetry(() => import('@components/module-specific-data-wrappers/SeismicDataWrapper'), 'SeismicDataWrapper');
const SharePointDataWrapper = lazyLoadWithRetry(() => import('@components/module-specific-data-wrappers/SharePointDataWrapper'), 'SharePointDataWrapper');
const ZohoDataWrapper = lazyLoadWithRetry(() => import('@components/module-specific-data-wrappers/ZohoDataWrapper'), 'ZohoDataWrapper');

export default function WrapperProvider(props: { wrapperList: WRAPPER[]; skipLoader: boolean; children: any }) {
  const { wrapperList = [], skipLoader = false } = props;
  const [wrapperCount, setWrapperCount] = useState<number>(0);

  const getWrapperComponent = (wrapper: string): React.ComponentType<any> => {
    switch (wrapper) {
      case WRAPPER.AUTO_FILL_DETAILS:
        return AutoFillDataWrapper;
      case WRAPPER.HIGH_SPOT:
        return HighspotDataWrapper;
      case WRAPPER.PROJECT_CATEGORY:
        return ProjectCategoriesListDataWrapper;
      case WRAPPER.SALESFORCE:
        return SalesForceDataWrapper;
      case WRAPPER.SEISMIC:
        return SeismicDataWrapper;
      case WRAPPER.SHARE_POINT:
        return SharePointDataWrapper;
      case WRAPPER.ZOHO:
        return ZohoDataWrapper;
      default:
        return Fragment;
    }
  };

  const updateCompletedCount = () => {
    setWrapperCount(count => {
      return ++count;
    });
  };

  //convert array of wrapper into valid renderer
  const renderComponentWithWrapper = () =>
    wrapperList.reduce((acc, wrapper) => {
      const Provider = getWrapperComponent(wrapper);
      return <Provider callBack={updateCompletedCount}>{acc}</Provider>;
    }, <>{wrapperCount < wrapperList.length ? <RfpLoader loading={!skipLoader} /> : <>{props.children}</>}</>);

  return <RfpLazyLoad skipLoader={true}>{wrapperList.length > 0 ? renderComponentWithWrapper() : <>{props.children}</>}</RfpLazyLoad>;
}
