import DockTinyMCEStore from '@interfaces/DockTinyMCEStore';
import { action } from 'easy-peasy';

const getTinyTool = () => {
  const userPref = localStorage.getItem('userPreference');
  if (userPref && userPref !== null) {
    const data = JSON.parse(userPref);
    if (data.richtextToolbarPosition && data.richtextToolbarPosition !== null && data.richtextToolbarPosition.position) {
      return data.richtextToolbarPosition.position === 'TOP' ? true : false;
    }
  }
  return false;
};

const setTinyTool = (entry: boolean) => {
  const userPref = localStorage.getItem('userPreference');
  if (userPref && userPref !== null) {
    const data = JSON.parse(userPref);
    if (data.richtextToolbarPosition && data.richtextToolbarPosition !== null && data.richtextToolbarPosition.position) {
      data.richtextToolbarPosition.position = entry ? 'TOP' : 'BOTTOM';
    } else {
      data.richtextToolbarPosition = { position: 'BOTTOM' };
    }
    localStorage.setItem('userPreference', JSON.stringify(data));
  }
};

const DockTinyMCEStoreImpl: DockTinyMCEStore = {
  floatToolbar: getTinyTool(),
  hideTool: true,
  activeAnswerId: '',
  pageType: 'PROJECT',
  focusSet: false,
  setFocusSet: action((state, entry) => {
    state.focusSet = entry;
  }),
  setPageType: action((state, entry) => {
    state.pageType = entry;
  }),
  setActiveAnswerId: action((state, entry) => {
    state.activeAnswerId = entry;
  }),
  setFloatToolbar: action((state, entry) => {
    state.floatToolbar = entry;
    setTinyTool(entry);
  }),
  setHideTool: action((state, entry) => {
    if (entry && !state.hideTool) {
      state.hideTool = entry;
    }

    if (!entry && state.hideTool) {
      state.hideTool = entry;
    }
  })
};

export default DockTinyMCEStoreImpl;
