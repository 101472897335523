import ManageTeamsVO from '@interfaces/Models/ManageTeamsVO';
import UserVO from '@interfaces/Models/UserVO';
import store from '@store/index';
import { isFeatureEnabled } from '@utils/FeaturePreference';

interface IBuAndModuleBasedUserMap {
  /** available business unit list */
  buList?: string[];
  restrictTeam?: boolean;
  module?: string;
  permissions?: string[];
  restrictUserlist?: boolean;
  /** attributes consider as addtional roles  */
  attributes?: string[];
  showAllTeams?: boolean;
  userStatus?: string[];
  checkInactiveUser?: boolean;
}

interface IvalidateUser extends IBuAndModuleBasedUserMap {
  userVO: UserVO;
}

/**
 * @description validate current/active user based on module,bu,permissions and return true if meet criteria
 */
export const validateUser = (data: IvalidateUser) => {
  const { attributes, userVO, permissions, module, checkInactiveUser = true } = data;
  //skip in active user
  if (checkInactiveUser && (!userVO || userVO?.userStatus === 'IN_ACTIVE')) {
    return false;
  }
  const permissionMap = store.getState().userData.permissionMap;
  const userPermissionType: { [key: string]: any } = store.getState().userDetails.userDetails.userPermissionTypes || {};
  let hasPermission = true;
  const validForPermissionCheck = (userVO?.userRole === 'SUPER_ADMIN' && attributes && attributes?.length > 0) || userVO?.userRole !== 'SUPER_ADMIN';

  if (validForPermissionCheck && (permissions || module || attributes)) {
    const additionalRoles = userVO?.additionalRoles || [];
    const roles = [userVO?.userRole, ...additionalRoles];
    const moduleSpecificPermissions: string[] = permissions || userPermissionType[module] || [];
    const userPermissions = roles.map(role => role && permissionMap[role]).flat();
    const hasAttributeValidation = attributes && attributes?.length > 0 ? additionalRoles.some(attr => attributes?.includes(attr)) : true;
    if (moduleSpecificPermissions.length > 0 && userVO?.userRole !== 'SUPER_ADMIN') {
      hasPermission = moduleSpecificPermissions.some(permission => userPermissions.includes(permission));
    }
    hasPermission = hasPermission && hasAttributeValidation;
  }

  return hasPermission;
};

/**
 * @description filter users (based on module,bu,permissions) in Map format
 */
export const getUserMap = (data: IBuAndModuleBasedUserMap) => {
  const { buList = ['ALL'], restrictTeam = false, restrictUserlist = false, showAllTeams = false, userStatus = [] } = data;
  let businessUnitList = buList.includes('ALL') || buList.length === 0 ? ['ALL'] : buList;
  let details: { [key: string]: UserVO | ManageTeamsVO } = {};
  const userVOMap = store.getState().userData.companyUserMap;
  const buBaseduserList = store.getState().userData.buBaseduserList || {};
  const validTeamMap = !restrictTeam && isFeatureEnabled('manage_teams') ? getValidTeam(showAllTeams) : {};
  const validUserMap = !restrictUserlist ? userVOMap : {};
  const eligibleTeams: { [key: string]: ManageTeamsVO } = {};

  businessUnitList.forEach(buId => {
    const buUserList = buBaseduserList[buId] || [];
    const moduleBasedUsers: { [key: string]: UserVO | ManageTeamsVO } = buUserList.reduce((acc, userId) => {
      const userVO: UserVO | ManageTeamsVO = validUserMap[userId] || validTeamMap[userId];
      let hasPermission = true;

      if (validUserMap[userId]) {
        hasPermission = validateUser({ ...data, userVO });
      }

      if (validTeamMap[userId] && !businessUnitList.includes('ALL')) {
        hasPermission = businessUnitList.includes(userVO.primaryBU);
      }

      if (validTeamMap[userId]) {
        eligibleTeams[userId] = userVO as unknown as ManageTeamsVO;
      }

      if (hasPermission && validUserMap[userId] && (userStatus.length === 0 || userStatus.includes(userVO.userStatus))) {
        acc[userId] = userVO;
      }
      return acc;
    }, {} as { [key: string]: UserVO | ManageTeamsVO });

    details = { ...details, ...moduleBasedUsers };
  });

  const filteredTeamsMap = hasValidUserInTeam({ teams: eligibleTeams, ...data });
  //group all eligible teams as last in userMap
  return { ...details, ...filteredTeamsMap };
};

/**
 * @description filter users (based on module,bu,permissions) in Array format
 */
export const getUserList = (data: IBuAndModuleBasedUserMap): (UserVO | ManageTeamsVO)[] => {
  const allUserMap = getUserMap(data);
  return Object.values(allUserMap);
};

/**
 * @description cross verify existing userList with business units
 */
export const validateExistingUsersWithBU = (data: { userList: UserVO[]; buList?: string[] }) => {
  const { userList } = data;
  const buList = !data?.buList || data?.buList.length === 0 ? ['ALL'] : data?.buList;
  const filteredUserList: UserVO[] = [];
  const userVOMap = store.getState().userData.companyUserMap;

  if (buList.includes('ALL')) {
    return userList;
  }

  userList.forEach(user => {
    const userVO = typeof user === 'string' ? userVOMap[user] : user;
    const businessUnits = userVO?.businessUnits || ['ALL'];
    const hasFullAccess = businessUnits.includes('ALL') || userVO?.userRole === 'SUPER_ADMIN';
    const hasBu = buList.some(buId => businessUnits.includes(buId));
    if (hasBu || hasFullAccess) {
      filteredUserList.push(userVO);
    }
  });
  return filteredUserList;
};

/** construct active user list to usersMap */
export const getActiveUsersMap = () => {
  const userVOMap = store.getState().userData.companyUserMap;
  const activeUserList = store.getState().userData.activeUserList;

  let activeUsersVOMap: { [key: string]: UserVO } = {};
  activeUserList.filter(userId => {
    const userVO = userVOMap[userId];
    if (userVO) {
      activeUsersVOMap[userId] = userVO;
    }
  });

  return activeUsersVOMap;
};

/** filter those teams have more than 0 members */
export const getValidTeam = (showAllTeams?: boolean) => {
  const teamVOMap = store.getState().userData.teamsMap;
  const validTeams = showAllTeams ? Object.entries(teamVOMap) : Object.entries(teamVOMap).filter(([, teamVO]) => teamVO.members?.length > 0);
  return Object.fromEntries(validTeams);
};

/** validate team based on team members permission */
export const hasValidUserInTeam = (data: IBuAndModuleBasedUserMap & { teams: { [key: string]: ManageTeamsVO } }) => {
  const { teams, permissions = [] } = data;
  const userVOMap = store.getState().userData.companyUserMap;

  if (permissions?.length > 0) {
    const filteredTeamsMap = Object.entries(teams).filter(([_teamId, teamVO]) => {
      const members = teamVO?.members || [];
      return members.some(user => userVOMap[user].userStatus === 'ACTIVE' && validateUser({ userVO: userVOMap[user], permissions }));
    });
    return Object.fromEntries(filteredTeamsMap);
  }

  return teams;
};

export const extractEmailIdsUsingRegex = (event: React.ClipboardEvent<HTMLDivElement>) => {
  let tempValue = event?.clipboardData?.getData('Text').replace(/(\r\n|\n|\r)/gm, ' ') || '';
  let emailAddresses = [];

  if (tempValue.includes('<') && tempValue.includes('>')) {
    // Scenario 1: Extract email addresses copied from email
    const regex = /<([^>]+)>/g;
    let match;
    while ((match = regex.exec(tempValue)) !== null) {
      emailAddresses.push(match[1]);
    }
  } else {
    // Scenario 2: Split by comma or semicolon
    emailAddresses = tempValue.split(/[,;]/).map(email => email.trim());
  }
  return emailAddresses;
};
