export default interface BusinessUnitVO {
  id: string;
  name: string;
  description: string;
  users: string[];
  sharedUsers: string[];
  companyId: string;
  createdBy: string;
  updatedBy: string;
  createdDate: Date;
  updatedDate: Date;
  projectLimit: number;
  backgroundColor: string;
  foregroundColor: string;
  canEdit?: boolean;
  additional_message?: string;
}

export const businessUnitVOList: BusinessUnitVO[] = [
  {
    id: 'ALL',
    name: 'ALL',
    description: '',
    users: [],
    sharedUsers: [],
    companyId: '',
    createdBy: '',
    updatedBy: '',
    createdDate: new Date(),
    updatedDate: new Date(),
    projectLimit: 0,
    backgroundColor: '#f3dd44',
    foregroundColor: '#151515',
    canEdit: true,
    additional_message: ''
  }
];
