import CustomFieldVO from '@interfaces/Models/CustomFieldVO';
import { ICustomFieldReplicaVO } from '@org/interfaces/props-type/custom-fields/OrgCustomFieldsPropsType';
import { ActionCreator } from 'easy-peasy';

export enum CustomFieldTypes {
  TEXT_BOX = 'TEXT_BOX',
  TEXT_AREA = 'TEXT_AREA',
  DROPDOWN = 'DROPDOWN',
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
  SELECT_USER = 'SELECT_USER',
  MULTI_SELECT_DROPDOWN = 'MULTI_SELECT_DROPDOWN',
  DATE = 'DATE',
  DATE_AND_TIME = 'DATE_AND_TIME',
  AUTO_INCREMENTAL = 'AUTO_INCREMENTAL'
}

export const initialCustomFieldVO: Partial<ICustomFieldReplicaVO> = {
  actionType: '',
  autoIncCode: '',
  autoIncValue: 0,
  type: 'TEXT_BOX',
  values: [],
  entityType: '',
  editable: false,
  enabled: true,
  reportable: true,
  name: '',
  displayName: '',
  groupName: ''
};

// Note Add your entity type according to your module
export enum OrgCustomFieldEntityType {
  PROJECT = 'PROJECT',
  ANSWER_LIBRARY = 'ANSWER_LIBRARY',
  DOCUMENT_LIBRARY = 'DOCUMENT_LIBRARY',
  PROPOSAL_BUILDER = 'PROPOSAL_BUILDER',
  USER_MANAGEMENT = 'USER',
  REQUEST = 'REQUEST',
  VENDOR = 'VENDOR',
  PROJECT_REQUEST = 'PROJECT_REQUEST',
  INTAKE_REQUEST = 'INTAKE_REQUEST',
  ALL = 'ALL',
  SECTION_TEMPLATE = 'SECTION_TEMPLATE',
  CATALOG = 'CATALOG'
}

export enum OrgCustomFieldCategoryType {
  PROJECT = 'PROJECT',
  CONTENT = 'CONTENT',
  INTAKE_REQUEST = 'INTAKE_REQUEST',
  QA_PAIRS = 'QA_PAIRS',
  DOCUMENTS = 'DOCUMENTS',
  SECTIONS = 'SECTIONS',
  CATALOGS = 'CATALOGS',
  ALL = 'ALL'
}

export const OrgCCLStoreKeys = ['customFieldMap', 'customFieldMapDL', 'sectionTemplateCustomFieldMap', 'customFieldMapCatalog'];

// custom fields which are shown in custom fields list page and not in reorder, advance filter groupname
export const nonEditableSystemDefinedCFs = ['businessunits', 'contactname', 'projectcreatedbyname', 'projectcreatedbyemail'];

export interface CustomFieldGroupNameMapType {
  name: string;
  values: CustomFieldVO[];
  expanded: boolean;
  skipDraggable: boolean;
  id?: string;
  isRemove?: boolean;
  indexValue?: number;
}

export interface CustomFieldsFilterQueryType {
  mergeTagName?: string;
  searchKeyWord?: string;
  displayName?: string;
  groupName?: string;
  preferences?: Array<keyof CustomFieldVO>;
  associationType?: string[];
  projectType?: string[];
}

export interface ManageUserCustomFieldRendererProps {
  groupName: string;
  isBulkUpdate: boolean;
  disable?: boolean;
  setMandatoryFields?: (fields: boolean) => void;
}

export interface OrgUserCustomFieldsRendererProps {
  customField: CustomFieldVO;
  isBulkUpdate: boolean;
  updatePermission?: boolean;
  permission?: any;
  updateCustomFieldVO?: any;
  disabled?: boolean;
  isClearDate?: boolean;
  setClearDate?: (isClearDate: boolean) => void;
  setIsEdited?: ActionCreator<string>;
  handleRadioOptionChange?: (value: string, field: CustomFieldVO) => void;
}

export interface fieldLabelAreaRendererProps {
  field: CustomFieldVO;
  skipRadio?: boolean;
  skipFieldName?: boolean;
  handleRadioOptionChange?: (value: string, field: CustomFieldVO) => void;
}

export interface emptyStateTextProps {
  displayName: string;
}

export enum AddUserDefaultGroups {
  USER_DETAILS = 'User Details',
  ADDITIONAL_DETAILS = 'Additional Details',
  NEWSLETTER_PREFERENCE = 'Newsletter Prefernce',
  CUSTOM_FIELDS = 'Custom Fields'
}

export enum SeperateUrlLink {
  MANAGE_USER_CUSTOM_FIELDS = '/v2/org-settings/manage-users-customfields'
}

export enum CustomFieldActionType {
  ADD = 'ADD',
  EDIT = 'EDIT'
}

interface ObjectListEntry {
  key: string;
  value: string;
  text: string;
}

export interface SalesforceObjectDetails {
  $$objectList: ObjectListEntry[];
  [name: string]: string | ObjectListEntry[];
}

interface SalesforceCRMRecord {
  [key: string]: SalesforceObjectDetails | boolean;
  connectToSalesforce: boolean;
}

export type SalesforceCRMDetails = Record<string, Partial<SalesforceCRMRecord>>;
