import CRMLoginLayout from '@containers/crm/crm-common-containers/CRMLogin';
import RouteProps from '../RouteProps';
import DefaultLayout from '@layouts/defaultLayout';
import { lazyLoadWithRetry } from '@rfp-components/RfpLazyWithRetry';

const SFDCManageProjectInitiator = lazyLoadWithRetry(() => import('@containers/crm/salesforce/manage-project/SFDCManageProjectInitiator'), 'SFDCManageProjectInitiator');
const SFDCProjectDetailsInitiator = lazyLoadWithRetry(() => import('@containers/crm/salesforce/project-details/SFDCProjectDetailsInitiator'), 'SFDCProjectDetailsInitiator');
const SFDCCreateProjectInitiator = lazyLoadWithRetry(() => import('@containers/crm/salesforce/create-project/SFDCCreateProjectInitiator'), 'SFDCCreateProjectInitiator');
const SFDCAllProjectsInitiator = lazyLoadWithRetry(() => import('@containers/crm/salesforce/all-projects/SFDCAllProjectsInitiator'), 'SFDCAllProjectsInitiator');
const SFDCAssociateProjectsInitiator = lazyLoadWithRetry(() => import('@containers/crm/salesforce/manage-project/SFDCAssociateProjectsInitiator'), 'SFDCAssociateProjectsInitiator');
const SFDCCreateIntakeInitiator = lazyLoadWithRetry(() => import('@containers/crm/salesforce/create-intake/SFDCCreateIntakeInitiator'), 'SFDCCreateIntakeInitiator');
const CRMForbiddenError = lazyLoadWithRetry(() => import('@containers/crm/crm-common-containers/CRMForbiddenError'), 'CRMForbiddenError');
const HubspotManageProjectsInitiator = lazyLoadWithRetry(() => import('@containers/crm/hubspot/view-all-projects/HubspotManageProjectsInitiator'), 'HubspotManageProjectsInitiator');
const HubspotProjectDetailsInitiator = lazyLoadWithRetry(() => import('@containers/crm/hubspot/project-details/HubspotProjectDetailsInitiator'), 'HubspotProjectDetailsInitiator');
const HubspotCreateProjectInitiator = lazyLoadWithRetry(() => import('@containers/crm/hubspot/create-project/HubspotCreateProjectInitiator'), 'HubspotCreateProjectInitiator');
const HubspotCreateIntakeInitiator = lazyLoadWithRetry(() => import('@containers/crm/hubspot/create-intake/HubspotCreateIntakeInitiator'), 'HubspotCreateIntakeInitiator');

const SFDCManageProactiveProposalInitiator = lazyLoadWithRetry(() => import('@containers/crm/salesforce/proactive-proposal/SFDCManageProactiveProposalInitiator'));
const SFDCCreateProactiveProposalInitiator = lazyLoadWithRetry(() => import('@containers/crm/salesforce/proactive-proposal/SFDCCreateProactiveProposalInitiator'));
const SFDCAssociateProactiveProposalInitiator = lazyLoadWithRetry(() => import('@containers/crm/salesforce/proactive-proposal/SFDCAssociateProactiveProposalInitiator'));
const SFDCManageProfilesInitiator = lazyLoadWithRetry(() => import('@containers/crm/salesforce/profiles/SFDCManageProfilesInitiator'), 'SFDCManageProfilesInitiator');
const SFDCProfileViewInitiator = lazyLoadWithRetry(() => import('@containers/crm/salesforce/profiles/SFDCProfileViewInitiator'), 'SFDCProfileViewInitiator');
const BuildPreviewPageInitiator = lazyLoadWithRetry(() => import('@containers/profile/view/preview-content/BuildPreviewPageInitiator'), 'BuildPreviewPageInitiator');
const PreviewProfilePageInitiator = lazyLoadWithRetry(() => import('@containers/profile/view/preview-content/PreviewProfilePage'), 'PreviewProfilePage');
const CRMExecutiveDashboard = lazyLoadWithRetry(() => import('@containers/crm/crm-common-containers/dashboard/CRMExecutiveDashboardInitiator'), 'CRMExecutiveDashboardInitiator');

//MSDynamics
const MSDAssociateProjectsInitiator = lazyLoadWithRetry(
  () => import('@containers/crm/microsoftdynamics/associate-disassociate/MSDynamicsAssociateProjectsInitiator'),
  'MSDynamicsAssociateProjectsInitiator'
);

const MSDynamicsProjectDetailsInitiator = lazyLoadWithRetry(() => import('@containers/crm/microsoftdynamics/project-details/MSDynamicsProjectDetailsInitiator'), 'MSDynamicsProjectDetailsInitiator');

const MSDynamicsManageProjectInitiator = lazyLoadWithRetry(() => import('@containers/crm/microsoftdynamics/view-all-projects/MSDynamicsManageProjectInitiator'), 'MSDynamicsManageProjectInitiator');

const MSDynamicsCreateProjectInitiator = lazyLoadWithRetry(() => import('@containers/crm/microsoftdynamics/create-project/MSDynamicsCreateProjectInitiator'), 'MSDynamicsCreateProjectInitiator');

const MSDynamicsCreateIntakeInitiator = lazyLoadWithRetry(() => import('@containers/crm/microsoftdynamics/create-intake/MSDynamicsCreateIntakeInitiator'), 'MSDynamicsCreateIntakeInitiator');

const MSDynamicsManageProfilesInitiator = lazyLoadWithRetry(() => import('@containers/crm/microsoftdynamics/profiles/MSDynamicsManageProfilesInitiator'), 'MSDynamicsManageProfilesInitiator');

const MSDynamicsProfileViewInitiator = lazyLoadWithRetry(() => import('@containers/crm/microsoftdynamics/profiles/MSDynamicsProfileViewInitiator'), 'MSDynamicsProfileViewInitiator');

const HubspotRoutes: RouteProps[] = [
  {
    path: '/v2/hubspot/overview-rfp',
    component: HubspotProjectDetailsInitiator,
    title: 'Responsive - Overview Page',
    needsAuth: true,
    layout: DefaultLayout,
    metricsPageName: 'Responsive - CRM 2.0 - hubspot',
    moduleName: 'CRM',
    menuModule: 'CRM'
  },
  {
    path: '/v2/hubspot/view-all-projects',
    component: HubspotManageProjectsInitiator,
    title: 'Responsive - View All Projects Page',
    needsAuth: true,
    layout: DefaultLayout,
    metricsPageName: 'Responsive - CRM 2.0 - hubspot',
    moduleName: 'CRM',
    menuModule: 'CRM'
  },
  {
    path: '/v2/hubspot/create-rfp',
    component: HubspotCreateProjectInitiator,
    title: 'Responsive - Create Project',
    needsAuth: true,
    layout: DefaultLayout,
    metricsPageName: 'Responsive - CRM 2.0 - Create Project',
    moduleName: 'CRM',
    menuModule: 'CRM'
  },
  {
    path: '/v2/hubspot/create-intake',
    component: HubspotCreateIntakeInitiator,
    title: 'Responsive - Create Intake',
    needsAuth: true,
    layout: DefaultLayout,
    metricsPageName: 'Responsive - CRM 2.0 - Create Intake',
    moduleName: 'CRM',
    menuModule: 'CRM'
  }
];
const MicrosoftDynamicsRoutes: RouteProps[] = [
  {
    path: '/v2/microsoftdynamicscrm/associate-projects',
    component: MSDAssociateProjectsInitiator,
    title: 'Responsive - Associate',
    needsAuth: true,
    layout: DefaultLayout,
    metricsPageName: 'Responsive - CRM 2.0 - microsoftdynamics',
    moduleName: 'CRM',
    menuModule: 'CRM'
  },
  {
    path: '/v2/microsoftdynamicscrm/overview-rfp',
    component: MSDynamicsProjectDetailsInitiator,
    title: 'Responsive - Overview Page',
    needsAuth: true,
    layout: DefaultLayout,
    metricsPageName: 'Responsive - CRM 2.0 - microsoftdynamics',
    moduleName: 'CRM',
    menuModule: 'CRM'
  },
  {
    path: '/v2/microsoftdynamicscrm/view-all-projects',
    component: MSDynamicsManageProjectInitiator,
    title: 'Responsive - View All Projects Page',
    needsAuth: true,
    layout: DefaultLayout,
    metricsPageName: 'Responsive - CRM 2.0 - microsoftdynamics',
    moduleName: 'CRM',
    menuModule: 'CRM'
  },
  {
    path: '/v2/microsoftdynamicscrm/create-rfp',
    component: MSDynamicsCreateProjectInitiator,
    title: 'Responsive - Create Project',
    needsAuth: true,
    layout: DefaultLayout,
    metricsPageName: 'Responsive - CRM 2.0 - microsoftdynamics',
    moduleName: 'CRM',
    menuModule: 'CRM'
  },
  {
    path: '/v2/microsoftdynamicscrm/create-intake',
    component: MSDynamicsCreateIntakeInitiator,
    title: 'Responsive - Create Intake',
    needsAuth: true,
    layout: DefaultLayout,
    metricsPageName: 'Responsive - CRM 2.0 - microsoftdynamics',
    moduleName: 'CRM',
    menuModule: 'CRM'
  },
  {
    path: '/v2/microsoftdynamicscrm/manage-profiles',
    component: MSDynamicsManageProfilesInitiator,
    title: 'Responsive - Profile Center',
    needsAuth: true,
    layout: DefaultLayout,
    metricsPageName: 'Responsive - CRM 2.0 - microsoftdynamics',
    moduleName: 'CRM',
    menuModule: 'PROFILE_CENTER'
  },
  {
    path: '/v2/microsoftdynamicscrm/profile-view',
    component: MSDynamicsProfileViewInitiator,
    title: 'Responsive - Profile View',
    needsAuth: true,
    layout: DefaultLayout,
    metricsPageName: 'Responsive - CRM 2.0 - microsoftdynamics',
    moduleName: 'CRM',
    menuModule: 'PROFILE_CENTER'
  },
  {
    path: '/v2/microsoftdynamicscrm/profile/:profileId/preview-content',
    component: BuildPreviewPageInitiator,
    needsAuth: true,
    title: 'Profile Center - Preview Content',
    layout: DefaultLayout,
    metricsPageName: 'Responsive - CRM 2.0 - microsoftdynamics',
    moduleName: 'CRM',
    menuModule: 'PROFILE_CENTER'
  },
  {
    path: '/v2/microsoftdynamicscrm/profile/:profileId/preview',
    component: PreviewProfilePageInitiator,
    needsAuth: true,
    title: 'Profile Center - Preview',
    layout: DefaultLayout,
    metricsPageName: 'Responsive - CRM 2.0 - microsoftdynamics',
    moduleName: 'CRM',
    menuModule: 'PROFILE_CENTER'
  }
];

export const CRMRoutes: RouteProps[] = [
  {
    path: '/v2/sdk/manage-projects',
    component: SFDCManageProjectInitiator,
    title: 'Responsive - CRM 2.0',
    needsAuth: true,
    layout: DefaultLayout,
    metricsPageName: 'Responsive - CRM 2.0 - salesforce',
    moduleName: 'CRM',
    menuModule: 'CRM'
  },
  {
    path: '/v2/sdk/overview-rfp',
    component: SFDCProjectDetailsInitiator,
    title: 'Responsive - CRM 2.0',
    needsAuth: true,
    layout: DefaultLayout,
    metricsPageName: 'Responsive - CRM 2.0 - salesforce',
    moduleName: 'CRM',
    menuModule: 'CRM'
  },
  {
    path: '/v2/sdk/create-rfp',
    component: SFDCCreateProjectInitiator,
    title: 'Responsive - Create Project',
    needsAuth: true,
    layout: DefaultLayout,
    metricsPageName: 'Responsive - CRM 2.0 - Create Project',
    moduleName: 'CRM',
    menuModule: 'CRM'
  },
  {
    path: '/v2/sdk/all-projects',
    component: SFDCAllProjectsInitiator,
    title: 'Responsive - CRM 2.0',
    needsAuth: true,
    layout: DefaultLayout,
    metricsPageName: 'Responsive - CRM 2.0 - salesforce',
    moduleName: 'CRM',
    menuModule: 'CRM'
  },
  {
    path: '/v2/sdk/associate-projects',
    component: SFDCAssociateProjectsInitiator,
    title: 'Responsive - CRM 2.0',
    needsAuth: true,
    layout: DefaultLayout,
    metricsPageName: 'Responsive - CRM 2.0 - salesforce',
    moduleName: 'CRM',
    menuModule: 'CRM'
  },
  {
    path: '/v2/sdk/create-intake',
    component: SFDCCreateIntakeInitiator,
    title: 'Responsive - Create Intake',
    needsAuth: true,
    layout: DefaultLayout,
    metricsPageName: 'Responsive - CRM 2.0 - salesforce',
    moduleName: 'CRM',
    menuModule: 'CRM'
  },
  {
    path: '/v2/crm/forbidden-error',
    component: CRMForbiddenError,
    title: 'Responsive - CRM 2.0',
    needsAuth: true,
    layout: DefaultLayout,
    metricsPageName: 'Responsive - CRM 2.0 - forbidden',
    moduleName: 'CRM',
    menuModule: 'CRM'
  },
  {
    path: '/v2/sdk/manage-profiles',
    component: SFDCManageProfilesInitiator,
    title: 'Responsive - Profile Center - Salesforce',
    needsAuth: true,
    layout: DefaultLayout,
    metricsPageName: 'Responsive - CRM 2.0 - salesforce',
    moduleName: 'CRM',
    menuModule: 'PROFILE_CENTER'
  },
  {
    path: '/v2/sdk/profile-view',
    component: SFDCProfileViewInitiator,
    title: 'Responsive - Profile View',
    needsAuth: true,
    layout: DefaultLayout,
    metricsPageName: 'Responsive - CRM 2.0 - salesforce',
    moduleName: 'CRM',
    menuModule: 'PROFILE_CENTER'
  },
  {
    path: '/v2/sdk/profile/:profileId/preview-content',
    component: BuildPreviewPageInitiator,
    needsAuth: true,
    title: 'Profile Center - Preview Content',
    layout: DefaultLayout,
    metricsPageName: 'Responsive - CRM 2.0 - salesforce',
    moduleName: 'CRM',
    menuModule: 'PROFILE_CENTER'
  },
  {
    path: '/v2/sdk/profile/:profileId/preview',
    component: PreviewProfilePageInitiator,
    needsAuth: true,
    title: 'Profile Center - Preview',
    layout: DefaultLayout,
    metricsPageName: 'Responsive - CRM 2.0 - salesforce',
    moduleName: 'CRM',
    menuModule: 'PROFILE_CENTER'
  },
  {
    path: '/v2/sdk/proactive-proposal',
    component: SFDCManageProactiveProposalInitiator,
    title: 'Responsive - CRM 2.0',
    needsAuth: true,
    layout: DefaultLayout,
    metricsPageName: 'Responsive - CRM 2.0 - salesforce',
    moduleName: 'CRM',
    menuModule: 'PROACTIVE_PROPOSAL_BUILDER'
  },
  {
    path: '/v2/sdk/create-proposal',
    component: SFDCCreateProactiveProposalInitiator,
    title: 'Responsive - CRM 2.0',
    needsAuth: true,
    layout: DefaultLayout,
    metricsPageName: 'Responsive - CRM 2.0 - salesforce',
    moduleName: 'CRM',
    menuModule: 'PROACTIVE_PROPOSAL_BUILDER'
  },
  {
    path: '/v2/sdk/associate_proactive_proposal',
    component: SFDCAssociateProactiveProposalInitiator,
    title: 'Responsive - CRM 2.0',
    needsAuth: true,
    layout: DefaultLayout,
    metricsPageName: 'Responsive - CRM 2.0 - salesforce',
    moduleName: 'CRM',
    menuModule: 'PROACTIVE_PROPOSAL_BUILDER'
  },
  {
    path: '/v2/sdk/rfp-dashboard',
    component: CRMExecutiveDashboard,
    title: 'Responsive - CRM 2.0',
    needsAuth: true,
    layout: DefaultLayout,
    metricsPageName: 'Responsive - CRM 2.0 - salesforce',
    moduleName: 'CRM',
    menuModule: 'CRM'
  },
  {
    path: '/v2/crm/login',
    component: CRMLoginLayout,
    title: 'Responsive - CRM 2.0',
    needsAuth: false,
    layout: DefaultLayout,
    metricsPageName: 'Responsive - CRM 2.0 - Login',
    moduleName: 'CRM'
  }
]
  .concat(HubspotRoutes)
  .concat(MicrosoftDynamicsRoutes);
