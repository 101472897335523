import React, { Suspense, useEffect } from 'react';
import RfpErrorBoundary from '@rfp-components/RfpErrorBoundary';
import OrientationDialogBox from '@components/dialogModels/OrientationDialogBox';
import { LoadingBar } from '@hooks/LoadingBar';
import { SnackbarUtilsConfigurator } from '@utils/SnackBarUtils';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import { SnackbarProvider } from 'notistack';
import { generalUseStoreState } from '@hooks/index';
import JQueryWidgets from './JQueryWidgets';
import { checkValidUser } from '@containers/login/LoginUtils';
import { lazyLoadWithRetry } from '@rfp-components/RfpLazyWithRetry';
import TagManager from 'react-gtm-module';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';

const ResponsiveBase = lazyLoadWithRetry(() => import('@components/ResponsiveBase'), 'ResponsiveBase');
const LoginBase = lazyLoadWithRetry(() => import('@containers/login/UserLogin/LoginBase'), 'LoginBase');

const BaseLayoutInitiator = () => {
  /*UserData can be accessed here only when the component rerenders,
  so used LoadBaseComponent to rerender the baseLayoutInitiator and display After Login Pages*/
  const loadBaseComponent = generalUseStoreState(state => state.additionalInfoStore.loadBaseComponent);
  const theme = generalUseStoreState(state => state.theme.theme);

  useEffect(() => {
    if (window.constants.gtm_tracker) {
      const tagManagerArgs = {
        gtmId: window.constants.gtm_tracker
      };
      TagManager.initialize(tagManagerArgs);
    }
  }, []);

  return (
    <RfpErrorBoundary>
      <JQueryWidgets>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <OrientationDialogBox />
          <SnackbarProvider maxSnack={4} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <SnackbarUtilsConfigurator />
            <LoadingBar>
              <Suspense fallback={<p></p>}>{checkValidUser() ? <ResponsiveBase /> : <LoginBase />}</Suspense>
            </LoadingBar>
          </SnackbarProvider>
        </ThemeProvider>
      </JQueryWidgets>
    </RfpErrorBoundary>
  );
};

export default BaseLayoutInitiator;
