import QuestionDetailsVO from '@interfaces/Models/QuestionDetailsVO';
import dayjs from 'dayjs';
import axiosInstance from '@helpers/Axios';
import { GuestSectionInfoDetailVO } from '@interfaces/Models/GuestSectionInfoVO';
import cloneDeep from 'clone-deep';
import { errorLogger } from './ErrorLogging';

export const validateQuestionIsEdited = (quest: QuestionDetailsVO) => {
  if (quest.contentType === 'DESCRIPTION') {
    if (
      !(
        (quest.question === '<p><br></p>' || quest.question === '<p><br data-mce-bogus="1"></p>') &&
        (!quest.existingQuestion || quest.existingQuestion === '<p><br></p>' || quest.existingQuestion === '<p><br data-mce-bogus="1"></p>')
      )
    ) {
      quest.isEdited = quest.question !== quest.existingQuestion;
    } else {
      quest.isEdited = false;
    }
  } else {
    const len = quest.answers.length;
    let isEdited = false;
    for (let i = 0; i < len; i++) {
      if (
        quest.answers[i].answerType === 'RICH_TEXT' &&
        (quest.answers[i].answer === '<p><br></p>' || quest.answers[i].answer === '<p><br data-mce-bogus="1"></p>') &&
        (!quest.existingAnswers[i].answer || quest.existingAnswers[i].answer === '<p><br></p>' || quest.existingAnswers[i].answer === '<p><br data-mce-bogus="1"></p>')
      ) {
        //tinymce empty answer
        continue;
      }
      if (
        quest.answers[i].answerType === 'DATE' &&
        ((quest.existingAnswers[i].answer === '' && quest.answers[i].answer === null) ||
          (quest.existingAnswers[i].answer && quest.answers[i].answer && dayjs(quest.existingAnswers[i].answer, 'YYYY-MM-DD HH:mm:ss Z').toDate().toString() === quest.answers[i].answer.toString()))
      ) {
        continue;
      }
      if (
        (quest.existingAnswers[i].answer && quest.answers[i].answer?.replace(/\n/g, '') !== quest.existingAnswers[i].answer.replace(/\n/g, '')) ||
        (!quest.existingAnswers[i].answer && quest.answers[i].answer && quest.answers[i].answer !== '')
      ) {
        isEdited = true;
        break;
      }
    }
    quest.isEdited = isEdited;
  }
};

export const isAnswered = (question: QuestionDetailsVO) => {
  for (let i = 0; i < question.answers.length; i++) {
    const ans = question.answers[i];
    if (!ans.optional) {
      if (
        !['LABEL', 'FILE_ATTACHMENT', 'DROPDOWN'].includes(ans.answerType) &&
        (!ans.answer ||
          ans.answer === '' ||
          ans.answer === null ||
          ans.answer === '<div></div>' ||
          ans.answer === '<p><br></p>' ||
          ans.answer === '<p>&nbsp;</p>' ||
          ans.answer === '<div>&nbsp;</div>' ||
          ans.answer === '<p><br data-mce-bogus="1"></p>')
      ) {
        return false;
      } else if (ans.answerType === 'CHECKBOX') {
        const tempAns = JSON.parse(ans.answer + '');
        if (tempAns.length === 0) {
          return false;
        }
      } else if (ans.answerType === 'FILE_ATTACHMENT') {
        if (!ans.files || !(ans.files.length > 0)) {
          return false;
        }
      } else if (ans.answerType === 'DROPDOWN') {
        const selectRegex = /^\bselect\b/gi;
        const hasSelect = selectRegex.test(ans.answer);
        if (ans.answer === '' || hasSelect) {
          return false;
        }
      }
    }
  }
  return true;
};

export const getAnswerData = (question: QuestionDetailsVO) => {
  const answerData = [];
  for (let i = 0; i < question.answers.length; i++) {
    const a = { id: question.answers[i].id, answer: question.answers[i].answer };
    answerData.push(a);
  }
  return answerData;
};

export const getGuestUserData = () => {
  const guestUserData: string | null = localStorage.getItem('guestUserData');
  return guestUserData && JSON.parse(guestUserData);
};

export const getEntityId = () => {
  const guestUserData: string | null = getGuestUserData();
  const entityId = guestUserData && guestUserData.companyGuestVO && guestUserData.companyGuestVO.id;
  return entityId || '';
};

export const getRequestVendorId = () => {
  return getEntityId();
};

export const getGuestKey = () => {
  const guestUserData: string | null = getGuestUserData();
  return guestUserData?.guestKey || '';
};

export const getGuestName = () => {
  const guestUserData: any = getGuestUserData();
  const name = guestUserData && guestUserData.companyGuestVO && guestUserData.companyGuestVO.guest;
  return name || '';
};

export const isGuestAsUser = () => {
  const guestUserData: any = getGuestUserData();
  return guestUserData?.user || false;
};

export const startSection = async (sectionId: string, guestSectionsInfo?: any, updateGuestSectionsInfo?: any, guestId?: any) => {
  if (guestSectionsInfo[sectionId].sectionStatus === 'ASSIGNED') {
    const response = await axiosInstance().post('/guest/start-section/' + guestId, { sectionId: sectionId });
    if (response && response.data && response.status === 200) {
      const updatedGuestSectionInfo: GuestSectionInfoDetailVO = cloneDeep(guestSectionsInfo[sectionId]);
      updatedGuestSectionInfo.sectionStatus = response.data.sections[0].status;
      updateGuestSectionsInfo({ sectionId: sectionId, sectionInfoDetails: updatedGuestSectionInfo });
    }
  }
};

export const saveAnswerApiCall = async (url?: string, guestId?: any, obj?: any) => {
  try {
    const response = await axiosInstance().post(url + guestId, obj);
    return response;
  } catch (e) {
    errorLogger(e, false, 'Guest Utils save answer/ edit question');
  }
};
