import i18next from 'i18next';
import axiosInstance from '@helpers/Axios';
import { errorLogger } from '@utils/ErrorLogging';
import SnackBarUtils from '@utils/SnackBarUtils';
import UserAccountInfoVO from '@interfaces/Models/UserAccountInfoVO';
import { AxiosRequestConfig } from 'axios';
import { serializeData } from '@utils/TransformRequestUtil';
import { downloadFileByUrl } from '@utils/FileUtil';
import { axiosRequestConfig } from '@utils/TransformRequestUtil';

const config: AxiosRequestConfig = {
  transformRequest: (data: any) => serializeData(data),
  headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8' }
};

export const getUserPreferences = async (callBack: (data: { [key: string]: any }) => void) => {
  try {
    const response = await axiosInstance().get('users/get-user-preferences');
    if (response && response.status === 200 && response.data) {
      callBack(response.data);
    }
  } catch (e) {
    errorLogger(e, false, 'Caught from getAllTemplates method in MyAccountService.ts');
  }
};

export const saveMyAccountPreferences = async (userPreferencesVO: { [key: string]: { [key: string]: string } }, uiPreferences: { [key: string]: string }, callBack: () => void) => {
  try {
    const obj = {
      userPreferencesVO: userPreferencesVO,
      uiPreferences: uiPreferences
    };
    const response = await axiosInstance().post('users/save-my-account-preferences', obj);
    if (response && response.status === 200 && response.data && callBack) {
      callBack();
    }
  } catch (e) {
    errorLogger(e, false, 'Caught from saveMyAccountPreferences method in MyAccountService.ts');
  }
};

export const updateProfileMyAccount = async (updateObj: any) => {
  try {
    return await axiosInstance().post('users/update', updateObj);
  } catch (e) {
    errorLogger(e, false, 'Caught from UpdateProfileMyAccount method in MyAccountService.ts');
  }
};

export const getApitoken = async (callBack: (data: { [key: string]: UserAccountInfoVO }) => void, type?: any) => {
  try {
    const response = await axiosInstance().get('users/get-user-account', { params: { type } });
    if (response && response.status === 200 && response.data) {
      const userAccountMap: { [key: string]: UserAccountInfoVO } = {};
      response.data.userAccountList?.forEach((account: UserAccountInfoVO) => {
        userAccountMap[account.accountType] = account;
      });
      callBack(userAccountMap);
    }
  } catch (e) {
    errorLogger(e, false, 'Caught from getApitoken method in MyAccountService.ts');
  }
};

export const removeAccount = async (callBack: (data: { [key: string]: UserAccountInfoVO }) => void, type: string) => {
  try {
    const obj = {
      accountType: type
    };
    const response = await axiosInstance().post('users/delete-user-account', obj);
    if (response && response.status === 200 && response.data) {
      const userAccountMap: { [key: string]: UserAccountInfoVO } = {};
      response.data.userAccountList?.forEach((account: UserAccountInfoVO) => {
        userAccountMap[account.accountType] = account;
      });
      callBack(userAccountMap);
      SnackBarUtils.success(i18next.t('ACCOUNT_REMOVED'));
    }
  } catch (e) {
    errorLogger(e, false, 'Caught form removeAccount method in MyAccountService.ts');
  }
};

export const resetPassword = async (username: string, callBack: (showDialog: boolean) => void) => {
  try {
    const obj = {
      username: username.toLowerCase()
    };
    const response = await axiosInstance().post('login/forgot-password', obj);
    if (response && response.status === 200) {
      callBack(false);
    }
  } catch (e) {
    errorLogger(e, false, 'Caught from resetPassword method in MyAccountService.ts');
  }
};

export const changePassword = async (user: { [key: string]: any }, callBack: () => void) => {
  try {
    const obj = {
      old_password: user.CURRENT_PASSWORD,
      password: user.NEW_PASSWORD
    };
    const response = await axiosInstance().post('users/change-password', obj, config);
    if (response && response.status === 200) {
      callBack();
      SnackBarUtils.success(i18next.t('PASSWORD_SAVED_SUCCESS'));
    }
  } catch (e) {
    errorLogger(e, false, 'Caught from changePassword method in MyAccountService.ts');
  }
};

export const generateNewSecret = async (callBack: (data: string) => void) => {
  try {
    const response = await axiosInstance().get('users/generate-2fa');
    if (response && response.status === 200 && response?.data.additional_message) {
      callBack(response.data.additional_message);
      SnackBarUtils.success(i18next.t('SECRET_KEY_GENERATED'));
    }
  } catch (e) {
    errorLogger(e, false, 'Caught from generateNewSecret method in MyAccountService.ts');
  }
};

export const validateCode2FA = async (code1: string, code2: string, callBack: (data: boolean) => void) => {
  try {
    const obj = {
      code1,
      code2
    };
    const response = await axiosInstance().post('users/validate-code-2fa', obj);
    if (response && response.status === 200) {
      callBack(true);
      SnackBarUtils.success(i18next.t('VERIFICATION_DONE'));
    }
  } catch (e) {
    errorLogger(e, false, 'Caught from validateCode2FA method in MyAccountService.ts');
  }
};

export const saveApiToken = async (type: string, accessValue: string, callBack: (data: { [key: string]: UserAccountInfoVO | string }) => void) => {
  try {
    const obj = {
      accountType: type,
      accessValue: { accessToken: accessValue }
    };
    const userAccount: { [key: string]: UserAccountInfoVO } = {};
    const response = await axiosInstance().post('users/add-user-account', obj);
    if (response && response.status === 200 && response.data) {
      response.data.userAccountList?.forEach((data: UserAccountInfoVO) => {
        if (type === data.accountType) {
          callBack({ type: type, value: data });
        }
        userAccount[data.accountType] = data;
      });
      SnackBarUtils.success(i18next.t('TOKEN_UPDATED'));
    }
    return userAccount;
  } catch (e) {
    errorLogger(e, false, 'Caught from saveApiToken method in MyAccountService.ts');
  }
};

export const generateMSTokens = async (callBack: (data: string) => void) => {
  try {
    const response = await axiosInstance().get('users/generate-auth-token-by-type', { params: { type: 'rfpio_lookup', hostType: 'Powerpoint_Companion' } });
    if (response && response.status === 200 && response.data) {
      callBack(response.data.additional_message);
      SnackBarUtils.success(i18next.t('TOKEN_GENERATED'));
    }
  } catch (e) {
    errorLogger(e, false, 'Caught from generateMSToken method in MyAccountService.ts');
  }
};

export const generateApiToken = async (callBack: (data: string) => void) => {
  try {
    const response = await axiosInstance().get('users/generate-api-token');
    if (response && response.status === 200 && response.data) {
      callBack(response.data.additional_message);
      SnackBarUtils.success(i18next.t('TOKEN_GENERATED'));
    }
  } catch (e) {
    errorLogger(e, false, 'Caught from generateApiToken method in MyAccountService.ts');
  }
};

export const getOfficeCompanionApp = async (extensionType: string) => {
  try {
    const response = await axiosInstance().get('reports/download-office-companion-app', { params: { extensionType: extensionType } });
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
  } catch (e) {
    errorLogger(e, false, 'Caught from getOfficeCompanionApp method in MyAccountService.ts');
  }
};
export const getDynamicsCRMSolution = async () => {
  try {
    const response = await axiosInstance().get('reports/download-dynamics-crm-solution');
    if (response && response.status === 200) {
      const fileName = 'Responsive_1_1_0_0.zip';
      const exeDownloadKey = response.data.additional_message;
      downloadFileByUrl(window.constants.baseUrl + 'projects/files/download/' + exeDownloadKey + '/' + fileName, fileName);
    }
  } catch (e) {
    errorLogger(e, false, 'Caught form getDynamicsCRMSolution method in MyAccountService.ts');
  }
};

export const downloadNativeApp = async (osType: string, appType: string, callBack: (exeDownloadKey: string) => void) => {
  try {
    const response = await axiosInstance().get('reports/download-native-app', { params: { appType, osType } });
    if (response && response.status === 200) {
      callBack(response.data.additional_message);
    }
  } catch (e) {
    errorLogger(e, false, 'Caught from downloadNativeApp method in MyAccountService.ts');
  }
};

export const loadSwaggerData = async (callBack: (data: { [key: string]: any }) => void) => {
  try {
    const response = await axiosInstance().get('ext/rfpioswagger.json?group=External');
    if (response && response.status === 200 && response.data) {
      callBack(response.data);
    }
  } catch (e) {
    errorLogger(e, false, 'Caught form loadSwaggerData method in MyAccountService.ts');
  }
};

export const getAPISettings = async (callBack: (data: { [key: string]: string }) => void) => {
  try {
    const response = await axiosInstance().get('users/get-api-settings');
    if (response && response.status === 200 && response.data && response.data.settings) {
      callBack(response.data.settings);
    }
  } catch (e) {
    errorLogger(e, false, 'Caught form getAPISettings method in MyAccountService.ts');
  }
};

export const updateProfilePic = async (convertedImage: Blob, callBack: (pic: string) => void) => {
  const imageData = {
    file: convertedImage
  };
  try {
    const response = await axiosInstance().post('users/update-profile-pic', imageData, axiosRequestConfig);
    if (response && response.status === 200 && response.data?.additional_message) {
      callBack(response.data.additional_message);
    }
  } catch (e) {
    errorLogger('Error occurs in update-profile-pic in MyAccountService.tsx file');
  }
};
