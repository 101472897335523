import { action } from 'easy-peasy';
import { GuestIdStore } from '@interfaces/GuestStoreInterface';

const GuestIdStoreImpl: GuestIdStore = {
  id: '',
  setGuestId: action((state, entry) => {
    state.id = entry;
  })
};

export default GuestIdStoreImpl;
