import React, { Suspense } from 'react';
import RfpLoader from './RfpLoader';

interface IRfpLazyLoad {
  skipLoader?: boolean;
  fallBack?: JSX.Element;
  children?: JSX.Element;
}

export default function RfpLazyLoad(props: IRfpLazyLoad) {
  const { skipLoader, fallBack, children } = props;
  return <Suspense fallback={<>{skipLoader ? <></> : fallBack ? fallBack : <RfpLoader loading={true} />}</>}>{children}</Suspense>;
}
