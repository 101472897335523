import store from '../store';

interface IprojectLockedStoreProps {
  projectId: string;
}

export default function ProjectLockedStoreTrigger(props: IprojectLockedStoreProps) {
  const { projectId } = props;

  // error.response.data.additional_message is project id sent from the backend
  store.getActions().projectMasterData.setProjectLockedStatus(projectId);

  // To trigger project locked popper
  store.getActions().projectMasterData.setProjectLockedStatus('TRIGGERED_' + projectId);

  if (!store.getState().projectMasterData.projectLockedStatus.currentProjectId) {
    store.getActions().projectMasterData.setLockedCurrentProjectId(projectId);
  }
}
