import UserDataStore from '@interfaces/userDataStore';
import { action, thunk } from 'easy-peasy';
import { constructBuBasedUsers, constructUserSpecificDeatils } from '../utils/user-load-details/UserLoadProcessors';
import { TRIAL, trialFeaturePermissions, trialFeaturesViewOnlyPermissionMap } from '../constants/TrialFeatureConstants';
import { setUnsetListItem } from '@utils/GeneralUtils';
import { updateTeamIdInBUMap, updateTeamIds } from '@utils/user-load-details/UserLoadHelper';

const UserDataStoreImpl: UserDataStore = {
  buBaseduserList: {},
  permissionMap: {},
  companyUserMap: {},
  teamsMap: {},
  activeUserList: [],
  permissionData: { isPermissionEnabled: {}, isSuperAdmin: false, isSupport: false, userPermission: {} },
  usersTagList: [],
  displayNameForRolesMap: {},
  allUserPolicies: {},

  setUserLoadDetails: action((state, entry) => {
    state.buBaseduserList = entry.buBaseduserList;
    state.permissionMap = entry.permissionMap;
    state.companyUserMap = entry.companyUserMap;
    state.teamsMap = entry.teamsMap;
    state.activeUserList = entry.activeUserList;
    state.permissionData = entry?.permissionData;
    state.usersTagList = entry.usersTagList || [];
    state.allUserPolicies = entry.allUserPolicies;
  }),
  updateUserLoadDetails: action((state, entry) => {
    // pass only fields that needs to be updated
    Object.assign(state, entry);
  }),
  updatePermissionMap: action((state, entry) => {
    state.permissionMap[entry.roleId] = entry.value;
  }),
  deletePermissionMap: action((state, entry) => {
    delete state.permissionMap[entry];
  }),
  setDisplayNameForRolesMap: action((state, entry) => {
    state.displayNameForRolesMap = entry;
  }),
  addCompanyUserMap: action((state, entry) => {
    let updatedBuUserList = { ...state.buBaseduserList };
    const { activeUserList = [], availableTagList = [] } = constructUserSpecificDeatils(Object.values(entry), updatedBuUserList);
    Object.assign(state.companyUserMap, entry);
    //update active user list and tag list
    state.activeUserList.push(...activeUserList);
    const uniqueTagList = [...state.usersTagList, ...availableTagList];
    state.usersTagList = Array.from(new Set(uniqueTagList));
    //push newly added users into respective BUs
    state.buBaseduserList = updatedBuUserList;
  }),
  updateCompanyUserMap: action((state, entry) => {
    Object.entries(entry).forEach(([userId, userVo]) => {
      const userData = state.companyUserMap[userId];
      if (userData) {
        state.companyUserMap[userId] = { ...userData, ...userVo };
      }
    });
  }),
  updateBuBaseduserList: action((state, entry) => {
    const updatedBuUserList = { ...state.buBaseduserList };
    constructBuBasedUsers(entry, updatedBuUserList);
    state.buBaseduserList = updatedBuUserList;
  }),
  addMemberToTeamsMap: action((state, entry) => {
    Object.keys(entry).forEach((key: string) => {
      const teamData = state.teamsMap[key];
      if (teamData) {
        const members = entry[key];
        const membersCount = members.length;
        state.teamsMap[key] = { ...teamData, members, membersCount };
      }
    });
  }),
  addOrUpdateTeam: action((state, entry) => {
    const teamId: string = entry.id;
    const currentTeam = state.teamsMap[teamId];
    let buUsersList = state.buBaseduserList;
    if (!currentTeam) {
      // Create Team Flow

      // update teamId in companyUsermap->userInfo for newly added member.
      const members: string[] = entry.members || [];
      members.forEach(userName => {
        const user = state.companyUserMap[userName];
        updateTeamIds(user, teamId, 'ADD');
      });

      //Add team id in ALL and BU List
      const primaryBU = entry.primaryBU;
      if (primaryBU) {
        updateTeamIdInBUMap(buUsersList, [primaryBU, 'ALL'], teamId, 'ADD');
      }
    } else {
      //Edit Team flow

      //Remove teamId if members was removed from team
      const members = currentTeam.members;
      const updatedMembers = entry.members;

      const unProcessedTeamList = [...members, ...updatedMembers];
      unProcessedTeamList.forEach(userName => {
        const user = state.companyUserMap[userName];
        if (updatedMembers.indexOf(userName) < 0) {
          updateTeamIds(user, teamId, 'REMOVE');
        }

        if (members.indexOf(userName) < 0) {
          updateTeamIds(user, teamId, 'ADD');
        }
      });

      if (currentTeam.primaryBU && entry.primaryBU && currentTeam.primaryBU !== entry.primaryBU) {
        //Bu update -> Remove teamId from old bu and add it in new bu.
        updateTeamIdInBUMap(buUsersList, [currentTeam.primaryBU], teamId, 'REMOVE');
        updateTeamIdInBUMap(buUsersList, [entry.primaryBU], teamId, 'ADD');
      }
    }
    state.teamsMap[teamId] = entry;
  }),
  removeTeam: action((state, entry) => {
    const currentTeam = state.teamsMap[entry];
    if (currentTeam) {
      // remove teamIds in companyUsermap->userInfo for all members.
      const members: string[] = currentTeam.members || [];
      members.forEach(userName => {
        const user = state.companyUserMap[userName];
        updateTeamIds(user, entry, 'REMOVE');
      });
      //Remove teamId from BU and ALL
      const primaryBU = currentTeam.primaryBU;
      if (primaryBU) {
        let buUsersList = state.buBaseduserList;
        updateTeamIdInBUMap(buUsersList, [primaryBU, 'ALL'], entry, 'REMOVE');
      }

      delete state.teamsMap[entry];
    }
  }),
  setPermissionData: action((state, entry) => {
    state.permissionData = entry;
  }),
  //to enable given permissions once user initiates trial
  updateTrialFeaturePermission: action((state, entry) => {
    const permissions = entry;
    const permissionMap: { [key: string]: boolean } = {};
    permissions.forEach((permission: string) => {
      permissionMap[permission] = true;
    });
    state.permissionData.isPermissionEnabled = { ...state.permissionData.isPermissionEnabled, ...permissionMap };
  }),
  // to provide permissions related to given trial feature
  enableTrialFeaturePermissions: thunk((action, input, { getStoreState }) => {
    const storeData = getStoreState();
    const userDetails = storeData.userDetails;
    const featurePreferenceMap = userDetails.userDetails.companyPreferencesVO['featurePreferences'];
    const permissionMap: { [key: string]: boolean } = {};
    const permissionData = storeData.userData.permissionData;
    const isSupportOrSuperAdmin = permissionData.isSuperAdmin || permissionData.isSupport;
    Object.entries(trialFeaturePermissions).forEach(([trialFeatureName, permissions]) => {
      permissions.forEach(permission => {
        const moduleName = trialFeatureName.replace(TRIAL, '').toUpperCase();
        const isTrialFeaturePresentInFeatureDetails = userDetails.userDetails.companyPreferencesVO?.featureDetails?.hasOwnProperty(trialFeatureName);
        //to access trial feature if user don't have permission check whether it is an allowed trial feature and is valid to provide permission
        const isValidToProvidePermission =
          !isSupportOrSuperAdmin && isTrialFeaturePresentInFeatureDetails && (!permissionData.userPermission[moduleName] || !permissionData.userPermission[moduleName].includes(permission));
        if (isValidToProvidePermission) {
          const isTrialFeatureEnabled = featurePreferenceMap[trialFeatureName] === '1';
          const isUserEnabledTrialFeature = userDetails.userTrialFeatureDetailsMap?.hasOwnProperty(trialFeatureName);
          let enablePermission = false;
          const viewOnlyPermissions: string[] = trialFeaturesViewOnlyPermissionMap[trialFeatureName];
          /* if particular trial feature is enabled at company level and user level provide that trial feature related permissions,
             else if particular trial feature is enabled at company level and user doesn't enabled trial provide only view type permissions. 
             We need to add trial feature key and view only permissions in trialFeaturesViewOnlyPermissionMap based on feature in order to allow user to view */
          if (viewOnlyPermissions) {
            if (isTrialFeatureEnabled) {
              enablePermission = isUserEnabledTrialFeature || viewOnlyPermissions.includes(permission);
            } else {
              /*If trial is disabled check that trial key is available featureDetails present in companyPreferenceVO then provide view only permission for that particular trial feature*/
              enablePermission = isTrialFeaturePresentInFeatureDetails && viewOnlyPermissions.includes(permission);
            }
          }
          permissionMap[permission] = enablePermission;
        }
      });
    });
    if (Object.keys(permissionMap).length > 0) {
      Object.assign(permissionData.isPermissionEnabled, permissionMap);
      action.setPermissionData(permissionData);
    }
  }),
  updateActiveUserList: action((state, entry) => {
    setUnsetListItem(state.activeUserList, entry);
  })
};

export default UserDataStoreImpl;
