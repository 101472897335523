import React from 'react';
import 'jquery-ui/themes/base/resizable.css';
import 'jquery-ui/themes/base/draggable.css';

// require('jquery-ui/ui/version');
// require('jquery-ui/ui/plugin');
// require('jquery-ui/ui/widget');
// require('jquery-ui/ui/widgets/mouse');
require('jquery-ui/ui/widgets/resizable');
require('jquery-ui/ui/widgets/draggable');

export default function JQueryWidgets(props: any) {
  return <>{props.children}</>;
}
