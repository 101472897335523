import BusinessUnitVO from './BussinessUnitVO';
import CompanyCollectionVO from './CompanyCollectionVO';
import CompanyInfoVO, { companyInfoVO } from './CompanyInfoVO';
import MasterDataVO from './MasterDataVO';
import { SavedSearchVO } from './SavedSearchVO';

export default interface UserDetailsVO {
  userSharePointSites: string[];
  fontFamily: { text: string; value: string }[];
  collectionList: CompanyCollectionVO[];
  companyInfoVO: CompanyInfoVO;
  companyPolicyMap: {};
  companyPreferencesVO: any;
  contentFlagList: { dataList: MasterDataVO[] };
  projectFlagList: { dataList: MasterDataVO[] };
  projectStageList: { dataList: MasterDataVO[] };
  projectTypeList: { dataList: MasterDataVO[] };
  requestTypeList: { dataList: MasterDataVO[] };
  requestStageList: { dataList: MasterDataVO[] };
  vendorStageList: { dataList: MasterDataVO[] };
  leaderboardStatusList: { dataList: MasterDataVO[] };
  savedSearchVOList: SavedSearchVO[];
  alSavedSearchVOList?: SavedSearchVO[];
  moderationSavedSearchVOList?: SavedSearchVO[];
  tabId: string;
  tinymceStyleList: [];
  userInfoVO: UserInfoVO;
  userPermissionTypes: {};
  businessUnits: BusinessUnitVO[];
}

export const userDetailsVO: UserDetailsVO = {
  collectionList: [],
  companyInfoVO: companyInfoVO,
  companyPolicyMap: {},
  companyPreferencesVO: {},
  customFieldVOList: { companyCustomFieldList: [] },
  contentFlagList: { dataList: [] },
  projectFlagList: { dataList: [] },
  projectStageList: { dataList: [] },
  projectTypeList: { dataList: [] },
  requestTypeList: { dataList: [] },
  requestStageList: { dataList: [] },
  vendorStageList: { dataList: [] },
  savedSearchVOList: [],
  alSavedSearchVOList: [],
  moderationSavedSearchVOList: [],
  tabId: '',
  tinymceStyleList: [],
  userInfoVO: {
    activationCodeSentStatus: false,
    additionalRoles: [],
    companyId: '',
    company_info: [],
    displayName: '',
    emailVerified: false,
    firstName: '',
    language: '',
    lastName: '',
    outOfOffice: false,
    srcName: {},
    tags: [],
    teamIds: [],
    timeZone: '',
    businessUnits: [],
    uiPreferences: {
      dateFormat: '',
      landingPage: '',
      projectLandingPage: '',
      timeFormat: '',
      version: '',
      cclUser: '',
      intakeUser: '',
      isV2TourShown: '',
      isProfileOnboardingDone: '',
      proactiveProposalUser: '',
      isCompactTourShown: '',
      ppbRocketRippleEffect: {
        shownForProposal: false,
        shownForTemplate: false
      }
    },
    user: false,
    userName: '',
    userRole: '',
    userStatus: '',
    avatarChar: '',
    avatarLetterColor: ''
  },
  userPermissionTypes: {},
  userVOList: { users: [] },
  businessUnits: [],
  teamsList: [],
  fontFamily: [],
  userSharePointSites: []
};

export interface UserInfoVO {
  activationCodeSentStatus: boolean;
  additionalRoles: [];
  companyId: string;
  company_info: [];
  displayName: string;
  emailVerified: boolean;
  firstName: string;
  language: string;
  lastName: string;
  outOfOffice: boolean;
  srcName: {};
  tags: [];
  teamIds: [];
  timeZone: string;
  uiPreferences: uiPreferences;
  user: boolean;
  userName: string;
  userRole: string;
  userStatus: string;
  primaryBU: string;
  sharedBUs: string[];
  businessUnits: string[];
  avatarChar?: string;
  avatarLetterColor?: string;
}

export interface uiPreferences {
  dateFormat: string;
  landingPage: string;
  projectLandingPage: string;
  timeFormat: string;
  version: string;
  cclUser: string;
  intakeUser: string;
  isV2TourShown: string;
  isProfileOnboardingDone: string;
  proactiveProposalUser: string;
  isCompactTourShown: string;
  ppbRocketRippleEffect: { shownForTemplate: boolean; shownForProposal: boolean };
  isStrictProjectV2: string;
  isStrictCCLV2: string;
  isClassicEngagement?: string;
}

export interface UserFeatureDetailsVO {
  createdCount: number;
  enabledDate: string;
}
