import React from 'react';
import Cookies from 'universal-cookie';
import { Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import LoginButton from '@containers/login/LoginComponents/LoginButton';
import { updateCurrentUser } from './CRMUtils';
import { useHistory } from 'react-router-dom';
import { isIE } from 'react-device-detect';

const CRMLogin = (props: any) => {
  const history = useHistory();

  const responsiveLoad = () => {
    let newLocation: string = '';

    let existingAccessToken: string = '';
    const userData = localStorage.getItem('userData');
    if (userData !== null && userData !== 'null') {
      const user = JSON.parse(userData);
      existingAccessToken = user.access_token;
    }

    newLocation = newLocation + '/v2/login?closeAfterLogin=true';
    if (isIE) {
      newLocation += '&rfpioCRMIELogin=true';
    }

    const cookies = new Cookies();

    cookies.remove('rfpioCRMLoginData', { path: '/' });
    cookies.remove('rfpioCRMIELogin', { path: '/' });
    const loginWindow = window.open(newLocation, 'ResponsiveLogin', 'width=400, height=600, resizable=yes');
    loginWindow && loginWindow.postMessage('READY', '*');

    const intervalFunction = setInterval(() => {
      const data = cookies.get('rfpioCRMLoginData');
      if (data) {
        cookies.remove('rfpioCRMLoginData', { path: '/' });
        const loginData = JSON.parse(data);
        if (typeof loginData === 'object') {
          const keys = Object.keys(loginData);
          let i = keys.length;
          while (i--) {
            localStorage.setItem(keys[i], loginData[keys[i]]);
          }
        }
      }
      let accessToken = null;
      const userData = localStorage.getItem('userData');
      if (userData !== null && userData !== 'null') {
        const user = JSON.parse(userData);
        updateCurrentUser(user);
        accessToken = user.access_token;
      }

      if (accessToken != null && accessToken !== existingAccessToken) {
        existingAccessToken = accessToken;
        clearInterval(intervalFunction);
        loginWindow && loginWindow.close();
        cookies.remove('rfpioCRMLoginData', { path: '/' });
        cookies.remove('rfpioCRMIELogin', { path: '/' });

        const redirectLocation = window.location.search.split('crm_v2=')[1];
        history.push(redirectLocation);
      }
    }, 1000);
  };

  // to synchronize multi window localStorage in IE
  // window.onstorage = function (e) { };

  // window.addEventListener('storage', function (e) { }, false);

  // });

  return (
    <Grid container alignItems='center' justify='center' className='background-blue height-100-imp' direction='column'>
      <Typography className='text-grey mt-15 mb-15'> Please Login into Responsive application.</Typography>
      <LoginButton
        color='primary'
        variant='contained'
        className='max-width-230 font500'
        dataQa='CONNECT_TO_RESPONSIVE'
        onClick={() => {
          responsiveLoad();
        }}>
        Connect to Responsive
      </LoginButton>
      <Typography className='text-grey mt-10'>We will open a new page to connect your Responsive application.</Typography>
    </Grid>
  );
};

export default CRMLogin;
