import axiosInstance from '@helpers/Axios';
import { errorLogger } from '@utils/ErrorLogging';
import { UserUpdateObject } from './LoginUtils';

export const getLoginAdditionalDetails = async (companyId: string, landingPage: string, ssoName?: string | null, userName?: string) => {
  try {
    return await axiosInstance().get('login/login-additional-details', { params: { companyId, landingPage, ssoName, userName } });
  } catch (e) {
    errorLogger(e, false, 'login-additional-details in LoginService.ts');
  }
};

export const bulkGenerateMSaddinToken = async (users: string[], hostType: string) => {
  try {
    return await axiosInstance().post('users/generate-ms-addin-token', { users, hostType, isSystemGenerated: true });
  } catch (e) {
    errorLogger(e, false, 'generate-ms-addin-token in LoginService.ts');
  }
};

export const getLoginAdditionalDetailsUser = async (userName: string, landingPage: string) => {
  try {
    return await axiosInstance().get('login/login-additional-details-user', { params: { userName, landingPage } });
  } catch (e) {
    errorLogger(e, false, 'login-additional-details-user in LoginService.ts');
  }
};

export const userValidate = async (userName: string) => {
  try {
    return await axiosInstance().get('login/user-validate', { params: { userName } });
  } catch (e) {
    errorLogger(e, false, 'user-validate in LoginService.ts');
  }
};

export const loginUser = async (username: string, password: string, fromAskPWA?: string, userAgent?: string) => {
  try {
    return await axiosInstance().post('login/login-basic', { username, password, fromAskPWA, userAgent });
  } catch (e) {
    errorLogger(e, false, 'login-basic in LoginService.ts');
  }
};

export const googleLogin = async (authenticationValue: string, fromAskPWA?: string, userAgent?: string) => {
  try {
    return await axiosInstance().post('login/login-google', { authenticationValue, fromAskPWA, userAgent });
  } catch (e) {
    errorLogger(e, false, 'login-google in LoginService.ts');
  }
};

export const supportGoogleLogin = async (authenticationValue: string, companyId?: string) => {
  try {
    return await axiosInstance().post('login/login-support-google', { companyId, authenticationValue });
  } catch (e) {
    errorLogger(e, false, 'login-support-google in LoginService.ts');
  }
};

export const samlLogin = async (authenticationValue: string, fromAskPWA?: string, userAgent?: string) => {
  try {
    return await axiosInstance().post('login/login-saml', { authenticationValue, fromAskPWA, userAgent });
  } catch (e) {
    errorLogger(e, false, 'login-saml in LoginService.ts');
  }
};

export const oauthCode = async (code: string, type: string, fromAskPWA?: string, userAgent?: string) => {
  try {
    return await axiosInstance().post('login/login-oauth', { code, type, fromAskPWA, userAgent });
  } catch (e) {
    errorLogger(e, false, 'login-oauth in LoginService.ts');
  }
};

export const forgotPassword = async (username: string) => {
  try {
    return await axiosInstance().post('login/forgot-password', { username });
  } catch (e) {
    errorLogger(e, false, 'forgot-password in LoginService.ts');
  }
};

export const logOut = async () => {
  try {
    return await axiosInstance().get('users/logout');
  } catch (e) {
    errorLogger(e, false, 'forgot-password in LoginService.ts');
  }
};

export const serverHealthCheck = async () => {
  try {
    return await axiosInstance().get('health-check/status');
  } catch (e) {
    errorLogger(e, false, 'health-check/status in LoginService.ts');
  }
};

export const chooseNewPassword = async (authenticationValue: string, newPassword: string) => {
  try {
    return await axiosInstance().post('login/password-validation', { authenticationValue, newPassword });
  } catch (e) {
    errorLogger(e, false, 'password-validation in LoginService.ts');
  }
};

export const validateCode = async (key: string, code: string) => {
  try {
    return await axiosInstance().post('login/validate-code-2fa', { key, code });
  } catch (e) {
    errorLogger(e, false, 'validate-code-2fa in LoginService.ts');
  }
};

export const changeCompany = async (companyId: string, userName: string) => {
  try {
    return await axiosInstance().get('login/change-company', { params: { companyId, userName } });
  } catch (e) {
    errorLogger(e, false, 'change-company in LoginService.ts');
  }
};

export const checkActivate = async (activationKey: string) => {
  try {
    return await axiosInstance().post('login/check-activate', { activationKey });
  } catch (e) {
    errorLogger(e, false, 'check-activate in LoginService.ts');
  }
};

export const updateUser = async (userObj: UserUpdateObject, key: string) => {
  const updateObj = {
    activationKey: key,
    authenticationType: 'basic',
    firstname: userObj.firstName,
    lastname: userObj.lastName,
    authenticationValue: userObj.password,
    activationCode: userObj.activationCode,
    verificationCode2FA: userObj.verificationCode
  };

  try {
    return await axiosInstance().post('login/activate', updateObj);
  } catch (e) {
    errorLogger(e, false, 'login/activate updateUser in LoginService.ts');
  }
};

//For Google,SalesForce User Activation and  Reset Password Api.
export const userActivation = async (activationKey: string, authenticationValue: string, authenticationType: string, activationCode?: string) => {
  try {
    return await axiosInstance().post('login/activate', { activationKey, authenticationValue, activationCode, authenticationType });
  } catch (e) {
    errorLogger(e, false, 'login/activate activateGoogle in LoginService.ts');
  }
};
