import { lazy } from 'react';
import { errorLogger } from '@utils/ErrorLogging';

export const lazyLoadWithRetry = (importer: any, key: string) => {
  const retryImport = async () => {
    try {
      return await importer();
    } catch (error) {
      await new Promise(resolve => {
        const time = setTimeout(() => {
          resolve();
          clearTimeout(time);
        }, 1000);
      });
      const isLazyComponentRetry = window.sessionStorage.getItem(`lazyRetry-${key}`);
      errorLogger(error, false, key);
      if (isLazyComponentRetry == null) {
        window.sessionStorage.setItem(`lazyRetry-${key}`, 'true');
        window.location.reload();
      }
      throw error;
    }
  };
  return lazy(retryImport);
};
