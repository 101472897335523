import React, { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import RfpDialog, { RfpDialogTitle, RfpDialogContent, RfpDialogActions } from './RfpDialog';
import { useTranslation } from 'react-i18next';
import RfpButton from './RfpButton';
import { errorLogger } from '@utils/ErrorLogging';
import pagenotfound from '@images/pagenotfound.svg';
import { Typography } from '@material-ui/core';
import CommonThemeStyles from '@styles/CommonThemeStyles';
import { withErrorBoundary } from 'react-error-boundary';

const RfpErrorBoundary = (props: any) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}>
      {props.children}
    </ErrorBoundary>
  );
};

export default RfpErrorBoundary;

export const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const { t } = useTranslation('translations');
  const commonThemeClasses = CommonThemeStyles();
  return (
    <div className='error-boundary-content p-10'>
      <div className='text-center'>
        <img src={pagenotfound} width='20%' height='auto' alt={t('SOMETHING_WENT_WRONG')} />
        <Typography variant='h3' className='font-20 pt-10 pb-20'>
          {t('SOMETHING_WENT_WRONG')}..
        </Typography>
        <Typography variant='h5'>{t('PLEASE_TRY_AGAIN')}..</Typography>
        <div className='text-center pt-20'>
          <RfpButton
            dataQa='RESET'
            color='primary'
            variant='contained'
            onClick={() => {
              resetErrorBoundary();
            }}>
            {t('TRY_AGAIN')}
          </RfpButton>
        </div>
      </div>
    </div>
  );
};

export const RfpwithErrorBoundary = (props: any) => {
  return withErrorBoundary(props.component, {
    FallbackComponent: DialogErrorFallback
  });
};

export const DialogErrorFallback = ({ error, resetErrorBoundary }) => {
  const [openErrorBoundaryDialog, setOpenErrorBoundaryDialog] = useState<boolean>(true);
  const { t } = useTranslation('translations');

  return (
    <RfpDialog
      dataQa='ErrorDialog'
      open={openErrorBoundaryDialog}
      onClose={() => {
        setOpenErrorBoundaryDialog(false);
      }}
      maxWidth='sm'
      fullWidth={true}>
      <RfpDialogContent className='text-center'>
        <img src={pagenotfound} width='20%' height='auto' alt={t('SOMETHING_WENT_WRONG')} />
        <Typography variant='h3' className='font-20 pt-10 pb-20'>
          {t('SOMETHING_WENT_WRONG')}..
        </Typography>
        <Typography variant='h5'>{t('PLEASE_TRY_AGAIN')}..</Typography>
      </RfpDialogContent>
      <RfpDialogActions className='justifyContentCenter'>
        <RfpButton
          dataQa='RESET'
          color='primary'
          variant='contained'
          onClick={() => {
            resetErrorBoundary();
            setOpenErrorBoundaryDialog(false);
          }}>
          {t('TRY_AGAIN')}
        </RfpButton>
      </RfpDialogActions>
    </RfpDialog>
  );
};

export const rfpErrorHandler = (error: Error, info: { componentStack: string }) => {
  errorLogger(error, false);
};
