import i18next from 'i18next';
import store from '@store/index';
import { Modules } from '@utils/EnumUtils';
import { getCurrentUser, isFeatureEnabled, isSupport } from '@utils/UserLoadDetailUtil';
import { getUXPreference } from '@utils/GeneralUtils';
import { IAvatarLauncherMenuItems } from '@interfaces/header-wrapper/HeaderWrapperPropstype';
import { Theme } from '@material-ui/core';
import EmailMessageVO, { EmailRecipient } from '@interfaces/Models/EmailMessageVO';

export const setUxPreferences = () => {
  const userDetails = store.getState().userDetails.userDetails;
  const uiMode = userDetails.companyInfoVO.uiMode;
  const cclMode = userDetails.companyInfoVO.cclMode;
  const uiPreferences = userDetails.userInfoVO.uiPreferences;

  const myWorkVersion: string = isFeatureEnabled('request') ? 'v1' : uiPreferences?.my_work_version || 'v2';
  const version: string = uiPreferences?.version || (isFeatureEnabled('ux_v2') && uiMode === 'UI_V2_STRICT' ? 'v2' : 'v1');
  const cclVersion: string = uiPreferences?.ccl_version || (isFeatureEnabled('consolidated_content_library') && cclMode === 'UI_V2_STRICT' ? 'v2' : 'v1');
  const userUXPref: { [key: string]: string } = getUXPreference();

  if (!userUXPref[Modules.PROJECT] || userUXPref[Modules.PROJECT] !== version) {
    userUXPref[Modules.PROJECT] = version;
  }
  if (!userUXPref[Modules.CONTENT_LIBRARY] || userUXPref[Modules.CONTENT_LIBRARY] !== cclVersion) {
    userUXPref[Modules.CONTENT_LIBRARY] = cclVersion;
  }
  if (!userUXPref[Modules.MY_WORK] || userUXPref[Modules.MY_WORK] !== myWorkVersion) {
    userUXPref[Modules.MY_WORK] = myWorkVersion;
  }
  if (!userUXPref[Modules.INTAKE] || userUXPref[Modules.INTAKE] !== 'v2') {
    userUXPref[Modules.INTAKE] = 'v2';
  }
  localStorage.setItem('uxPreference', JSON.stringify(userUXPref));

  return userUXPref;
};

export const darkModeEnabledModules: string[] = ['PROJECT', 'E_SIGNATURE', 'INTAKE', 'RESPONSE_TEMPLATES', 'MY_ACCOUNT', 'MY_WORK', 'ORGANIZATION_SETTINGS', 'CONTENT_ANALYSIS'];

export const interFontEnabledModules: string[] = ['ORGANIZATION_SETTINGS', 'MY_ACCOUNT', 'MY_WORK'];

export const UI_V2_STRICT = [
  'MY_WORK',
  'E_SIGNATURE',
  'INTAKE',
  'RESPONSE_TEMPLATES',
  'CONTENT_ANALYSIS',
  'PROFILE',
  'PROACTIVE_PROPOSAL_BUILDER',
  'MY_ACCOUNT',
  'REPORTS',
  'ORGANIZATION_SETTINGS',
  'REQUEST',
  'RESPONDERS'
];

export enum HelpCenterLinkType {
  SUBMIT_IDEA = 'SUBMIT_IDEA',
  REPORT_BUG = 'IN_ACTIVE',
  EMAIL_INTERNAL = 'EMAIL_INTERNAL',
  TEAM_CHAT = 'TEAM_CHAT',
  SEND_FEEDBACK = 'SEND_FEEDBACK'
}

const checkMyPermission = function (permission: string[], isPermissionEnabled: any) {
  if (!isPermissionEnabled) {
    return false;
  }
  let isEnabled: boolean = false;
  if (!Array.isArray(permission)) {
    return isPermissionEnabled[permission];
  } else {
    for (let i = 0; i < permission.length; i++) {
      isEnabled = isPermissionEnabled[permission[i]];
      if (isEnabled) {
        return isEnabled;
      }
    }
  }
  return isEnabled;
};

const getIsStrictV2 = (module: string) => {
  const userDetails = store.getState().userDetails.userDetails;
  const uiPreferences = userDetails.userInfoVO.uiPreferences;
  const isProjectPage: boolean = module === Modules.PROJECT;

  if (uiPreferences) {
    return isProjectPage ? !uiPreferences.isStrictProjectV2 : !uiPreferences.isStrictCCLV2;
  }
  return true;
};

export const constructAvatarMenuItem = function (module: string, theme: Theme, darkEnabled: boolean, isPermissionEnabled: any) {
  const userDetails = store.getState().userDetails.userDetails;
  const uiMode = userDetails.companyInfoVO.uiMode;
  const cclMode = userDetails.companyInfoVO.cclMode;
  const isClassicEngagement = userDetails.userInfoVO.uiPreferences?.isClassicEngagement;
  const switchToV1 =
    !isFeatureEnabled('strict_ux_v2') &&
    !isClassicEngagement &&
    module !== Modules.MY_WORK &&
    getIsStrictV2(module) &&
    (module !== Modules.PROJECT || uiMode !== 'UI_V2_STRICT') &&
    !UI_V2_STRICT.includes(module);

  const menuItems: IAvatarLauncherMenuItems[] = [
    {
      label: 'COMPANY',
      key: 'COMPANY',
      icon: 'rfpio-font-icon icon-company',
      url: '/v2/companyList',
      backgroundColor: '#2E93FF',
      fontSize: '16px',
      hasAccess: true,
      isV2Redirect: true
    },
    {
      label: i18next.t('MY_ACCOUNT'),
      key: 'MY_ACCOUNT',
      icon: 'rfpio-font-icon icon-my-account',
      url: '/v2/my-account',
      isV2Redirect: true,
      backgroundColor: '#9CC83B',
      fontSize: '14px',
      hasAccess: !isSupport()
    },
    {
      label: i18next.t('ORGANIZATION_SETTINGS'),
      key: 'ORG_SETTINGS',
      icon: 'rfpio-font-icon icon-settings-line',
      url: '/v2/organization-settings',
      backgroundColor: '#2E93FF',
      fontSize: '18px',
      isV2Redirect: true,
      hasAccess: checkMyPermission(userDetails.userPermissionTypes.ORGANIZATION_SETTINGS, isPermissionEnabled)
    },
    {
      label: i18next.t('SWITCH_TO_CLASSIC'),
      key: 'CLASSIC_UI',
      icon: 'rfpio-font-icon icon-classic-ui-icon',
      backgroundColor: '#9CC83B',
      fontSize: '18px',
      hasAccess: module === Modules.CONTENT_LIBRARY ? switchToV1 && cclMode !== 'UI_V2_STRICT' : switchToV1
    },
    {
      label: theme.palette.type === 'dark' ? i18next.t('LIGHT_MODE') : i18next.t('DARK_MODE'),
      key: 'DARK',
      icon: theme.palette.type === 'dark' ? 'rfpio-font-icon icon-light-mode' : 'rfpio-font-icon icon-dark-mode',
      backgroundColor: theme.palette.type === 'dark' ? '#2E93FF' : '#024069',
      fontSize: '16px',
      hasAccess: module && darkEnabled
    },
    {
      label: i18next.t('HELP_CENTER'),
      key: 'HELP_CENTER',
      icon: 'rfpio-font-icon icon-question-line',
      backgroundColor: '#9CC83B',
      fontSize: '18px',
      hasAccess: true
    },
    {
      label: i18next.t('PLANS_AND_BILLING'),
      key: 'PLANS_AND_BILLING',
      icon: 'rfpio-font-icon icon-payment-billing',
      url: '/#/company-dashboard',
      backgroundColor: '#EE7E32',
      fontSize: '18px',
      hasAccess: isPermissionEnabled['BILLING']
    }
  ].filter(item => {
    return item.hasAccess;
  });

  return menuItems;
};

export const currentModuleMap: { [key: string]: string } = {
  MY_WORK: 'MY_WORK',
  MY_ACCOUNT: 'MY_ACCOUNT',
  E_SIGNATURE: 'AUTOGRAPH',
  RESPONSE_TEMPLATES: 'TEMPLATES',
  PROJECT: 'PROJECTS',
  PROACTIVE_PROPOSAL_BUILDER: 'PROPOSAL_BUILDER_TYPE',
  INTAKE: 'INTAKES',
  CONTENT_LIBRARY: 'CONTENT_LIBRARY',
  REPORTS: 'REPORTS',
  PROFILE: 'PROFILE_CENTER',
  CONTENT_ANALYSIS: 'REQUIREMENT_ANALYSIS',
  REQUEST: 'REQUESTS',
  SUPPORT_PORTAL: 'Support Portal',
  TOOLS: 'Tools',
  ANNOUNCEMENTS: 'Announcements',
  RESPONDERS: 'REQUESTS',
  ORGANIZATION_SETTINGS: 'ORGANIZATION_SETTINGS'
};

export const isNotificationRead = (email: EmailMessageVO) => {
  const userName = getCurrentUser().username;
  return email.recipients.some(recipient => recipient.address === userName && recipient.read);
};

export const getUserSpecificLandingPage = (landingPage: string) => {
  switch (landingPage) {
    case 'app.respondRfp':
      return '/v2/project/manage-project';
    case 'app.reports':
      return '/v2/reports/application-usage-report';
    case 'app.documents-lib':
    case 'app.answer-lib':
      return '/v2/content-library/library';
    case 'app.request':
      return '/v2/request/manage-request';
    case 'app.profile':
      return '/v2/profile/list';
    default:
      return '/v2/my-work';
  }
};

export enum iconMap {
  AI_ASSITANT = 'AI_ASSITANT',
  COMMUNITY = 'COMMUNITY',
  CONTACT_ADMIN = 'CONTACT_ADMIN',
  CONTENT_ANALYSIS = 'CONTENT_ANALYSIS',
  CONTENT_LIBRARY = 'CONTENT_LIBRARY',
  E_SIGNATURE = 'E_SIGNATURE',
  HELP_CONTACT = 'HELP_CONTACT',
  INTAKE = 'INTAKE',
  MY_WORK = 'MY_WORK',
  PROFILE = 'PROFILE',
  PROJECT = 'PROJECT',
  PROACTIVE_PROPOSAL_BUILDER = 'PROACTIVE_PROPOSAL_BUILDER',
  REPORTS = 'REPORTS',
  REQUEST = 'REQUEST',
  ACADEMY = 'ACADEMY',
  TOUR = 'TOUR',
  RESPONSE_TEMPLATES = 'RESPONSE_TEMPLATES',
  SUPPORT_PORTAL = 'SUPPORT_PORTAL',
  TOOLS = 'SUPPORT_PORTAL',
  ANNOUNCEMENTS = 'SUPPORT_PORTAL',
  RESPONDERS = 'RESPONDERS',
  CHAT = 'CHAT',
  SUPPORT_ACCESS = 'SUPPORT_ACCESS',
  ORGANIZATION_SETTINGS = 'ORGANIZATION_SETTINGS'
}

export interface IBreadCrumb {
  isLink?: boolean;
  breadCrumbTitle?: string;
}

export interface breadCrumbMap {
  levelOneBreadCrumb?: IBreadCrumb;
  levelTwoBreadCrumb?: IBreadCrumb;
}
