import { action } from 'easy-peasy';
import contentMasterDataStore from '@interfaces/manage-content-store/ContentMasterDataStore';

const contentMasterDataStoreImpl: contentMasterDataStore = {
  contentFlagMap: {},
  contentFlagIdMap: {},
  contentFlagList: [],
  flagList: [],
  mergeTagMap: {},
  usedMergeTagMap: {},
  contentMergeTagMap: [],
  activeMergeTagMap: {},

  setContentMasterData: action((state, entry) => {
    state.contentFlagList = entry.contentFlagList;
    state.contentFlagMap = entry.contentFlagMap;
    state.contentFlagIdMap = entry.contentFlagIdMap;
    state.flagList = entry.flagList;
  }),
  setMergeTagMap: action((state, entry) => {
    state.mergeTagMap = entry;
  }),
  setUsedMergeTagMap: action((state, entry) => {
    state.usedMergeTagMap = entry;
  }),
  setContentMergeTagMap: action((state, entry) => {
    state.contentMergeTagMap = entry;
  }),
  setActiveMergeTagMap: action((state, entry) => {
    state.activeMergeTagMap = entry;
  })
};

export default contentMasterDataStoreImpl;
