import { action } from 'easy-peasy';
import CompanyCollectionStore from '@interfaces/CompanyCollectionStore';
import CompanyCollectionVO from '@interfaces/Models/CompanyCollectionVO';

const CompanyCollectionStoreImpl: CompanyCollectionStore = {
  collectionList: [],
  collectionMap: {},
  setCompanyCollectionData: action((state, entry) => {
    state.collectionList = entry.collectionList;
    state.collectionMap = entry.collectionMap;
  }),
  addCompanyCollectionData: action((state, entry) => {
    state.collectionList.push(entry);
    state.collectionMap[entry.id] = entry;
  }),
  updateCompanyCollectionData: action((state, entry) => {
    const updateIds = Object.keys(entry);
    state.collectionList = state.collectionList.map((coll: CompanyCollectionVO) => {
      return updateIds.includes(coll.id) ? entry[coll.id] : coll;
    });
    updateIds.forEach((id: string) => {
      state.collectionMap[id] = entry[id];
    });
  }),
  updateUsersInCollection: action((state, entry) => {
    const newUsers = Object.keys(entry);
    newUsers.forEach(user => {
      entry[user]?.collectionList.forEach(coll => {
        const idx = state.collectionList.findIndex(collection => collection.id === coll);
        if (idx >= 0) {
          state.collectionList[idx].users = [...state.collectionList[idx].users, user];
          state.collectionMap[coll].users = [...state.collectionMap[coll].users, user];
        }
      });
    });
  })
};

export default CompanyCollectionStoreImpl;
