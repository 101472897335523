import { action } from 'easy-peasy';
import { GuestPageLayoutStore } from '@interfaces/GuestStoreInterface';
import { GuestPageLayoutVODefault } from '@interfaces/Models/GuestPageLayoutVO';

const GuestPageLayoutStoreImpl: GuestPageLayoutStore = {
  guestPageLayout: {
    ...GuestPageLayoutVODefault,
    position: {
      column1: 1,
      column2: 10,
      column3: 1,
      smOuterColumn1: false,
      smOuterColumn2: 12,
      smOuterColumn3: false,
      smColumn1: false,
      smColumn2: 12,
      smColumn3: false,
      isColumn1Hidden: false,
      isColumn3Hidden: false
    }
  },
  currentQuestionAnswers: [],
  currentDescQuestion: '',
  setNoQuestionsAssigned: action((state, value) => {
    state.guestPageLayout.noQuestionsAssigned = value;
  }),
  setShowSaved: action((state, value) => {
    state.guestPageLayout.showSaved = value;
  }),
  setShowSaving: action((state, value) => {
    state.guestPageLayout.showSaving = value;
  }),
  setShowHelpDemo: action((state, value) => {
    state.guestPageLayout.showHelpDemo = value;
  }),
  setShowSubmitPage: action((state, value) => {
    state.guestPageLayout.showSubmitPage = value;
  }),
  openComments: action(state => {
    state.guestPageLayout.isCommentsOpen = true;
    state.guestPageLayout.isCommentsOpenClick = true;
    state.guestPageLayout.isSectionListOpen = false;
    state.guestPageLayout.position = {
      column1: 'auto',
      column2: 9,
      column3: 3,
      smOuterColumn1: false,
      smOuterColumn2: 2,
      smOuterColumn3: 10,
      smColumn1: false,
      smColumn2: 12,
      smColumn3: false,
      isColumn1Hidden: true,
      isColumn3Hidden: false
    };
  }),
  resetOpenCommentsClick: action(state => {
    state.guestPageLayout.isCommentsOpenClick = false;
  }),
  closeComments: action(state => {
    state.guestPageLayout.isCommentsOpen = false;
    state.guestPageLayout.position = {
      column1: 1,
      column2: 10,
      column3: 1,
      smOuterColumn1: false,
      smOuterColumn2: 12,
      smOuterColumn3: false,
      smColumn1: false,
      smColumn2: 12,
      smColumn3: false,
      isColumn1Hidden: false,
      isColumn3Hidden: false
    };
  }),
  openSectionList: action(state => {
    state.guestPageLayout.isCommentsOpen = false;
    state.guestPageLayout.isSectionListOpen = true;
    state.guestPageLayout.position = {
      column1: 3,
      column2: 9,
      column3: 'auto',
      smOuterColumn1: 10,
      smOuterColumn2: 2,
      smOuterColumn3: false,
      smColumn1: false,
      smColumn2: 12,
      smColumn3: false,
      isColumn1Hidden: false,
      isColumn3Hidden: true
    };
  }),
  closeSectionList: action(state => {
    state.guestPageLayout.isSectionListOpen = false;
    state.guestPageLayout.position = {
      column1: 1,
      column2: 10,
      column3: 1,
      smOuterColumn1: false,
      smOuterColumn2: 12,
      smOuterColumn3: false,
      smColumn1: false,
      smColumn2: 12,
      smColumn3: false,
      isColumn1Hidden: false,
      isColumn3Hidden: false
    };
  }),
  setActiveQuestionState: action((state, value) => {
    const questionStateObj = { activeQuestionID: value.activeQuestionID, activeQuestionIndex: value.activeQuestionIndex, activeSectionID: value.activeSectionID };
    state.guestPageLayout.activeQuestionState = questionStateObj;
    if (typeof value.currentQuestionAnswer === 'string') {
      state.currentDescQuestion = value.currentQuestionAnswer;
    } else if (value.currentQuestionAnswer) {
      state.currentQuestionAnswers = value.currentQuestionAnswer;
    }
  }),
  setStickySectionID: action((state, value) => {
    state.guestPageLayout.stickySectionID = value;
  }),
  updateCurrentQuestionAnswers: action((state, entry) => {
    if (entry.answerIndex >= 0) {
      state.currentQuestionAnswers[entry.answerIndex] = entry.answer;
    } else {
      state.currentDescQuestion = entry.answer.answer;
    }
  })
};

export default GuestPageLayoutStoreImpl;
