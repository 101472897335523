import React from 'react';
import PropTypes from 'prop-types';

// import { Wrapper } from "./styles";

export default function DefaultLayout(props: any) {
  return <div>{props.children}</div>;
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired
};
