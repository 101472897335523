export const allowedTrialFeatures = ['content_analysis'];

//add trial feature key and view only permissions based on feature in order to allow user to view if trial is enabled at company level but current user not enabled or if trial is disabled
export const trialFeaturesViewOnlyPermissionMap: { [key: string]: string[] } = {
  content_analysis_trial: ['VIEW_WORKSPACE']
};

//add trial feature key and its permissions based in order to allow user to access it during trial
export const trialFeaturePermissions: { [key: string]: string[] } = {
  content_analysis_trial: ['VIEW_WORKSPACE', 'CREATE_EDIT_WORKSPACE', 'EDIT_COMPLIANCE_MATRIX']
};

export const TRIAL = '_trial';
