import eventTrackStore from '@interfaces/eventTrackStore';
import { action } from 'easy-peasy';

const eventTrackStoreImpl: eventTrackStore = {
  eventTrackMap: {
    COMMENTS: '',
    CLARIFICATIONS: ''
  },
  setEventTrackMap: action((state, value) => {
    state.eventTrackMap = { ...state.eventTrackMap, ...value };
  })
};

export default eventTrackStoreImpl;
