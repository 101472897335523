import React, { useState } from 'react';
import { RfpWarningDialog } from '@rfp-components/RfpDialog';
import RfpNewWarningDialog from '@rfp-components/RfpNewWarningDialog';
import RfpToolTip from '@rfp-components/RfpToolTip';
import { handleRemoveMergeTags } from '@utils/MergeTagUtils';
import { useTranslation } from 'react-i18next';
import { generalUseStoreState } from '@hooks/index';
import DOMPurify from 'dompurify';

export default function ToolTipComponents() {
  const { t } = useTranslation('translations');
  const [modelOpen, updateModelOpen] = useState<boolean>(false);
  const [tagDetails, updateTagDetails] = useState<any>([]);
  const [replacedValue, setReplacedValue] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const menuModule = generalUseStoreState(state => state.additionalInfoStore.additionalInfo?.currentModule?.menuModule);
  const removeMergeTagTitle: string = tagDetails && tagDetails[1]?.startsWith('[c-') ? t('RESPONSE_REF_TAG_REMOVED_WARN_MSG') : t('MERGE_TAG_REF_REMOVED_WARN_MSG');
  const handleOnConfirm = () => {
    updateModelOpen(false);
    handleRemoveMergeTags();
  };
  const handleModelClose = () => {
    updateModelOpen(false);
  };
  const dialogData = {
    title: <span className='pl-5'>{removeMergeTagTitle}</span>,
    onClose: () => {
      handleModelClose();
    },
    onConfirm: () => {
      handleOnConfirm();
    }
  };

  const dialogContent = {
    title: <div className='grey-800'>{t<string>(tagDetails[1]?.startsWith('[c-') ? 'CONTENT_REFERENCE_TAG_WILL_BE_REMOVED' : 'DYNAMIC_TEXT_FIELD_REFERENCE_REMOVED')}</div>,
    message: (
      <div className='flex-wrap text-align-center mt-10'>
        <div className='mb-20 grey-800'>{t<string>(tagDetails[1]?.startsWith('[c-') ? 'WANT_TO_CONTINUE' : 'SELECTED_DYNAMIC_FIELD_WOULD_BE_REPLACED')}</div>
        <div className='display-flex justifyContentCenter'>
          <span className='grey-800'>{`${t('TAG_NAME') + ':'}`}&nbsp;</span>
          <span className='font600 grey-800'>{tagDetails[1]}</span>
        </div>
        <span>{`${t(tagDetails[1]?.startsWith('[c-') ? 'TAG_VALUE' : 'REPLACED_CONTENT') + ':'}`}&nbsp;</span>
        <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(replacedValue) }} className='font600 grey-800' />
      </div>
    ),
    option1: 'NO',
    option2: 'YES',
    option2Callback: handleOnConfirm,
    isImgNeeded: true,
    isButtonStyleNeeded: true
  };

  const handleClickEvent = (event: any) => {
    const value = event.currentTarget.parentElement.previousElementSibling.previousElementSibling.querySelector('span').textContent;
    const tag = event.currentTarget.parentElement.previousElementSibling.innerText;

    if (menuModule === 'PROACTIVE_PROPOSAL_BUILDER') {
      const tagId = $('#merge_tag_tooltip_text>span, #merge_tag_tooltip_text>div').attr('tagid');
      const mergeTag = document.querySelector(`[tag-id='${tagId}']`);
      setReplacedValue(mergeTag?.innerHTML);
    }
    updateTagDetails([tag, value]);
    menuModule === 'PROACTIVE_PROPOSAL_BUILDER' ? setOpenDialog(true) : updateModelOpen(true);
  };
  return (
    <>
      <div id='tooltip_layout'>
        <div id='merge_tag_tooltip'>
          <div id='merge_tag_tooltip_text'></div>
          <div id='merge_tag_tooltip_value'></div>
          <div id='merge_tag_tooltip_action' className='layout-column cursor prevent-save'>
            <span id='remove_merge' onClick={e => handleClickEvent(e)} className='warning-icon-color fa fa-chain-broken pl-7'>
              <RfpToolTip title={t('REMOVE_REFERENCE')}>
                <span></span>
              </RfpToolTip>
            </span>
          </div>
        </div>
      </div>

      <div id='annotation_tooltip_layout'>
        <div id='annotation_tooltip'>
          <div id='annotation_tooltip_text'></div>
        </div>
      </div>

      {modelOpen && (
        <RfpWarningDialog maxWidth='sm' dialogData={dialogData} open={modelOpen} onClose={() => updateModelOpen(false)} onConfirm={() => handleOnConfirm()}>
          <div className='pt-5 pb-10'>
            <strong className='font-16-imp'>{t('WANT_TO_CONTINUE')}</strong>
          </div>
          <div className='pb-5'>
            <span className='font-14-imp font-600'>{t('TAG_NAME')} : </span>
            <span className='pl-5'>{tagDetails[1]}</span>
          </div>
          <div>
            <span className='font-14-imp font-600'>{t('TAG_VALUE')} : </span>
            <span className='pl-5'>{tagDetails[0]}</span>
          </div>
        </RfpWarningDialog>
      )}
      {openDialog && (
        <RfpNewWarningDialog
          onClose={() => {
            setOpenDialog(false);
          }}
          openDialog={openDialog}
          dialogContentMap={dialogContent}
        />
      )}
    </>
  );
}
