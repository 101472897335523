import { action } from 'easy-peasy';
import AutoFillDetailsStore from '@interfaces/AutoFillDetailsStore ';
import { autoFillDetailsVO } from '@interfaces/Models/AutoFillDetailsVO';

const getKeysFromObjList = (list: any[], key: string) => {
  const keys: string[] = [];
  list &&
    list.forEach(item => {
      if (item[key]) {
        keys.push(item[key]);
      }
    });
  return keys;
};

const AutoFillDetailsStoreImpl: AutoFillDetailsStore = {
  autoFillDetails: autoFillDetailsVO,
  viewSimilarAutoFillDetail: autoFillDetailsVO,
  tagMap: {},
  masterBUTagsMap: {},
  masterTagList: [],
  projectNameList: [],
  sectionNameList: [],
  languageList: [],
  answerHeaderList: [],
  catalogMergeTagList: [],
  importFileNameList: [],
  collectionsList: [],
  businessUnitList: [],
  translatedJobNames: [],
  standardResponseList: [],
  translatedSrcLangFacets: [],
  viewSimilarProjectList: [],
  viewSimilarSectionList: [],
  viewSimilarlanguageList: [],
  viewSimilarAnswerHeaderList: [],
  viewSimilarStandardResponseList: [],
  viewSimilarTranslatedJobNames: [],
  viewSimilarTranslatedSrcLangFacets: [],
  buTags: [],
  autoFillRefreshFlag: false,
  setAutoFillDetails: action((state, entry) => {
    state.autoFillDetails = entry;
    state.projectNameList = getKeysFromObjList(entry.projectFacets, 'key');
    state.sectionNameList = getKeysFromObjList(entry.sectionFacets, 'key');
    state.languageList = getKeysFromObjList(entry.languageFacet, 'key');
    state.answerHeaderList = getKeysFromObjList(entry.answerHeaderFacets, 'key');
    state.importFileNameList = getKeysFromObjList(entry.importFileNameFacets, 'key');
    state.standardResponseList = getKeysFromObjList(entry.standardResponseList.dataList, 'value');
    state.dlTagList = state.dlTagList;
    state.translatedJobNames = entry.translatedJobNames;
    state.translatedSrcLangFacets = getKeysFromObjList(entry.translatedSrcLangFacets, 'key');
    state.catalogMergeTagList = getKeysFromObjList(entry.catalogNameFacets, 'key');
    state.catalogNameList = getKeysFromObjList(entry.catalogNameList, 'key');
  }),
  setViewSimilarAutoFillDetails: action((state, entry) => {
    state.viewSimilarAutoFillDetail = entry;
    state.viewSimilarProjectList = getKeysFromObjList(entry.projectFacets, 'key');
    state.viewSimilarSectionList = getKeysFromObjList(entry.sectionFacets, 'key');
    state.viewSimilarlanguageList = getKeysFromObjList(entry.languageFacet, 'key');
    state.viewSimilarAnswerHeaderList = getKeysFromObjList(entry.answerHeaderFacets, 'key');
    state.viewSimilarStandardResponseList = getKeysFromObjList(entry.standardResponseList.dataList, 'value');
    state.viewSimilarTranslatedJobNames = entry.translatedJobNames;
    state.viewSimilarTranslatedSrcLangFacets = getKeysFromObjList(entry.translatedSrcLangFacets, 'key');
  }),
  setTagMap: action((state, entry) => {
    state.tagMap = entry;
  }),
  setMasterTagList: action((state, entry) => {
    state.masterTagList = entry;
  }),
  updateMasterTagList: action((state, entry) => {
    state.masterTagList = Array.from(new Set([...state.masterTagList, ...entry]));
  }),
  setMasterBUTagsMap: action((state, entry) => {
    state.masterBUTagsMap = entry;
  }),
  dlTagList: [],
  setDlTagList: action((state, entry) => {
    state.dlTagList = entry;
  }),

  dlFileTypeList: [],
  setDlFileTypeList: action((state, entry) => {
    state.dlFileTypeList = entry;
  }),
  setDLAutoFillDetails: action((state, entry) => {
    state.dlTagList = getKeysFromObjList(entry.dlTagFacets, 'key');
    state.dlFileTypeList = entry.dlFileTypeList;
  }),
  setAnswerHeaderList: action((state, entry) => {
    state.answerHeaderList = entry;
  }),
  setBuTags: action((state, entry) => {
    state.buTags = entry;
  }),
  setProjectNameList: action((state, entry) => {
    state.projectNameList = entry;
  }),
  setSectionNameList: action((state, entry) => {
    state.sectionNameList = entry;
  }),
  setAutoFillRefreshFlag: action((state, entry) => {
    state.autoFillRefreshFlag = entry;
  }),
  setSubSectionIdMap: action((state, entry) => {
    state.autoFillDetails.subSectionIdMap = entry;
  }),
  setParentSectionNameMap: action((state, entry) => {
    state.autoFillDetails.parentSectionNameMap = entry;
  }),
  sectionTemplateTagList: [],
  setSectionTemplateTagList: action((state, entry) => {
    state.sectionTemplateTagList = entry;
  }),
  sectionTemplateCollectionsMap: {},
  setSectionTemplateCollectionsMap: action((state, entry) => {
    state.sectionTemplateCollectionsMap = entry;
  }),
  setCatalogMergeTagList: action((state, entry) => {
    state.catalogMergeTagList = entry;
  }),
  setCatalogNameList: action((state, entry) => {
    state.catalogNameList = entry;
  }),
  updateCollectionsMap: action((state, entry) => {
    Object.keys(entry).forEach((id: string) => {
      state.autoFillDetails.collectionList[id] = entry[id];
    });
  }),
  updateTagsList: action((state, entry) => {
    state.autoFillDetails.tagList.dataList = entry;
  }),
  deleteCollection: action((state, entry) => {
    if (state.autoFillDetails.collectionList[entry]) {
      delete state.autoFillDetails.collectionList[entry];
    }
  })
};

export default AutoFillDetailsStoreImpl;
