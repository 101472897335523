import i18next from 'i18next';
import store from '../store';
import { getHourMinute, getTimeZoneFromDateTimeZone, setConvertedDate, setModelValueForDatePicker } from './DayjsUtils';
import dayjs from 'dayjs';
import CustomFieldVO from '@interfaces/Models/CustomFieldVO';
import CustomFieldVOList, { customFieldVOList } from '@interfaces/Models/CustomFieldVOList';
import IntakeVO from '@interfaces/Models/IntakeVO';
import ProjectVO from '@interfaces/Models/ProjectVO';
import MasterDataVO from '@interfaces/Models/MasterDataVO';
import i18n from '../i18n';
import { isFeatureEnabled, isSuperAdmin, isSupport } from './UserLoadDetailUtil';
import MergeTagVO from '@interfaces/Models/MergeTagVO';
import { OrgCustomFieldEntityType } from '@org/interfaces/models/org-custom-fields/OrgCustomFieldType';

const cloneDeep = require('clone-deep');

const systemDefinedCustomFieldsMap: any = {};
const setSystemDefinedCustomFields = function (mapData: any) {
  const customFields = mapData.customFieldsData.customFields;
  customFields.companyCustomFieldList.forEach(function (customField: any) {
    if (!['ANSWER_LIBRARY', 'DOCUMENT_LIBRARY', 'SECTION_TEMPLATE', 'CATALOG', 'ALL', 'PROACTIVE_PROPOSALS'].includes(customField.entityType)) {
      systemDefinedCustomFieldsMap[customField.name] = customField;
    }
  });
  mapData['systemDefinedCustomFieldsMap'] = systemDefinedCustomFieldsMap;
  // store.getActions().customFieldDetails.setSystemDefinedCustomFieldsMap(systemDefinedCustomFieldsMap);
};

export const defultALCustomFieldGroups: { [key: string]: string } = {
  questionandanswer: 'QUESTION_AND_ANSWER',
  metadata: 'METADATA',
  'privacy,usageandreview': 'PRIVACY_USAGE_AND_REVIEW',
  filedetails: 'FILE_DETAILS',
  customfield: 'CUSTOM_FIELD',
  section: 'SECTION_FIELD'
};

const translationMap: any = {
  projectduedate: { label: 'PROJECT_DUE_DATE', default: 'Due Date' },
  projectname: { label: 'PROJECT_NAME', default: 'Project Name' },
  projectdescription: { label: 'DESCRIPTION', default: 'Description' },
  projectstage: { label: 'PROJECT_STAGE', default: 'Project Stage' },
  projectcategory: { label: 'CATEGORY', default: 'Category' },
  projectvalue: { label: 'PROJECT_VALUES', default: 'Project Value' },
  projecttype: { label: 'PROJECT_TYPE', default: 'Project Type' },
  contactemail: { label: 'PROJECT_PRIMARY_CONTACT', default: 'Project Primary Contact' },
  additionalprimarycontacts: { label: 'ADDITIONAL_PRIMARY_CONTACT', default: 'Additional Primary Contact' },
  clientname: { label: 'CLIENT_NAME', default: 'Client Name' },
  clientaddress1: { label: 'CLIENT_ADDRESS', default: 'Client Address 1' },
  clientaddress2: { label: 'CLIENT_ADDRESS', default: 'Client Address 2' },
  sectionname: { label: 'SECTION_NAME', default: 'Section Name' },
  tags: { label: 'TAGS', default: 'Tags' },
  reviewflag: { label: 'FLAG', default: 'Flag' },
  starRating: { label: 'STAR_RATING', default: 'Star Rating' },
  language: { label: 'ANSWER_LIB_LANGUAGE', default: 'Language' },
  contentscore: { label: 'CONTENT_SCORE_LABEL', default: 'Content Score' },
  alternatequestions: { label: 'ALTERNATE_QUESTIONS', default: 'Alternate Questions' },
  question: { label: 'QUESTION', default: 'Question' },
  standardresponse: { label: 'STANDARD_RESPONSE', default: 'Standard Response' },
  clientphone: { label: 'CLIENT_PHONE', default: 'Client Phone No.' },
  clientemail: { label: 'CLIENT_EMAIL', default: 'Client Email' },
  approvers: { label: 'MODERATORS', default: 'Moderators' },
  // duedate: { label: 'NEXT_REVIEW_DATE', default: 'Next Review Date' },
  alerttext: { label: 'ALERT_TEXT', default: 'Alert Text' },
  customreviewcycleindays: { label: 'REVIEW_CYCLE', default: 'Review Cycle' },
  owners: { label: 'OWNERS', default: 'Owners' },
  collectionList: { label: 'COLLECTIONS', default: 'Collections' },
  editprivacysetting: { label: 'EDIT_PRIVACY', default: 'Edit Privacy' },
  privacySetting: { label: 'VIEW_PRIVACY', default: 'View Privacy' },
  name: { label: 'FILE_NAME', default: 'File Name' },
  description: { label: 'FILE_DESCRIPTION', default: 'File Description' },
  businessunits: { label: 'BUSINESS_UNITS', default: 'Business Units' },
  catalogStructure: { label: 'CATALOG_STRUCTURE', default: 'Catalog Structure' },
  catalogName: { label: 'NAME', default: 'Name' },
  catalogDisplayName: { label: 'DISPLAY_NAME', default: 'Display Name' },
  sectionTemplateName: { label: 'SECTION_NAME', default: 'Section Name' },
  sectionGroupName: { label: 'GROUP_NAME', default: 'Group Name' }
};

const contentLibCustomFieldTypeList: string[] = ['ANSWER_LIBRARY', 'DOCUMENT_LIBRARY', 'SECTION_TEMPLATE', 'CATALOG', 'ALL'];
const multiValueCustomFieldList: string[] = ['CHECKBOX', 'RADIO', 'DROPDOWN', 'MULTI_SELECT_DROPDOWN'];

export const getActiveProjectTypeData = () => {
  const projectTypeList = store.getState().projectMasterData.projectTypeList;
  const localProjectTypeList: string[] = [];

  projectTypeList?.forEach((type: MasterDataVO) => {
    if (type.status === 'ACTIVE' && type.lowerCase && type.projectFormat && type.projectFormat !== 'DESCRIPTIVE_PROPOSAL') {
      localProjectTypeList.push(type.value);
    }
  });
  return localProjectTypeList;
};

export const updateCustomFieldsDisplayName = function (customFieldData?: any, language?: string) {
  const customFields = customFieldData || store.getState().customFieldDetails.customFields;
  const userlanguage = store.getState().userDetails.userDetails.userInfoVO.language;
  if ((!language || userlanguage === language) && i18n.language) {
    customFields.companyCustomFieldList.forEach(function (data: any) {
      const key = data.name.toLowerCase();
      if (data.defaultMergeTag && translationMap[key] && translationMap[key].default === data.displayName) {
        let valueParam = {};
        if (key === 'clientaddress1' || key === 'clientaddress2') {
          valueParam = { no: key.substr(key.length - 1) };
        }
        data.displayName = i18next.t(translationMap[key].label, valueParam);
        if (systemDefinedCustomFieldsMap[key]) {
          systemDefinedCustomFieldsMap[key].displayName = data.displayName;
        }
      }
    });
    if (language || !customFieldData) {
      store.getActions().customFieldDetails.setSystemDefinedCustomFieldsMap(systemDefinedCustomFieldsMap);
    }
    return systemDefinedCustomFieldsMap;
  }
};

export const groupByGroupName = function (indexedGroup: any, list: any) {
  if (list.type != 'LABEL' && list.type != 'AUTO_INCREMENTAL') {
    const groupIsNew = indexedGroup.indexOf(list.groupName.toLowerCase()) === -1;
    if (groupIsNew) {
      indexedGroup.push(list.groupName.toLowerCase());
    }
    return groupIsNew;
  }
  return false;
};

export const displayGroupBy = (groupName: string, list: any) => {
  return list.groupName.toLowerCase() === groupName.toLowerCase();
};

export const setCustomFields = (inData: any, isFirst: any) => {
  let customFields = {};
  let libraryCustomFieldMap = {};
  let customFieldMap = {};
  let customFieldMapDL = {};
  let customFieldMapCatalog = {};
  let sectionTemplateCustomFieldMap = {};
  let projectCustomFieldMap = {};
  let projectRequestCustomFieldMap = {};
  let requestCustomFieldMap = {};
  const proposalCustomFieldMap = {};
  if (isFirst) {
    customFields = { companyCustomFieldList: [] };
  }
  inData.companyCustomFieldList.forEach(function (data: any) {
    if (!data.id) {
      return;
    }
    if (!data.isDeleted) {
      customFields.companyCustomFieldList.push(data);
      if (contentLibCustomFieldTypeList.includes(data.entityType)) {
        libraryCustomFieldMap[data.name] = data;
        if (['ANSWER_LIBRARY', 'ALL'].includes(data.entityType)) {
          customFieldMap[data.name] = data;
        }
        if (['DOCUMENT_LIBRARY', 'ALL'].includes(data.entityType)) {
          customFieldMapDL[data.name] = data;
        }
        if (['SECTION_TEMPLATE', 'ALL'].includes(data.entityType) && !['language', 'contentScore', 'approvers'].includes(data.name)) {
          sectionTemplateCustomFieldMap[data.name] = data;
        }
        if (['CATALOG', 'ALL'].includes(data.entityType) && !['language', 'contentScore', 'approvers'].includes(data.name)) {
          customFieldMapCatalog[data.name] = data;
        }
      } else if (data.entityType === 'PROPOSAL_BUILDER') {
        proposalCustomFieldMap[data.name] = data;
      } else if (data.entityType === 'USER') {
        //do nothing
      } else {
        projectCustomFieldMap[data.id] = data;
        if (data.entityType === 'PROJECT_REQUEST') {
          projectRequestCustomFieldMap[data.id] = data;
        } else if (data.entityType === 'REQUEST') {
          requestCustomFieldMap[data.id] = data;
        } else {
          // do nothing
        }
      }
    }
  });
  customFields.companyCustomFieldList = customFields.companyCustomFieldList.sort((ele1: any, ele2: any) => {
    return ele1.sortingOrder - ele2.sortingOrder;
  });

  if (!isFirst) {
    updateCustomFieldsDisplayName();
  }

  const customFieldsData = {
    customFields: customFields,
    libraryCustomFieldMap: libraryCustomFieldMap,
    customFieldMap: customFieldMap,
    customFieldMapDL: customFieldMapDL,
    customFieldMapCatalog: customFieldMapCatalog,
    sectionTemplateCustomFieldMap: sectionTemplateCustomFieldMap,
    projectCustomFieldMap: projectCustomFieldMap,
    projectRequestCustomFieldMap: projectRequestCustomFieldMap,
    requestCustomFieldMap: requestCustomFieldMap,
    proposalCustomFieldMap
  };
  const customFieldMapData = { customFieldsData: customFieldsData, systemDefinedCustomFieldsMap: {}, alCustomFields: {} };
  setSystemDefinedCustomFields(customFieldMapData);
  customFieldMapData.alCustomFields = setAlCustomFieldsDetails(customFields);

  return customFieldMapData;
};

export const getProjectsSearchableCustomFields = (viewType: any) => {
  let customFields: any;
  if (viewType === 'respond') {
    customFields = store.getState().customFieldDetails.projectCustomFieldMap;
  } else if (viewType === 'project-request') {
    customFields = store.getState().customFieldDetails.projectRequestCustomFieldMap;
  } else {
    customFields = store.getState().customFieldDetails.requestCustomFieldMap;
  }

  const searchableCustomFields: any[] = [];
  Object.values(customFields).forEach(function (customField: any) {
    if (customField.searchable) {
      searchableCustomFields.push(customField);
    }
  });
  return searchableCustomFields;
};

export const getUserDefinedSearchableCustomFields = (viewType: string) => {
  let customFields: { [key: string]: CustomFieldVO };
  if (viewType === 'respond') {
    customFields = store.getState().customFieldDetails.projectCustomFieldMap;
  } else if (viewType === 'project-request') {
    customFields = store.getState().customFieldDetails.projectRequestCustomFieldMap;
  } else {
    customFields = store.getState().customFieldDetails.requestCustomFieldMap;
  }

  const searchableCustomFields: any[] = [];
  Object.values(customFields).forEach(function (customField: CustomFieldVO) {
    if (customField.searchable && !customField.defaultMergeTag) {
      searchableCustomFields.push(customField);
    }
  });
  return searchableCustomFields;
};

export const getSystemDefinedCustomFieldMap = () => {
  return systemDefinedCustomFieldsMap;
};

export const getSystemDefinedCustomFieldMapByEntityType = (entityType: any) => {
  const customFieldMap = store.getState().customFieldDetails.customFields.companyCustomFieldList;
  return Object.values(customFieldMap).filter((data: any) => data.entityType === entityType);
};

export const getContentTypeMergeTagsMap = () => {
  const contentTypeMergeTagMap: { [key: string]: CustomFieldVO } = {};
  const customFieldList = store.getState().customFieldDetails.customFields.companyCustomFieldList;
  customFieldList?.forEach((value: CustomFieldVO) => {
    if (value.entityType === 'PROJECT' && value.type === 'LABEL' && value.name) {
      contentTypeMergeTagMap[value.name.toLowerCase()] = value;
    }
  });
  return contentTypeMergeTagMap;
};

export const getProjectCustomFieldNameByMergeTagNameAsMap = (mergeTagNames: string[]) => {
  const customFieldMap: any = store.getState().customFieldDetails.systemDefinedCustomFieldsMap;
  const displayNameMap: any = {};
  for (let i = 0; i < mergeTagNames.length; i++) {
    if (customFieldMap[mergeTagNames[i]]) {
      displayNameMap[mergeTagNames[i]] = customFieldMap[mergeTagNames[i]].displayName;
    }
  }
  return displayNameMap;
};

export const getProjectCustomFieldName = (name: string) => {
  const customFieldMap: any = store.getState().customFieldDetails.systemDefinedCustomFieldsMap;
  if (customFieldMap[name]) {
    return customFieldMap[name].displayName;
  }
  const customFieldIdMap: any = store.getState().customFieldDetails.projectCustomFieldMap;
  return customFieldIdMap[name] ? customFieldIdMap[name].displayName : '';
};

export const getProjectCustomFieldObjByName = (name: string) => {
  if (!name) {
    return {};
  }
  const customFieldMap: any = store.getState().customFieldDetails.systemDefinedCustomFieldsMap;
  if (customFieldMap) {
    return customFieldMap[name];
  }
};

export const getProjectCustomFieldAutoFillValues = (id: string) => {
  const customField = store.getState().customFieldDetails.projectCustomFieldMap[id];
  if (customField && customField.values) {
    return customField.values;
  }
  return [];
};

export const getProjectCustomFieldById = (id: string) => {
  const customField = store.getState().customFieldDetails.projectCustomFieldMap[id];
  return customField ? customField : '';
};

export const getBusinessUnitsAutoFillMap = () => {
  if (!isFeatureEnabled('business_units')) {
    return {};
  }

  const userBusinessUnits: string[] = store.getState().userDetails.userDetails.userInfoVO.businessUnits || [];
  const userRole: string = store.getState().userDetails.userDetails.userInfoVO.userRole || '';
  const isSuperAdminOrSupportUser = isSuperAdmin({ userRole }) || isSupport();
  const buNameMap = cloneDeep(store.getState().businessUnitDetails.businessUnitNameMap) || {};

  if (userBusinessUnits.includes('ALL') || isSuperAdminOrSupportUser) {
    delete buNameMap['ALL'];
    return buNameMap;
  }

  const filteredBUNameMap = userBusinessUnits.reduce((acc, id) => {
    if (buNameMap[id]) {
      acc[id] = buNameMap[id];
    }
    return acc;
  }, {} as { [key: string]: string });

  if (filteredBUNameMap['ALL']) {
    delete filteredBUNameMap['ALL'];
  }

  return filteredBUNameMap;
};

export const getBusinessUnitsObjectMap = () => {
  return store.getState().businessUnitDetails.businessUnitIdMap;
};

export const getBusinessUnitList = () => {
  const buMap = store.getState().businessUnitDetails.businessUnitIdMap;
  return Object.values(buMap);
};

export const getBusinessUnitNameMap = () => {
  return store.getState().businessUnitDetails.businessUnitNameMap;
};

export const getCatalogStructureList = () => {
  return store.getState().autoFillDetailsStore.autoFillDetails.catalogStructures;
};

export const getCatalogNameList = () => {
  return store.getState().autoFillDetailsStore.autoFillDetails.catalogNameList;
};

export const getCatalogMergeTagList = () => {
  return store.getState().autoFillDetailsStore.autoFillDetails.catalogMergeTagList;
};

export const getProjectCustomFieldMergeTagNameById = (id: string) => {
  const customField = store.getState().customFieldDetails.projectCustomFieldMap[id];
  if (customField) {
    return customField.name;
  }
  return '';
};

export const getALCustomFieldGroupList = (customFields: any, isALEditor: boolean = false, alCustomFieldsParam?: CustomFieldVOList, isGuest?: boolean) => {
  let alCustomFields = {};
  let groupByALIndexed: any = [];
  alCustomFields = cloneDeep(alCustomFieldsParam || store.getState().customFieldDetails.alCustomFields);
  if (!alCustomFields) {
    alCustomFields = setAlCustomFieldsDetails();
  }
  if (!isALEditor && alCustomFields.companyCustomFieldList) {
    alCustomFields.companyCustomFieldList = alCustomFields.companyCustomFieldList.filter((obj: any) => {
      return obj.defaultMergeTag != true;
    });
  }

  if (!alCustomFields.companyCustomFieldList) {
    alCustomFields.companyCustomFieldList = [];
  } else {
    alCustomFields.groupList = [];
    alCustomFields.companyCustomFieldList.forEach(function (list: any) {
      let projectCustomFields: any = {};
      if (customFields) {
        projectCustomFields = customFields;
      }
      if (projectCustomFields && projectCustomFields[list.name]) {
        if (list.type === 'CHECKBOX') {
          const values = projectCustomFields[list.name];
          if (values != null) {
            let index = 0;
            list.values.forEach(function (val: any) {
              const idx = values.indexOf(val);
              if (idx >= 0) {
                list['modelVal' + index] = true;
              }
              index++;
            });
          }
        } else if (list.type === 'DATE') {
          list.modelVal = setModelValueForDatePicker(projectCustomFields[list.name]);
        } else if (list.type === 'SELECT_USER') {
          if (projectCustomFields[list.name]) {
            if (Array.isArray(projectCustomFields[list.name])) {
              list.values = projectCustomFields[list.name];
            } else {
              list.values[0] = projectCustomFields[list.name];
            }
          }
        } else if (list.type === 'DATE_AND_TIME') {
          list.dueDate = setConvertedDate(projectCustomFields[list.name]);
          list.dueDate = setModelValueForDatePicker(list.dueDate);
          list.time = getHourMinute(projectCustomFields[list.name]);
          !isGuest && (list.zone = store.getState().userDetails.userDetails.userInfoVO.timeZone);
        } else {
          list.modelVal = projectCustomFields[list.name];
        }
      } else {
        if (list.type === 'CHECKBOX') {
          let index = 0;
          list.values.forEach(function (val: any) {
            list['modelVal' + index] = false;
            index++;
          });
        } else {
          list.modelVal = '';
        }
      }

      if (projectCustomFields) {
        if (projectCustomFields && projectCustomFields[list.name]) {
          if (list.type === 'CHECKBOX') {
            let responseData = [];
            if (Array.isArray(projectCustomFields[list.name])) {
              responseData = projectCustomFields[list.name];
            } else {
              responseData = projectCustomFields[list.name].split(';');
            }

            for (let i = 0; i < list.values.length; i++) {
              const idx = responseData.indexOf(list.values[i]);
              list['modelVal' + i] = false;
              if (idx >= 0) {
                list['modelVal' + i] = true;
              }
            }
          } else if (list.type === 'DATE') {
            list.modelVal = setModelValueForDatePicker(projectCustomFields[list.name]);
          } else if (list.type === 'SELECT_USER') {
            if (projectCustomFields[list.name].length > 0) {
              if (Array.isArray(projectCustomFields[list.name])) {
                list.values = projectCustomFields[list.name];
              } else {
                list.values[0] = projectCustomFields[list.name];
              }
            }
          } else if (list.type === 'DATE_AND_TIME') {
            list.dueDate = setConvertedDate(projectCustomFields[list.name]);
            list.dueDate = setModelValueForDatePicker(list.dueDate);
            list.time = getHourMinute(projectCustomFields[list.name]);
            !isGuest && (list.zone = store.getState().userDetails.userDetails.userInfoVO.timeZone);
          } else {
            list.modelVal = projectCustomFields[list.name];
          }
        }
      }

      if (groupByGroupName(groupByALIndexed, list)) {
        if (!list.defaultMergeTag) {
          alCustomFields.groupList.push(list);
        }
      }
    });
  }
  return alCustomFields;
};

export const getALcustomFieldsObj = (customFieldList: any) => {
  let alCustomFields: any = {};
  customFieldList.forEach(function (list: CustomFieldVO) {
    if (list.type === 'CHECKBOX') {
      let tempVal: any = [];
      let index = 0;
      list.values.forEach(function (val: any) {
        if (list['modelVal' + index]) {
          tempVal.push(val);
        }
        index++;
      });
      alCustomFields[list.name] = tempVal;
    } else if (list.type === 'DATE') {
      let date;
      if (list.modelVal !== '' && list.modelVal != null) {
        date = dayjs(list.modelVal).format('YYYY-MM-DD');
      }
      alCustomFields[list.name] = date;
    } else if (list.type === 'SELECT_USER') {
      alCustomFields[list.name] = list.values;
    } else if (list.type === 'AUTO_INCREMENTAL') {
      alCustomFields[list.name + '#code'] = list.autoIncCode;
      alCustomFields[list.name + '#value'] = list.autoIncVal;
      let autoInCodeVal = list.autoIncCode;
      if (list.autoIncVal) {
        autoInCodeVal = autoInCodeVal + list.autoIncVal;
      }
      alCustomFields[list.name] = autoInCodeVal;
    } else if (list.type === 'MULTI_SELECT_DROPDOWN') {
      alCustomFields[list.name] = list.modelVal;
    } else {
      alCustomFields[list.name] = list.modelVal;
    }
  });
  return alCustomFields;
};
// RES-69161 custom field TEXT_BOX same as TEXT_AREA.
export const removeTextBoxLineBreak = (content: any) => {
  const companyCustomFieldList = store.getState().customFieldDetails.customFields.companyCustomFieldList;
  companyCustomFieldList.forEach((customField: CustomFieldVO) => {
    if (customField.type === 'TEXT_BOX' && content.customFields && content.customFields.hasOwnProperty(customField.id) && content.customFields[customField.id]) {
      if (content.customFields[customField.id] instanceof Array) {
        content.customFields[customField.id][0] = content.customFields[customField.id][0].replace(/\n/g, ' ');
      } else {
        content.customFields[customField.id] = content.customFields[customField.id].replace(/\n/g, ' ');
      }
    }
  });
};

export const setAlCustomFieldsDetails = (tempcustomFields?: any) => {
  const fieldsForStatus = ['starRating', 'projectName', 'sectionName', 'description', 'alertText'];
  const customFieldsObject = tempcustomFields ? tempcustomFields : store.getState().customFieldDetails.customFields;
  let customFields = { companyCustomFieldList: [] };
  if (customFieldsObject.companyCustomFieldList) {
    customFields.companyCustomFieldList = customFieldsObject.companyCustomFieldList.filter((d: any) => {
      if (d.entityType === 'ANSWER_LIBRARY' || d.entityType === 'ALL') {
        if (fieldsForStatus.indexOf(d.name) >= 0 || !d.defaultMergeTag) {
          return d.enabled;
        }
        return true;
      }
    });
  }
  if (!tempcustomFields) {
    store.getActions().customFieldDetails.setALCustomFields(customFields);
  }
  return customFields;
};

export const getLanguageValues = () => {
  const alFields = store.getState().customFieldDetails.customFields;
  let languageValues: any = {};
  languageValues = alFields.companyCustomFieldList.filter((item: any) => item.name === 'language');
  return languageValues[0].values;
};

export const getProjectCustomFieldsMergeTagList = (isDetailList?: boolean, entityType?: string) => {
  const mergeTagList: string[] = [];
  const customfields = store.getState().customFieldDetails.customFields;
  customfields.companyCustomFieldList.forEach(d => {
    if (
      (entityType && entityType === d.entityType) ||
      (!entityType && d.entityType !== 'INTAKE_REQUEST' && d.entityType !== 'ANSWER_LIBRARY' && d.entityType !== 'DOCUMENT_LIBRARY' && d.entityType !== 'ALL')
    )
      isDetailList ? mergeTagList.push(d) : mergeTagList.push(d.name);
  });
  return mergeTagList;
};
export const getProjectCustomFieldsMergeTagMap = () => {
  const mergeTagMap: any = {};
  const customfields = store.getState().customFieldDetails.customFields;
  customfields.companyCustomFieldList.forEach(d => {
    if (d.entityType !== 'INTAKE_REQUEST' && d.entityType !== 'ANSWER_LIBRARY' && d.entityType !== 'DOCUMENT_LIBRARY' && d.entityType !== 'ALL') mergeTagMap[d.name] = d;
  });
  return mergeTagMap;
};
export const getCLCustomFieldsMergeTagList = (type: string) => {
  const mergeTagList: string[] = [];
  const customfields = store.getState().customFieldDetails.customFields;
  customfields.companyCustomFieldList.forEach(d => {
    if (d.entityType === type || d.entityType === 'BOTH') {
      if (d.defaultMergeTag) {
        mergeTagList.push(d.name);
      } else {
        mergeTagList.push(d.displayName.replace(/ +/g, ''));
      }
    }
  });
  return mergeTagList;
};
export const getBooleanBasedOnItsType = (key: string) => {
  const customfields = store.getState().customFieldDetails.customFields;
  const isValid = (d: any) =>
    (key === d.name || key === d.id) && d.entityType === 'PROJECT' && (d.type === 'DROPDOWN' || d.type === 'RADIO' || d.type === 'CHECKBOX' || d.type === 'MULTI_SELECT_DROPDOWN');
  return customfields.companyCustomFieldList.some(d => isValid(d));
};

export const checkHasCustomFields = (customFields: any) => {
  const customFieldMap = store.getState().customFieldDetails.customFieldMap;
  if (!customFields) {
    return false;
  }
  let returnValue = false;
  Object.keys(customFields).forEach((key: string) => {
    if (customFieldMap[key] && customFieldMap[key].enabled) {
      returnValue = true;
    }
  });
  return returnValue;
};

export const checkHasCatalogCustomFields = (customFields: any): boolean => {
  const customFieldMap = store.getState().customFieldDetails.customFieldMapCatalog;
  if (!customFields) {
    return false;
  }
  let returnValue = false;
  Object.keys(customFields).forEach((key: string) => {
    if (customFieldMap[key] && customFieldMap[key].enabled) {
      returnValue = true;
    }
  });
  return returnValue;
};

export const checkHasDLCustomFields = (customFields: any) => {
  const customFieldMap = store.getState().customFieldDetails.customFieldMapDL;
  if (!customFields) {
    return false;
  }
  let returnValue = false;
  const customFieldKeys: string[] = Object.keys(customFields);
  for (let i = 0, len = customFieldKeys.length; i < len; i++) {
    if (customFieldMap[customFieldKeys[i]] && customFieldMap[customFieldKeys[i]].enabled) {
      returnValue = true;
      break;
    }
  }
  return returnValue;
};

export const checkHasSectionTemplateCustomFields = (customFields: any) => {
  const customFieldMap = store.getState().customFieldDetails.sectionTemplateCustomFieldMap;

  return customFields && Object.keys(customFields).some(key => customFieldMap[key]?.enabled);
};

const skippableCustomFields = ['projectname', 'projecttype', 'additionalprimarycontacts', 'businessunits'];
export const getProjectCustomField = (
  companyCustomFields: [],
  groupList: string[],
  setProjectCustomFieldList: any,
  companyInfoVO: any,
  entityType?: string,
  expandCollapseMap?: { [key: string]: boolean }
) => {
  const projectCustomFieldsMap: { [key: string]: CustomFieldVO } = {};
  const projectCustomFieldList: string[] = [];
  const entity = entityType ? entityType : 'PROJECT';
  companyCustomFields.forEach((customField: CustomFieldVO) => {
    if (customField.entityType === entity && customField.name !== 'additionalprimarycontacts') {
      if (customField.type === 'DATE_AND_TIME' && !customField.time) {
        customField.time = entity === 'PROJECT' ? '00:00' : '17:00';
      }
      if (customField.type === 'DATE_AND_TIME' && !customField.zone) {
        const tempZone: any = store.getState().userDetails.userDetails.userInfoVO.timeZone;
        customField.zone = tempZone ? tempZone : companyInfoVO.timeZone;
      }
      // RES-82869,RES-93677 If customFields disabled, enabled boolean value become true .So we won’t show the below fields.
      if (!['projectcategory', 'clientemail', 'clientphone', 'clientaddress2', 'clientaddress1'].includes(customField.name) || !customField.enabled) {
        projectCustomFieldsMap[customField.name] = customField;
        projectCustomFieldList.push(customField.name);
      }
      if (groupList.indexOf(customField.groupName) < 0 && skippableCustomFields.indexOf(customField.name) < 0) {
        groupList.push(customField.groupName);
        if (expandCollapseMap) {
          if (!Object.keys(expandCollapseMap).includes(customField.groupName)) {
            expandCollapseMap[customField.groupName] = true;
          }
        }
      }
    }
  });
  setProjectCustomFieldList(projectCustomFieldList);
  return projectCustomFieldsMap;
};

export const getIntakeCustomField = (setIntakeCustomFieldList: any) => {
  const intakeCustomFieldVOMap: { [key: string]: CustomFieldVO } = {};
  const intakeCustomFieldList: string[] = [];
  const userDetails = store.getState().userDetails.userDetails;
  const companyInfoVO = userDetails.companyInfoVO;
  const companyCustomFieldsInStore = store.getState().customFieldDetails.customFields.companyCustomFieldList;
  const companyCustomFields = cloneDeep(companyCustomFieldsInStore);
  companyCustomFields.forEach((customField: CustomFieldVO) => {
    if (customField.entityType === 'INTAKE_REQUEST' && customField.name !== 'additionalprimarycontacts') {
      if (customField.type === 'DATE_AND_TIME' && !customField.time) {
        customField.time = '00:00';
      }
      if (customField.type === 'DATE_AND_TIME' && !customField.zone) {
        const tempZone: any = store.getState().userDetails.userDetails.userInfoVO.timeZone;
        customField.zone = tempZone ? tempZone : companyInfoVO.timeZone;
      }
      intakeCustomFieldVOMap[customField.name] = customField;
      intakeCustomFieldList.push(customField.name);
    }
  });
  setIntakeCustomFieldList(intakeCustomFieldList);
  return intakeCustomFieldVOMap;
};

export const checkCustomField = (customFields: { [key: string]: any }, customFieldMap: { [key: string]: any }) => {
  const queryCustomField: { [key: string]: any } = {};
  Object.entries(customFields).forEach(([key, value]) => {
    if (customFieldMap.hasOwnProperty(key)) {
      queryCustomField[key] = customFieldMap[key].enabled ? updateALCustomField(customFieldMap[key], cloneDeep(value)) : null;
    }
  });
  return queryCustomField;
};

export const getIntakeFieldFromCustomField = (field: any) => {
  let fieldFromStore = getProjectCustomFieldById(field.id);
  if (!fieldFromStore || !field.id) {
    if (field.name !== 'contactemail' && field.name !== 'additionalprimarycontacts') {
      field.isRemove = true;
    }
    if (Array.isArray(field.value) && field.value.length > 0) {
      for (var i = 0; field.value.length > i; i++) {
        const tempId = field.value[i];
        fieldFromStore = getProjectCustomFieldById(tempId.id);
        if (fieldFromStore) {
          break;
        }
      }
      if (!fieldFromStore) {
        field.value = [];
      }
    }
  }
  if (fieldFromStore && fieldFromStore.type && fieldFromStore.type === 'DATE_AND_TIME' && fieldFromStore.name !== 'projectduedate') {
    let tempDate = new Date();
    tempDate.setDate(tempDate.getDate());
    fieldFromStore.time = '18:00';
    fieldFromStore.date = tempDate;
    fieldFromStore.zone = getTimeZoneFromDateTimeZone(tempDate);
  }
  Object.keys(fieldFromStore).forEach((key: string) => {
    if (key !== 'isRemove' && key !== 'mandatory' && key !== 'sortingOrder' && key !== 'indexValue' && key !== 'value') {
      field[key] = fieldFromStore ? fieldFromStore[key] : '';
    }
  });
};

export const getIntakeCustomFieldsFromStore = () => {
  const allCustomFields = store.getState().customFieldDetails.customFields;
  return allCustomFields.companyCustomFieldList.filter(field => {
    return field.entityType === 'INTAKE_REQUEST' || field.name === 'businessunits';
  });
};

export const getCustomFieldsByEntityTypeFromStore = (entityType: string): CustomFieldVOList => {
  const allCustomFields = cloneDeep(store.getState().customFieldDetails.customFields);
  allCustomFields.companyCustomFieldList = allCustomFields.companyCustomFieldList.filter(field => {
    return field.entityType === entityType;
  });

  return allCustomFields;
};

export const getAssociatedCustomFieldsByEntityType = (list: MergeTagVO, entityType: string) => {
  var allCustomFields = getCustomFieldsByEntityTypeFromStore(entityType);

  const matchedFeild = allCustomFields.companyCustomFieldList.find(customFieldVO => customFieldVO.name.toLowerCase() === list.name.toLowerCase());

  return matchedFeild || list;
};

export const validateIfError = (customField: CustomFieldVO, requestVO: ProjectVO | IntakeVO, isIntake: boolean): boolean => {
  let isError: boolean = false;
  const rfpType = isIntake ? requestVO.projecttype : requestVO.rfpType;
  if ((customField.projectTypes?.length === 0 || customField.projectTypes?.indexOf(rfpType) >= 0) && customField.mandatory) {
    const name = customField.name;
    switch (customField.type) {
      case 'DROPDOWN':
        if ((name === 'businessunits' && requestVO.primaryBU === '') || (name === 'projecttype' && rfpType === '')) {
          isError = true;
        } else {
          isError = false;
        }
        if (name === 'contactemail') {
          isError = customField.modelVal && customField.modelVal.length > 0 ? false : true;
        }
        if (name !== 'businessunits' && name !== 'projecttype' && name !== 'contactemail') {
          isError = !customField.modelVal ? true : false;
        }
        break;
      case 'DATE_AND_TIME':
        if (name === 'projectduedate') {
          isError = !customField.modelVal || !customField.time || !customField.zone ? true : false;
        } else {
          isError = customField.modelVal && customField.modelVal.length > 0 ? false : true;
        }
        break;
      case 'AUTO_INCREMENTAL':
        isError = !customField.autoIncCode ? true : false;
        break;
      case 'CHECKBOX':
      case 'MULTI_SELECT_DROPDOWN':
      case 'SELECT_USER':
        if (name !== 'additionalprimarycontacts') {
          isError = customField.modelVal && customField.modelVal.length > 0 ? false : true;
        }
        break;
      default:
        isError = !customField.modelVal ? true : false;
        break;
    }
  }
  return isError;
};

export const getIntakeCustomFieldMergeTagList = () => {
  let mergeTagList: string[] = [];
  const allCustomFields = store.getState().customFieldDetails.customFields;
  const intake_request: any = cloneDeep(store.getState().userDetails.userDetails.companyPreferencesVO.data.intake_request);
  allCustomFields.companyCustomFieldList.forEach(intakeField => {
    if (intakeField.entityType === 'INTAKE_REQUEST') {
      mergeTagList.push(intakeField.name);
    }
  });
  if (intake_request && intake_request.groupList) {
    intake_request.groupList.forEach((group: any) => {
      group.value.forEach((mergeTag: any) => {
        if (!mergeTag.isRemove) {
          if (mergeTagList.indexOf(mergeTag.name) === -1) {
            mergeTagList.push(mergeTag.name);
          }
        } else {
          if (mergeTagList.indexOf(mergeTag.name) > -1) {
            mergeTagList.splice(mergeTagList.indexOf(mergeTag.name), 1);
          }
        }
      });
    });
  }
  return mergeTagList;
};

export const getIntakeAdvanceFilterCF = () => {
  const projectCustomFieldMap = store.getState().customFieldDetails.projectCustomFieldMap;
  const userDetails = store.getState().userDetails.userDetails;
  const intake_request: any = cloneDeep(userDetails.companyPreferencesVO.data.intake_request);
  const companyCustomFieldsInStore = store.getState().customFieldDetails.customFields.companyCustomFieldList;
  const companyCustomFields = cloneDeep(companyCustomFieldsInStore);

  const intakefields = companyCustomFields.filter((n: any) => {
    return n.entityType === 'INTAKE_REQUEST';
  });
  const groupList = intake_request.groupList;
  const defultFieldsList: CustomFieldVO[] = [];
  const customFieldsList: any[] = [];
  const addedCustomFieldList: string[] = [];
  let filterCustomFields = [];

  for (let i = 0; i < groupList.length; i++) {
    groupList[i].value.forEach((customField: CustomFieldVO) => {
      const custDetails = projectCustomFieldMap[customField.id];
      if (!customField.isRemove && custDetails) {
        if (groupList[i].name === 'Basic Details') {
          if (custDetails.searchable && custDetails.name !== 'projectcategory' && custDetails.name !== 'projecttype') {
            if (custDetails.defaultMergeTag) {
              defultFieldsList.push(custDetails);
            } else {
              customFieldsList.push(custDetails);
            }
            addedCustomFieldList.push(custDetails.id);
          }
        } else if (custDetails.searchable) {
          addedCustomFieldList.push(custDetails.id);
          customFieldsList.push(custDetails);
        }
      }
    });
  }
  const leftOutIntakeFields = intakefields.filter((n: any) => {
    return !addedCustomFieldList.includes(n.id) && n.searchable;
  });
  leftOutIntakeFields.forEach((obj: any) => {
    customFieldsList.push(obj);
  });
  filterCustomFields = defultFieldsList.concat(customFieldsList);
  return filterCustomFields;
};

export const getProposalCustomFieldsVOMap = () => {
  const projectCustomFieldMap = store.getState().customFieldDetails.projectCustomFieldMap;
  const proposalCustomFieldVOMap: { [key: string]: CustomFieldVO } = {};

  Object.entries(projectCustomFieldMap).forEach(([id, customField]) => {
    if (customField.type !== 'LABEL' && (customField.projectTypes.length == 0 || customField.projectTypes.includes('Proposal'))) {
      if (customField.defaultMergeTag) {
        if (customField.groupName === 'Client Details') {
          proposalCustomFieldVOMap[id] = customField;
        }
      } else {
        proposalCustomFieldVOMap[id] = customField;
      }
    }
  });
  return proposalCustomFieldVOMap;
};

export const updateALCustomField = (customfieldVO: CustomFieldVO, modelValue: string | string[]) => {
  let tempModelValue: string | string[] = cloneDeep(modelValue);
  if (customfieldVO && modelValue?.length > 0 && multiValueCustomFieldList.includes(customfieldVO.type)) {
    if (Array.isArray(tempModelValue)) {
      modelValue.forEach((modValue: string) => {
        if (!customfieldVO.values?.includes(modValue)) {
          const modValIndex: number = tempModelValue.indexOf(modValue);
          modValIndex >= 0 && tempModelValue.splice(modValIndex, 1);
        }
      });
    } else if (!customfieldVO.values?.includes(tempModelValue)) {
      tempModelValue = '';
    } else {
      // do nothing
    }
  }
  return tempModelValue;
};

export const getActiveProjectStage = () => {
  const projectStageList = store.getState().projectMasterData.projectStageList;
  let projectStage;
  for (let i = 0; i < projectStageList.length; i++) {
    if (projectStageList[i].status === 'ACTIVE') {
      projectStage = projectStageList[i].value;
      break;
    }
  }
  return projectStage;
};

export const updateCustomFieldDetailsList = (list: CustomFieldVO[], customField: CustomFieldVO, isDelete: boolean, currentMergeTagName?: string): CustomFieldVO[] => {
  if (isDelete) {
    return list.filter(cf => cf.id !== customField.id);
  } else if (currentMergeTagName) {
    return list.map(cf => (cf.name === currentMergeTagName ? customField : cf));
  } else {
    return [...list, customField];
  }
};

export const updateCustomFieldsStoreKey = (entityType: string, name: string) => {
  if (OrgCustomFieldEntityType.ANSWER_LIBRARY === entityType) {
    return 'customFieldMap';
  } else if (OrgCustomFieldEntityType.DOCUMENT_LIBRARY === entityType) {
    return 'customFieldMapDL';
  } else if (OrgCustomFieldEntityType.SECTION_TEMPLATE === entityType && !['language', 'contentScore', 'approvers'].includes(name)) {
    return 'sectionTemplateCustomFieldMap';
  } else if (OrgCustomFieldEntityType.CATALOG === entityType && !['language', 'contentScore', 'approvers'].includes(name)) {
    return 'customFieldMapCatalog';
  } else {
  }
};

export const cclEntityTypes = [
  OrgCustomFieldEntityType.ANSWER_LIBRARY,
  OrgCustomFieldEntityType.ALL,
  OrgCustomFieldEntityType.SECTION_TEMPLATE,
  OrgCustomFieldEntityType.DOCUMENT_LIBRARY,
  OrgCustomFieldEntityType.CATALOG
];
