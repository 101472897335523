import DuplicateProjectStore from '@interfaces/DuplicateProjectStore';
import { action } from 'easy-peasy';

const DuplicateProjectStoreImpl: DuplicateProjectStore = {
  openAutoConfigDialog: false,
  setOpenAutoConfigDialog: action((state, entry) => {
    state.openAutoConfigDialog = entry;
  }),
  isSourceFile: false,
  setIsSourceFile: action((state, entry) => {
    state.isSourceFile = entry;
  }),
  sourceProjectData: {},
  setSourceProjectData: action((state, entry) => {
    state.sourceProjectData = entry;
  }),
  duplicateProjectVO: {},
  setDuplicateProjectVO: action((state, entry) => {
    state.duplicateProjectVO = entry;
  }),
  dupProjectStatusObj: {
    comparingFiles: 'STARTED',
    configuringFiles: '',
    configuringAnswers: '',
    configuredSheet: 0,
    nonConfiguredSheet: 0,
    updateSectionsAndQuestions: '',
    totalSectionsCount: 0,
    addedSectionsCount: 0,
    deletedSectionsCount: 0,
    totalQuestionsCount: 0,
    addedQuestionsCount: 0,
    deletedQuestionsCount: 0,
    updateFailed: false
  },
  setDupProjectStatusObj: action((state, entry) => {
    state.dupProjectStatusObj = entry;
  })
};

export default DuplicateProjectStoreImpl;
