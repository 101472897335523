import { action } from 'easy-peasy';
import crmStore from '@interfaces/crmStore';
const crmStoreImpl: crmStore = {
  salesforceURL: {},
  zohoURL: {},
  zohoInstanceList: [],
  zohoLoginURL: 'https://crm.zoho.com/crm/',
  defaultURL: 'https://ap1.salesforce.com',
  salesforceLoginURL: 'https://login.salesforce.com',
  salesforceInstanceList: [],
  sharepointSites: {},
  seismicTenant: { tenantId: 'https://apps.seismic.com', isConnected: false, hasUserAccess: false },
  highspotDetails: { highspotUrl: 'https://api.highspot.com/v0.5', isConnected: false },
  crmDataRefreshFlags: {},
  setSalesforceURL: action((state, entry) => {
    state.salesforceURL = entry;
  }),
  setZohoURL: action((state, entry) => {
    state.zohoURL = entry;
  }),
  setSalesforceInstanceList: action((state, entry) => {
    state.salesforceInstanceList = entry;
  }),
  setZohoInstanceList: action((state, entry) => {
    state.zohoInstanceList = entry;
  }),
  setSharepointSites: action((state, entry) => {
    state.sharepointSites = entry;
  }),
  updateSharepointSites: action((state, entry) => {
    Object.entries(entry).forEach(([key, value]) => {
      state.sharepointSites[key] = value;
    });
  }),
  setSeismicDetails: action((state, entry) => {
    state.seismicTenant = entry;
  }),
  setHighspotDetails: action((state, entry) => {
    state.highspotDetails = entry;
  }),
  updateCrmDataRefreshFlags: action((state, entry) => {
    Object.assign(state.crmDataRefreshFlags, entry);
  })
};
export default crmStoreImpl;
