import React, { createRef, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import RfpScrollbar from '@rfp-components/RfpScrollbar';

interface ResizeProps {
  skipScroll?: boolean;
  removeFooterWidth?: number | 0;
  removeHeight?: number;
  onScroll?: any;
  id?: string;
  className?: string;
  children: any;
  zIndexClassName?: string;
  scrollProps?: any;
  useMaxheight?: boolean;
  scrollBarClassName?: any;
}

const useRefDimensions = (ref: any, removedHeight?: number) => {
  const [dimensions, setDimensions] = useState(100);
  React.useEffect(() => {
    const updateScrollHeight = () => {
      if (ref.current) {
        const { current } = ref;
        const boundingRect = current.getBoundingClientRect();
        const documnetHeight = document.documentElement.clientHeight;
        const { top } = boundingRect;
        const newHeight = documnetHeight - (removedHeight || 0) - top;
        setDimensions(newHeight);
      }
    };
    updateScrollHeight();
    window.addEventListener('resize', updateScrollHeight);
    window.addEventListener('scroll', updateScrollHeight);

    return () => {
      window.removeEventListener('resize', updateScrollHeight);
      window.removeEventListener('scroll', updateScrollHeight);
    };
  }, [ref]);
  return dimensions;
};

export default function ResizeHeight(props: ResizeProps) {
  const { id, className, children, onScroll, removeHeight, removeFooterWidth, skipScroll, scrollProps, useMaxheight, scrollBarClassName } = props;
  const classes = ScrollBarStyles();
  const footerHeight = removeFooterWidth ? 0 : 12;
  const removedHeight = removeHeight ? removeHeight + footerHeight : footerHeight;
  const documnetHeight = document.documentElement.clientHeight;
  const onScrollHandler = () => {
    localStorage.setItem('LastResponseTime', new Date().getTime() + '');
    onScroll && onScroll();
  };

  const ref = createRef();
  const elemSize = useRefDimensions(ref, removedHeight);

  return (
    <div dh={documnetHeight} rh={removedHeight} eh={elemSize} ref={ref} style={{ [useMaxheight ? 'maxHeight' : 'height']: elemSize, width: '100%' }} id={id} className={className}>
      {skipScroll ? (
        children
      ) : (
        <RfpScrollbar onScrollStop={onScrollHandler} {...scrollProps} className={scrollBarClassName}>
          {children}
        </RfpScrollbar>
      )}
    </div>
  );
}

const ScrollBarStyles = makeStyles((theme: Theme) => ({
  scrollBar: {
    backgroundColor: '#BBBBBB',
    borderRadius: '5px',
    width: '8px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#999999'
    }
  }
}));
