export default interface CompanyInfoVO {
  modelVal: string;
  value: any;
  companyId?: string;
  companyOwner?: string;
  companyShortName?: string;
  companyStatus?: string;
  theme?: {
    primaryColor: string;
    primaryContrast: string;
    primaryName: string;
    secondary: string;
    secondaryContrast: string;
    secondaryName: string;
  };
  time?: string;
  timeZone?: string;
  uiPreferences?: {
    dateFormat: string;
    timeFormat: string;
  };
  smallLogo?: string;
  largeLogo?: string;
  additionalUIParams?: {
    defaultStyleMap?: string;
    companyFeaturePreferences: {};
  };
  currency: { name: string; symbol: string };
  primaryBU: string;
  sharedBUs: string[];
  businessUnits: any;
  uiMode: string;
  cclMode: string;
  applyToAutograph: boolean;
  address1?: string;
  address2?: string;
  website?: string;
  industryType?: string;
  city?: string;
  state?: string;
  country?: string;
  zipCode?: string;
  podName: string;
}

export const companyInfoVO: CompanyInfoVO = {
  companyOwner: '',
  companyShortName: '',
  companyStatus: '',
  theme: {
    primaryColor: '',
    primaryContrast: '',
    primaryName: '',
    secondary: '',
    secondaryContrast: '',
    secondaryName: ''
  },
  time: '',
  timeZone: '',
  uiPreferences: {
    dateFormat: '',
    timeFormat: ''
  },
  smallLogo: '',
  additionalUIParams: {
    defaultStyleMap: '',
    companyFeaturePreferences: {}
  },
  currency: { name: '', symbol: '' },
  primaryBU: '',
  sharedBUs: [],
  businessUnits: {},
  uiMode: '',
  cclMode: '',
  applyToAutograph: false,
  address1: '',
  address2: '',
  website: '',
  industryType: '',
  city: '',
  state: '',
  country: '',
  zipCode: '',
  modelVal: '',
  value: undefined,
  podName: ''
};
