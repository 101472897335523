import { action } from 'easy-peasy';
import AutoRespondDataStore from './AutoRespondDataStore';

const AutoRespondDataStoreImpl: AutoRespondDataStore = {
  autoRespondInfo: {},
  setAutoRespondInfo: action((state, entry) => {
    const newVal = { ...state.autoRespondInfo, ...entry };
    state.autoRespondInfo = newVal;
  }),
  updateAutoRespondInfo: action((state, entry) => {
    state.autoRespondInfo[entry.username] = { ...state.autoRespondInfo[entry.username], ...entry.payload };
  }),
  setAutoRespondResults: action((state, entry) => {
    state.autoRespondInfo[entry.username] = { ...state.autoRespondInfo[entry.username].autoRespondResults, ...entry.payload };
  }),
  removeAutoRespondInfo: action((state, entry) => {
    delete state.autoRespondInfo[entry];
  })
};

export default AutoRespondDataStoreImpl;
