// import axiosInstance from '@helpers/Axios';

export const errorLogger = async (error: any, isApi?: boolean | false, additionalMessage?: string) => {
  let err = {};
  if (error === null || error === undefined) {
    return;
  }
  const errorToSkip = error && error.stack && error.stack.toString();

  //Reload page, if it is old code chulk.
  if (errorToSkip && (errorToSkip.includes('It seems you are still using the old wait option') || errorToSkip.includes('ChunkLoadError'))) {
    window.location.reload();
    return;
  }
  if (isApi) {
    err = { stack: error };
  } else {
    err = { stack: error.stack };
  }
  err['location'] = window.location.href;
  if (additionalMessage) {
    err['additionalMessage'] = additionalMessage;
  }
  if (error.code === 'ECONNABORTED' || (error && error.stack && (error.stack.includes('Network Error') || error.stack.message === 'Network Error'))) {
    console.log('Network Error');
    return;
  }
  console.error(err);
  if (errorToSkip && (errorToSkip.indexOf('tinymce.js') || errorToSkip.indexOf('bundle.js')) > -1) {
    return;
  }
  fetch('/rfpserver/ui-error-logger/log-error', {
    method: 'POST',
    body: JSON.stringify({ err }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8'
    }
  });
  // await axiosInstance().post('/ui-error-logger/log-error', err);
};
