import TimerInfoStore from '@interfaces/TimerInfoStore';
import { action } from 'easy-peasy';

const TimerInfoStoreImpl: TimerInfoStore = {
  currentTimer: null,
  updateCurrentTimer: action((state, entry) => {
    state.currentTimer = entry;
  }),
  reRenderTimeTable: false,
  setReRenderTimeTable: action((state, entry) => {
    state.reRenderTimeTable = entry;
  })
};

export default TimerInfoStoreImpl;
