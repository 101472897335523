import { action, computed } from 'easy-peasy';
import { GuestSectionStore } from '@interfaces/GuestStoreInterface';
const cloneDeep = require('clone-deep');

const GuestSectionStoreImpl: GuestSectionStore = {
  guestDetails: [],
  setGuestDetails: action((state, entry) => {
    state.guestDetails[0] = entry;
  }),
  setGuestSectionsInfo: action((state, entry) => {
    state.guestDetails[0].guestSectionsInfo = entry;
  }),
  updateSectionQuestions: action((state, entry) => {
    entry.questions.forEach(quest => {
      if (quest.contentType == 'DESCRIPTION') {
        quest.existingQuestion = cloneDeep(quest.question);
      } else {
        quest.existingAnswers = cloneDeep(quest.answers);
      }
    });
    state.guestDetails[0].questionsMap[entry.sectionId] = entry.questions;
    state.guestDetails[0].sectionFilesMap[entry.sectionId] = entry.sectionFiles;
  }),
  updateQuestion: action((state, entry) => {
    if (entry.question.status != state.guestDetails[0].questionsMap[entry.sectionId][entry.index].status) {
      if (entry.question.status == 'ANSWERED') {
        state.guestDetails[0].guestSectionsInfo[entry.sectionId].answeredCount++;
        state.guestDetails[0].guestSectionsInfo[entry.sectionId].unansweredCount--;
      } else {
        state.guestDetails[0].guestSectionsInfo[entry.sectionId].answeredCount--;
        state.guestDetails[0].guestSectionsInfo[entry.sectionId].unansweredCount++;
      }
    }
    if (entry.question.contentType == 'DESCRIPTION') {
      entry.question.existingQuestion = cloneDeep(entry.question.question);
    } else {
      entry.question.existingAnswers = cloneDeep(entry.question.answers);
    }
    state.guestDetails[0].questionsMap[entry.sectionId][entry.index] = cloneDeep(entry.question);
  }),
  unsetQuestionEditedStatus: action((state, entry) => {
    state.guestDetails[0].questionsMap[entry.sectionId][entry.index].isEdited = false;
  }),
  updateAnswers: action((state, entry) => {
    const contentType = state.guestDetails[0].questionsMap[entry.sectionId][entry.questionIndex].contentType;
    if (contentType === 'DESCRIPTION') {
      state.guestDetails[0].questionsMap[entry.sectionId][entry.questionIndex].question = entry.answer.answer;
    } else {
      state.guestDetails[0].questionsMap[entry.sectionId][entry.questionIndex].answers[entry.answerIndex] = cloneDeep(entry.answer);
    }
  }),
  attachFilesToQuestion: action((state, entry) => {
    state.guestDetails[0].questionsMap[entry.sectionId][entry.index].files = entry.questionFiles;
  }),
  attachFilesToSection: action((state, entry) => {
    state.guestDetails[0].sectionFilesMap[entry.sectionId] = entry.files;
  }),
  assignedUsersInfo: action((state, entry) => {
    const assignedUsersInfo: string[] = [];
    Object.entries(entry).forEach(([sectionId, sectionInfo]) => {
      if (sectionInfo && sectionInfo.assignedByUsers) {
        sectionInfo.assignedByUsers.forEach(user => {
          if (assignedUsersInfo.indexOf(user) < 0) {
            assignedUsersInfo.push(user);
          }
        });
      }
    });
    state.guestDetails[0].assignedUsers = assignedUsersInfo;
  }),
  questionCountInfo: computed(state => {
    const questionCountInfo = { answeredCount: 0, totalCount: 0 };
    if (!state.guestDetails[0] || !state.guestDetails[0].guestSectionsInfo) {
      return questionCountInfo;
    }
    Object.entries(state.guestDetails[0].guestSectionsInfo).forEach(([sectionId, sectionInfo]) => {
      questionCountInfo.answeredCount += sectionInfo.answeredCount;
      questionCountInfo.totalCount += sectionInfo.answeredCount + sectionInfo.unansweredCount;
    });
    return questionCountInfo;
  }),
  setQuestionVisiblity: action((state, entry) => {
    if (state.guestDetails[0].questionsMap[entry.sectionId][entry.qIndex].hidden === entry.isHidden) {
      return;
    }
    state.guestDetails[0].questionsMap[entry.sectionId][entry.qIndex] = { ...state.guestDetails[0].questionsMap[entry.sectionId][entry.qIndex], hidden: entry.isHidden };
    if (entry.isHidden) {
      if (state.guestDetails[0].questionsMap[entry.sectionId][entry.qIndex].status == 'ANSWERED') {
        state.guestDetails[0].guestSectionsInfo[entry.sectionId].answeredCount--;
      } else {
        state.guestDetails[0].guestSectionsInfo[entry.sectionId].unansweredCount--;
      }
    } else {
      if (state.guestDetails[0].questionsMap[entry.sectionId][entry.qIndex].status == 'ANSWERED') {
        state.guestDetails[0].guestSectionsInfo[entry.sectionId].answeredCount++;
      } else {
        state.guestDetails[0].guestSectionsInfo[entry.sectionId].unansweredCount++;
      }
    }
  }),
  updateGuestSectionsInfo: action((state, entry) => {
    if (state.guestDetails[0].guestSectionsInfo[entry.sectionId].answeredCount !== entry.sectionInfoDetails.answeredCount) {
      entry.sectionInfoDetails.answeredCount = state.guestDetails[0].guestSectionsInfo[entry.sectionId].answeredCount;
      entry.sectionInfoDetails.unansweredCount = state.guestDetails[0].guestSectionsInfo[entry.sectionId].unansweredCount;
    }
    state.guestDetails[0].guestSectionsInfo[entry.sectionId] = entry.sectionInfoDetails;
  })
};

export default GuestSectionStoreImpl;
