export const checkIfArraysHasContainsAny = function (arr1: any, arr2: any, lowerCase?: boolean) {
  if (lowerCase) {
    arr1 = arr1.map((ele: any) => ele.toLowerCase());
    arr2 = arr2.map((ele: any) => ele.toLowerCase());
  }
  if (!arr1 || arr1.length === 0 || !arr2 || arr2.length === 0) {
    return false;
  }
  if (arr1.length <= arr2.length) {
    for (let i = 0; i < arr1.length; i++) {
      if (arr2.indexOf(arr1[i]) >= 0) {
        return true;
      }
    }
  } else {
    for (let i = 0; i < arr2.length; i++) {
      if (arr1.indexOf(arr2[i]) >= 0) {
        return true;
      }
    }
  }
  return false;
};

export const findArrayIndexforObjectByKey = (arr: any, key: string, value: any): number => {
  let idx = -1;
  if (!arr || arr.length === 0 || !key) {
    return -1;
  }
  for (let i = 0; i < arr.length; i++) {
    if (arr[i][key] === value) {
      idx = i;
    }
  }
  return idx;
};

export const checkArrayEquality = (arrayOne?: any[], arrayTwo?: any[]) => {
  if (!arrayOne || !arrayTwo) {
    return false;
  }
  if (arrayOne.length !== arrayTwo.length) {
    return false;
  }
  return arrayOne.every(i => arrayTwo.includes(i));
};

export const convertStringToArray = (values: string | string[]) => {
  return !Array.isArray(values) ? values?.split('\n') || [] : values;
};

export const convertArrayToString = (values: string | string[]) => {
  return Array.isArray(values) ? values?.join('\n') || '' : values;
};

export const removeDuplicateIgnoringCase = (values: string[]) => {
  const map = new Map();
  for (const value of values) {
    const lowerCaseValue = value.toLowerCase();
    if (!map.has(lowerCaseValue)) {
      map.set(lowerCaseValue, value);
    }
  }
  return Array.from(map.values());
};

/**
 * Sorts an array of strings based on their proximity to a given word.
 * Optionally, you can sort based on a specific key in a keyComparator object.
 *
 * @param {string[]} array - The array of strings to sort.
 * @param {string} word - The word to prioritize in the sorting.
 * @param {Record<string, any>} [keyComparator] - An optional object mapping strings to objects with optional keys.
 * @param {string} [comparatorKey] - The key to use in the keyComparator object for sorting.
 * @returns {string[]} - The sorted array.
 */
export const sortArrayByInputWord = (array: string[], word: string, keyComparator?: Record<string, any>, comparatorKey?: string) => {
  const lowerCaseWord = word.toLowerCase();

  return array.slice().sort((a, b) => {
    const aLower = (keyComparator?.[a]?.[comparatorKey as string] || a).toLowerCase();
    const bLower = (keyComparator?.[b]?.[comparatorKey as string] || b).toLowerCase();

    const aContains = aLower.includes(lowerCaseWord);
    const bContains = bLower.includes(lowerCaseWord);

    // Prioritize elements containing the word
    if (aContains && !bContains) return -1;
    if (!aContains && bContains) return 1;

    // If both strings either contain or do not contain the word, sort alphabetically
    if (aContains && bContains) {
      // Sort by how close the search word is to the start of the string
      const aIndex = aLower.indexOf(lowerCaseWord);
      const bIndex = bLower.indexOf(lowerCaseWord);
      if (aIndex !== bIndex) return aIndex - bIndex;
    }

    return aLower.localeCompare(bLower);
  });
};
