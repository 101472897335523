import { thunk } from 'easy-peasy';
import { GuestSaveAnswerThunkType } from '@interfaces/GuestStoreInterface';
import { getAnswerData, isAnswered, saveAnswerApiCall, startSection, validateQuestionIsEdited } from '@utils/GuestUtils';
import { errorLogger } from '@utils/ErrorLogging';
import cloneDeep from 'clone-deep';

const SaveAnswerThunkImpl: GuestSaveAnswerThunkType = {
  saveAnswers: thunk(async (actions, thunkInfo, { getStoreState, getStoreActions }) => {
    const { updateGuestSectionsInfo, updateQuestion, setQuestionVisiblity, unsetQuestionEditedStatus } = getStoreActions().guestSection;
    const { guestDetails } = getStoreState().guestSection;
    const guestSectionsInfo = guestDetails[0] && guestDetails[0].guestSectionsInfo ? guestDetails[0].guestSectionsInfo : {};
    const guestId = getStoreState().guestId.id;
    const { setShowSaved, setShowSaving, setActiveQuestionState } = getStoreActions().guestPageLayout;
    const { activeQuestionState } = getStoreState().guestPageLayout.guestPageLayout;
    const { activeQuestionID, activeQuestionIndex, activeSectionID } = thunkInfo ? thunkInfo?.activateStateUpdateValues : { questionId: '', questionIndex: 0, sectionId: '' }; //card clicked question details

    const { activeQuestionID: questionId, activeQuestionIndex: questionIndex, activeSectionID: sectionId } = activeQuestionState; //save answer question details
    const questionsMap = guestDetails && guestDetails[0] && guestDetails[0].questionsMap ? guestDetails[0].questionsMap : {};

    const question = sectionId && cloneDeep(questionsMap[sectionId][questionIndex]);
    const isDesc = sectionId && questionIndex && questionsMap[sectionId][questionIndex].contentType === 'DESCRIPTION';
    if (!isDesc && question && questionId && activeQuestionID !== questionId) {
      if (!question) {
        return;
      }
      const questionAnswer = getStoreState().guestPageLayout.currentQuestionAnswers;
      const descQuestion = getStoreState().guestPageLayout.currentDescQuestion;

      if (descQuestion && question && question.contentType === 'DESCRIPTION') {
        question.question = descQuestion;
      } else {
        question.answers = questionAnswer;
      }

      question && validateQuestionIsEdited(question);

      try {
        if (activeQuestionID && activeQuestionIndex && activeSectionID && !question?.isEdited) {
          const currentQuestionAnswer = questionsMap[activeSectionID][activeQuestionIndex].answers;
          setActiveQuestionState({ activeSectionID, activeQuestionID, activeQuestionIndex, currentQuestionAnswer });
        }
        if (!question.isEdited) {
          return;
        }
        const currentSectionId = question.sectionId;
        startSection(currentSectionId, guestSectionsInfo, updateGuestSectionsInfo, guestId);
        setShowSaving(true);
        setShowSaved(false);
        let obj = {};
        let url = '';
        let message = '';

        if (question?.contentType && question.contentType === 'DESCRIPTION') {
          obj = question;
          url = '/guest/edit-question/';
          message = 'Description Saved';
        } else {
          obj = {
            id: question.id,
            projectId: question.projectId,
            sectionId: question.sectionId,
            status: isAnswered(question) ? 'ANSWERED' : 'NOT_ANSWERED',
            answers: getAnswerData(question)
          };
          url = '/guest/save-answers/';
        }

        const response = await saveAnswerApiCall(url, guestId, obj);
        if (response && response.data && response.status === 200) {
          setShowSaving(false);
          setShowSaved(true);
          if (response.data && !response.data.displayOrder && question.displayOrder) {
            response.data.displayOrder = question.displayOrder;
          }
          updateQuestion({ sectionId: response.data.sectionId, question: response.data, index: questionIndex });
          if (response.data.additionalUIParams && (response.data.additionalUIParams.visible || response.data.additionalUIParams.invisible)) {
            questionsMap[sectionId] &&
              questionsMap[sectionId].forEach(function (question, qIndex) {
                if (response.data.additionalUIParams.visible && response.data.additionalUIParams.visible.indexOf(question.id) >= 0) {
                  setQuestionVisiblity({ sectionId: sectionId, qIndex: qIndex, isHidden: false });
                }
                if (response.data.additionalUIParams.invisible && response.data.additionalUIParams.invisible.indexOf(question.id) >= 0) {
                  setQuestionVisiblity({ sectionId: sectionId, qIndex: qIndex, isHidden: true });
                }
              });
          }
          if (activeQuestionID && activeSectionID && activeQuestionIndex) {
            const currentQuestionAnswer = questionsMap[activeSectionID][activeQuestionIndex].answers;
            setActiveQuestionState({ activeSectionID, activeQuestionID, activeQuestionIndex, currentQuestionAnswer });
          } else {
            const currentQuestionAnswer = questionsMap[sectionId][questionIndex].answers;
            setActiveQuestionState({ sectionId, questionId, questionIndex, currentQuestionAnswer });
          }
        }
        if (response && response.data && response.status === 204) {
          setShowSaving(false);
          setShowSaved(true);
          unsetQuestionEditedStatus({ sectionId: sectionId, index: questionIndex });
        }
      } catch (e) {
        errorLogger(e, false, 'Guest Page save answer or edit question');
      }
    } else if (activeQuestionID !== questionId) {
      const currentQuestionAnswer = questionsMap[activeSectionID][activeQuestionIndex].answers;
      setActiveQuestionState({ activeSectionID, activeQuestionID, activeQuestionIndex, currentQuestionAnswer });
      return;
    } else {
      return;
    }
  })
};

export default SaveAnswerThunkImpl;
