import React, { useContext } from 'react';
import { styled } from '@material-ui/core/styles';
import Loading from '../components/Loading';
import i18next from 'i18next';

const loading = i18next.t('LOADING');

type LoadingBarContextType = {
  openLoading: (message: string | React.ReactNode) => void;
  closeLoading: () => void;
};

const LoadingBarContext = React.createContext<LoadingBarContextType>();

const useLoadingBar = (): LoadingBarContextType => useContext(LoadingBarContext);

const DIV = styled('div')({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  width: `100vw`,
  height: '100vh',
  backgroundColor: '#21212161',
  zIndex: 9999,
  color: '#ffffff',
  fontSize: 18
});

const LoadingBar = (props: any) => {
  const [loadingNode, setLoadingNode] = React.useState<{
    open: boolean;
    message: string | React.ReactNode | null;
  }>({ open: false, message: null });

  const openLoading = (message: string | React.ReactNode) => {
    setLoadingNode({ open: true, message });
  };
  const closeLoading = () => {
    setLoadingNode({ open: false, message: null });
  };

  return (
    <LoadingBarContext.Provider value={{ openLoading, closeLoading }}>
      {props.children}
      {loadingNode.open && (
        <DIV className='layout-row justifyContentCenter align-items-center'>
          {loadingNode.message ? (
            loadingNode.message
          ) : (
            <>
              <Loading height={24} width={24} />
              {loading}
            </>
          )}
        </DIV>
      )}
    </LoadingBarContext.Provider>
  );
};

export { useLoadingBar, LoadingBar };
