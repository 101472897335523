import { Theme, createTheme } from '@material-ui/core/styles';
declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    accentColor: Palette['primary'];
  }
  interface PaletteOptions {
    accentColor: PaletteOptions['primary'];
  }
}

declare module '@material-ui/core/styles/createMixins' {
  interface Mixins {
    interFontStack: string;
  }
}

const theme: Theme = createTheme({
  typography: {
    fontFamily: '"Source Sans Pro", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    h1: {
      fontSize: 18,
      fontWeight: 600
    },
    h3: {
      fontSize: 16,
      fontWeight: 600
    },
    h5: {
      fontSize: 16,
      fontWeight: 500
    },
    h6: {
      fontSize: 14,
      fontWeight: 600
    },
    caption: {
      fontSize: 12
    }
  },
  mixins: {
    interFontStack: 'var(--inter-font-stack)'
  },
  palette: {
    type: 'light',
    accentColor: {
      main: '#7baf27'
    },
    primary: {
      main: '#7baf27',
      light: '',
      dark: ''
    },
    secondary: {
      main: '#1c3443',
      contrastText: '#FFFFFF'
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: { primary: '#2F4354', secondary: '#FFFFFF' }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 2,
        textTransform: 'capitalize',
        fontWeight: 600,
        padding: '5px 25px'
      }
    },
    MuiRadio: {
      root: {
        color: '#7f8286'
      }
    },
    MuiCheckbox: {
      root: {
        color: '#7f8286'
      }
    },
    MuiIconButton: {
      root: {
        padding: '0px',
        color: '#2F4354'
      }
    },
    MuiListItemAvatar: {
      root: {
        minWidth: 'auto',
        paddingRight: 5
      }
    },
    MuiListItem: {
      root: {
        paddingTop: 0,
        paddingBottom: 0
      },
      container: {
        display: 'inline-block',
        border: '1px solid #1C3443',
        borderRadius: 15,
        padding: 5,
        marginRight: 10
      },
      // gutters: {
      //     paddingLeft: 0,
      //     paddingRight: 0
      // },
      secondaryAction: {
        paddingRight: 5
      }
    },
    MuiListItemText: {
      root: {
        marginTop: 0,
        marginBottom: 0
      },
      primary: {
        fontSize: '11px',
        cursor: 'pointer'
      }
    },
    MuiListSubheader: {
      root: {
        lineHeight: '1.2em'
      }
    },
    MuiCardMedia: {
      root: {
        backgroundSize: 'contain'
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: '11.5px 14px'
      },
      multiline: {
        padding: '11.5px 14px'
      }
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: 8
      }
    },
    MuiInput: {
      multiline: {
        padding: '0'
      }
    },
    MuiChip: {
      outlined: {
        border: '1px solid #1C3443'
      }
    },
    MuiMenuItem: {
      root: {
        padding: '6px 16px !important',
        fontSize: '14px',
        minWidth: '100px'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 35,
        color: '#2F4354'
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#2F4354',
        fontSize: '0.8rem'
      }
    },
    MuiTab: {
      root: {
        textTransform: 'capitalize'
      }
    },
    MuiToolbar: {
      gutters: {
        padding: '0px 12px !important'
      }
    }
  }
});

export default theme;
