import TagManager from 'react-gtm-module';
import { getCompanyId } from './GeneralUtils';
export interface DatalayerArgs {
  dataLayer: {
    event: string;
    attributes: {};
    pii?: {};
  };
}

export const AskGTMEventName: { [key: string]: string } = {
  openAsk: 'ask open button click',
  typeAndExit: 'type and exit event for ask'
};

export interface TrackerAdditionalParams {
  userId: string;
  companyId?: string;
  companyName?: string;
  userRole?: string;
  additionalParams?: any;
  uxVersion?: string;
  planType: string;
  companyEmailPreference: any;
  companyUIPreference: string[];
  userStatus: string;
  userRoleName: string;
  userUIPreference: string[];
}

export interface CustomTrackEventParams {
  eventName: string;
  eventProperties: any;
}

const guestuserData: string = localStorage.getItem('guestUserData') || '';
const companyGuestData = guestuserData ? JSON.parse(guestuserData) : {};
const companyGuestVO = companyGuestData['companyGuestVO'] || {};
const companyId: string = companyGuestVO['companyId'];
const guest: string = companyGuestVO['guest'];
const userData = localStorage.getItem('userData');
const user = userData ? JSON.parse(userData) : {};
const isRFPIOUser = user && (user.username?.endsWith('@rfpio.com') || user.username?.endsWith('@responsive.io'));
const canFire = window?.constants?.environment === 'Development' || !isRFPIOUser;
const isGuest = window.location.href.includes('profile-view');

export const trackEvent = (category: any, action: any, label: any, additionalParams?: TrackerAdditionalParams) => {
  if (window.constants.gtm_tracker && canFire) {
    const datalayerArgs: DatalayerArgs = {
      dataLayer: {
        event: 'rfpioEvents',
        attributes: {
          eventCategory: category,
          eventAction: action,
          eventLabel: label
        }
      }
    };
    if (additionalParams) {
      datalayerArgs.dataLayer.pii = additionalParams;
    }
    TagManager.dataLayer(datalayerArgs);
  }
};

export const fireUserIdentity = (userInfo: TrackerAdditionalParams) => {
  if (window.constants.gtm_tracker && canFire) {
    const datalayerArgs: DatalayerArgs = {
      dataLayer: {
        event: 'userIdentity',
        attributes: {},
        pii: userInfo
      }
    };
    TagManager.dataLayer(datalayerArgs);
  }
};

export const trackCustomEvent = (params: CustomTrackEventParams) => {
  if (window.constants.gtm_tracker && canFire) {
    if (isGuest) {
      params.eventProperties['userName'] = guest;
      params.eventProperties['companyId'] = companyId;
    }
    if (!params.eventProperties['companyId']) {
      params.eventProperties['companyId'] = getCompanyId();
    }

    params.eventProperties['ux_version'] = 'v2';
    params.eventProperties = JSON.stringify(params.eventProperties);
    const datalayerArgs: DatalayerArgs = {
      dataLayer: {
        event: 'customEvent',
        attributes: {},
        pii: params
      }
    };
    TagManager.dataLayer(datalayerArgs);
  }
};

export const setUserProperty = (userInfo: TrackerAdditionalParams) => {
  if (window.constants.gtm_tracker && canFire) {
    const datalayerArgs: DatalayerArgs = {
      dataLayer: {
        event: 'setUserProperty',
        attributes: {},
        pii: userInfo
      }
    };
    TagManager.dataLayer(datalayerArgs);
  }
};

export const convertObjToString = (data: any) => {
  let resultArray: any = [];
  if (!data) {
    return;
  }
  Object.entries(data).forEach(([key, value]: any) => {
    if (value != '0') {
      resultArray.push(key);
    }
  });
  return resultArray.join('|');
};

export const MixPanelEventNameTrackMap: { [key: string]: string } = {
  IndividualSectionOption: 'Project - Answering page - Individual section options',
  AnsweringPageExpandOrCollapse: 'Project - Answering Page - Expand/Collapse',
  AnsweringPageQuestionFilter: 'Project - Answering Page - All Question/My Questions',
  AnsweringPageAddDescription: 'Project - Answering page - Add Description',
  HamburgerMenuCloseIcon: 'Project - Answering page - Hamburger Menu Close icon',
  HamburgerMenuSearchField: 'Project - Answering page - Hamburger Menu Search field',
  BackArrrowUsage: 'Project - Answering page - Back arrrow(Section List)',
  HamburgerMenuUsage: 'Project - Answering page - Hamburger Menu Usage',
  AnsweringPageAddSection: 'Project - Answering page - Add Section(TOC)',
  PreviousAndNextArrows: 'Project - Answering page - Previous and Next Arrows',
  QuestionCardMenuOptions: 'Project - Answering page - Question card menu options',
  QuestionCardIcons: 'Project - Answering page - Question Card Icons',
  SectionDescriptionOptions: 'Project - Answering page - Section description Options',
  AnsweringPageAdvancedFilters: 'Project - Answering Page - Advanced Filters usage(Manual Actions)',
  RecommendationsAdvancedFilters: 'Project - Answering page - Recommendations Advanced filters(Manual Actions)',
  HelpMeChooseAuthorsReviewers: 'User Assignment based on Help Me Choose Authors & Reviewers',
  ProjectAnsweringPageBulkUpdate: 'Project -  Answering page - Bulk update',
  AnsweringPageDescriptionComments: 'Project - Answering page - Description Comments',
  AnsweringPageDescriptionOptions: 'Project - Answering page - Description Options',
  SectionDescriptionIcons: 'Project - Answering page - Section description Icons'
};
