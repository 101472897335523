import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';
import axiosInstance from '@helpers/Axios';
import { updateCustomFieldsDisplayName } from '@utils/CustomFieldUtils';

const loadLocaleBundle = async (locale: string) => {
  if (locale) {
    let url = '/translate/get';
    if (window.location.href.includes('/profile-center') && usePodName) {
      url += `?podName=${usePodName}`;
    }

    return await axiosInstance()
      .get(url, { params: { lang: locale, appVersion: 'V2' } })
      .then(response => {
        return constructRequiredJson(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }
};

const constructRequiredJson = async (data: any) => {
  return { ...data['actions'], ...data['labels'], ...data['message'], ...data['tooltip'] };
};

const backendOptions = {
  loadPath: '{{lng}}|{{ns}}',
  request: (options: any, url: any, payload: any, callback: any) => {
    try {
      const [lng] = url.split('|');
      loadLocaleBundle(lng)
        .then(data => {
          callback(null, {
            data: data,
            status: 200
          });
        })
        .then(() => {
          updateCustomFieldsDisplayName(null, lng);
        });
    } catch (e) {
      console.error(e);
      callback(null, {
        status: 500
      });
    }
  }
};

i18n
  .use(LanguageDetector)
  .use(backend)
  .init({
    backend: backendOptions,
    fallbackLng: 'en',
    debug: false,
    load: 'languageOnly',
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'b', 'strong', 'i', 'p', 'a'],
      wait: true
    }
  });

let usePodName = '';
export const resetI18 = (podName: string) => {
  usePodName = podName;
  i18n
    .use(LanguageDetector)
    .use(backend)
    .init({
      backend: backendOptions,
      fallbackLng: 'en',
      debug: false,
      load: 'languageOnly',
      ns: ['translations'],
      defaultNS: 'translations',
      keySeparator: false,
      interpolation: {
        escapeValue: false,
        formatSeparator: ','
      },
      react: {
        transKeepBasicHtmlNodesFor: ['br', 'b', 'strong', 'i', 'p', 'a'],
        wait: true
      }
    });
};

export default i18n;
