import React, { useEffect, useMemo, useState } from 'react';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import ButtonGroup, { ButtonGroupProps } from '@material-ui/core/ButtonGroup';

const RfpioButton = withStyles(theme => ({
  root: {
    fontSize: 14,
    lineHeight: '18px',
    padding: '0px 16px',
    minWidth: '74px',
    minHeight: '34px',
    borderRadius: '5px',
    textTransform: 'none'
  },
  contained: {
    boxShadow: 'none !important'
  },
  text: {
    '&.tertiaryButton': {
      border: theme.palette.type === 'light' ? '1px solid #0000003b' : '1px solid #777777',
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 3px 12px #00000017',
      '&:hover': {
        backgroundColor: theme.palette.background.paper
      }
    },
    '&.cancelButton': {
      '&:hover': {
        backgroundColor: theme.palette.type === 'dark' ? '#444444 !important' : ''
      }
    }
  },
  sizeSmall: {
    minWidth: 40,
    padding: '5px 12px',
    fontSize: 12,
    minHeight: 30
  }
}))(Button);

const RfpioButtonGroup = withStyles(theme => ({
  root: {
    borderColor: theme.palette.primary.main
  }
}))(ButtonGroup);
interface RfpButtonProps extends Partial<ButtonProps> {
  dataQa: string;
  addBulkSelectionStyle?: boolean;
  isBulkButton?: boolean;
}

export function RfpButton(props: RfpButtonProps) {
  const [hoverColor, setHoverColor] = useState<string>('');
  const classes = ProjectButtonStyles({ hoverColor });
  const { ref, dataQa, addBulkSelectionStyle, isBulkButton, className, ...other } = props;

  return (
    <RfpioButton ref={ref} data-qa={dataQa} className={`${className} ${addBulkSelectionStyle && classes.buttonProp} ${isBulkButton && classes.bulkButton}`} {...other}>
      {props.children}
    </RfpioButton>
  );
}
const ForwardedRefButton = React.forwardRef(RfpButton);
const ProjectButtonStyles = makeStyles(theme => ({
  buttonProp: {
    marginLeft: '10px',
    fontSize: 14,
    borderColor: `#999999`,
    '&:hover': {
      backgroundColor: '#1C3443 !important',
      color: '#FFFFFF'
    }
  },
  bulkButton: {
    background: '#FFFFFF',
    border: '0px !important'
  }
}));

export default ForwardedRefButton;

interface RfpButtonGroupProps extends Partial<ButtonGroupProps> {
  dataQa: string;
}

export function RfpButtonGroup(props: RfpButtonGroupProps) {
  const { dataQa, ...other } = props;
  return (
    <RfpioButtonGroup data-qa={dataQa} {...other}>
      {props.children}
    </RfpioButtonGroup>
  );
}
