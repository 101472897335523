import { action, thunk, computed } from 'easy-peasy';
import SessionTimeoutStore from '@interfaces/sessionTimeoutStore';

const SessionTimeoutStoreImpl: SessionTimeoutStore = {
  reminderMin: 5,
  setReminderMin: action((state, entry) => {
    state.reminderMin = entry;
  }),
  showSessionWarningDialogue: false,
  setShowSessionWarningDialogue: action((state, entry) => {
    state.showSessionWarningDialogue = entry;
  })
};

export default SessionTimeoutStoreImpl;
