import { action } from 'easy-peasy';
import AdditionalInfoStore from './AdditionalInfoStore';

const AdditionalInfoStoreImpl: AdditionalInfoStore = {
  additionalInfo: {},
  loadBaseComponent: false,
  setAdditionalInfo: action((state, entry) => {
    const newVal = { ...state.additionalInfo, ...entry };
    state.additionalInfo = newVal;
  }),
  setAdditionalInfoResult: action((state, entry) => {
    if (state.additionalInfo.openAI && state.additionalInfo.openAI.result) {
      state.additionalInfo.openAI.result.result = entry;
    }
  }),
  setLoadBaseComponent: action(state => {
    state.loadBaseComponent = !state.loadBaseComponent;
  }),
  updateAutoRespondBatchData: action((state, entry) => {
    state.additionalInfo.autoRespondBatchData[entry.username] = { ...state.additionalInfo.autoRespondBatchData[entry.username], ...entry.payload };
  }),
  removeAutoRespondBatchData: action((state, entry) => {
    delete state.additionalInfo.autoRespondBatchData[entry];
  })
};

export default AdditionalInfoStoreImpl;
