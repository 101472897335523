import { getCompanyId, getCurrentUser, setCompanyId } from '@utils/UserLoadDetailUtil';
import { bulkGenerateMSaddinToken } from './LoginService';
import Cookies from 'universal-cookie';
import { PasswordValidationVO } from '@interfaces/Models/PasswordValidationVO';
import { getUrlParam } from '@utils/URLParamUtil';
import { ActionCreator } from 'easy-peasy';

const regex2FApattern: RegExp = /^\d*\.?\d*$/;
let redirectUrl: string = '';
let returnTo: string = '';

export enum ServerStatus {
  SERVER_READY = 'Server Ready',
  SERVER_NOT_READY = 'Server Not Ready',
  SERVER_FAILED = 'Server Failed'
}

export enum EmailValidationError {
  EMAIL_REQUIRED = 'Email is required',
  EMAIL_INVALID = 'Email is invalid',
  EMAIL_VALID = ''
}

export interface PasswordPolicy {
  atleast_one_lowercase: boolean;
  atleast_one_number: boolean;
  atleast_one_special_character: boolean;
  atleast_one_uppercase: boolean;
  begin_with_alphabet: boolean;
  minimum_password_length: number;
}

export interface SSOData {
  signInGoogle: boolean;
  signInPassword: boolean;
  signInSalesforce: boolean;
  samlUrls: { [key: string]: string };
}

export interface UserUpdateObject {
  password: string;
  confirmNewPassword: string;
  firstName?: string;
  lastName?: string;
  activationCode?: string;
  verificationCode?: string;
}

export const getPasswordPolicyObj = (passwordValObj: PasswordValidationVO) => {
  const passwordPolicyKeys: (keyof PasswordPolicy)[] = [
    'atleast_one_lowercase',
    'atleast_one_number',
    'atleast_one_special_character',
    'atleast_one_uppercase',
    'begin_with_alphabet',
    'minimum_password_length'
  ];
  const passwordPolicy: { [key: string]: any } = {};

  passwordPolicyKeys.forEach(key => {
    if (passwordValObj[key] === true || (key === 'minimum_password_length' && passwordValObj[key] > 0)) {
      passwordPolicy[key] = passwordValObj[key];
    }
  });

  return passwordPolicy;
};

export const errorCheck2FACode = (verificationCode?: string) => (verificationCode ? verificationCode === '' || !regex2FApattern.test(verificationCode) : true);

/*
1.SkipLoginUrls has only the guest and authentication not needed items base url.
*/
export const skipLoginUrls: string[] = [
  '/v2/validation-2fa',
  '/v2/activate',
  '/v2/password-expiration',
  '/v2/guest-content',
  '/profile-view',
  '/profile-center',
  '/v2/proactive-proposal-view',
  '/v2/company-registration',
  '/v2/guest-section',
  '/v2/guest-sign',
  '/v2/respondent-view',
  '/v2/salesforce-callback',
  '/v2/saml-callback',
  '/v2/sso-login',
  '/v2/logout',
  '/v2/support-login',
  '/v2/notifyMSAddin',
  '/v2/nofifyMS'
];

export const sendLoginReady = () => {
  window.notifyMSAddin = window.notifyMSAddin || localStorage.getItem('notifyMSAddin');
  window.notifyMS = window?.notifyMS || localStorage.getItem('notifyMS');
  if (window?.notifyMS) {
    notifyMSTeamSuccess();
  } else if (window?.notifyMSAddin) {
    notifyMSAddinSuccess();
    return true;
  } else {
    const cookies = new Cookies();
    const rfpioCRMIELogin = cookies.get('rfpioCRMIELogin');
    if (rfpioCRMIELogin) {
      cookies.remove('rfpioCRMIELogin', { path: '/' });
      const loginData: { [key: string]: any } = {};

      const keys = Object.keys(localStorage);
      let i = keys.length;
      while (i--) {
        loginData[keys[i]] = localStorage.getItem(keys[i]);
      }
      cookies.set('rfpioCRMLoginData', JSON.stringify(loginData), { path: '/' });
    }
    if (window?.opener !== window) {
      if (!window?.toolbar || window?.toolbar.visible) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }
};

export const notifyMSTeamSuccess = () => {
  const element = document.createElement('script');
  element.type = 'text/javascript';
  element.src = 'https://statics.teams.microsoft.com/sdk/v1.0/js/MicrosoftTeams.min.js';
  element.onload = () => {
    localStorage.removeItem('notifyMS');
    microsoftTeams.initialize();
    microsoftTeams.authentication.notifySuccess();
  };
  document.head.appendChild(element);
};

export const notifyMSAddinSuccess = () => {
  const companyId: string = getCompanyId();
  const element = document.createElement('script');
  element.type = 'text/javascript';
  element.src = 'https://appsforoffice.microsoft.com/lib/1/hosted/office.js';
  element.onload = () => {
    Office.onReady(() => {
      localStorage.removeItem('notifyMSAddin');
      console.log('notifyMSAddinSuccess Office initializer called');
      if (Office.context.ui && typeof Office.context.ui.messageParent == 'function') {
        generateToken();
      } else if (!sendLoginReady()) {
        //need to handle user specific logic here
        window.history.pushState(null, '', `/v2/my-work?companyId=${companyId}`);
      } else {
        //do nothing
      }
    });
  };
  document.head.appendChild(element);
};

const generateToken = async () => {
  const userList: any[] = [];
  //Need to get ms office here, dummy const added
  // const Office = {};
  const currentUser = getCurrentUser();
  if (!currentUser) {
    console.log('current user is null in generateToken');
    return;
  }
  userList.push(currentUser.username);
  let host: string = '';
  if (Office?.context?.diagnostics) {
    host = Office.context.diagnostics.host + '_' + Office.context.diagnostics.platform;
  }
  const response = await bulkGenerateMSaddinToken(userList, host);
  if (response && response.status === 200 && response.data) {
    const token: string = response.data.additional_message;
    const jsonMessage = JSON.stringify({ data: token, success: true });
    Office.context.ui.messageParent(jsonMessage);
  } else {
    const jsonMessage = JSON.stringify({ success: false });
    Office.context.ui.messageParent(jsonMessage);
  }
};

/*
afterLoginPageRedirect method is for after the successfull login this handles below items-

1.If the user landing company is active and if so it will move to respective landing page based on preference
1.If company not active it will redirect to companyActivation Page
3.If the url companyId doesnot match it will take 1st company and redirect to that

*/
export const afterLoginPageRedirect = (results: any, history: any, loadBaseComponent: ActionCreator<void>) => {
  const companyId: string = getCompanyId();
  let idx: number = 0,
    status: string = '';
  if (results.data['company_name_1'] && !companyId) {
    history.push('/v2/companyList');
    loadBaseComponent();
  } else {
    if (companyId) {
      while (results.data['id_' + idx]) {
        if (results.data['id_' + idx] === companyId) {
          status = results.data['status_' + idx];
        }
        idx++;
      }
    }
    if (!status) {
      status = results.data?.status_0;
      if (results.data?.id_0) {
        setCompanyId(results.data.id_0);
      }
    }
    landInRespectivePage(status, results.data?.landingPage, history, loadBaseComponent);
  }
};

export const landInRespectivePage = (status: string, landingPage: string, history: any, loadBaseComponent: () => void, selectedCompanyId?: boolean) => {
  const companyId: string = getCompanyId();
  if (status === 'ACTIVE') {
    history.push(`/v2/home?companyId=${selectedCompanyId || companyId}`);
    loadBaseComponent();
  } else if (status) {
    history.push(`/v2/company-activate?companyId=${selectedCompanyId || companyId}`);
    loadBaseComponent();
  }
};

export const setRedirectUrl = (url: string) => {
  redirectUrl = url;
};

export const getRedirectUrl = () => {
  return redirectUrl;
};

/*getUserSpecificLandingPage method is to get users landing page url based on user preference*/
export const getUserSpecificLandingPage = (landingPage: string) => {
  switch (landingPage) {
    case 'app.respondRfp':
      return '/v2/project/manage-project';
    case 'app.reports':
      return '/v2/reports/application-usage-report';
    case 'app.documents-lib':
    case 'app.answer-lib':
      return '/v2/content-library/library';
    case 'app.request':
      return '/v2/request/manage-request';
    default:
      return '/v2/my-work';
  }
};

export const handleAndGetSSOobj = (ssoObj: { [key: string]: any }, isActivate?: boolean) => {
  const ssoData: SSOData = { signInGoogle: true, signInPassword: true, signInSalesforce: true, samlUrls: {} };

  if (ssoObj.rfpio_lite === 'true') {
    ssoData.signInSalesforce = false;
  }

  if (!ssoObj.signin_google || ssoObj.signin_google === 'false') {
    ssoData.signInGoogle = false;
  }

  if (!ssoObj.signin_password || ssoObj.signin_password === 'false') {
    ssoData.signInPassword = false;
  }

  if (!ssoObj.signin_salesforce || ssoObj.signin_salesforce === 'false') {
    ssoData.signInSalesforce = false;
  }

  if (ssoObj?.samlUrls) {
    if (!isActivate) {
      const stateInfo: { [key: string]: any } = {};
      stateInfo.returnTo = getUrlParam('return_to');
      if (!stateInfo.returnTo) {
        stateInfo.returnTo = getUrlParam('target');
      }
      stateInfo?.returnTo && localStorage.setItem('samlReturnToObj', JSON.stringify(stateInfo));
    }
    ssoData.samlUrls = ssoObj.samlUrls;
  }
  return ssoData;
};

export const setReturnTo = (returnUrl: string) => {
  returnTo = returnUrl;
};

export const getReturnTo = () => {
  return returnTo;
};

export const checkValidUser = () => {
  if (window.location.pathname?.startsWith('/v2/vendor-registration')) {
    return true;
  }
  const isAskPWA = localStorage.getItem('v2_redirect_url_ask')?.includes('return_to_ask') && window.matchMedia('(display-mode: standalone)')?.matches;
  let userData = isAskPWA ? localStorage.getItem('userDataAsk') : localStorage.getItem('userData');
  if (userData) {
    userData = JSON.parse(userData);
  }
  return userData?.access_token && checkLoginPath();
};

/*In checkLoginPath method, the currentPath should not match any of the skipLoginUrls
when this is sucessfull it will move to ResponsiveBase else LoginBase will be loaded.
*/
const checkLoginPath = () => {
  const currentPath: string = window.location.pathname;
  if (currentPath.startsWith('/v2/login')) {
    return !(Boolean(getUrlParam('strictLogin')) || Boolean(getUrlParam('closeAfterLogin')));
  }
  return skipLoginUrls.every((element: string) => !currentPath.startsWith(element));
};
