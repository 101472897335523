import { useSnackbar, WithSnackbarProps, OptionsObject, SnackbarMessage, SnackbarKey } from 'notistack';

let useSnackbarRef: WithSnackbarProps;

export const SnackbarUtilsConfigurator = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

interface Options extends OptionsObject {
  showMultipleToast?: boolean;
}
export default {
  success(msg: SnackbarMessage, options: Options = {}) {
    this.toast(msg, { ...options, variant: 'success' });
  },
  warning(msg: SnackbarMessage, options: Options = {}) {
    this.toast(msg, { ...options, variant: 'warning' });
  },
  info(msg: SnackbarMessage, options: Options = {}) {
    this.toast(msg, { ...options, variant: 'info' });
  },
  error(msg: SnackbarMessage, options: Options = {}) {
    this.toast(msg, { ...options, variant: 'error' });
  },
  toast(msg: SnackbarMessage, options: Options = {}) {
    if (!options.showMultipleToast) {
      this.close();
    }
    useSnackbarRef.enqueueSnackbar(msg, options);
  },
  close(key?: SnackbarKey) {
    useSnackbarRef.closeSnackbar(key);
  }
};
