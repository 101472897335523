import { lazyLoadWithRetry } from '@rfp-components/RfpLazyWithRetry';
import RouteProps from '../RouteProps';
import GuestLayout from '@layouts/guestLayout';

const CustomDomainPageInitiator = lazyLoadWithRetry(() => import('@components/custom-domain/CustomDomainPageInitiator'), 'CustomDomainPageInitiator');
const PublicProfilePageInitiator = lazyLoadWithRetry(() => import('@containers/profile/public-profile/PublicProfilePageInitiator'), 'PublicProfilePageInitiator');

const profileGuestRoutes = [
  {
    path: '/profile-center/:profileId/:companyId/preview-content/:guestKey?',
    component: PublicProfilePageInitiator,
    needsAuth: false,
    title: 'Profile Center - Guest Public View',
    layout: GuestLayout,
    metricsPageName: 'Profile Center - Guest Page',
    moduleName: 'profile',
    menuModule: 'PROFILE',
    viewType: 'profile'
  },
  {
    path: '/profile-center/:profileId/:companyId/search/:guestKey?',
    component: PublicProfilePageInitiator,
    needsAuth: false,
    title: 'Profile Center - Guest Search',
    layout: GuestLayout,
    metricsPageName: 'Profile Center - Guest Search',
    moduleName: 'profile',
    menuModule: 'PROFILE',
    viewType: 'profile'
  }
];

export const CustomDomainRoutes: RouteProps[] = [
  {
    path: '',
    component: CustomDomainPageInitiator,
    needsAuth: false,
    title: 'Responsive',
    layout: GuestLayout
  }
].concat(profileGuestRoutes);
