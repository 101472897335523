import { action } from 'easy-peasy';
import ApplicationFrameWorkStore from '@interfaces/header-store/ApplicationFrameWorkStore';
import { IBreadCrumb, breadCrumbMap } from '@components/header-wrapper/HeaderWrapperUtils';
import { setUnsetListItem } from '@utils/GeneralUtils';

const ApplicationFrameWorkStoreImpl: ApplicationFrameWorkStore = {
  navExpanded: JSON.parse(localStorage.getItem('hamBurgerExpandedModules') || '[]') || [],
  showPinnedIconInHamburger: true,
  actionLevelTriggered: '',
  pageTitle: { title: '', skipGoBack: false },
  breadCrumbMap: {
    levelOneBreadCrumb: { breadCrumbTitle: '', isLink: false }
  },
  notificationCount: 0,
  setShowPinnedIconInHamburger: action((state, entry) => {
    state.showPinnedIconInHamburger = entry;
  }),
  setNavExpanded: action((state, entry) => {
    setUnsetListItem(state.navExpanded, entry);
    localStorage.setItem('hamBurgerExpandedModules', JSON.stringify(state.navExpanded));
  }),
  setPageTitle: action((state, entry) => {
    if (typeof entry === 'string') {
      state.pageTitle.title = entry;
      state.pageTitle.skipGoBack = false;
    } else {
      state.pageTitle = entry;
    }
  }),
  setActionLevelTriggered: action((state, entry) => {
    state.actionLevelTriggered = entry;
  }),
  setBreadCrumbMap: action((state, entry) => {
    //update for each individual breadcrumb modification using modelVal
    if (entry.hasOwnProperty('modelVal')) {
      const { modelVal, value } = entry as {
        modelVal: keyof breadCrumbMap;
        value: IBreadCrumb;
      };
      state.breadCrumbMap[modelVal as keyof breadCrumbMap] = value;
    } else {
      state.breadCrumbMap = entry as breadCrumbMap;
    }
  }),
  setNotificationCount: action((state, entry) => {
    state.notificationCount = entry;
  }),
  changeNotificationCount: action((state, entry) => {
    if (entry) {
      if (state.notificationCount > 0) {
        state.notificationCount = state.notificationCount - 1;
      }
    } else {
      state.notificationCount = 0;
    }
  })
};

export default ApplicationFrameWorkStoreImpl;
