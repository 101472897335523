export const setUrlParam = (key: string, value: string) => {
  setUrlParams({ [key]: value });
};

export const setUrlParams = (params: { [key: string]: string }) => {
  if (window.history.pushState) {
    let searchParams = new URLSearchParams(window.location.search);
    Object.keys(params).forEach((key: string) => {
      searchParams.set(key, params[key]);
    });
    let newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + searchParams.toString();
    window.history.pushState({ path: newurl }, '', newurl);
  }
};

export const getUrlParam = (key: string) => {
  let searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(key);
};

export const getUrlParamList = (key: string) => {
  let searchParams = new URLSearchParams(window.location.search);
  return searchParams.getAll(key);
};

export const removeUrlParam = (key: string) => {
  if (window.history.replaceState) {
    let searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(key);
    let newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + searchParams.toString();
    window.history.replaceState({ path: newurl }, '', newurl);
  }
};

export const isValidURL = (str: string) => {
  // if str is empty return false
  if (!str) {
    return false;
  }

  // Regex to check valid Domain Name
  const urlRegex = new RegExp(/^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(:[0-9]+)?(\/[^?#]*)?(\?[^#]*)?(#.*)?$/);

  // Return true if the str matched the ReGex
  return urlRegex.test(str);
};
